import * as React from 'react';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {
  ChartSection,
  InstrumentActionsSection,
  NonWorkingHoursSection,
  OverviewInstrumentSection,
  PromoSection,
  TradingViewChartSection,
} from '../sections';
import {MarketStatsCell, AboutInstrumentCell} from '../cells';

export type InstrumentHODLSurfaceProps = {
  instrumentId: string;
};

export const InstrumentHODLSurface = ({
  instrumentId,
}: InstrumentHODLSurfaceProps) => {
  const {getInstrumentById} = HODLsTariffsFeature.use({});
  const {baseTicker, quoteTicker, isCategoryRollovers} =
    getInstrumentById(instrumentId)!;

  const [isTradingViewChartOpen, setIsTradingViewChartOpen] =
    React.useState(false);

  const handleTradingViewChartOpen = React.useCallback(() => {
    setIsTradingViewChartOpen(true);
  }, []);

  const handleTradingViewChartClose = React.useCallback(() => {
    setIsTradingViewChartOpen(false);
  }, []);

  return (
    <>
      <OverviewInstrumentSection instrumentId={instrumentId} />

      {!isTradingViewChartOpen && (
        <ChartSection
          instrumentId={instrumentId}
          onPressTradingViewModeButton={handleTradingViewChartOpen}
        />
      )}
      {isTradingViewChartOpen && (
        <TradingViewChartSection
          instrumentId={instrumentId}
          onClose={handleTradingViewChartClose}
        />
      )}
      {isCategoryRollovers && (
        <PromoSection
          baseTicker={baseTicker}
          quoteTicker={quoteTicker}
          mx={-1}
        />
      )}
      <MarketStatsCell baseTicker={baseTicker} quoteTicker={quoteTicker} />
      <AboutInstrumentCell baseTicker={baseTicker} quoteTicker={quoteTicker} />
      <NonWorkingHoursSection instrumentId={instrumentId} />
      <InstrumentActionsSection
        instrumentId={instrumentId}
        backgroundColor="$ui-background"
        borderBottomLeftRadius={8}
        borderBottomRightRadius={8}
        position="sticky"
        bottom={0}
      />
    </>
  );
};

export const InstrumentHODLSurfaceContent: React.FC<
  InstrumentHODLSurfaceProps
> = () => {
  throw new Error('Not implemented');
};

export const InstrumentHODLSurfaceHeader: React.FC<
  InstrumentHODLSurfaceProps
> = () => {
  throw new Error('Not implemented');
};
