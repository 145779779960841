import * as React from 'react';
import {Button} from '@youtoken/ui.buttons';

type ButtonProps = React.ComponentProps<typeof Button>;

const variants: Record<string, ButtonProps> = {
  full: {
    size: 'small',
  },
  short: {
    width: 32,
    height: 32,
  },
};

export const getActionButtonPropsByVariant = (
  variant?: string
): ButtonProps => {
  const customProps = variant ? variants[variant] : {};

  return {
    type: 'secondary',
    ...customProps,
  };
};
