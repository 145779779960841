import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const SuccessContourIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg width={size} height={size} viewBox="0 0 20 20" fill="none"  {...props}>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.8 2.6a.6.6 0 00.6-.6.6.6 0 00.6.6.6.6 0 00-.6.6.6.6 0 00-.6-.6zM16 2.2a1.4 1.4 0 01-1.4 1.4A1.4 1.4 0 0116 5a1.4 1.4 0 011.4-1.4A1.4 1.4 0 0116 2.2zM1.4 9.6A1.4 1.4 0 010 11a1.4 1.4 0 011.4 1.4A1.4 1.4 0 012.8 11a1.4 1.4 0 01-1.4-1.4zM17 15.2a1 1 0 01-1 1 1 1 0 011 1 1 1 0 011-1 1 1 0 01-1-1zm-.6-5.2a6.4 6.4 0 11-12.8 0 6.4 6.4 0 0112.8 0zM9.742 2.604a7.4 7.4 0 013.151.585l.078-.184a7.6 7.6 0 00-10.377 5.29l.194.046a7.4 7.4 0 016.954-5.736zm7.629 6.74a7.4 7.4 0 00-.815-2.776l.177-.093a7.598 7.598 0 01-6.087 11.098 7.6 7.6 0 01-7.404-4.097l.177-.091a7.4 7.4 0 0013.952-4.04zm-7.888 2.939l4-4-.566-.566L9.2 11.434 7.283 9.517l-.566.566 2.2 2.2.283.283.283-.283z"
          fill={color}
        />
      </Svg>
    );
  }
);
