import * as React from 'react';
import {Platform, Share} from 'react-native';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation, i18n} from '@youtoken/ui.service-i18n';
import {SUPPORT} from '@youtoken/ui.support';

const isNative = Platform.select({
  native: true,
  web: false,
});

const handleIntercom = () => {
  SUPPORT.openSupportChat();
};

const handleShare = () => {
  if (isNative) {
    Share.share({
      message: i18n.t('surface.more.heading.share.message'),
    });
  } else {
    // @ts-ignore Marina says canShare could be undefined in some cases
    if (navigator?.canShare) {
      const shareData = {
        text: i18n.t('surface.more.heading.share.message'),
        title: 'YouHodler',
        url: 'https://app.youhodler.com',
      };

      navigator.share(shareData);
    }
  }
};

export const TopButtons: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  return (
    <Box flexDirection="row" justifyContent="space-between" {...boxProps}>
      {/* @ts-ignore same */}
      {(isNative || navigator?.canShare) && (
        <Box flexGrow={1} flexBasis={0} mr={12}>
          <Button
            icon="share"
            type="secondary"
            size={isNative ? 'small' : 'medium'}
            onPress={handleShare}
          >
            {t('surface.profile.heading.share_button')}
          </Button>
        </Box>
      )}
      <Box flexGrow={1} flexBasis={0}>
        <Button
          icon="message"
          type="secondary"
          size={isNative ? 'small' : 'medium'}
          onPress={handleIntercom}
        >
          {t('surface.profile.heading.feedback_button')}
        </Button>
      </Box>
    </Box>
  );
});
