import * as React from 'react';
import {observer} from 'mobx-react';
import {
  Box,
  Text,
  type BoxProps,
  type TColorTokens,
} from '@youtoken/ui.primitives';
import {MinerOverviewResource} from '@youtoken/ui.resources-miner';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {GetMoreSparksModal} from './GetMoreSparksModal';
import {ConfirmationModal} from './ConfirmationModal';

interface NextActionProps {
  openGetMoreSparksModal: (miningPrice: number) => void;
}

const TEXT_COLORS: {[key: string]: keyof TColorTokens} = {
  TAP_TO_COLLECT: '$attention-01',
  TAP_TO_MINE: '$attention-01',
  WAIT_FOR_MINE: '$success-01',
  GET_SPARKS_TO_MINE: '$text-01',
  GET_SPARKS_TO_UNBLOCK: '$text-01',
  UNBLOCK_BLOCKS: '$attention-01',
};

export const NextAction: React.FC<NextActionProps & BoxProps> = observer(
  ({openGetMoreSparksModal, ...boxProps}) => {
    const {t} = useTranslation();
    const {
      resetCost,
      amountToUnlock,
      finalSparkBalance,
      data: {proposedAction},
    } = MinerOverviewResource.use({});

    const handleOpenModal = React.useCallback(() => {
      openGetMoreSparksModal(0);
    }, [openGetMoreSparksModal]);

    const [isMoreSparksModalOpen, setMoreSparksModalOpen] =
      React.useState(false);
    const [isConfirmationModalOpen, setConfirmationModalOpen] =
      React.useState(false);

    const openMoreSparksModal = React.useCallback(() => {
      setMoreSparksModalOpen(true);
    }, [setMoreSparksModalOpen]);

    const onMoreSparksModalClose = React.useCallback(() => {
      setMoreSparksModalOpen(false);
    }, [setMoreSparksModalOpen]);

    const openConfirmationModal = React.useCallback(() => {
      DATA_LAYER.trackStrict('miner-board-unlock-preview', {
        category: 'miner',
        type: 'board-unlock',
      });
      setConfirmationModalOpen(true);
    }, [setConfirmationModalOpen]);

    const onConfirmationModalClose = React.useCallback(() => {
      setConfirmationModalOpen(false);
    }, [setConfirmationModalOpen]);

    const handlePressUnlockBlocks = React.useCallback(() => {
      if (finalSparkBalance < resetCost) {
        openMoreSparksModal();
      } else {
        openConfirmationModal();
      }
    }, [
      finalSparkBalance,
      resetCost,
      openMoreSparksModal,
      openConfirmationModal,
    ]);

    return (
      <>
        <Box alignItems="center" alignSelf="stretch" px={20} {...boxProps}>
          <Text
            variant="$heading-02"
            color={TEXT_COLORS[proposedAction]}
            textAlign="center"
            testID="MINER_NEXT_ACTION"
          >
            {t(`surface.miner.action.${proposedAction}`, {
              amount: amountToUnlock,
            })}
          </Text>
          {proposedAction === 'UNBLOCK_BLOCKS' && (
            <Button
              icon="spark"
              mt={12}
              type="primary"
              color="attention"
              onPress={handlePressUnlockBlocks}
              px={24}
              alignSelf={{default: 'stretch', tablet: 'center'}}
              testID="MINER_UNBLOCK_BLOCKS_BUTTON"
            >
              {t('surface.miner.unblock_blocks.action', {amount: resetCost})}
            </Button>
          )}
          {['GET_SPARKS_TO_UNBLOCK', 'GET_SPARKS_TO_MINE'].includes(
            proposedAction
          ) && (
            <Button
              icon="spark"
              mt={12}
              type="primary"
              onPress={handleOpenModal}
              px={24}
              alignSelf={{default: 'stretch', tablet: 'center'}}
              testID="MINER_GET_SPARKS_BUTTON"
            >
              {t('surface.upgrade_modal.sparks.title')}
            </Button>
          )}
        </Box>

        <GetMoreSparksModal
          title={t('surface.upgrade_modal.sparks.title')}
          isOpen={isMoreSparksModalOpen}
          onClose={onMoreSparksModalClose}
          noticeText={t('surface.miner.unlock_blocks.need_more_sparks', {
            amount: resetCost - finalSparkBalance,
          })}
        />

        <ConfirmationModal
          title={t('surface.miner.unlock_blocks_confirmation.title')}
          isOpen={isConfirmationModalOpen}
          onClose={onConfirmationModalClose}
        />
      </>
    );
  }
);
