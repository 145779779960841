import * as React from 'react';
import {Helmet} from 'react-helmet';
import {observer} from 'mobx-react';
import {ManagePaymentMethodsSurface} from '@youtoken/ui.surfaces-wallets';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {WebPageWrapper} from '@youtoken/ui.screen-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {BorderBoxWrapper} from '@youtoken/ui.screen-elements';
import {goBack} from '../../../../services/shared-router';
import {InnerPageShell} from '../../../layout/Shell';

const handlePressAddCard = () => {
  SHARED_ROUTER_SERVICE.navigate('AddPaymentMethod');
};

export const PaymentMethodsPage: React.FC = observer(() => {
  const {t} = useTranslation();

  const {checkProductAvailability} = AuthMeResource.use({});

  React.useEffect(() => {
    if (!checkProductAvailability('depositFiatCheckoutBankCardFrame')) {
      SHARED_ROUTER_SERVICE.navigate('More', {});
    }
  }, [checkProductAvailability]);

  return (
    <InnerPageShell>
      <Helmet title={t('surface.wallets.payment_methods')} />
      <WebPageWrapper
        hasBackButton
        onBackPress={goBack}
        windowTitle={t('surface.wallets.payment_methods')}
        title={t('surface.wallets.payment_methods')}
      >
        <BorderBoxWrapper>
          <ManagePaymentMethodsSurface onAddCard={handlePressAddCard} />
        </BorderBoxWrapper>
      </WebPageWrapper>
    </InnerPageShell>
  );
});
