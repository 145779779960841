import * as React from 'react';
import {
  type NativeSyntheticEvent,
  Platform,
  type TextInputFocusEventData,
} from 'react-native';
import {InputLikeWrapper} from '../InputLikeWrapper';
import {createInput} from './createInput';
import {useInputStyleProps} from './styles';
import {type TextInputProps} from './types';
import {useActiveThemeType} from '@youtoken/ui.primitives';
import {TextInputComponentContext} from './TextInputComponentContext';

export type {TextInputProps};
export {TextInputComponentContext} from './TextInputComponentContext';

const isIOS = Platform.OS === 'ios';

export const TextInput = React.forwardRef<
  {
    blur: () => void;
  },
  TextInputProps
>(
  (
    {
      UnderlayInputElement,
      setCaretPositionToEndOnFocus,
      onFocus,
      onBlur,
      focused: forceFocused,
      variant = 'default',
      ...props
    },
    ref
  ) => {
    const textInputComponent = React.useContext(TextInputComponentContext);
    const TextInputComponent = React.useMemo(
      () => createInput(textInputComponent),
      [textInputComponent]
    );

    const themeType = useActiveThemeType();
    const [focused, setFocused] = React.useState(false);

    const handleFocus = (
      event: NativeSyntheticEvent<TextInputFocusEventData>
    ) => {
      if (setCaretPositionToEndOnFocus && isIOS) {
        const positionEnd = props.value?.length ?? 0;

        // @ts-ignore why?? target is a number??
        event.target.setNativeProps({
          selection: {
            start: positionEnd,
            end: positionEnd,
          },
        });
      }

      setFocused(true);
      onFocus?.(event);
    };

    const handleBlur = React.useCallback(
      (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        setFocused(false);
        onBlur?.(event);
      },
      [onBlur]
    );

    const styleProps = useInputStyleProps({
      ...props,
      focused: focused || forceFocused,
      variant,
    });

    const inputVerticalPadding = props.multiline
      ? getPaddingVerticalForMultiline(props.size)
      : 0;

    return (
      <InputLikeWrapper
        LeftPartComponent={props.LeftPartComponent}
        RightPartComponent={props.RightPartComponent}
        hasError={props.hasError}
        disabled={props.disabled}
        focused={focused || forceFocused}
        size={props.size}
        variant={variant}
      >
        <TextInputComponent
          onFocus={handleFocus}
          onBlur={handleBlur}
          height="100%"
          zIndex={2} // NOTE: fix to choose input, not BackgroundInputElement by click
          focusable
          accessible
          accessibilityState={{
            disabled: props.disabled,
          }}
          editable={!props.disabled}
          // NOTE: Specific fix for identical logic ios and android (paddingVertical doesn't work on ios)
          pt={inputVerticalPadding} // IMPORTANT pt not py
          pb={inputVerticalPadding} // IMPORTANT pb not py
          verticalAlign={props.multiline ? 'top' : 'middle'}
          textAlign="left"
          keyboardAppearance={themeType}
          {...styleProps}
          {...props}
          ref={ref}
        />
        {UnderlayInputElement && (
          <>
            {typeof UnderlayInputElement === 'function' && (
              <UnderlayInputElement
                hasError={props.hasError}
                disabled={props.disabled}
                focused={focused}
              />
            )}
            {React.isValidElement(UnderlayInputElement) && UnderlayInputElement}
          </>
        )}
      </InputLikeWrapper>
    );
  }
);

const sizePaddingVerticalMultiline: Record<
  Exclude<TextInputProps['size'], undefined>,
  number
> = {
  small: 0,
  medium: 3, // height(38) - (lineHeight(16) * 2) = py(3)
  large: 7, // height(46) - (lineHeight(16) * 2) = py(7)
  textarea: 16, // height(162) - (lineHeight(16) * 8) - 2 (to prevent scrollbar) = py(16)
};

const getPaddingVerticalForMultiline = (size: TextInputProps['size']) => {
  return (size && sizePaddingVerticalMultiline[size]) ?? 0;
};
