import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {HowItWorksBanner as HowItWorksBannerComponent} from '../components';

export const HowItWorksBanner: React.FC<BoxProps> = boxProps => {
  return (
    <Box
      px={{default: 20, tablet: 24}}
      py={{default: 16, tablet: 20}}
      borderWidth={1}
      borderColor="$ui-03"
      borderRadius={16}
      {...boxProps}
    >
      <HowItWorksBannerComponent />
    </Box>
  );
};
