import * as React from 'react';
import {observer} from 'mobx-react';
import {FriendRewardsSurfaceCell} from '@youtoken/ui.surface-new-referral';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {WebPageWrapper} from '@youtoken/ui.screen-elements';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';
import {NewReferralActivityResource} from '@youtoken/ui.resource-new-referral';

export const FriendRewardsPage: React.FC = observer(() => {
  const {queryParams} = RouterResource.use({});

  const {t} = useTranslation();

  const {activities} = NewReferralActivityResource.use({
    inviteeId: queryParams.id,
  });

  React.useEffect(() => {
    if (!queryParams.id) {
      SHARED_ROUTER_SERVICE.navigate('MyFriends');
    }
  }, [queryParams.id]);

  return (
    <InnerPageShell>
      <WebPageWrapper
        hasBackButton
        onBackPress={() => SHARED_ROUTER_SERVICE.goBack()}
        title={activities[0]?.inviteeName}
        windowTitle={t('surface.new_referral.invite_friends.title')}
      >
        <FriendRewardsSurfaceCell
          px={24}
          py={32}
          borderWidth={1}
          borderColor="$ui-01"
          borderRadius={10}
          friendId={queryParams.id}
        />
      </WebPageWrapper>
    </InnerPageShell>
  );
});
