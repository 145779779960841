import * as React from 'react';
import {observer} from 'mobx-react';
import {
  HODLsTariffsFeature,
  type TInstrumentsList,
} from '@youtoken/ui.resource-hodl-tariffs';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {Button} from '@youtoken/ui.buttons';
import {Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

type FavoritesFilterProps = {
  list?: TInstrumentsList;
};

export const FavoritesFilter: React.FC<FavoritesFilterProps> = observer(
  ({list}) => {
    const {t} = useTranslation();
    const {starredFilterIsActive, setStarredFilter} = HODLsTariffsFeature.use(
      {}
    );

    const handleToggleFilter = React.useCallback(() => {
      DATA_LAYER.trackStrict('hodl-instruments-filtering', {
        category: 'favorites',
      });
      setStarredFilter(!starredFilterIsActive);
    }, [starredFilterIsActive]);

    return (
      <Button
        size="small"
        type={starredFilterIsActive ? 'primary' : 'secondary'}
        icon={starredFilterIsActive ? 'star_filled' : 'star'}
        onPress={handleToggleFilter}
        testID="ALL_INSTRUMENTS_STARRED_FILTER_BUTTON"
      >
        <Text>{t('surface.hodls.instruments.favorites_button')}</Text>
      </Button>
    );
  }
);
