import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AgreementsList} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {Box} from '@youtoken/ui.primitives';

interface FooterSectionProps {
  onCLoseButtonPress: () => void;
  isClosing: boolean;
  amountFormatted?: string;
  tickerFormatted: string;
  isApproximate?: boolean;
}

export const FooterSection: React.FC<FooterSectionProps> = observer(
  ({
    onCLoseButtonPress,
    isClosing,
    amountFormatted,
    tickerFormatted,
    isApproximate,
  }) => {
    const {t} = useTranslation();

    if (amountFormatted === 'undefined') {
      return null;
    }

    return (
      <Button
        m={24}
        mt={0}
        size="large"
        onPress={onCLoseButtonPress}
        disabled={isClosing}
        testID="CLOSE_AND_GET_BUTTON"
      >
        {`${t('surface.hodls.close_now.action_button')} ${
          isApproximate ? '≈' : ''
        } ${amountFormatted} ${tickerFormatted}`}
      </Button>
    );
  }
);
