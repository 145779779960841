import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {getCoinSymbol, type currencyName} from '@youtoken/ui.coin-utils';
import {priceFormatterInThousands} from '@youtoken/ui.formatting-utils';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {type LoyaltyIconName} from '@youtoken/ui.icons';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {SavingsProgressBar} from '@youtoken/ui.progress-bar';
import {
  getLevelColor,
  getLevelIconName,
} from '@youtoken/ui.loyalty-miner-utils';
import {LevelIcon} from '@youtoken/ui.loyalty-miner-components';
import {SavingsProgressWithLevelsMarkIcon} from './MarkIcon';
import {SavingsProgressWithLevelsMark} from './Mark';
import {SavingsProgressWithLevelsMarkLabel} from './MarkLabel';

export interface SavingsProgressWithLevelsProps {
  ticker: currencyName;
  amount: number;
  limit: number;
  limitNext?: number;
  level: number;
  levelNext?: number;
}

export const SavingsProgressWithLevels: React.FC<
  SavingsProgressWithLevelsProps & BoxProps
> = observer(
  ({ticker, amount, limit, limitNext, level, levelNext, ...boxProps}) => {
    const {t} = useTranslation();

    const hasNext = Boolean(levelNext && limitNext);

    const multiplier = hasNext ? 0.75 : 1;

    const valueSource = amount / limit;
    const valueTarget = Math.min(valueSource, 1) * multiplier;

    const levelColor = getLevelColor(level);
    const levelIconName = getLevelIconName(level);
    const levelWidth = multiplier * 100 + '%';

    // NOTE: some value handled with ! because there is conditional render below
    const levelNextIconName = hasNext && getLevelIconName(levelNext!);
    const levelNextWidth = (1 - multiplier) * 100 + '%';

    const tickerSymbol = getCoinSymbol(ticker);

    const amountFormatted = tickerSymbol + priceFormatterInThousands(amount);

    const limitZeroFormatted = tickerSymbol! + 0;
    const limitFormatted = tickerSymbol + priceFormatterInThousands(limit);
    const limitNextFormatted =
      tickerSymbol + priceFormatterInThousands(limitNext!);

    return (
      <Box {...boxProps}>
        <Box
          testID="SAVINGS_PROGRESS_WITH_LEVELS"
          alignItems="center"
          width="100%"
          px={32}
          py={16}
          mt={16}
          backgroundColor={levelColor}
          borderRadius={12}
        >
          <LevelIcon
            name={levelIconName as LoyaltyIconName}
            bg={levelColor}
            borderColor="$ui-background"
            position="absolute"
            top={-28}
          />
          <Box width="100%">
            <Box flexDirection="row" width="100%">
              <Box
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width={levelWidth}
              >
                <Box
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  mb={12}
                  ml={-2}
                >
                  <Text
                    testID="SAVINGS_PROGRESS_WITH_LEVELS_YIELD_LIMIT"
                    variant="$body-01-high-accent"
                    color="$text-01"
                    mr={4}
                  >
                    {t('surface.savings.yield_limit')}
                  </Text>
                  <QuestionTooltip
                    content={t('surface.savings.yield_limit_hint')}
                  />
                </Box>
                <Box alignItems="flex-end" flex={1}>
                  <SavingsProgressWithLevelsMarkIcon
                    name={levelIconName!}
                    borderColor="$ui-background"
                  />
                  <SavingsProgressWithLevelsMark mr={-0.5} mt={1} />
                </Box>
              </Box>
              {hasNext && (
                <Box alignItems="flex-end" width={levelNextWidth}>
                  <SavingsProgressWithLevelsMarkIcon
                    name={levelNextIconName as LoyaltyIconName}
                  />
                  <SavingsProgressWithLevelsMark mr={-0.5} mt={1} />
                </Box>
              )}
            </Box>
            <SavingsProgressBar
              testID="SAVINGS_PROGRESS_BAR"
              amountTestID="SAVINGS_PROGRESS_BAR_VALUE"
              value={valueTarget}
              valueText={amountFormatted}
              color={valueSource > 1 ? 'red' : 'blue'}
              backgroundColor="$ui-background"
            />
            <Box flexDirection="row" width="100%">
              <Box
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-start"
                width={levelWidth}
              >
                <Box
                  testID="SAVINGS_PROGRESS_WITH_LEVELS_LIMIT_ZERO"
                  alignItems="flex-start"
                >
                  <SavingsProgressWithLevelsMark ml={-0.5} />
                  <SavingsProgressWithLevelsMarkLabel
                    title={limitZeroFormatted}
                    ml={-2}
                  />
                </Box>
                <Box
                  testID="SAVINGS_PROGRESS_WITH_LEVELS_LIMIT_CURRENT"
                  alignItems="flex-end"
                  flex={1}
                >
                  <SavingsProgressWithLevelsMark mr={-0.5} />
                  <SavingsProgressWithLevelsMarkLabel
                    title={
                      <Text
                        testID="SAVINGS_PROGRESS_WITH_LEVELS_MARK_LABEL_TITLE"
                        variant="$body-02-medium-accent"
                        color="$text-01"
                      >
                        {limitFormatted}
                      </Text>
                    }
                    description={t(
                      'surface.savings.progress_bar_with_levels.current_level'
                    )}
                    descriptionProps={{
                      textAlign: 'right',
                    }}
                    alignItems="flex-end"
                    ml={4}
                    mr={-2}
                  />
                </Box>
              </Box>
              {hasNext && (
                <Box
                  testID="SAVINGS_PROGRESS_WITH_LEVELS_LIMIT_NEXT"
                  alignItems="flex-end"
                  width={levelNextWidth}
                >
                  <SavingsProgressWithLevelsMark mr={-0.5} />
                  <SavingsProgressWithLevelsMarkLabel
                    title={limitNextFormatted}
                    description={t(
                      'surface.savings.progress_bar_with_levels.next_level'
                    )}
                    descriptionProps={{
                      textAlign: 'right',
                    }}
                    alignItems="flex-end"
                    ml={4}
                    mr={-2}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);
