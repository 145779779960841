export enum Category {
  NEW = 'new',
  POPULAR = 'popular',
  ROLLOVERS = 'rollovers',
}

export type CategoryFilter =
  | Category.NEW
  | Category.POPULAR
  | Category.ROLLOVERS
  | 'all';

export interface SortingItem {
  value: string;
  label: string;
  type: string;
  direction: 'asc' | 'desc';
}
