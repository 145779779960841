import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {HODLsInstrumentsSectorsFeature} from '../../features/HODLsInstrumentsSectorsFeature';
import {SectorsDescriptionModal} from '../../modals/SectorsDescriptionModal';
import {SectorsModal} from '../../modals/SectorsModal';
import {SectorsCard} from './components';
import {ErrorFallback} from '@youtoken/ui.error-boundary';
import {ScrollableArea} from '@youtoken/ui.elements';
import {cardsData} from './data';

export const SectorsSection: React.FC<BoxProps> = cell(
  ({...boxProps}) => {
    const {
      shouldShowHodlsSectors,
      hodlsSectorsData,
      setChosenSector,
      removeChosenSector,
    } = HODLsInstrumentsSectorsFeature.use({});
    const {t} = useTranslation();

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isAboutModalOpen, setIsAboutModalOpen] = React.useState(false);

    const handleModalClose = React.useCallback(() => {
      removeChosenSector();
      setIsAboutModalOpen(false);
      setIsModalOpen(false);
    }, [removeChosenSector]);

    const handleAboutModalClose = React.useCallback(() => {
      setIsAboutModalOpen(false);
    }, []);

    const handleAboutModalOpen = React.useCallback(() => {
      setIsAboutModalOpen(true);
    }, []);

    const handleCardPress = React.useCallback(
      (sector: string) => {
        setChosenSector(sector);
        setIsModalOpen(true);
      },
      [setChosenSector]
    );

    const renderItem = React.useCallback(
      ({
        item,
        index,
      }: {
        item: {name: string; count: number};
        index: number;
        isParentListExpanded?: boolean;
      }) => {
        const {name, count} = item;
        return (
          <Box mx={4} width={155} key={index}>
            <SectorsCard
              {...cardsData[name]!}
              itemCount={count}
              itemName={name}
              onPress={handleCardPress}
              key={name}
            />
          </Box>
        );
      },
      [handleCardPress]
    );

    if (!shouldShowHodlsSectors) {
      return null;
    }

    return (
      <>
        <Box {...boxProps}>
          <ScrollableArea
            title={t('surface.hodls.portfolio.sectors_title_short')}
            items={hodlsSectorsData}
            renderItem={renderItem}
            mobileEstimatedListHeight={64}
            isExpandRestricted
          />
        </Box>
        <SectorsModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          onHelpIconPress={handleAboutModalOpen}
        />
        <SectorsDescriptionModal
          isOpen={isAboutModalOpen}
          onClose={handleAboutModalClose}
        />
      </>
    );
  },
  {
    ErrorFallback: ({...props}) => (
      //   todo check height
      <Box width="100%" height={116}>
        <ErrorFallback {...props} />
      </Box>
    ),
  }
);
