import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Wallet} from '@youtoken/ui.resource-wallets';
import type {BaseItemOption} from '@youtoken/ui.select';
import {Icon} from '@youtoken/ui.icons';

export const useWalletActionsAdditionalProps = (
  item: Wallet
): [ReturnType<typeof useItems>, ReturnType<typeof useItemsAction>] => {
  const items = useItems(item);
  const action = useItemsAction(item);

  return [items, action];
};

const useItems = ({
  allowMoveToShown,
  allowMoveToHidden,
  allowRemove,
}: Wallet) => {
  const {t} = useTranslation();

  return React.useMemo(() => {
    let items: BaseItemOption[] = [];

    if (allowMoveToShown) {
      items.push({
        label: t('surface.wallets.item.show'),
        icon: <Icon name="show" />,
        value: 'show',
      });
    }

    if (allowMoveToHidden) {
      items.push({
        label: t('surface.wallets.item.hide'),
        icon: <Icon name="hide" />,
        value: 'hide',
      });
    }

    if (allowRemove) {
      items.push({
        label: t('surface.wallets.item.remove'),
        icon: <Icon name="remove" />,
        value: 'remove',
      });
    }

    return items;
  }, [t, allowMoveToShown, allowMoveToHidden, allowRemove]);
};

const useItemsAction = ({ticker}: Wallet) => {
  const {appendToShowedTickers, appendToHiddenTickers, removeFromAddedTickers} =
    AuthMeResource.use({});

  const handlePressShow = React.useCallback(() => {
    DATA_LAYER.trackStrict('wallet-show-attempt', {ticker});
    appendToShowedTickers(ticker);
  }, [ticker, appendToShowedTickers]);

  const handlePressHide = React.useCallback(() => {
    DATA_LAYER.trackStrict('wallet-hide-attempt', {ticker});
    appendToHiddenTickers(ticker);
  }, [ticker, appendToHiddenTickers]);

  const handlePressRemove = React.useCallback(() => {
    DATA_LAYER.trackStrict('wallet-remove-attempt', {ticker});
    removeFromAddedTickers(ticker);
  }, [ticker, removeFromAddedTickers]);

  return React.useCallback(
    (value: string) => {
      if (value === 'show') {
        return handlePressShow();
      }

      if (value === 'hide') {
        return handlePressHide();
      }

      if (value === 'remove') {
        return handlePressRemove();
      }
    },
    [handlePressShow, handlePressHide, handlePressRemove]
  );
};
