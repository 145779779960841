import * as React from 'react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {cell} from '@youtoken/ui.cell';
import {type BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {type ActionProps} from '../types';
import {getActionButtonPropsByVariant} from '../utils';
import {WalletActionCellWrap} from './WalletActionCellWrap';
import {WalletActionSuspenseFallback} from './WalletActionSuspenseFallback';
import {WalletActionErrorFallback} from './WalletActionErrorFallback';

export const Withdraw: React.FC<ActionProps & BoxProps> = cell(
  ({
    tooltip,
    variant,
    item: {isFiat, ticker, tickerFormatted, withdrawEnabled},
    ...boxProps
  }) => {
    const {products, checkProductAvailability} = AuthMeResource.use({});

    const isWithdrawalAvailable = React.useMemo(() => {
      const {available, isEnabled, requirements} =
        products[isFiat ? 'withdrawalFiat' : 'withdrawalCrypto'];

      // NOTE: Withdrawal button should be active to trigger validations from the requirements list even if product itself is not available yet
      if (requirements.length > 0 && isEnabled) {
        return true;
      }

      return available && isEnabled && !requirements.length;
    }, [isFiat, products]);

    const disabled = React.useMemo(() => {
      return !isWithdrawalAvailable || !withdrawEnabled;
    }, [isWithdrawalAvailable, withdrawEnabled]);

    const handlePressFiat = React.useCallback(() => {
      if (!checkProductAvailability('withdrawalFiat')) {
        return;
      }

      SHARED_ROUTER_SERVICE.navigate('FiatWithdrawal', {ticker});
    }, [ticker, checkProductAvailability]);

    const handlePressCrypto = React.useCallback(() => {
      if (!checkProductAvailability('withdrawalCrypto')) {
        return;
      }

      SHARED_ROUTER_SERVICE.navigate('CryptoWithdrawal', {ticker});
    }, [ticker, checkProductAvailability]);

    const handlePress = React.useCallback(() => {
      if (isFiat) {
        return handlePressFiat();
      }

      return handlePressCrypto();
    }, [isFiat, handlePressFiat, handlePressCrypto]);

    return (
      <Button
        testID={`${tickerFormatted}_WITHDRAW_BUTTON`}
        icon={isFiat ? 'fiat_withdraw' : 'send'}
        disabled={disabled}
        onPress={handlePress}
        {...getActionButtonPropsByVariant(variant)}
        {...boxProps}
      />
    );
  },
  {
    CellWrap: WalletActionCellWrap,
    SuspenseFallback: WalletActionSuspenseFallback,
    ErrorFallback: WalletActionErrorFallback,
  }
);
