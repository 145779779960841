import * as React from 'react';
import {observer} from 'mobx-react';
import {useResources, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {
  NotificationsResource,
  type Notification,
} from '@youtoken/ui.resource-notifications';
import {Box, type BoxProps, useIsDesktop} from '@youtoken/ui.primitives';
import {
  Slider,
  PromoBanner,
  type PromoBannerImageName,
} from '@youtoken/ui.elements';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

export const CarouselBanners: React.FC<BoxProps> = observer(boxProps => {
  const {
    notifications: {carousel, close},
  } = useResources({
    notifications: getResourceDescriptor(NotificationsResource, {}),
  });

  const isDesktop = useIsDesktop();

  const handlePressBanner = React.useCallback((url?: string) => {
    if (!url) {
      return;
    }
    const [name, params, query] = SHARED_ROUTER_SERVICE.urlToRoute(url);
    SHARED_ROUTER_SERVICE.navigate(name, params, query);
  }, []);

  const renderItem = React.useCallback(
    ({item}: {item: Notification}) => {
      const {
        notificationId,
        conditions: {canClose, url},
        texts: {title, body, button},
        design,
      } = item;

      return (
        <PromoBanner
          onPressBanner={() => handlePressBanner(url)}
          onPressClose={canClose ? () => close(notificationId) : undefined}
          showActionButton={Boolean(button)}
          text={body ?? ''}
          secondaryText={title}
          buttonText={button}
          imageName={design?.iconName as PromoBannerImageName}
          variant={
            design?.kind === 'secondary'
              ? 'interactiveSecondary'
              : 'interactive'
          }
          testID={`CAROUSEL_BANNER_${notificationId}`}
        />
      );
    },
    [handlePressBanner, close]
  );

  if (carousel.length === 0) {
    return null;
  }

  return (
    <Box testID="BANNERS_CAROUSEL" width="100%" minHeight={64} {...boxProps}>
      <Slider
        cardFullWidth={isDesktop}
        cardHeight={64}
        gap={isDesktop ? 0 : 20}
        data={carousel}
        renderItem={renderItem}
        paginationBoxProps={{pt: 8, pb: 0}}
      />
    </Box>
  );
});
