import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  type TouchableBoxProps,
  type BoxProps,
  Box,
  Text,
  TouchableBox,
} from '@youtoken/ui.primitives';
import {LoyaltyIcon} from '@youtoken/ui.icons';
import {useAccountLevel} from './hooks';

export const AccountLevelShort: React.FC<TouchableBoxProps> = cell(
  touchableBoxProps => {
    const {name, cardColor, cardIconName, handlePress} = useAccountLevel();

    return (
      <TouchableBox
        testID="ACCOUNT_LEVEL_SHORT"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        px={8}
        py={7}
        backgroundColor={cardColor}
        borderRadius={24}
        onPress={handlePress}
        {...touchableBoxProps}
      >
        <LoyaltyIcon name={cardIconName} size={20} />
        <Box mx={4}>
          <Text variant="$body-02-medium-accent" color="$text-01">
            {name}
          </Text>
        </Box>
      </TouchableBox>
    );
  },
  {
    CellWrap: ({children, ...boxProps}) => {
      return (
        <Box minHeight={28} {...(boxProps as BoxProps)}>
          {children}
        </Box>
      );
    },
    ErrorFallback: ({dismissError}) => {
      const {t} = useTranslation();

      return (
        <TouchableBox
          justifyContent="center"
          alignItems="center"
          px={8}
          py={4}
          bg="$danger-02"
          borderRadius={24}
          onPress={dismissError}
        >
          <Text variant="$body-02-medium-accent" color="$danger-01">
            {t('common.errors.smth_went_wrong')}
          </Text>
        </TouchableBox>
      );
    },
  }
);
