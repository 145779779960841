import {deserialize} from 'serializr';
import {computed, action} from 'mobx';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {CardItem} from './CardsResponse';

export {CardItem} from './CardsResponse';

export class CardsResource extends createResource<{}, CardItem[]>({
  getKey: () => `CardsResource`,
  getData: () => {
    return TRANSPORT.API.get('/v2/cards', {
      params: {
        provider: 'checkoutBankCard',
      },
    }).then(res => {
      return deserialize(CardItem, res.data as CardItem[]);
    });
  },
}) {
  @computed
  public get cardsList() {
    return this.data;
  }

  @action
  public setCardAsDefault = async (cardId: string) => {
    await TRANSPORT.API.post(`v2/cards/default`, {
      provider: 'checkoutBankCard',
      cardId,
    });
    await this.refetch();
  };
}
