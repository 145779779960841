import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {AmountButton} from './AmountButton';

interface PredefinedAmountButtonsProps extends BoxProps {
  amountList: string[];
  currentAmount: string;
  onPressAmount: (amount: string, index?: number) => void;
  buttonsSize?: 'large' | 'medium' | 'small' | 'tiny';
}

export const PredefinedAmountButtons: React.FC<PredefinedAmountButtonsProps> =
  observer(
    ({amountList, currentAmount, onPressAmount, buttonsSize, ...boxProps}) => {
      return (
        <Box flexDirection="row" flexGrow={1} {...boxProps}>
          {amountList.map((amount, index) => {
            return (
              <AmountButton
                key={amount}
                index={index}
                amount={amount}
                onPressAmount={onPressAmount}
                type={
                  Number(currentAmount) === Number(amount)
                    ? 'primary'
                    : 'secondary'
                }
                size={buttonsSize}
                mr={index === amountList.length - 1 ? 0 : 8}
                testID={`PREDEFINED_AMOUNT_BUTTON_${amount}`}
              />
            );
          })}
        </Box>
      );
    }
  );
