import {warning} from '@youtoken/ui.utils';
import {
  getLevelColor,
  getLevelIconName,
} from '@youtoken/ui.loyalty-miner-utils';
import {
  type NotificationPayloadLevel,
  type NotificationPayloadDesignKind,
  type NotificationPayloadDesignVariant,
  NotificationType,
  NotificationDesign,
} from '@youtoken/ui.resource-notifications';
import {type TColorTokens} from '@youtoken/ui.primitives';
import {type IButtonProps} from '@youtoken/ui.buttons';
import {type BaseIconName, type LoyaltyIconName} from '@youtoken/ui.icons';

interface Data {
  iconName?: BaseIconName | LoyaltyIconName;
  iconColor?: keyof TColorTokens;
  bodyBackground: keyof TColorTokens;
  bodyColor: keyof TColorTokens;
  buttonType: IButtonProps['type'];
  buttonColor: IButtonProps['color'];
  closeColor?: keyof TColorTokens;
}

export const getData = (
  type: NotificationType,
  payload: NotificationPayloadLevel,
  design: NotificationDesign
) => {
  const {level} = payload;

  if (type === NotificationType.LOYALTY) {
    return getLoyaltyData(level!);
  }

  return getBaseData(design!);
};

const getLoyaltyData = (level: number): Data => {
  return {
    iconName: getLevelIconName(level),
    bodyBackground: getLevelColor(level),
    bodyColor: '$text-01',
    buttonType: 'primary',
    buttonColor: 'interactive',
    closeColor: '$text-02',
  };
};

const getBaseData = (design: NotificationDesign): Data => {
  const {kind, variant, iconName} = design;

  return {
    iconName,
    iconColor: getIconColor(kind, variant),
    bodyBackground: getBodyBackground(kind, variant),
    bodyColor: getBodyColor(kind, variant),
    buttonType: getButtonType(kind),
    buttonColor: getButtonColor(kind, variant),
    closeColor: getCloseColor(kind, variant),
  };
};

const iconColors = {
  primary: {
    interactive: '$text-04',
    success: '$text-04',
    attention: '$text-04',
    danger: '$text-04',
  },
  secondary: {
    interactive: '$interactive-01',
    success: '$success-01',
    attention: '$attention-01',
    danger: '$danger-01',
  },
} as const;

export const getIconColor = (
  kind: NotificationPayloadDesignKind,
  variant: NotificationPayloadDesignVariant
) => {
  const value = iconColors[kind]?.[variant];

  warning(value, `getIconColor: Invalid kind or variant`, {
    kind,
    variant,
  });

  return value;
};

const bodyColors = {
  primary: '$text-04',
  secondary: '$text-01',
} as const;

export const getBodyColor = (
  kind: NotificationPayloadDesignKind,
  _variant: NotificationPayloadDesignVariant
) => {
  const value = bodyColors[kind];

  warning(value, `getBodyColor: Invalid kind or variant`, {
    kind,
  });

  return value;
};

const bodyBackgrounds = {
  primary: {
    interactive: '$interactive-01',
    success: '$success-01',
    attention: '$attention-01',
    danger: '$danger-01',
  },
  secondary: {
    interactive: '$interactive-02',
    success: '$success-02',
    attention: '$attention-02',
    danger: '$danger-02',
  },
} as const;

export const getBodyBackground = (
  kind: NotificationPayloadDesignKind,
  variant: NotificationPayloadDesignVariant
) => {
  const value = bodyBackgrounds[kind]?.[variant];

  warning(value, `getBodyBackground: Invalid kind or variant`, {
    kind,
    variant,
  });

  return value;
};

const buttonTypes = {
  primary: 'inverted',
} as const;

export const getButtonType = (
  kind: NotificationPayloadDesignKind
): IButtonProps['type'] => {
  return buttonTypes[kind as keyof typeof buttonTypes] ?? 'primary';
};

const buttonColors = {
  primary: {
    success: 'success',
    attention: 'attention',
    danger: 'danger',
  },
} as const;

export const getButtonColor = (
  kind: NotificationPayloadDesignKind,
  variant: NotificationPayloadDesignVariant
): IButtonProps['color'] => {
  return (
    buttonColors[kind as keyof typeof buttonColors]?.[
      variant as keyof (typeof buttonColors)['primary']
    ] ?? 'interactive'
  );
};

const closeColors = {
  primary: '$text-04',
} as const;

export const getCloseColor = (
  kind: NotificationPayloadDesignKind,
  _variant: NotificationPayloadDesignVariant
) => {
  return closeColors[kind as keyof typeof closeColors] ?? '$text-02';
};
