import {type OnboardingStep} from './types';

const STEP_TO_PARAMS: Record<OnboardingStep, string> = {
  verification: 'verificationPass',
  deposit: 'firstDeposit',
  trade: 'firstTrade',
};

export const stepsToParams = (steps: OnboardingStep[]) => {
  return steps.map(step => STEP_TO_PARAMS[step]);
};
