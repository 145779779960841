export function isRecaptchaError(error: any) {
  // if the error was thrown during recaptcha v3 lifecycle
  if (error?.message?.toLowerCase()?.includes('recaptcha')) {
    return true;
  }

  // if server token v3 validation failed;
  if (
    JSON.stringify(error?.response?.data)?.toLowerCase()?.includes('recaptcha')
  ) {
    return true;
  }

  return false;
}
