import * as React from 'react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {ActionsProps} from './types';
import {WalletActionsFull} from './index.full';
import {WalletActionsShort} from './index.short';

export const WalletActions: React.FC<ActionsProps & BoxProps> = ({
  variant = 'short',
  ...props
}) => {
  if (variant === 'full') {
    return <WalletActionsFull {...props} />;
  }

  return <WalletActionsShort {...props} />;
};
