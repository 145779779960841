import {action, computed, observable} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import Big from 'big.js';
import {formatByTicker, toBig} from '@youtoken/ui.formatting-utils';
import * as yupPackage from '@youtoken/ui.yup';
import {messages} from '@youtoken/ui.validation-messages';
import {normalizeAmountByTicker} from '@youtoken/ui.normalizers';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {i18n} from '@youtoken/ui.service-i18n';
import {TRANSPORT} from '@youtoken/ui.transport';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import type {
  BankCardAddMoneyFormArgs,
  BankCardAddMoneyFormResources,
} from './types';

export class Form {
  @observable
  public args: BankCardAddMoneyFormArgs;

  @observable
  public resources: BankCardAddMoneyFormResources;

  @observable
  public instance: MobxReactForm;

  public constructor(
    args: BankCardAddMoneyFormArgs,
    resources: BankCardAddMoneyFormResources
  ) {
    this.args = args;
    this.resources = resources;

    const fields = {
      sum: {
        name: 'sum',
        label: 'From',
      },
    };

    const hooks = {
      onSuccess: () => {
        return TRANSPORT.API.post('/v1/intergiro/cards/top-up', {
          cardId: this.args.cardId,
          amount: formatByTicker(this.sumValue, this.ticker),
        })
          .then(() => {
            this.resources.bankCards.refetch();
            this.args.onSuccess();
            LOCAL_NOTIFICATIONS.info({
              text: i18n.t('surface.bank_cards.deposit_processing', {
                amount: this.sumValue,
                ticker: this.tickerFormatted,
              }),
            });
          })
          .catch(e => {
            return handleFormSubmitError(this.instance, e, {
              inputAmount: 'sum',
            });
          });
      },
    };

    const plugins = {
      yup: yupValidator({
        package: yupPackage,
        schema: (yup: typeof yupPackage) =>
          yup.lazy(() => {
            return yup.object().shape({
              sum: yup
                .big()
                .gt(0, () =>
                  messages.SHOULD_BE_GT({
                    value: '0',
                    ticker: this.ticker.toUpperCase(),
                  })
                )
                .gte(this.minAmount, () =>
                  messages.MIN_AMOUNT({
                    value: this.minAmount.toString(),
                    ticker: this.ticker.toUpperCase(),
                  })
                )
                .lte(this.maxAmount, () =>
                  messages.MAX_AMOUNT({
                    value: this.maxAmount.toString(),
                    ticker: this.ticker.toUpperCase(),
                  })
                )
                .required(messages.REQUIRED),
            });
          }),
      }),
    };

    const options = {
      validateOnBlur: false,
      validateOnChange: false,
      validateOnChangeAfterSubmit: true,
      showErrorsOnReset: false,
    };

    this.instance = new MobxReactForm({fields}, {plugins, hooks, options});
  }

  @computed
  get wallet() {
    return this.resources.wallets.getByTicker(this.ticker)!;
  }

  //#region ticker
  ticker = 'eur';

  @computed
  get tickerFormatted() {
    return this.ticker.toUpperCase();
  }

  //#endregion ticker

  //#region sum field
  @computed
  public get sumField() {
    return this.instance.$('sum');
  }

  @computed
  get sumValue(): Big {
    return toBig(this.sumField.value);
  }

  @computed
  public get sumFormatted(): string {
    return formatByTicker(this.sumValue, this.ticker);
  }

  @computed
  public get hasSumError() {
    return Boolean(this.sumField.error);
  }

  @computed
  public get sumError() {
    return this.sumField.error;
  }

  @computed
  public get sumMax() {
    return Math.min(
      this.wallet.amount.toNumber(),
      this.bankCardLimits.maxAmount.toNumber()
    );
  }

  @action
  onSumChange = (value: string | number) => {
    const onChange = this.sumField.get('onChange');
    onChange(normalizeAmountByTicker(value.toString(), this.ticker));
  };

  @action
  onSumSetMax = () => {
    this.onSumChange(this.sumMax);
  };

  //#endregion sum field

  //#region amount limits
  @computed
  public get bankCardLimits() {
    return this.resources.bankCardLimits.data;
  }

  @computed
  public get minAmount() {
    return this.resources.bankCardLimits.data.minAmount;
  }

  @computed
  public get maxAmount() {
    return this.resources.bankCardLimits.data.maxAmount;
  }

  //#endregion amount limits
}
