import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ExpandableCardList} from './ExpandableCardList';
import {Button} from '@youtoken/ui.buttons';

export const LevelsStack: React.FC<{}> = observer(() => {
  const [isListExpanded, setListExpanded] = React.useState(false);

  const handleToggle = React.useCallback(() => {
    setListExpanded(prev => !prev);
  }, []);

  const {t} = useTranslation();

  return (
    <>
      <ExpandableCardList isExpanded={isListExpanded} />

      <Button
        type="ghost"
        onPress={handleToggle}
        testID="MINER_CARD_LIST_TOGGLE"
      >
        {t(
          isListExpanded
            ? 'surface.miner.cards.show_less'
            : 'surface.miner.cards.show_all'
        )}
      </Button>
    </>
  );
});
