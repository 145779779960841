import * as React from 'react';
import {observer} from 'mobx-react';
import {
  type TPerkItem,
  type OnboardingStep,
} from '@youtoken/ui.resource-onboarding-step-perks';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {useTranslation, type TKey} from '@youtoken/ui.service-i18n';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {
  CoindropPromiseBlock,
  IncentivesPromiseBlock,
  MinerPromiseBlock,
  SavingsPromiseBlock,
} from './Blocks';

type PerksSectionProps = {
  variant: OnboardingStep;
  items?: TPerkItem[];
};

const TITLES: Record<OnboardingStep, TKey> = {
  verification: 'surface.wallets.first_action_modal.verification.title',
  deposit: 'surface.wallets.first_action_modal.deposit.title',
  trade: 'surface.wallets.first_action_modal.trade.title',
};

export const PerksSection: React.FC<PerksSectionProps> = observer(
  ({variant, items}) => {
    const {t} = useTranslation();

    const {getLevel} = LoyaltyResource.use({});

    const {requiredDepositVisible} = getLevel(2)!;

    const subtitle = {
      verification: t(
        'surface.wallets.first_action_modal.verification.subtitle'
      ),
      deposit: t(
        'surface.wallets.first_action_modal.subtitle_deposit_at_least',
        {
          amount: requiredDepositVisible,
        }
      ),
      trade: t('surface.wallets.first_action_modal.trade.subtitle'),
    }[variant];

    const nextLevel = {
      verification: undefined,
      deposit: 2,
      trade: 3,
    }[variant];

    if (!items || items.length === 0) {
      return null;
    }

    return (
      <Box>
        <Box gap={8}>
          <Heading variant="$heading-02" color="$text-01">
            {t(TITLES[variant])}
          </Heading>
          <Text>{subtitle}</Text>
        </Box>

        <Box gap={16} mt={16}>
          {items.map(({type, params}) => {
            switch (type) {
              case 'COINDROP':
                return <CoindropPromiseBlock coins={params.coins} />;

              case 'SPARKS':
                return <MinerPromiseBlock sparks={params.amount} />;

              case 'INCENTIVES':
                return <IncentivesPromiseBlock percent={params.percent} />;

              case 'SAVINGS':
                return nextLevel ? (
                  <SavingsPromiseBlock
                    level={nextLevel}
                    maxApr={params.maxApr}
                    tickers={params.tickers}
                  />
                ) : null;

              default:
                return null;
            }
          })}
        </Box>
      </Box>
    );
  }
);
