import * as React from 'react';
import {observer} from 'mobx-react';
import AppleLogin from 'react-apple-login';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Form, SubmitButton, makeForm} from '@youtoken/ui.form-elements';
import {Box, type BoxProps, Heading} from '@youtoken/ui.primitives';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {OrSeparator} from '@youtoken/ui.elements';
import {GoogleAuthButton, AppleAuthButton} from '@youtoken/ui.elements-smart';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {AppEnv, WebAppEnv} from '@youtoken/ui.env-utils';
import {useIsWebView} from '@youtoken/ui.hooks';
import {SignInForm} from './state';
import {EmailField, PasswordField, ResetPassword} from './components';

type SignInSurfaceProps = {
  showTitle?: boolean;
  showSSO?: boolean;
};

export const SignInSurface: React.FC<SignInSurfaceProps & BoxProps> = observer(
  ({showTitle = true, showSSO = true, ...boxProps}) => {
    const {t} = useTranslation();

    const authMe = AuthMeResource.use({});
    // VerificationResource is unavailable before signin form submission
    const verification = VerificationResource.use({withFallback: true});

    const form = makeForm(() => new SignInForm({}, {authMe, verification}));

    const isWebView = useIsWebView();
    const isLedger = ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ledger-app'];

    return (
      <Form form={form}>
        <Box
          flex={1}
          height="100%"
          justifyContent={{default: 'space-between', tablet: 'flex-start'}}
          {...boxProps}
        >
          {showTitle && (
            <Box mb={24}>
              <Heading variant="$heading-01-responsive">
                {t('forms.sign_in.actions.sign_in')}
              </Heading>
            </Box>
          )}

          {showSSO && !isWebView && !isLedger && (
            <>
              <GoogleAuthButton mb={12} />
              <AppleLogin
                clientId={
                  ENVIRONMENT.APP_ENV === AppEnv.production
                    ? 'com.youhodler.youhodler.login'
                    : 'com.youhodler.youhodler.dev.login'
                }
                redirectURI={`${ENVIRONMENT.BACKEND_URL}/auth/apple/callback`}
                responseMode="form_post"
                responseType="code id_token"
                scope="name email"
                render={renderProps => <AppleAuthButton {...renderProps} />}
              />
              <OrSeparator my={24} />
            </>
          )}

          <Box>
            <EmailField mb={24} />
            <PasswordField mb={24} />
          </Box>

          <Box>
            <SubmitButton size="large" testID="SIGN_IN_BUTTON">
              {t('forms.sign_in.actions.sign_in')}
            </SubmitButton>
            <ResetPassword mt={24} />
          </Box>
        </Box>
      </Form>
    );
  }
);
