import {SortingItem} from './types';
import {i18n} from '@youtoken/ui.service-i18n';

export const DEFAULT_SORTING_ITEMS: SortingItem[] = [
  {
    value: 'price_asc',
    label: i18n.t('surface.hodls.instruments.sorter.price'),
    type: 'price',
    direction: 'asc',
  },
  {
    value: 'price_desc',
    label: i18n.t('surface.hodls.instruments.sorter.price'),
    type: 'price',
    direction: 'desc',
  },
  {
    value: 'dif24_asc',
    label: i18n.t('surface.hodls.instruments.sorter.change'),
    type: 'dif24',
    direction: 'asc',
  },
  {
    value: 'dif24_desc',
    label: i18n.t('surface.hodls.instruments.sorter.change'),
    type: 'dif24',
    direction: 'desc',
  },
  {
    value: 'name_asc',
    label: i18n.t('surface.hodls.instruments.sorter.name'),
    type: 'name',
    direction: 'asc',
  },
  {
    value: 'name_desc',
    label: i18n.t('surface.hodls.instruments.sorter.name'),
    type: 'name',
    direction: 'desc',
  },
];
