import {deserialize} from 'serializr';
import {action, computed, observable, reaction} from 'mobx';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {type IUserStoryItem} from '@youtoken/ui.insta-stories';
import {TRANSPORT} from '@youtoken/ui.transport';
import {InstaStoriesResponse, Story} from './InstaStoriesResponse';
import sortBy from 'lodash/sortBy';
import findIndex from 'lodash/findIndex';
import {computedFn} from 'mobx-utils';
import {AppState, type AppStateStatus, Image, Platform} from 'react-native';
import {warning} from '@youtoken/ui.utils';

export class InstaStoriesResource extends createStaticResource<
  {},
  InstaStoriesResponse
>({
  getKey: () => `InstaStoriesResource`,
  getData: async () => {
    return TRANSPORT.API.get('/v1/story', {}).then(({data}) => {
      return deserialize(InstaStoriesResponse, data);
    });
  },
  skipRefreshOnVisible: true,
}) {
  @observable stories: Array<Story & {seen: boolean}> = [];
  @observable activeStoryIndex = -1;
  @observable imagesLoaded: Record<string, boolean> = {};
  @observable appIsActive = true;
  _disposers: any[] = [];

  constructor(props: {}, args: InstaStoriesResponse) {
    super(props, args);

    const isNative = Platform.select({default: true, web: false});
    if (isNative) {
      const eventListener = AppState.addEventListener(
        'change',
        this.onAppStateChange
      );
      this._disposers.push(eventListener.remove);
    }

    this._disposers.push(
      reaction(
        () => this.data,
        () => {
          this.updateStories();
        },
        {
          fireImmediately: true,
        }
      )
    );
  }

  onDestroy(): void {
    this._disposers.forEach((disposer: any) => disposer?.());
  }

  private onAppStateChange = (nextAppState: AppStateStatus) => {
    this.appIsActive = nextAppState === 'active';
  };

  @computed.struct get storiesSorted() {
    return sortBy(this.stories, 'shown');
  }

  @computed get haveStories() {
    return this.stories.length > 0;
  }

  @action markAsShown = (id: string, slide: number) => {
    TRANSPORT.API.post('/v1/story/shown', {
      id,
      slide,
    });
  };

  @action markAsSeen = (slug: string) => {
    const storiesFE = this.stories;
    const seenStoryIndex = findIndex(storiesFE, story => story.slug === slug);
    if (storiesFE[seenStoryIndex]) {
      storiesFE[seenStoryIndex]!.seen = true;
      this.stories = storiesFE;
    }
  };

  @action updateStories = () => {
    this.stories = this.data.stories.map(story => ({
      ...story,
      seen: story.shown,
    }));
  };

  @action updateImagesLoaded = (imageUrl: string) => {
    this.imagesLoaded = {...this.imagesLoaded, [imageUrl]: true};
  };

  isImageLoaded = computedFn((imageUrl: string) => {
    return this.imagesLoaded[imageUrl];
  });

  @action prefetchImagesForSlide = (slide: IUserStoryItem) => {
    const imageUrls = [slide.backgroundUrl, slide.foregroundUrl].filter(
      i => typeof i !== 'undefined'
    ) as string[];

    imageUrls.forEach(url => {
      if (this.isImageLoaded(url)) {
        return;
      }

      Image.prefetch(url)
        .then(() => {
          this.updateImagesLoaded(url);
        })
        .catch(() => {
          warning(false, 'Can`t prefetch image', {
            url,
          });
        });
    });
  };

  @action checkLoadingState = (url: string) => {
    if (this.isImageLoaded(url)) {
      return;
    }

    this.updateImagesLoaded(url);
  };

  @action updateActiveStoryIndex = (storyId?: string) => {
    if (!storyId) {
      this.activeStoryIndex = -1;
      return;
    }

    this.activeStoryIndex = findIndex(
      this.storiesSorted,
      story => story.storyId === storyId
    );
  };
}
