import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {NewReferralOverviewResource} from '@youtoken/ui.resource-new-referral';

type RewardExplanationProps = {};

export const RewardExplanation: React.FC<RewardExplanationProps & BoxProps> =
  observer(boxProps => {
    const {t} = useTranslation();

    const {
      overview: {
        conditions: {percentFormatted},
      },
    } = NewReferralOverviewResource.use({});

    return (
      <Box
        flexDirection="row"
        alignItems="center"
        px={18}
        py={16}
        backgroundColor="$success-02"
        borderRadius={16}
        {...boxProps}
      >
        <Box
          backgroundColor="$ui-background"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          width={40}
          height={40}
          borderRadius={40}
          mr={12}
        >
          <Icon name="gift" size={20} color="$success-01" />
        </Box>

        <Box flexDirection="column" flex={1}>
          <Text variant="$body-02" mb={2}>
            {t('surface.new_referral.pending_friend.your_reward.title')}
          </Text>
          <Text variant="$body-01-high-accent">
            {t('surface.new_referral.pending_friend.your_reward.description', {
              percent: percentFormatted,
            })}
          </Text>
        </Box>
      </Box>
    );
  });
