import * as React from 'react';
import {CHALLENGES_SERVICE} from '@youtoken/ui.challenge-service';
import type {TSumSubVerificationImperativeMethods} from './types';
import {SumSubVerificationComponent} from './SumSubVerificationComponent';

export const __GLOBAL_SUM_SUB_VERIFICATION__: TSumSubVerificationImperativeMethods =
  {
    launch: (levelName, onDismiss) => {
      if (!CHALLENGES_SERVICE.refs.sumsub?.current?.launch) {
        return Promise.reject(new Error('SumSub is not ready'));
      }

      return CHALLENGES_SERVICE.refs.sumsub?.current?.launch(
        levelName,
        onDismiss
      );
    },
  };

export const SumSubVerificationContext =
  React.createContext<TSumSubVerificationImperativeMethods>(
    __GLOBAL_SUM_SUB_VERIFICATION__
  );

export const SumSubVerificationContextProvider: React.FC = ({children}) => {
  return (
    <SumSubVerificationContext.Provider value={__GLOBAL_SUM_SUB_VERIFICATION__}>
      <SumSubVerificationComponent ref={CHALLENGES_SERVICE.refs.sumsub} />
      {children}
    </SumSubVerificationContext.Provider>
  );
};

export const useSumSubVerification = () => {
  const context = React.useContext(SumSubVerificationContext);

  if (!context) {
    throw new Error(
      'useSumSubVerification must be used within a SumSubVerificationContextProvider'
    );
  }

  return context;
};
