export * from './ModalContentSmart';
import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Heading, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Separator, Trans} from '@youtoken/ui.elements';
import {Icon, IconSectorsLogo} from '@youtoken/ui.icons';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {HODLsInstrumentsSectorsFeature} from '../../features/HODLsInstrumentsSectorsFeature';
import {cardsData} from '../../sections/SectorsSection/data';
import {ScrollWrapper} from '../components';
import {
  SectorsInstrumentsListSmart,
  SectorsInstrumentsSorter,
} from '../../sections';

export interface ModalContentSmartProps {
  onHelpIconPress: () => void;
  onClose: () => void;
}

export const ModalContentSmart: React.FC<ModalContentSmartProps> = observer(
  ({onClose, onHelpIconPress}) => {
    const {
      chosenSector,
      getSectorItemCount,
      sectorInfo: {description: instrumentDescription},
    } = HODLsInstrumentsSectorsFeature.use({});

    const handlePressInstrument = React.useCallback(() => {
      DATA_LAYER.trackStrict('hodl-open-instrument', {
        openedFrom: 'sector',
        sectorName: chosenSector!,
      });

      onClose();
    }, [chosenSector, onClose]);

    React.useEffect(() => {
      if (chosenSector) {
        DATA_LAYER.trackStrict('hodl-sector', {
          sectorName: chosenSector,
        });
      }
    }, [chosenSector]);

    if (!chosenSector) {
      return null;
    }

    return (
      <>
        {/* Custom Heading */}
        <Box
          height={142}
          backgroundColor={cardsData[chosenSector]!.cardColor}
          alignItems="center"
          justifyContent="center"
        >
          <Box zIndex={1} alignItems="center">
            <Heading variant="$heading-02" color="$text-04" mb={4}>
              {cardsData[chosenSector]!.title}
            </Heading>
            <Text variant="$body-02" color="$text-04">
              <Trans
                i18nKey="surface.hodls.portfolio.sectors_card_text"
                values={{
                  count: getSectorItemCount(chosenSector),
                }}
              />
            </Text>
          </Box>
          <Box position="absolute" top={-65} zIndex={0} opacity={0.2}>
            <IconSectorsLogo name={cardsData[chosenSector]!.logo} size={256} />
          </Box>
        </Box>
        <TouchableBox
          position="absolute"
          left={8}
          top={8}
          width={36}
          height={36}
          justifyContent="center"
          alignItems="center"
          activeOpacity={0.8}
          onPress={onHelpIconPress}
          zIndex={1}
        >
          <Icon name="question" color={'$text-04'} />
        </TouchableBox>

        {/* Body */}
        <Box
          flex={1}
          backgroundColor="$ui-background"
          borderRadius={14}
          mt={-16}
        >
          {Boolean(instrumentDescription) && (
            <>
              <Text m={24} variant="$body-02" color="$text-02">
                {instrumentDescription}
              </Text>
              <Separator />
            </>
          )}
          <SectorsInstrumentsSorter pt={16} px={24} pb={8} />
          <ScrollWrapper>
            <SectorsInstrumentsListSmart
              withoutBorders
              onPressInstrument={handlePressInstrument}
              onPressAction={onClose}
            />
          </ScrollWrapper>
        </Box>
      </>
    );
  }
);
