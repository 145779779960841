import {Platform} from 'react-native';
import {
  action,
  comparer,
  computed,
  IReactionDisposer,
  observable,
  reaction,
  toJS,
  transaction,
  runInAction,
} from 'mobx';
import {deserialize} from 'serializr';
import {concat, uniq, without} from 'lodash';
import axios, {type AxiosError} from 'axios';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource, DATA_STORAGE} from '@youtoken/ui.data-storage';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {i18n} from '@youtoken/ui.service-i18n';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {GLOBAL} from '@youtoken/ui.service-global';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {invariant} from '@youtoken/ui.utils';
import {PasscodeResource} from '@youtoken/ui.resource-passcode';
import {LocalAuthResource} from '@youtoken/ui.resource-local-auth';
import {
  type VerificationItemCode,
  VerificationItemAccountCode,
  VerificationResource,
} from '@youtoken/ui.resource-verification';
import {
  Challenge,
  ClientSettingsSectionVisibilities,
  AuthMeResponse,
  localClientSettings,
  updateLocalClientSettings,
} from './AuthMeResponse';
import {
  AddressStatus,
  type AlertsMethods,
  KYCStatus,
  type ProductName,
  SavingsAndRewardsType,
} from './types';
import {currencyName} from '@youtoken/ui.coin-utils';
import {signOutFromGoogleSSO} from './utils/signOutFromGoogleSSO';

export {localClientSettings};

ENVIRONMENT.events.on('unauthorizedError', () => {
  const AuthMeResourceInstance = AuthMeResource.getInstanceSafely({});

  if (AuthMeResourceInstance) {
    AuthMeResourceInstance.signOut();
  } else {
    GLOBAL.removeToken();
    ENVIRONMENT.regenerateSessionId();
    PasscodeResource.getInstanceSafely({})?.clearData();
    LocalAuthResource.getInstanceSafely({})?.clearData();
  }
});

const handleChallenge = (challenge?: Challenge) => {
  if (!challenge) {
    return;
  }

  if (
    ['ios', 'android'].includes(Platform.OS) &&
    !PasscodeResource.getInstanceSafely({})?.hasPasscode
  ) {
    return;
  }

  if (challenge.type === 'emailConfirmation') {
    // adding new invariant for operationId
    invariant(challenge.operationId, 'Operation ID is required');

    SHARED_ROUTER_SERVICE.navigate(
      'VerifyEmail',
      {
        operationId: challenge.operationId,
      },
      {
        operationId: challenge.operationId,
      }
    );
  }

  if (challenge.type === 'disclosuresSignature') {
    SHARED_ROUTER_SERVICE.navigate('ReSignDisclosures');
  }

  if (challenge.type === 'forceKycLevel') {
    VerificationResource.launchVerification(challenge.level!);
  }
};

export const AuthMeGuestResponse = toJS(new AuthMeResponse());

export class AuthMeResource extends createResource<{}, AuthMeResponse>({
  skipRefreshOnVisible: false,
  shouldBeDeletedOnCleanup: false,
  cacheTime: 24 * 60 * 60 * 1000,
  getKey: () => 'AuthMe',
  getData: () => {
    if (!GLOBAL.token) {
      return Promise.resolve(AuthMeGuestResponse);
    }

    const {token, cancel} = axios.CancelToken.source();

    const promise = TRANSPORT.API.get(`/v1/auth/me`, {
      cancelToken: token,
    })
      .then(res => deserialize(AuthMeResponse, res.data))
      .then(data => {
        updateLocalClientSettings(toJS(data.clientSettings));

        handleChallenge(data.challenges?.[0]);

        return data;
      })
      .catch(error => {
        if (error.message === '__CANCELLED_REQUEST__') {
          throw error;
        }

        // NOTE: terrible hotfix, needs revision
        return (
          DATA_STORAGE?.storage?.['resource:AuthMe']?.data ??
          AuthMeGuestResponse
        );
      });
    // @ts-ignore: reason - non standard promise method;
    promise.cancel = cancel;

    return promise;
  },
}) {
  disposers: Array<IReactionDisposer> = [];

  constructor(args: {}, data: AuthMeResponse) {
    super(args, data);

    this.disposers = [
      reaction(
        () => TRANSPORT.SOCKET,
        () => {
          this.unsubscribeSocketUserState();
          if (GLOBAL.isAuthorized) {
            this.subscribeSocketUserState();
          }
        },
        {
          fireImmediately: true,
        }
      ),
      reaction(
        () => [i18n.language, GLOBAL.isAuthorized],
        ([language, isAuthorized]) => {
          if (isAuthorized) {
            TRANSPORT.API.post(`/v1/profile/language`, {
              language,
            }).catch(() => {
              // nothing
            });
          }
        },
        {
          fireImmediately: false,
        }
      ),
    ];

    const {language = ''} = data || {};

    if (language && i18n.language !== language) {
      i18n._changeLanguage(language);
    }
  }

  onDestroy(): void {
    super.onDestroy();

    this.disposers?.forEach(disposer => disposer?.());
  }

  //#regionstart SocketUserState

  protected subscribeSocketUserState = () => {
    this.connectSocketUserState();
    TRANSPORT.SOCKET.on('connect', this.connectSocketUserState);
    TRANSPORT.SOCKET.on('userState', this.handleMessageSocketUserState);
  };

  protected unsubscribeSocketUserState = () => {
    this.disconnectSocketUserState();
    TRANSPORT.SOCKET.off('connect', this.connectSocketUserState);
    TRANSPORT.SOCKET.off('userState', this.handleMessageSocketUserState);
  };

  protected connectSocketUserState = () => {
    TRANSPORT.SOCKET.emit('sub', {
      name: 'userState',
    });
  };

  protected disconnectSocketUserState = () => {
    TRANSPORT.SOCKET.emit('unsub', {
      name: 'userState',
    });
  };

  protected handleMessageSocketUserState = (data: any) => {
    runInAction(() => {
      this.data = deserialize(AuthMeResponse, data);
    });
  };

  //#endregion SocketUserState

  @action handleSignInError = (
    error: AxiosError<{
      twoFactorAuthType: string;
      operationType: string;
      operationId: string;
    }>
  ): Promise<void> => {
    const {
      response: {data: {twoFactorAuthType, operationType, operationId}} = {
        data: {},
      },
    } = error;

    if (
      operationType === 'confirm-device' && // fixme enum
      operationId
    ) {
      return this.deviceConfirmation(operationId, error);
    }

    if (
      twoFactorAuthType &&
      ['sms', 'ga', 'email'].includes(twoFactorAuthType) && // fixme enum
      operationId
    ) {
      return this.twoFactorAuth(operationId, error);
    }

    return Promise.reject(error);
  };

  @action signIn = (data: any): Promise<AuthMeResponse | void> => {
    return TRANSPORT.API.post('/v1/auth/signin', data)
      .then(res => deserialize(AuthMeResponse, res.data))
      .then(data => {
        transaction(() => {
          this.data = data;
          GLOBAL.setToken(data.token);
        });

        return data;
      })
      .catch((error: AxiosError) => {
        return this.handleSignInError(error);
      });
  };

  @action twoFactorAuth = (
    operationId: string,
    error: AxiosError
  ): Promise<void> => {
    SHARED_ROUTER_SERVICE.navigate('SignInConfirm', {}, {operationId});

    return Promise.resolve();
  };

  @action deviceConfirmation = (
    operationId: string,
    error: AxiosError
  ): Promise<void> => {
    SHARED_ROUTER_SERVICE.navigate('DeviceConfirm', {}, {operationId});

    return Promise.resolve();
  };

  @action signUp = (data: any) => {
    return TRANSPORT.API.post('/v1/auth/signup', data)
      .catch(error => {
        if (error.response?.data.operationType === 'sign-up') {
          SHARED_ROUTER_SERVICE.navigate(
            'VerifyEmail',
            {
              operationId: error.response.data.operationId,
            },
            {
              operationId: error.response.data.operationId,
            }
          );
        } else {
          throw error;
        }
      })
      .then(() => {
        DATA_LAYER.trackQuora('quora-sign-up');
      });
  };

  @observable _signOutMutex = false;

  @action signOut = () => {
    if (this._signOutMutex) {
      return Promise.resolve();
    }

    this._signOutMutex = true;

    return Promise.all([
      TRANSPORT.API.post('v1/auth/signout').catch(() => {}),
      () => {
        return signOutFromGoogleSSO();
      },
    ]).then(() => {
      GLOBAL.removeToken();
      ENVIRONMENT.regenerateSessionId();
      PasscodeResource.getInstanceSafely({})?.clearData();
      LocalAuthResource.getInstanceSafely({})?.clearData();
      this.data = AuthMeGuestResponse;
      DATA_STORAGE.clean();
      this._signOutMutex = false;
      LOCAL_NOTIFICATIONS.info({text: i18n.t('surface.auth.signed_out')});
    });
  };

  @action signInConfirmSuccess = (
    response: any,
    params?: {firstSignIn?: boolean}
  ): void => {
    transaction(() => {
      this.data = response.data;
      GLOBAL.setToken(response.data.token);
    });

    if (['ios', 'android'].includes(Platform.OS)) {
      return SHARED_ROUTER_SERVICE.navigate(
        'SetupPasscode',
        {},
        {
          firstSignIn: params?.firstSignIn,
        }
      );
    }

    // @ts-ignore
    return SHARED_ROUTER_SERVICE.navigate(
      this.data.forceIdentify ? 'PleaseVerify' : 'WalletsList',
      {}
    );
  };

  @action confirmDevice = (operationId: string) => {
    return TRANSPORT.API.post('/v1/security/get-device-confirmation', {
      operationId,
    });
  };

  @action confirmOperation = (payload: {
    token: string;
    operationId: string;
    code: string;
    cancelToken?: any;
  }) => {
    return TRANSPORT.API.post('/v1/security/confirm-operation', payload);
  };

  @action passwordRestoreRequest = (payload: {
    token: string;
    email: string;
  }) => {
    return TRANSPORT.API.post('/v1/auth/restore-password', payload)
      .then(() => {
        LOCAL_NOTIFICATIONS.info({
          text: i18n.t('forms.sign_in.forgot_password.message.sent'),
        });
        SHARED_ROUTER_SERVICE.navigate('SignIn');
      })
      .catch(error => {
        LOCAL_NOTIFICATIONS.error({
          text: i18n.t('forms.reset_password.message.sent_error'),
        });

        throw error;
      });
  };

  @action passwordResetRequest = (payload: {email: string}) => {
    return TRANSPORT.API.post('v1/auth/reset-password', payload).then(
      this.signOut
    );
  };

  @action setAcquisitionSurveyPassed = () => {
    this.data.clientSettings.enableAcquisitionSourcesSurvey = false;

    this.syncClientSettings();
  };

  @action setAlerts = (type: AlertsMethods, value: boolean) => {
    return TRANSPORT.API.post('v1/auth/me', {alerts: {[type]: value}});
  };

  @action setUpdates = (type: AlertsMethods, value: boolean) => {
    return TRANSPORT.API.post('v1/auth/me', {updates: {[type]: value}});
  };

  @action syncClientSettings = (needForceRefresh: boolean = true) => {
    this.fetcher.cancelRunningGetData();
    updateLocalClientSettings(this.data.clientSettings);

    return TRANSPORT.API.post('/v1/auth/me', {
      clientSettings: this.data.clientSettings,
    }).then(() => {
      needForceRefresh && this.refetch();
    });
  };

  @action enableTwoFactorAuthRequest = (payload: {type: string}) => {
    return TRANSPORT.API.post('v1/security/enable-2fa', payload);
  };

  @action disableTwoFactorAuthRequest = (payload: {token: string}) => {
    return TRANSPORT.API.post('v1/security/disable-2fa', payload);
  };

  @action getOperation = (payload: {operationId: string}) => {
    return TRANSPORT.API.post('v1/security/get-operation', payload);
  };

  @action refreshOperation = (payload: {
    token: string;
    operationId: string;
  }) => {
    return TRANSPORT.API.post('v1/security/refresh-operation', payload);
  };

  @action setNewPassword = (payload: {code: string; email: string}) => {
    return TRANSPORT.API.post('v1/auth/change-password', payload);
  };

  @computed get id() {
    return this.data.id;
  }

  @computed get sessionId() {
    return ENVIRONMENT.SESSION_ID;
  }

  @computed get accountId() {
    return this.data.accountId;
  }

  @computed get dateOfBirth() {
    return this.data.dateOfBirth;
  }

  @computed get fullName() {
    return this.data.fullName;
  }

  @computed get kycPhone() {
    return this.data.kycPhone;
  }

  @computed get userEmail() {
    return this.data.email;
  }

  @computed get hasSignedSavings() {
    return this.data.hasSignedSavings;
  }

  @computed get products() {
    return this.data.products;
  }

  @computed get depositBuyCryptoEnabled() {
    return this.data.products.depositBuyCrypto.isEnabled;
  }

  @computed get exchangeVersion() {
    return this.products.exchange.settings.version;
  }

  @computed get hasCoindrops(): boolean {
    return this.data.hasCoindrops;
  }

  @computed get enableAcquisitionSourcesSurvey() {
    return (
      this.data.clientSettings.enableAcquisitionSourcesSurvey &&
      ENVIRONMENT.WEB_APP_ENV !== WebAppEnv['ledger-app']
    );
  }

  @computed get enableSaving4() {
    return this.products.saving4.available;
  }

  @computed get savings4Active() {
    return (
      this.enableSaving4 && this.hasSignedSavings && this.savingVersion === 4
    );
  }

  @computed get hasSavings() {
    return this.savings4Active;
  }

  @computed get shouldSignSavings4() {
    return this.products.saving4.shouldSign;
  }

  @computed get shouldSignSavings() {
    return this.shouldSignSavings4;
  }

  @computed get shouldDisplayRewardAndBonuses() {
    return this.savingVersion === 4 && this.enableRewards;
  }

  @computed get savingVersion() {
    return this.data.savingVersion;
  }

  @computed get kycResult() {
    return this.data.kycResult;
  }

  @computed get kycSended() {
    return [
      KYCStatus.SEND,
      KYCStatus.MANUAL_REVIEW,
      KYCStatus.REPEATED,
    ].includes(this.kycResult);
  }

  @computed get addressResult() {
    return this.data.addressResult;
  }

  @computed get emailResult() {
    return this.data.emailResult;
  }

  @computed get kycPhoneResult() {
    return this.data.kycPhoneResult;
  }

  @computed get allowedMobilePush() {
    return this.data.allowedMobilePush;
  }

  @computed get bankWireDeposit() {
    return this.data.bankWireDeposit;
  }

  @computed get disableBankWireDepositButton() {
    return this.data.disableBankWireDepositButton;
  }

  @computed get twoFactorAuthType() {
    return this.data.twoFactorAuthType;
  }

  @computed get twoFactorEnabled() {
    return this.data.twoFactorAuthType !== 'email';
  }

  @computed get enableHodl() {
    return this.data.enableHodl;
  }

  @computed get mainCurrency(): currencyName {
    return this.data.clientSettings?.currency ?? 'usd';
  }

  @action setMainCurrency = (nextCurrency: currencyName) => {
    this.data.clientSettings.currency = nextCurrency;

    this.syncClientSettings();
  };

  @computed get starred() {
    return this.data?.clientSettings?.starredHODLsInstruments ?? [];
  }

  @action addStarredInstrument = (id: string) => {
    this.data.clientSettings.starredHODLsInstruments.push(id);

    this.syncClientSettings();
  };

  @action removeStarredInstrument = (id: string) => {
    this.data.clientSettings.starredHODLsInstruments = without(
      this.data.clientSettings.starredHODLsInstruments,
      id
    );

    this.syncClientSettings();
  };

  @action resetStarredInstruments = () => {
    this.data.clientSettings.starredHODLsInstruments = [];

    this.syncClientSettings();
  };

  @computed get residenceOrCountry() {
    return this.data.residence || this.data.country;
  }

  @computed get isRus() {
    return this.residenceOrCountry === 'RUS';
  }

  @computed get isChe() {
    return this.residenceOrCountry === 'CHE';
  }

  @computed get isFr() {
    return this.residenceOrCountry === 'FRA';
  }

  @computed get isGbr() {
    return this.residenceOrCountry === 'GBR';
  }

  @computed get creditCardProvider() {
    return this.isRus ? 'rbk' : 'paydoo';
  }

  @computed get isOnlyVisaAvailable() {
    return !['CHE'].includes(this.residenceOrCountry);
  }

  @computed get legalEntity() {
    return this.data.legalEntity;
  }

  //#region sections

  @computed({
    equals: comparer.shallow,
  })
  get showedWalletTickers() {
    return uniq(this.data?.clientSettings?.showedWalletTickers || []).filter(
      ticker => !this.hiddenWalletTickers.includes(ticker)
    );
  }

  @computed({
    equals: comparer.shallow,
  })
  get hiddenWalletTickers() {
    return uniq(this.data?.clientSettings?.hiddenWalletTickers ?? []);
  }

  @computed get addedWalletTickers() {
    return [...this.showedWalletTickers, ...this.hiddenWalletTickers];
  }

  @action setShowedSectionTickers = (
    sectionsVisibilities: ClientSettingsSectionVisibilities
  ) => {
    this.data.clientSettings.sectionVisibilities = sectionsVisibilities;

    this.syncClientSettings(false);
  };

  @action appendToShowedTickers = (...tickers: string[]) => {
    this.data.clientSettings.hiddenWalletTickers = without(
      this.hiddenWalletTickers,
      ...tickers
    );

    this.data.clientSettings.showedWalletTickers = concat(
      this.showedWalletTickers,
      ...tickers
    );

    this.syncClientSettings(false);
  };

  @action appendToHiddenTickers = (...tickers: string[]) => {
    this.data.clientSettings.showedWalletTickers = without(
      this.showedWalletTickers,
      ...tickers
    );

    this.data.clientSettings.hiddenWalletTickers = concat(
      this.hiddenWalletTickers,
      ...tickers
    );

    this.syncClientSettings(false);
  };

  @action removeFromAddedTickers = (...tickers: string[]) => {
    this.data.clientSettings.showedWalletTickers = without(
      this.showedWalletTickers,
      ...tickers
    );

    this.data.clientSettings.hiddenWalletTickers = without(
      this.hiddenWalletTickers,
      ...tickers
    );

    this.syncClientSettings(false);
  };

  //#endregion sections

  @action toggleDemoFunds = (shouldSyncClientSettings = true) => {
    this.data.clientSettings.enableDemo = !this.data.clientSettings.enableDemo;
    shouldSyncClientSettings && this.syncClientSettings(false);
  };

  @computed get appRatedAt() {
    return this.data?.clientSettings?.appRatedAt;
  }

  @action appRatedNow = () => {
    this.data.clientSettings.appRatedAt = Date.now();

    this.syncClientSettings();
  };

  @computed get isNeedShowAddWalletsDefault() {
    return this.data?.clientSettings?.isNeedShowAddWalletsDefault ?? false;
  }

  @computed get antiPhishingCode() {
    return this.data.antiPhishingCode;
  }

  @action toggleOffIsNeedShowAddWalletsDefault = () => {
    this.data.clientSettings.isNeedShowAddWalletsDefault = false;

    return this.syncClientSettings();
  };

  @action signSavings = (agree: boolean) => {
    this.fetcher.cancelRunningGetData();

    return TRANSPORT.API.post(`/v4/saving/sign`, {
      agreeToTerms: agree,
    });
  };

  @action createAntiPhishingCode = (antiPhishingCode: string) => {
    this.fetcher.cancelRunningGetData();

    return TRANSPORT.API.post('/v1/security/enable-ap', {
      code: antiPhishingCode,
    });
  };

  @action changeAntiPhishingCode = (antiPhishingCode: string) => {
    this.fetcher.cancelRunningGetData();

    return TRANSPORT.API.post('/v1/security/enable-ap', {
      code: antiPhishingCode,
    });
  };

  @action deleteAntiPhishingCode = () => {
    this.fetcher.cancelRunningGetData();

    return TRANSPORT.API.post('/v1/security/disable-ap');
  };

  @computed get walletsDisclosureSigned() {
    return this.data.hasSignedWallets && this.data.hasSignedConversions;
  }

  @computed get referralCode() {
    return this.data.referralCode || '';
  }

  @computed get referralLink() {
    return `${ENVIRONMENT.WEB_APP_URL}/sign-up?ref=${this.referralCode}`;
  }

  @computed get enableRewards() {
    return this.data.enableRewards;
  }

  @action updateReferralViewedDateTime = () => {
    this.data.clientSettings.referralViewedDateTime = Date.now();

    return this.syncClientSettings(false);
  };

  @action updateRewardViewedDateTime = () => {
    this.data.clientSettings.rewardViewedDateTime = Date.now();

    return this.syncClientSettings(false);
  };

  @computed get enableHodlQuiz() {
    return this.data.enableHodlQuiz;
  }

  @computed get traderQuizEnabled() {
    return this.data?.clientSettings?.enableHodlQuizBanner ?? false;
  }

  @computed get savingsAndRewardsType() {
    if (this.enableRewards) {
      return SavingsAndRewardsType.REWARDS;
    }

    return null;
  }

  @action setTraderQuizEnabled = (status: boolean) => {
    this.data.clientSettings.enableHodlQuizBanner = status;

    this.syncClientSettings();
  };

  @action setMinerHowItWorksBannerEnabled = (status: boolean) => {
    this.data.clientSettings.enableMinerHowItWorksBanner = status;

    this.syncClientSettings();
  };

  @action setSavingsHowItWorksBannerEnabled = (status: boolean) => {
    this.data.clientSettings.enableSavingsHowItWorksBanner = status;

    this.syncClientSettings();
  };

  @action setLoyaltyHowItWorksBannerEnabled = (status: boolean) => {
    this.data.clientSettings.enableLoyaltyHowItWorksBanner = status;

    this.syncClientSettings();
  };

  //#region IBAN
  @computed get enableWirexIBAN() {
    return this.data.enableWirex && this.data.enableWirexIBAN;
  }

  @computed get enableFiatRepublicIBAN() {
    return this.data.enableFiatRepublic && this.data.enableFiatRepublicIBAN;
  }

  @computed get isIBANBannerEnabled() {
    return (
      // NOTE: enableWirexIBANBanner is an old flag, It was left for backward compatibility
      // NOTE-2: both flags default value is true
      this.data?.clientSettings?.enableWirexIBANBanner &&
      this.data?.clientSettings?.enableIBANBanner
    );
  }

  @action setIBANBannerEnabled = (value: boolean) => {
    this.data.clientSettings.enableIBANBanner = value;
    this.syncClientSettings();
  };
  //#endregion IBAN

  @computed get forceIdentify() {
    return this.data.forceIdentify;
  }

  @computed get forceIdentifyWaiting() {
    return (
      this.kycResult === KYCStatus.MANUAL_REVIEW ||
      this.addressResult === AddressStatus.MANUAL_REVIEW
    );
  }

  checkProductAvailability = (
    productName: ProductName,
    // FIXME: a hack
    beforeNavigate?: () => void
  ) => {
    const {products} = this;

    invariant(products[productName], `Product "${productName}" not found`);

    const product = products[productName];

    if (!product!.requirements || !product!.requirements.length) {
      return true;
    }

    const firstRequirement = product!.requirements[0] as
      | VerificationItemCode
      | 'support'
      | 'agreement';

    if (!firstRequirement) {
      return true;
    }

    if (firstRequirement === 'support') {
      // unavailable under any circumstances
      return false;
    }

    if (firstRequirement === VerificationItemAccountCode.IDENTITY) {
      LOCAL_NOTIFICATIONS.error({
        text: i18n.t('validation.IDENTITY_VERIFICATION_REQUIRED'),
      });
      beforeNavigate?.();
      VerificationResource.launchIdentityVerification();
      return false;
    }

    if (firstRequirement === VerificationItemAccountCode.ADDRESS) {
      LOCAL_NOTIFICATIONS.error({
        text: i18n.t('validation.ADDRESS_VERIFICATION_REQUIRED'),
      });
      beforeNavigate?.();
      VerificationResource.launchAddressVerification();
      return false;
    }

    if (firstRequirement === VerificationItemAccountCode.FORM_A) {
      LOCAL_NOTIFICATIONS.error({
        text: i18n.t('validation.MISSING_FORM_A_SIGNED'),
      });
      beforeNavigate?.();
      VerificationResource.launchFormAVerification();
      return false;
    }

    if (firstRequirement === 'agreement') {
      beforeNavigate?.();
      SHARED_ROUTER_SERVICE.navigate('ReSignDisclosures');
      return false;
    }
  };

  @computed get shouldDisplayInitialCoindropModal(): boolean {
    return (
      this.hasCoindrops && !this.data.clientSettings.hasAlreadySeenCoindropModal
    );
  }

  @action setHasAlreadySeenCoindropModal = () => {
    this.data.clientSettings.hasAlreadySeenCoindropModal = true;

    return this.syncClientSettings();
  };

  @action setHasSeenMinerUpgradeModalFirstTime = () => {
    this.data.clientSettings.hasSeenMinerUpgradeModalFirstTime = true;

    return this.syncClientSettings();
  };

  @action setNewReferralBannerHidden = () => {
    this.data.clientSettings.newReferralBannerHidden = true;

    this.syncClientSettings();
  };

  @computed get newReferralEnabled() {
    return this.data.rewardVersion === 4 && this.data.enableRewards;
  }

  @action setHasSeenMinerTooltipFirstTime = () => {
    this.data.clientSettings.hasSeenMinerTooltipFirstTime = true;

    return this.syncClientSettings();
  };
}
