import * as React from 'react';
import {observer} from 'mobx-react';
import {Text, TextProps, Path} from 'react-native-svg';
import {FEBlockState} from '@youtoken/ui.resources-miner';
import {useTheme} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useLegacyOpacityAnimation, useMinerColors} from '../../hooks';

export type AmountProps = {
  amount: string;
  ticker: string;
  hexState: FEBlockState;
} & TextProps;

const OPACITIES: Record<FEBlockState, number> = {
  DISABLED: 0,
  AVAILABLE_NOT_ENOUGH_SPARKS: 0,
  AVAILABLE_ENOUGH_SPARKS: 0,
  MINING_STARTING: 0,
  MINING: 0,
  READY: 0,
  CLAIMING_STARTING: 0,
  CLAIMING: 1,
  CLAIMED_COLORED: 0,
  CLAIMED_GREY: 0,
  CLAIMED_INFO: 1,
};

const FONT_SIZE = 10;

export const Amount: React.FC<AmountProps> = observer(
  ({hexState, amount, ticker, ...rest}) => {
    const {textColor} = useMinerColors(hexState);
    const {t} = useTranslation();

    const style = useLegacyOpacityAnimation(OPACITIES, hexState);

    const text = `+${amount}`;

    const {fonts} = useTheme();

    if (style.opacity === 0) {
      return null;
    }

    return (
      <>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0303 6.46967C16.3232 6.76256 16.3232 7.23744 16.0303 7.53033L9.03033 14.5303C8.73744 14.8232 8.26256 14.8232 7.96967 14.5303L3.96967 10.5303C3.67678 10.2374 3.67678 9.76256 3.96967 9.46967C4.26256 9.17678 4.73744 9.17678 5.03033 9.46967L8.5 12.9393L14.9697 6.46967C15.2626 6.17678 15.7374 6.17678 16.0303 6.46967Z"
          fill={textColor}
          translateX={-8}
          translateY={-30}
          opacity={hexState === 'CLAIMED_INFO' ? 1 : 0}
        />

        <Text
          opacity={style.opacity}
          x={0}
          y={0}
          fill={textColor}
          textAnchor="middle"
          fontSize={FONT_SIZE}
          fontFamily={fonts.normal.bold}
          fontWeight={700}
          {...rest}
        >
          {text}
        </Text>
        <Text
          opacity={style.opacity}
          x={0}
          y={11}
          fill={textColor}
          textAnchor="middle"
          fontSize={8}
          fontFamily={fonts.normal.regular}
          fontWeight={400}
          {...rest}
        >
          {`${ticker.toUpperCase()} ${t('surface.miner.hexagon.earned')}`}
        </Text>
      </>
    );
  }
);
