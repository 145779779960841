import {nanoid} from 'nanoid';
import {SIMPLE_STORAGE} from '@youtoken/ui.service-storage';
import {SENTRY} from '@youtoken/ui.sentry';

const SESSION_ID_STORAGE_KEY = 'session_id';

export const getOrRegenerateSessionId = () => {
  return getSessionId() ?? regenerateSessionId();
};

export const regenerateSessionId = () => {
  const sessionId = nanoid();

  setSessionId(sessionId);

  return sessionId;
};

const getSessionId = () => {
  return SIMPLE_STORAGE.get(SESSION_ID_STORAGE_KEY);
};

const setSessionId = (sessionId: string) => {
  try {
    SIMPLE_STORAGE.set(SESSION_ID_STORAGE_KEY, sessionId);
  } catch (error: any) {
    SENTRY.capture(error, {
      source: 'setSessionId',
    });
  }
};
