import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, TouchableBox} from '@youtoken/ui.primitives';
import {Hidden, Other} from './components';
import {WalletsFeature} from '../../state';
import {SectionHeaderProps} from './types';

export const SectionHeader: React.FC<SectionHeaderProps & BoxProps> = observer(
  ({section, ...boxProps}) => {
    const {sections, sectionsVisibilities, setSectionVisibility} =
      WalletsFeature.use({});

    const tickers = React.useMemo(() => {
      return sections[section].map(({ticker}) => ticker);
    }, [sections, section]);

    const visibility = sectionsVisibilities[section];

    const handlePress = React.useCallback(() => {
      if (tickers.length > 0) {
        setSectionVisibility(section, !visibility);
      }
    }, [section, tickers, visibility, setSectionVisibility]);

    return (
      <TouchableBox
        testID={`WALLETS_SECTION_TOGGLER_${section.toUpperCase()}`}
        px={24}
        py={16}
        onPress={handlePress}
        {...boxProps}
      >
        {section === 'hidden' ? (
          <Hidden section={section} visibility={visibility} tickers={tickers} />
        ) : (
          <Other section={section} visibility={visibility} tickers={tickers} />
        )}
      </TouchableBox>
    );
  }
);
