import {deserialize} from 'serializr';
import {computed} from 'mobx';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {IncentivesResponse} from './IncentivesResponse';
import {getCoinSymbol, type currencyName} from '@youtoken/ui.coin-utils';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {formatDistanceToNowStrict} from '@youtoken/ui.date-fns';

export class IncentivesResource extends createStaticResource<
  {},
  IncentivesResponse
>({
  getKey: () => `IncentivesResource`,
  getData: async () => {
    return TRANSPORT.API.get('/v1/incentives', {}).then(({data}) => {
      return deserialize(IncentivesResponse, data);
    });
  },
  skipRefreshOnVisible: false,
}) {
  // todo use from utils/incentives-utils after Aleksey merges his branch into master
  incentivesTicker: currencyName = 'usd'; //  NOTE: we can't use yhusd in interfaces

  @computed get incentivesTickerSymbol() {
    return getCoinSymbol(this.incentivesTicker);
  }

  @computed get incentivesEnabled() {
    return this.data.enabled;
  }

  @computed get incentivesBalanceFormatted() {
    return formatByTicker(this.data.balance, this.incentivesTicker);
  }

  @computed get incentivesBurningIn() {
    return this.data.burningAt
      ? formatDistanceToNowStrict(this.data.burningAt, {
          addSuffix: true,
        })
      : undefined;
  }
}
