import React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {
  SavingsAndRewardsType,
  AuthMeResource,
} from '@youtoken/ui.resource-auth-me';
import {SavingsWidgetSmart} from '@youtoken/ui.surface-savings';
import {RewardsBannerSmart} from '@youtoken/ui.surfaces-referral-program';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {useResources, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {cell} from '@youtoken/ui.cell';

export const SavingsOrRewards: React.FC<BoxProps> = cell(
  boxProps => {
    const {
      me: {savings4Active},
      verifications: {hasKycOrFormABlocker},
    } = useResources({
      me: getResourceDescriptor(AuthMeResource, {}),
      verifications: getResourceDescriptor(VerificationResource, {}),
    });

    if (hasKycOrFormABlocker) {
      return null;
    }

    if (savings4Active) {
      return <SavingsWidgetSmart {...boxProps} />;
    }

    return <SavingsOrRewardsLegacy {...boxProps} />;
  },
  {
    SuspenseFallback: () => null,
  }
);

export const SavingsOrRewardsLegacy: React.FC<BoxProps> = observer(boxProps => {
  const {savingsAndRewardsType} = AuthMeResource.use({});

  if (!savingsAndRewardsType) {
    return null;
  }

  return (
    <>
      <Box flexDirection="row" {...boxProps}>
        {savingsAndRewardsType === SavingsAndRewardsType.REWARDS && (
          <Box flex={1}>
            <RewardsBannerSmart variant="row" />
          </Box>
        )}
      </Box>
    </>
  );
});
