import * as React from 'react';
import {DefaultSuspenseFallback, cell} from '@youtoken/ui.cell';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {FieldErrorMessage, useForm, Label} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {Combobox} from '@youtoken/ui.combobox';
import {
  countriesListSignUp,
  getCountriesListLocalized,
} from '@youtoken/ui.countries-utils';
import {SENTRY} from '@youtoken/ui.sentry';
import {TRANSPORT} from '@youtoken/ui.transport';
import {type SignUpForm} from '../state';
import {AxiosError} from '@youtoken/ui.errors';

type CountrySelectProps = {
  onChange?: (country: string) => void;
};

export const CountrySelect: React.FC<BoxProps & CountrySelectProps> = cell(
  ({onChange, ...boxProps}) => {
    const {t} = useTranslation();

    const {
      form: {
        countryItems,
        countryValue,
        hasCountryError,
        onCountryChange,
        countryError,
      },
    } = useForm<SignUpForm>();

    const handleCountryChange = (country: string) => {
      onCountryChange(country);
      onChange?.(country);
    };

    const [defaultCountryProcessed, setDefaultCountryProcessed] =
      React.useState(false);

    React.useEffect(() => {
      TRANSPORT.API.get(`/v1/auth/country`, {
        timeout: 5000,
      })
        .then(res => {
          const countryByIp = res.data?.iso3CountryCode;

          if (!countryByIp) {
            SENTRY.capture(new Error('Cant detect country'));
            return;
          }

          const item = getCountriesListLocalized(countriesListSignUp).find(
            item => item.code === countryByIp
          );

          if (!item) {
            return;
          }

          onCountryChange(item.code);
        })
        .catch((error: AxiosError) => {
          SENTRY.capture(error, {
            source: 'countrySelect',
          });
        })
        .finally(() => {
          setDefaultCountryProcessed(true);
        });
    }, []);

    if (!defaultCountryProcessed) {
      return <DefaultSuspenseFallback />;
    }

    return (
      <Box {...boxProps}>
        <Label mb={8}>{t('forms.sign_up.fields.country.label')}</Label>
        <Combobox
          size="large"
          items={countryItems}
          value={countryValue}
          onChange={handleCountryChange}
          hasError={hasCountryError}
          testID="COUNTRY_DD"
          nothingFoundItemKey="other"
        />
        <FieldErrorMessage visible={hasCountryError} placement="left">
          {getTranslatedValidationMessage(countryError)}
        </FieldErrorMessage>
      </Box>
    );
  },
  {
    displayName: 'CountrySelect',
    CellWrap: boxProps => {
      return <Box minHeight={68} zIndex={1} {...boxProps}></Box>;
    },
  }
);
