import {parseUrl} from 'query-string';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {TRANSPORT} from '@youtoken/ui.transport';

const urlPatternsDevelopment = [
  /^youhodler:\/\/.+/,
  /^http:\/\/localhost:3000\/.+/,
  /^https:\/\/.+\.youhodler\\.com\/.+/,
];

const urlPatternsProduction = [
  /^youhodler:\/\/.+/,
  /^https:\/\/app.youhodler.com\/.+/,
];

export const trackUrl = (url: string) => {
  if (!checkUrl(url)) {
    return;
  }

  return track(url);
};

const checkUrl = (url: string) => {
  return (
    ENVIRONMENT.DEV ? urlPatternsDevelopment : urlPatternsProduction
  ).some(r => r.test(url));
};

const track = (url: string) => {
  const {
    query: {link_id, ...data},
  } = parseUrl(url);

  if (link_id) {
    return TRANSPORT.API.post('/v1/trackLinkId', {
      link_id,
      data,
    });
  }
};
