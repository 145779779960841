import * as React from 'react';
import {
  TouchableBox,
  type TouchableBoxProps,
  Text,
} from '@youtoken/ui.primitives';

export const SelectActionButton: React.FC<TouchableBoxProps> = ({
  children,
  ...touchableBoxProps
}) => {
  return (
    <TouchableBox
      height={48}
      alignItems="flex-start"
      justifyContent="center"
      px={16}
      borderColor="$ui-01"
      borderTopWidth={1}
      borderBottomWidth={1}
      borderRadius={0}
      {...touchableBoxProps}
    >
      <Text color="$text-05">{children}</Text>
    </TouchableBox>
  );
};
