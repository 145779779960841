import {warning} from '@youtoken/ui.utils';

export const coinNames: {[key: string]: string} = {
  usd: 'U.S. Dollar',
  eur: 'Euro',
  eurs: 'Stasis Euro',
  gbp: 'British Pound',
  chf: 'Swiss Franc',
  rub: 'Rouble',
  btc: 'Bitcoin',
  eth: 'Ethereum',
  ltc: 'Litecoin',
  bch: 'Bitcoin Cash',
  xrp: 'Ripple',
  bsv: 'Bitcoin SV',
  xlm: 'Stellar',
  ethw: 'EthereumPoW',
  usdt: 'Tether',
  dash: 'Dash',
  eos: 'EOS Network',
  rep: 'Augur',
  bat: 'Basic Attention',
  hot: 'Holo',
  link: 'Chainlink',
  zil: 'Zilliqa',
  pax: 'Paxos Standard',
  paxg: 'PAX Gold',
  usdc: 'USD Coin',
  tusd: 'TrueUSD',
  bnb: 'Binance Coin',
  ybtc: 'YBTC',
  yusd: 'YUSD',
  ht: 'Huobi Token',
  dai: 'DAI',
  husd: 'HUSD',
  omg: 'OMG Network',
  zrx: 'ZRX',
  snx: 'Synthetix',
  yfi: 'yearn.finance',
  busd: 'BUSD',
  bnt: 'Bancor',
  sushi: 'Sushi Swap',
  doge: 'Dogecoin',
  shib: 'Shiba Inu',
  ada: 'Cardano',
  dot: 'Polkadot',
  xtz: 'Tezos',
  trx: 'TRON',
  aave: 'Aave',
  uni: 'Uniswap',
  comp: 'Compound',
  mkr: 'Maker',
  usdp: 'Pax Dollar',
  matic: 'Polygon',
  xmr: 'Monero',
  sol: 'Solana',
  theta: 'THETA',
  icp: 'Internet Computer',
  vet: 'VeChain',
  flr: 'Spark',
  sgb: 'Songbird',
  atom: 'Cosmos',
  avax: 'Avalanche',
  btt: 'BitTorrent',
  bttc: 'BitTorrent Chain',
  cake: 'PancakeSwap',
  egld: 'MultiversX',
  fil: 'Filecoin',
  ftm: 'Fantom',
  ftt: 'FTX Token',
  luna: 'Terra',
  near: 'NEAR Protocol',
  '1inch': '1inch Network',
  srm: 'Serum',
  wxt: 'Wirex Token',
  mav: 'Maverick Protocol',

  mana: 'Decentraland',
  sand: 'The Sandbox',
  axs: 'Axie Infinity',
  ilv: 'Illuvium',
  gala: 'Gala',

  ape: 'ApeCoin',

  gmt: 'STEPN',

  mhix: 'MultiHODL Index',

  gyen: 'Digital YEN',
  nzds: 'NZD Stablecoin',
  xchf: 'CryptoFranc',
  apt: 'Aptos',
  grt: 'The Graph',
  agix: 'SingularityNET',
  fet: 'Fetch.ai',
  thkd: 'TrueHKD',
  xaut: 'Tether Gold',
  pepe: 'Pepe',
  pyusd: 'PayPal USD',
  floki: 'Floki',
  arb: 'Arbitrum',
  sui: 'Sui',
  brz: 'Brazilian Digital Token',
  wld: 'Worldcoin',
  ton: 'Toncoin',
  algo: 'Algorand',
  inj: 'Injective',
  op: 'Optimism',
  rlb: 'Rollbit Coin',
  jup: 'Jupiter',
  bonk: 'Bonk',
  mnt: 'Mantle',
  stx: 'Stacks',
  tia: 'Celestia',
  wif: 'dogwifhat',
  meme: 'Memecoin',
  not: 'Notcoin',
  asi: 'Artificial Superintelligence',
  astr: 'Astar',
  ena: 'Ethena',
  hbar: 'Hedera',
  strk: 'Starknet',
  w: 'Wormhole',
  ray: 'Raydium',
  lista: 'Lista',
  pendle: 'Pendle',
  zro: 'LayerZero',
  hmstr: 'Hamster Kombat',
  boden: 'Jeo Boden',
  tremp: 'Doland Tremp',
  people: 'ConstitutionDAO',
  wen: 'Wen',
  io: 'io.net',
  myro: 'Myro',
  silly: 'Silly Dragon',
  dogs: 'DOGS',
  render: 'Render',
  beam: 'Beam',
  ens: 'Ethereum Name Service',
  flow: 'Flow',
  gt: 'GateToken',
  hnt: 'Helium',
  imx: 'Immutable',
  jasmy: 'JasmyCoin',
  ldo: 'Lido DAO',
  leo: 'UNUS SED LEO',
  om: 'MANTRA',
  ondo: 'Ondo',
  pyth: 'Pyth Network',
  qnt: 'Quant',
  zec: 'Zcash',
  banana: 'Banana Gun',
  pol: 'Polygon Ecosystem Token',
  yhdl: 'YHDL',
  yhdlbeta: 'YHDL - Beta',
  bars: 'Banksters Token',
  cati: 'Catizen',
  eigen: 'EigenLayer',
  popcat: 'Popcat (SOL)',

  // sentry fix
  tcad: 'TrueCAD',
  taud: 'TrueAUD',
  tgbp: 'TrueGBP',
  bonus: 'Deposit bonus',
};

export const getCoinName = (ticker: string): string => {
  const _ticker = ticker.replace('_', '');

  warning(coinNames[_ticker], `cannot find name for ticker`, {
    ticker,
  });

  return coinNames[_ticker] ?? ticker.toUpperCase();
};

/** @deprecated use `getCoinName` */
export const getCurrencyName = getCoinName;
