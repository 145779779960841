import * as React from 'react';
import {
  formatInstrumentsItems,
  getDefaultInstrument,
} from '@youtoken/ui.hodls-utils';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useResources, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {HodlsTariffsResource} from '@youtoken/ui.resource-hodl-tariffs';
import {cell} from '@youtoken/ui.cell';
import {type BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {type ActionProps} from '../types';
import {getActionButtonPropsByVariant} from '../utils';
import {WalletActionCellWrap} from './WalletActionCellWrap';
import {WalletActionSuspenseFallback} from './WalletActionSuspenseFallback';
import {WalletActionErrorFallback} from './WalletActionErrorFallback';

export const Trade: React.FC<ActionProps & BoxProps> = cell(
  ({
    tooltip,
    variant,
    item: {ticker, tickerFormatted, hodlEnabled},
    ...boxProps
  }) => {
    const {
      authMe: {enableHodl},
      tariffs: {tariffsCreateMHExists, data},
    } = useResources({
      authMe: getResourceDescriptor(AuthMeResource, {}),
      tariffs: getResourceDescriptor(HodlsTariffsResource, {}),
    });

    const disabled = React.useMemo(() => {
      return !hodlEnabled || !enableHodl || !tariffsCreateMHExists;
    }, [enableHodl, hodlEnabled, tariffsCreateMHExists]);

    const handlePressHodl = React.useCallback(() => {
      DATA_LAYER.trackStrict('wallet-open-hodl', {ticker});

      const instrument = getDefaultInstrument(
        formatInstrumentsItems(data),
        ticker,
        undefined
      );

      SHARED_ROUTER_SERVICE.navigate(
        'NewMultiHODL',
        {},
        {
          direction: 'buy',
          quoteTicker: instrument?.quoteTicker,
          baseTicker: instrument?.baseTicker,
          inputTicker: ticker,
        }
      );
    }, [ticker, data]);

    return (
      <Button
        testID={`${tickerFormatted}_BOOST_BUTTON`}
        icon="hodl"
        disabled={disabled}
        onPress={handlePressHodl}
        {...getActionButtonPropsByVariant(variant)}
        {...boxProps}
      />
    );
  },
  {
    CellWrap: WalletActionCellWrap,
    SuspenseFallback: WalletActionSuspenseFallback,
    ErrorFallback: WalletActionErrorFallback,
  }
);
