import {observable} from 'mobx';
import {
  serializable,
  primitive,
  list,
  object,
  deserialize,
  optional,
  custom,
} from 'serializr';
import {number} from '@youtoken/ui.utils-serializr';

export const optionalPerksDeserializer = custom(
  items => items,
  items => (items?.length ? deserializeOnboardingStepPerks(items) : undefined)
);

export class OnboardingStepPerksResponse {
  @serializable(optionalPerksDeserializer)
  @observable
  verificationPass?: TPerkItem[];

  @serializable(optionalPerksDeserializer)
  @observable
  firstDeposit?: TPerkItem[];

  @serializable(optionalPerksDeserializer)
  @observable
  firstTrade?: TPerkItem[];
}

export type TPerkItem =
  | CoindropPerk
  | SparksPerk
  | IncentivesPerk
  | SavingsPerk;

export class CoindropPerkDropItem {
  @observable
  @serializable(number())
  amount!: number;

  @observable
  @serializable(primitive())
  ticker!: string;
}

// #region params
export class CoindropPerkParams {
  @observable
  @serializable(list(object(CoindropPerkDropItem)))
  coins!: CoindropPerkDropItem[];
}

export class SparksPerkParams {
  @observable
  @serializable(number())
  amount!: number;
}

export class IncentivesPerkParams {
  @observable
  @serializable(number())
  percent!: number;

  @observable
  @serializable(optional(number()))
  amount?: number;
}

export class SavingsPerkParams {
  @observable
  @serializable(number())
  maxApr!: number;

  @observable
  @serializable(list(primitive()))
  tickers!: string[];
}
// #endregion params

//#region items
export class CoindropPerk {
  @observable
  @serializable(primitive())
  type: 'COINDROP' = 'COINDROP';

  @observable
  @serializable(object(CoindropPerkParams))
  params!: CoindropPerkParams;
}

export class SparksPerk {
  @observable
  @serializable(primitive())
  type: 'SPARKS' = 'SPARKS';

  @observable
  @serializable(object(SparksPerkParams))
  params!: SparksPerkParams;
}

export class IncentivesPerk {
  @observable
  @serializable(primitive())
  type: 'INCENTIVES' = 'INCENTIVES';

  @observable
  @serializable(object(IncentivesPerkParams))
  params!: IncentivesPerkParams;
}

export class SavingsPerk {
  @observable
  @serializable(primitive())
  type: 'SAVINGS' = 'SAVINGS';

  @observable
  @serializable(object(SavingsPerkParams))
  params!: SavingsPerkParams;
}
// #endregion items

// #region response
export const deserializeOnboardingStepPerks = (
  data: {type: string; params: Object}[]
): TPerkItem[] => {
  return data
    .map(item => {
      switch (item.type) {
        case 'COINDROP':
          return deserialize(CoindropPerk, item);
        case 'SPARKS':
          return deserialize(SparksPerk, item);
        case 'INCENTIVES':
          return deserialize(IncentivesPerk, item);
        case 'SAVINGS':
          return deserialize(SavingsPerk, item);
        default:
          return undefined;
      }
    })
    .filter(Boolean) as TPerkItem[];
};

// #endregion response
