import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {RewardBox} from '@youtoken/ui.elements';

type MinerPromiseBlockProps = {
  sparks: number;
};

export const MinerPromiseBlock: React.FC<MinerPromiseBlockProps> = observer(
  ({sparks}) => {
    const {t} = useTranslation();

    return (
      <RewardBox
        title={t('surface.wallets.first_action_modal.block_miner.title', {
          count: sparks,
        })}
        iconComponentName="Icon"
        iconName="spark"
        iconColor="$attention-01"
        iconBgColor="$attention-02"
        borderColor="$attention-03"
        text={t('surface.wallets.first_action_modal.block_miner.subtitle')}
      />
    );
  }
);
