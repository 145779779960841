import {Platform} from 'react-native';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {action, computed, observable, reaction} from 'mobx';
import Big from 'big.js';
import {toBig} from '@youtoken/ui.formatting-utils';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {calculateIncentivesDebounced} from '@youtoken/ui.incentives-utils';
import {FormBase} from '../FormBase';
import {
  type BuyCryptoFormAuthorizedStateBaseResources,
  DepositMethodRampEnum,
} from '../types';
import type {BuyCryptoFormAuthorizedOnRampStateArgs} from '../OnRamp/types';

export class Form extends FormBase {
  checkUrl = '/v2/converts/check';

  apiUrl = '/v1/on-ramp/start';

  @observable
  currentRequest = null;

  @observable
  incentivesBonus = toBig(0);

  @computed
  get redirectUrls() {
    return {
      successUrl: `/wallets/${this.conversionTicker}`,
      failUrl: `/wallets/${this.conversionTicker}`,
    };
  }

  @computed
  get advCashRedirectUrls() {
    const webUrl = `/wallets/${this.conversionTicker}`;

    return Platform.select({
      web: {
        successUrl: webUrl,
        failUrl: webUrl,
      },
      native: {
        successUrl: `/deposit/success`,
        failUrl: `/deposit/fail`,
      },
    })!;
  }

  @computed
  get bankCardsRedirectUrls() {
    const webUrl = `/wallets/${this.conversionTicker}`;

    return Platform.select({
      web: {
        successUrl: webUrl,
        declineUrl: webUrl,
        cancelUrl: webUrl,
      },
      native: {
        inprocessUrl: `/deposit/processing`,
        successUrl: `/deposit/success`,
        declineUrl: `/deposit/fail`,
        cancelUrl: `/deposit/cancel`,
      },
    })!;
  }

  @computed
  get possibleDepositMethods() {
    return [
      this.allowCheckoutBankCardFrame && DepositMethodRampEnum.BANK_CARD_FRAME,
      this.allowCheckoutBankCard && DepositMethodRampEnum.BANK_CARD,
      this.allowAdvCash && DepositMethodRampEnum.ADV_CASH,
    ].filter(Boolean) as DepositMethodRampEnum[];
  }

  @computed
  public get incentivesBonusFormatted(): string {
    return this.incentivesBonus.toFixed(getCoinDecimalPrecision('bonus'));
  }

  submit() {
    DATA_LAYER.trackStrict('buy-wizard-attempt', {
      method: this.depositMethod,
      fromAmount: this.amount,
      toAmount: this.conversionAmount,
      fromTicker: this.ticker,
      toTicker: this.conversionTicker,
    });

    return super.submit(this.args.onSubmit);
  }

  constructor(
    args: BuyCryptoFormAuthorizedOnRampStateArgs,
    resources: BuyCryptoFormAuthorizedStateBaseResources
  ) {
    super(args, resources);

    const calculateIncentivesDisposer = reaction(
      () => [this.amount, this.ticker],
      ([amount, ticker]) => {
        calculateIncentivesDebounced(amount, ticker, this.setIncentivesBonus);
      },
      {
        fireImmediately: true,
      }
    );

    this.disposers.push(calculateIncentivesDisposer);
  }

  @action setIncentivesBonus = (value: Big) => {
    this.incentivesBonus = value;
  };
}
