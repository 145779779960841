import {reaction, observable, runInAction} from 'mobx';
import {AxiosInstance} from 'axios';
import {Socket} from 'socket.io-client';
import EventEmitter from 'eventemitter3';
import io from 'socket.io-client';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {GLOBAL} from '@youtoken/ui.service-global';
import {invariant} from '@youtoken/ui.utils';
import {createRestApiClient} from './rest';

/** transport layer provider as service */
export class TransportService {
  @observable
  isInitialized: boolean = false;

  @observable.ref
  public API!: AxiosInstance;

  @observable.ref
  public SOCKET!: Socket;

  public events = new EventEmitter<'set'>();

  createSocket = () => {
    return io(ENVIRONMENT.SOCKET_URL, {
      transports: ['websocket'],
      autoConnect: true,
      reconnectionDelayMax: 5000,
      reconnectionDelay: 1000,
      reconnectionAttempts: Infinity,
      reconnection: true,
      path: '/ws/',
      auth: GLOBAL.token
        ? {
            authorization: GLOBAL.token,
          }
        : undefined,
    });
  };

  createApi = () => {
    return createRestApiClient();
  };

  initialize(
    createApi: () => AxiosInstance = this.createApi,
    createSocket: () => Socket = this.createSocket
  ) {
    invariant(!this.isInitialized, 'TransportService already initialized');

    this.createApi = createApi;
    this.createSocket = createSocket;

    reaction(
      () => {
        return [
          GLOBAL.token,
          ENVIRONMENT.SOCKET_URL,
          ENVIRONMENT.BACKEND_URL,
          ENVIRONMENT.SESSION_ID,
        ];
      },
      () => {
        if (this.SOCKET) {
          this.SOCKET.removeAllListeners();
          this.SOCKET.disconnect();
        }

        runInAction(() => {
          this.SOCKET = this.createSocket();
          this.API = this.createApi();
        });
      },
      {
        fireImmediately: true,
      }
    );
  }
}
