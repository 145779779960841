import * as React from 'react';
import {observer} from 'mobx-react';
import {TouchableBox, type BoxProps} from '@youtoken/ui.primitives';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Icon} from '@youtoken/ui.icons';
import {FreeBitcoinsModal} from './FreeBitcoinsModal';

export const FreeBitcoinsModalTrigger: React.FC<BoxProps> = observer(
  boxProps => {
    const {
      data: {
        clientSettings: {hasSeenMinerTooltipFirstTime},
      },
      setHasSeenMinerTooltipFirstTime,
    } = AuthMeResource.use({});

    const [isTooltipOpen, setTooltipOpen] = React.useState(
      !hasSeenMinerTooltipFirstTime
    );

    const handleCloseTooltip = React.useCallback(() => {
      setTooltipOpen(false);
      setHasSeenMinerTooltipFirstTime();
    }, [setHasSeenMinerTooltipFirstTime]);

    return (
      <>
        <TouchableBox
          onPress={() => setTooltipOpen(state => !state)}
          {...boxProps}
        >
          <Icon
            name="question_outlined"
            color={isTooltipOpen ? '$ui-04' : '$text-01'}
            size={28}
          />
        </TouchableBox>
        <FreeBitcoinsModal
          isOpen={isTooltipOpen}
          onClose={handleCloseTooltip}
        />
      </>
    );
  }
);
