import * as React from 'react';
import {observer} from 'mobx-react';
import {Svg} from 'react-native-svg';
import {Animated} from 'react-native';
import {FEBlockState} from '@youtoken/ui.resources-miner';
import {TickerColored} from './TickerColored';
import {AnimatedGroup} from '../AnimatedGroup';

type TickerColoredGroupProps = {
  width: number;
  height: number;
  offsetX: number;
  offsetY: number;
  xFromCorner: number;
  yFromCorner: number;
  hexState: FEBlockState;
  onAnimationEnd?: () => void;
};

export const TickerColoredGroup: React.FC<TickerColoredGroupProps> = observer(
  ({
    width,
    height,
    offsetX,
    offsetY,
    xFromCorner,
    yFromCorner,
    hexState,
    onAnimationEnd,
  }) => {
    const tickerTranslateX = React.useRef(new Animated.Value(-offsetX));
    const tickerTranslateY = React.useRef(new Animated.Value(-offsetY));
    const opacity = React.useRef(new Animated.Value(1));
    const zIndex = React.useRef(1);

    const style = React.useMemo(() => {
      return {
        transform: [
          {
            translateX: tickerTranslateX.current,
          },
          {
            translateY: tickerTranslateY.current,
          },
        ],
        opacity: opacity.current,
        zIndex: zIndex.current,
      };
    }, [
      tickerTranslateX.current,
      tickerTranslateY.current,
      opacity.current,
      zIndex.current,
    ]);

    React.useEffect(() => {
      if (hexState === 'CLAIMING_STARTING') {
        zIndex.current = 100;
      }

      if (hexState === 'CLAIMED_COLORED') {
        Animated.parallel([
          Animated.timing(tickerTranslateX.current, {
            toValue: -offsetX - xFromCorner,
            duration: 300,
            useNativeDriver: true,
          }),
          Animated.timing(tickerTranslateY.current, {
            toValue: -offsetX - yFromCorner,
            duration: 300,
            useNativeDriver: true,
          }),
          Animated.timing(opacity.current, {
            toValue: 0,
            duration: 300,
            useNativeDriver: true,
          }),
        ]).start(() => {
          onAnimationEnd?.();
          zIndex.current = 1;
        });
      }
    }, [hexState]);

    return (
      // @ts-ignore: expect-error - collision with animated and TS
      <AnimatedGroup style={style}>
        <Svg width={width} height={height} viewBox="0 0 64 74">
          <TickerColored hexState={hexState} />
        </Svg>
      </AnimatedGroup>
    );
  }
);
