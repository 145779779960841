import {
  action,
  comparer,
  computed,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import {TRANSPORT} from '@youtoken/ui.transport';
import {
  ActiveHODLsResource,
  ClosedHODLsResource,
  PendingHODLsResource,
} from '@youtoken/ui.resource-hodl';
import {getResourceDescriptor, createFeature} from '@youtoken/ui.data-storage';
import {RatesResource} from '@youtoken/ui.resource-rates';

interface HODLsPortfolioWSFeatureArgs {
  closedLimit?: number;
}

export class HODLsPortfolioWSFeature extends createFeature({
  getKey: ({closedLimit}: HODLsPortfolioWSFeatureArgs) =>
    `HODLsPortfolioWSFeature(${closedLimit})`,
  getResources: (args: HODLsPortfolioWSFeatureArgs) => {
    return {
      rates: getResourceDescriptor(RatesResource, {product: 'default'}),
      active: getResourceDescriptor(ActiveHODLsResource, {}),
      pending: getResourceDescriptor(PendingHODLsResource, {}),
      closed: getResourceDescriptor(ClosedHODLsResource, {
        limit: args.closedLimit,
        offset: 0,
        filter: [],
      }),
    };
  },
}) {
  @observable
  subscribed = false;

  @computed({equals: comparer.shallow})
  get hodlIds() {
    return [
      ...this.resources.active.data.rows,
      ...this.resources.pending.data.rows,
    ].map(h => h.id);
  }

  onInit() {
    super.onInit();

    TRANSPORT.SOCKET.on('connect', this.handleWsConnection);
  }

  onDestroy() {
    super.onDestroy();

    TRANSPORT.SOCKET.off('connect', this.handleWsConnection);
  }

  @action.bound
  handleWsConnection = () => {
    if (!this.subscribed) {
      return;
    }

    this.subscribeToUpdates();
  };

  @action subscribeToUpdates = () => {
    const disposer = reaction(
      () => this.hodlIds,
      ids => {
        TRANSPORT.SOCKET.emit('sub', {
          name: 'hodls',
          ids,
        });
      },
      {
        fireImmediately: true,
      }
    );

    TRANSPORT.SOCKET.emit('sub', {
      name: 'hodls:new',
    });

    this.subscribed = true;

    return () => {
      disposer();

      TRANSPORT.SOCKET.emit('unsub', {
        name: 'hodls',
      });

      TRANSPORT.SOCKET.emit('unsub', {
        name: 'hodls:new',
      });

      runInAction(() => {
        this.subscribed = false;
      });
    };
  };
}
