import * as React from 'react';
import {TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {type ArrowProps} from '../types';
import {useHover} from 'react-use-gesture';

const ARROW_SIZE = 32;

export const Arrow: React.FC<ArrowProps> = ({
  direction,
  iconColor,
  onPress,
  ...boxProps
}) => {
  const handleOnPress = React.useCallback(() => {
    onPress(direction);
  }, [direction, onPress]);

  const [hovering, setHovering] = React.useState(false);

  const hover = useHover(
    React.useCallback(({hovering}) => {
      setHovering(hovering);
    }, [])
  );

  return (
    <TouchableBox
      position="absolute"
      top="50%"
      alignItems="center"
      style={{
        ...(direction === 'back' && {left: 12}),
        ...(direction === 'forward' && {right: 12}),
        transform: [
          {
            translateY: -16,
          },
        ],
      }}
      backdropFilter={'blur(10px)'}
      justifyContent="center"
      onPress={handleOnPress}
      width={ARROW_SIZE}
      height={ARROW_SIZE}
      borderRadius={ARROW_SIZE / 2}
      backgroundColor={hovering ? '$blackout-01' : '$ui-shadow'}
      zIndex={1}
      {...hover()}
      {...boxProps}
    >
      <Icon
        size={20}
        color={iconColor || '$ui-background'}
        name={direction === 'back' ? 'chevron_left' : 'chevron_right'}
      />
    </TouchableBox>
  );
};
