import * as React from 'react';
import {observer} from 'mobx-react';
import {Svg, G, Polygon} from 'react-native-svg';
import {Animated, Platform, ViewStyle} from 'react-native';
import {MinerBlockData} from '@youtoken/ui.resources-miner';
import {MinerOverviewResourceContext} from '../../../context';
import {HexUtils, useLayoutContext} from '../_Library';
import {AnimatedGroup} from './AnimatedGroup';
import {
  Background,
  InnerHexagon,
  Border,
  TickerGrey,
  Timer,
  SparkPrice,
  Amount,
  TickerColoredGroup,
} from './elements';

const isNative = Platform.select({native: true, default: false});

export type HexagonProps = {
  size: number;
  q: number;
  r: number;
  s: number;
  block: MinerBlockData;
  onPress?: (block: MinerBlockData) => void;
  isPressing?: boolean;
};

/**
 * Renders a Hexagon cell at the given rqs-based coordinates.
 */
export const Hexagon: React.FC<HexagonProps> = observer(
  ({size, q, r, s, block, isPressing}) => {
    const {
      id,
      earnAmountFormatted,
      earnAmountTickerFormatted,
      miningPrice,
      timeLeft,
    } = block;

    const {
      onTimerEnd,
      onClaimBounceEnd,
      onClaimFlyOffEnd,
      onGreyBounceEnd,
      getBlockFinalStatus,
    } = React.useContext(MinerOverviewResourceContext).use({});

    const hexState = getBlockFinalStatus(id)!;

    const {layout} = useLayoutContext();

    const {x: xFromCorner, y: yFromCorner} = React.useMemo(() => {
      return HexUtils.hexToPixel({q, r, s}, layout);
    }, [q, r, s, layout]);

    const hexWidth = size * Math.sqrt(3);
    const hexHeight = size * 2;
    const offsetX = hexWidth / 2;
    const offsetY = hexHeight / 2;

    const scale = React.useRef(new Animated.Value(1));
    const x = React.useRef(new Animated.Value(isNative ? 0 : xFromCorner));
    const y = React.useRef(new Animated.Value(isNative ? 0 : yFromCorner));
    const opacity = React.useRef(new Animated.Value(1));

    const style = React.useMemo(() => {
      return {
        transform: [
          {
            translateX: x.current,
          },
          {
            translateY: y.current,
          },
          {
            scale: scale.current,
          },
        ],
        opacity: opacity.current,
      } as ViewStyle;
    }, [scale, x, y, opacity]);

    const {loopScaleDown, loopScaleUp, bounce, reset, shrink} =
      React.useMemo(() => {
        const loopScaleDown = Animated.sequence([
          Animated.timing(scale.current, {
            toValue: 1,
            duration: 30,
            useNativeDriver: false,
          }),
          Animated.loop(
            Animated.sequence([
              Animated.timing(scale.current, {
                toValue: 0.95,
                duration: 600,
                useNativeDriver: false,
              }),
              Animated.timing(scale.current, {
                toValue: 1,
                duration: 600,
                useNativeDriver: false,
              }),
            ])
          ),
        ]);

        // loading
        const loopScaleUp = Animated.sequence([
          Animated.timing(scale.current, {
            toValue: 1,
            duration: 30,
            useNativeDriver: false,
          }),
          Animated.loop(
            Animated.sequence([
              Animated.timing(scale.current, {
                toValue: 1.1,
                duration: 300,
                useNativeDriver: false,
              }),
              Animated.timing(scale.current, {
                toValue: 1,
                duration: 300,
                useNativeDriver: false,
              }),
            ])
          ),
        ]);

        // claming and grey_info
        const bounce = Animated.sequence([
          Animated.timing(scale.current, {
            toValue: 1,
            duration: 30,
            useNativeDriver: false,
          }),
          Animated.spring(scale.current, {
            damping: 10,
            stiffness: 300,
            mass: 0.9,
            restSpeedThreshold: 0.001,
            toValue: 1.5,
            useNativeDriver: false,
          }),
          Animated.delay(700),
          Animated.timing(scale.current, {
            toValue: 1.55,
            duration: 175,
            useNativeDriver: false,
          }),
          Animated.timing(scale.current, {
            toValue: 1,
            duration: 175,
            useNativeDriver: false,
          }),
        ]);

        // shrink and make a bit transparent on mouse down
        const shrink = Animated.parallel([
          Animated.timing(scale.current, {
            toValue: 0.95,
            duration: 300,
            useNativeDriver: false,
          }),
          Animated.timing(opacity.current, {
            toValue: 0.7,
            duration: 300,
            useNativeDriver: false,
          }),
        ]);

        const reset = Animated.timing(scale.current, {
          toValue: 1,
          duration: 30,
          useNativeDriver: false,
        });

        return {
          loopScaleDown,
          loopScaleUp,
          bounce,
          reset,
          shrink,
        };
      }, []);

    // on press down
    React.useEffect(() => {
      if (isPressing) {
        shrink.start();
      } else {
        shrink.reset();
        reset.start();
      }
    }, [isPressing]);

    // on hexState change
    React.useEffect(() => {
      loopScaleUp.reset();
      loopScaleDown.reset();
      bounce.reset();
      reset.reset();
      reset.start();

      if (hexState === 'READY') {
        setTimeout(() => {
          loopScaleDown.start();
        }, 0);
        return;
      }
      if (hexState === 'MINING_STARTING' || hexState === 'CLAIMING_STARTING') {
        loopScaleUp.start();
        return;
      }
      if (hexState === 'CLAIMING' || hexState === 'CLAIMED_INFO') {
        bounce.start(({finished}) => {
          if (finished) {
            handleBounceEnd();
          }
        });
        return;
      }
      if (hexState === 'CLAIMED_COLORED' || hexState === 'CLAIMED_GREY') {
        return;
      }
    }, [hexState]);

    const handleBounceEnd = () => {
      if (hexState === 'CLAIMING') {
        onClaimBounceEnd(id);
      }
      if (hexState === 'CLAIMED_INFO') {
        onGreyBounceEnd(id);
      }
    };

    const handleFlyOffEnd = () => {
      onClaimFlyOffEnd(id);
    };

    const handleTimerEnd = () => {
      onTimerEnd(id);
    };

    return (
      <AnimatedGroup
        testID={id}
        key={`${q},${r},${s},${id}`}
        style={style}
        transform={`translate(${xFromCorner}, ${yFromCorner})`}
      >
        <G transform={`translate(${-offsetX}, ${-offsetY})`}>
          <Svg width={hexWidth} height={hexHeight} viewBox="0 0 64 74">
            <Background hexState={hexState} />
            <Border hexState={hexState} />
            <InnerHexagon hexState={hexState} />
            <TickerGrey hexState={hexState} />
          </Svg>
        </G>

        <Timer
          hexState={hexState}
          timeLeft={timeLeft}
          onTimerEnd={handleTimerEnd}
        />
        <SparkPrice price={miningPrice} hexState={hexState} />
        {earnAmountFormatted && earnAmountTickerFormatted && (
          <Amount
            amount={earnAmountFormatted}
            ticker={earnAmountTickerFormatted}
            hexState={hexState}
          />
        )}

        <TickerColoredGroup
          width={hexWidth}
          height={hexHeight}
          offsetX={offsetX}
          offsetY={offsetY}
          xFromCorner={xFromCorner}
          yFromCorner={yFromCorner}
          hexState={hexState}
          onAnimationEnd={handleFlyOffEnd}
        />
      </AnimatedGroup>
    );
  }
);

type ClickableHexagonProps = HexagonProps & {
  onPress: (block: MinerBlockData) => void;
  onPressIn: (block: MinerBlockData) => void;
  onPressOut: () => void;
};

export const ClickableHexagon: React.FC<ClickableHexagonProps> = observer(
  ({q, r, s, block, onPress, onPressIn, onPressOut}) => {
    const {id} = block;

    const {getBlockFinalStatus} = React.useContext(
      MinerOverviewResourceContext
    ).use({});

    const {layout, points} = useLayoutContext();

    const hexState = getBlockFinalStatus(id);

    const {x: xFromCorner, y: yFromCorner} = React.useMemo(() => {
      return HexUtils.hexToPixel({q, r, s}, layout);
    }, [q, r, s, layout]);

    const cursor = React.useMemo(() => {
      if (
        hexState === 'AVAILABLE_NOT_ENOUGH_SPARKS' ||
        hexState === 'AVAILABLE_ENOUGH_SPARKS' ||
        hexState === 'READY' ||
        hexState === 'CLAIMED_GREY'
      ) {
        return 'pointer';
      }
      return 'default';
    }, [hexState]);

    const handlePress = () => {
      onPress(block);
    };

    const handlePressIn = () => {
      onPressIn(block);
    };

    const onPressProps = Platform.select({
      web: {
        onClick: handlePress,
        onMouseDown: handlePressIn,
        onMouseUp: onPressOut,
      },
      default: {
        onPress: handlePress,
        onPressIn: handlePressIn,
        onPressOut,
      },
    });

    return (
      <Polygon
        testID={id}
        points={points}
        fill="transparent"
        transform={`translate(${xFromCorner}, ${yFromCorner})`}
        // @ts-expect-error
        cursor={cursor}
        {...onPressProps}
      />
    );
  }
);
