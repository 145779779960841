import * as React from 'react';
import {Helmet} from 'react-helmet';
import {cell} from '@youtoken/ui.cell';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import {Content} from './Content';
import {InstaStoriesResource} from '@youtoken/ui.resource-insta-stories';
import {RouterResource} from '../../../routes/RouterResource';

export const WalletsList: React.FC = cell(() => {
  const {t} = useTranslation();
  const {
    queryParams: {storyId},
  } = RouterResource.use({});
  const storiesResource = InstaStoriesResource.getInstanceSafely({});

  React.useEffect(() => {
    if (storyId) {
      storiesResource?.updateActiveStoryIndex(storyId);
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, []);

  return (
    <InnerPageShell>
      <Helmet title={t('surface.wallets.tab_title')} />
      <Content />
    </InnerPageShell>
  );
});
