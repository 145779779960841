import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Select} from '@youtoken/ui.select';
import {Icon} from '@youtoken/ui.icons';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

const DownSortingIcon = (
  <Box flexDirection="row" mr={-8}>
    <Icon
      name="sorting"
      style={{
        transform: [{translateX: -4}],
      }}
      size={20}
    />
  </Box>
);

const UpSortingIcon = (
  <Box flexDirection="row" mr={-8}>
    <Icon
      name="sorting"
      style={{
        transform: [{rotate: '180deg'}, {translateX: 4}],
      }}
      size={20}
    />
  </Box>
);

export const InstrumentsSorter: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {sortingItems, activeSortingItemValue, setActiveSortingItemValue} =
    HODLsTariffsFeature.use({});

  const sortingItemsForSelect = sortingItems.map(item => ({
    value: item.value,
    label: item.label,
    icon: item.direction === 'asc' ? UpSortingIcon : DownSortingIcon,
  }));

  const handleSort = React.useCallback(
    (value: string) => {
      const [category, direction] = value.split('_');
      DATA_LAYER.trackStrict('hodl-instruments-sorting', {
        category: category || '',
        direction: direction || '',
      });
      setActiveSortingItemValue(value);
    },
    [setActiveSortingItemValue]
  );

  return (
    <Box {...boxProps}>
      <Select
        items={sortingItemsForSelect}
        onSelect={handleSort}
        selected={activeSortingItemValue}
        withExpandIcon={false}
        size="small"
        dropdownWidth={144}
        bottomSheetTitle={t('surface.hodls.instruments.sorter_title')}
        testID="INSTRUMENTS_SORTING_SELECT"
      />
    </Box>
  );
});
