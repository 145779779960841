import * as React from 'react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {NewReferralActivity} from '@youtoken/ui.resource-new-referral';

type SingleFriendRewardModalContentProps = {activity: NewReferralActivity};

export const SingleFriendRewardModalContent: React.FC<
  SingleFriendRewardModalContentProps & BoxProps
> = ({activity, ...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Box flexDirection="column" alignItems="center" {...boxProps}>
      <Text variant="$body-02" color="$text-02" mb={16}>
        {activity.createdAtFormatted}
      </Text>
      <Box
        backgroundColor="$success-02"
        width={40}
        height={40}
        borderRadius={40}
        justifyContent="center"
        alignItems="center"
        mb={8}
      >
        <Icon name="gift" color="$success-01" size={24} />
      </Box>
      <Text variant="$heading-02" mb={16}>
        {activity.inviteeName}
      </Text>
      <Box px={8} py={4} mb={24} backgroundColor="$ui-01" borderRadius={22}>
        <Text variant="$body-02">{activity.inviteeEmail}</Text>
      </Box>

      <Text variant="$body-02" color="$text-02" mb={4}>
        {t('surface.new_referral.reward.you_earned')}
      </Text>
      <Text variant="$heading-02" mb={4}>
        +{activity.rewardBTCFormatted} BTC
      </Text>
      <Text color="$text-02">${activity.rewardUSDFormatted}</Text>
    </Box>
  );
};
