import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Box, BoxProps, useIsMobile} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {Combobox} from '@youtoken/ui.combobox';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {InstrumentListItem} from '../InstrumentsList';

export const InstrumentsSearchBar: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();
  const isMobileWeb = isMobile && Platform.OS === 'web';

  const {instrumentsSorted} = HODLsTariffsFeature.use({list: 'search'});

  const items = React.useMemo(() => {
    return instrumentsSorted.map(item => ({
      item,
      touchable: false,
      height: 64,
      key: `${item.baseTicker}${item.quoteTicker}`,
      label: `${item.baseTickerFormatted}/${item.quoteTickerFormatted}`,
      value: item.id,
      // this hack is for MH item content to fit inside the dropdown...
      ...(isMobileWeb ? {px: 12} : {}),
    }));
  }, [instrumentsSorted]);

  const handleChange = (value?: string) => {
    if (value) {
      DATA_LAYER.trackStrict('hodl-open-instrument', {
        openedFrom: 'top_search',
      });
      SHARED_ROUTER_SERVICE.navigate('HODLInstrument', {
        symbol: value,
      });
    }
  };

  return (
    <Box {...boxProps}>
      <Combobox
        size="medium"
        placeholder={t('common.placeholder.search')}
        items={items}
        itemHeight={64}
        maxItemsAtATime={6}
        onChange={handleChange}
        // @ts-ignore
        ItemComponent={InstrumentListItem}
      />
    </Box>
  );
});
