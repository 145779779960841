import * as React from 'react';
import {observer} from 'mobx-react';
import {Path} from 'react-native-svg';
import {FEBlockState} from '@youtoken/ui.resources-miner';
import {useLegacyOpacityAnimation, useMinerColors} from '../../hooks';

type BackgroundProps = {
  hexState: FEBlockState;
};

const OPACITIES: Record<FEBlockState, number> = {
  DISABLED: 1,
  AVAILABLE_NOT_ENOUGH_SPARKS: 1,
  AVAILABLE_ENOUGH_SPARKS: 1,
  MINING_STARTING: 1,
  MINING: 1,
  READY: 1,
  CLAIMING_STARTING: 1,
  CLAIMING: 1,
  CLAIMED_COLORED: 1,
  CLAIMED_GREY: 0,
  CLAIMED_INFO: 1,
};

export const Background: React.FC<BackgroundProps> = observer(({hexState}) => {
  const {bgColor} = useMinerColors(hexState);

  const style = useLegacyOpacityAnimation(OPACITIES, hexState);

  if (style.opacity === 0) {
    return null;
  }

  return (
    <Path
      opacity={style.opacity}
      d="M27.996 2.31436L3.99598 16.1894C1.52304 17.619 0 20.2588 0 23.1152V50.8838C0 53.7403 1.52304 56.38 3.99598 57.8097L27.996 71.6847C30.4732 73.1169 33.5268 73.1169 36.004 71.6847L60.004 57.8097C62.477 56.38 64 53.7403 64 50.8838V23.1152C64 20.2588 62.477 17.619 60.004 16.1894L36.004 2.31435C33.5268 0.882192 30.4732 0.882195 27.996 2.31436Z"
      fill={bgColor}
    />
  );
});
