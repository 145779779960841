import {deserialize} from 'serializr';
import Big from 'big.js';
import {computed, observable, action} from 'mobx';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {
  NewReferralActivity,
  NewReferralActivityEvent,
  NewReferralActivityResponse,
} from './NewReferralActivityResponse';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {DEFAULT_LIMIT, DEFAULT_OFFSET} from '../constants';

export {NewReferralActivity, NewReferralActivityEvent};

export interface NewReferralActivityResourceArgs {
  limit?: number;
  offset?: number;
  inviteeId?: string;
}

export class NewReferralActivityResource extends createStaticResource({
  getKey: (args: NewReferralActivityResourceArgs) =>
    `newReferralActivityResource:${JSON.stringify(args)}`,
  getData: ({
    limit = DEFAULT_LIMIT,
    offset = DEFAULT_OFFSET,
    inviteeId,
  }: NewReferralActivityResourceArgs) =>
    TRANSPORT.API.get('/v3/referral/activity', {
      params: {limit, offset, inviteeId},
    }).then(res => deserialize(NewReferralActivityResponse, res.data)),
}) {
  @computed get activities() {
    return this.data.activities;
  }

  @computed get total() {
    return this.data.total;
  }

  @computed get rewards() {
    return this.activities.filter(
      activity => activity.event === NewReferralActivityEvent.REWARD
    );
  }

  @computed get totalEarnedBtcFormatted() {
    const sum = this.rewards.reduce(
      (acc, reward) => acc.plus(reward?.rewardBTC || new Big(0)),
      new Big(0)
    );
    return formatByTicker(sum, 'btc');
  }

  @computed get totalEarnedUsdFormatted() {
    const sum = this.rewards.reduce(
      (acc, reward) => acc.plus(reward.rewardUSD || new Big(0)),
      new Big(0)
    );
    return formatByTicker(sum, 'usd');
  }

  // Pagination

  @computed
  public get pageSize() {
    return this.args.limit || DEFAULT_LIMIT;
  }

  @computed
  public get totalPages() {
    return Math.ceil(this.total / this.pageSize);
  }

  @observable
  currentPage: number = 1;

  @action setPage = (page: number) => {
    const offset = this.pageSize * (page - 1);
    this.currentPage = page;

    if (offset > this.total) {
      return;
    }

    this.updateArgs({offset});
  };

  // Pagination end

  @action updateArgs = (newArgs: Partial<NewReferralActivityResourceArgs>) => {
    this.args = {
      ...this.args,
      ...newArgs,
    };
  };
}
