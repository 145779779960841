import {warning} from '@youtoken/ui.utils';

export type currencyName = 'usd' | 'eur' | 'btc';

export const coinSymbols: {[currency in currencyName]: string} = {
  usd: '$',
  eur: '€',
  btc: '₿',
};

export const getCoinSymbol = (ticker: currencyName) => {
  const symbol = coinSymbols[ticker];

  warning(symbol, `getCoinSymbol: symbol by ticker not found`, {
    ticker,
  });

  return symbol;
};
