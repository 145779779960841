import * as React from 'react';
import {Box, TouchableBox, Text, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {NewReferralActivity} from '@youtoken/ui.resource-new-referral';

type JoinedListItemProps = {
  activity: NewReferralActivity;
  onPress?: (activity: NewReferralActivity) => void;
};

export const JoinedListItem: React.FC<JoinedListItemProps & BoxProps> = ({
  activity,
  onPress,
  ...boxProps
}) => {
  return (
    <TouchableBox
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      borderWidth={1}
      borderColor="$ui-01"
      borderRadius={12}
      px={16}
      py={12}
      onPress={() => onPress?.(activity)}
      {...boxProps}
    >
      <Box flexDirection="row" alignItems="center">
        <Box
          width={40}
          height={40}
          borderRadius={20}
          mr={12}
          backgroundColor="$interactive-02"
          justifyContent="center"
          alignItems="center"
        >
          <Icon name="profile" size={24} color="$interactive-01" />
        </Box>
        <Box flexDirection="column">
          <Text testID="ACTIVITY_INVITEE_NAME">{activity.inviteeName}</Text>
          <Text variant="$body-02" color="$text-02">
            {activity.inviteeEmail}
          </Text>
        </Box>
      </Box>

      <Box flexDirection="column" alignItems="flex-end">
        <Text mb={2}>+{activity.rewardBTCFormatted} BTC</Text>
        <Text variant="$body-02" color="$text-02">
          ${activity.rewardUSDFormatted}
        </Text>
      </Box>
    </TouchableBox>
  );
};
