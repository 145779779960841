import * as React from 'react';
import {observer} from 'mobx-react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {Separator} from '@youtoken/ui.elements';
import {Box} from '@youtoken/ui.primitives';
import {
  AdjustPendingOrderSection,
  AgreementAndDetailsSection,
  ChartFullViewSection,
  HODLActionsSection,
  InfoTableSection,
  OpenHistorySection,
  OverviewSection,
  RepeatTradeSection,
  TradingViewChartSection,
} from '../sections';
import {HODLByIdFeature} from '../../features';
import {HODLItemStatusButton, OpenNowButton} from '../../components';
import {MarginCallIndicator} from '@youtoken/ui.hodl-elements';

interface HODLPageItemSurfaceProps {
  id: string;
  isRedirectFromFlipOrder?: boolean;
  onClickCloseNow: () => void;
  onClickAdjustHODL: () => void;
  onClickExtendHODL: () => void;
  onClickFlipHODL: () => void;
}

export const HODLPageItemSurface: React.FC<HODLPageItemSurfaceProps> = observer(
  ({
    id,
    isRedirectFromFlipOrder,
    onClickAdjustHODL,
    onClickExtendHODL,
    onClickFlipHODL,
  }) => {
    const {hodl, isExtendTpSlAvailableByVersion, subscribeToUpdates} =
      HODLByIdFeature.use({
        id,
      });

    const [isTradingViewChartOpen, setIsTradingViewChartOpen] =
      React.useState(false);

    const handleTradingViewChartOpen = React.useCallback(() => {
      setIsTradingViewChartOpen(true);
    }, []);

    const handleTradingViewChartClose = React.useCallback(() => {
      setIsTradingViewChartOpen(false);
    }, []);

    React.useEffect(subscribeToUpdates, []);

    React.useEffect(() => {
      if (hodl) {
        DATA_LAYER.trackStrict('hodl-open', {id});
      }
    }, []);

    if (!hodl) {
      if (isRedirectFromFlipOrder) {
        SHARED_ROUTER_SERVICE.navigate('MultiHODLPortfolio');
      } else {
        SHARED_ROUTER_SERVICE.navigate('NotFound', {});
      }
      return null;
    }

    return (
      <Box position="relative">
        <MarginCallIndicator hasAlertByMC={hodl.hasAlertByMC} />
        <OverviewSection hodl={hodl} px={24} py={16}>
          <Box ml={{default: 0, tablet: 24}} pt={{default: 24, tablet: 0}}>
            {hodl.isPending ? (
              <OpenNowButton
                hodl={hodl}
                minWidth={156}
                size="large"
                testID="PENDING_OPEN_NOW_BUTTON"
              />
            ) : (
              <HODLItemStatusButton
                hodl={hodl}
                testID="OPEN_CLOSE_AND_GET_MODAL_BUTTON"
                size="medium"
              />
            )}
          </Box>
        </OverviewSection>
        <Separator />
        {!isTradingViewChartOpen && (
          <ChartFullViewSection
            hodl={hodl}
            onPressTradingViewModeButton={handleTradingViewChartOpen}
          />
        )}
        {isTradingViewChartOpen && (
          <TradingViewChartSection
            hodlId={id}
            onClose={handleTradingViewChartClose}
          />
        )}
        {hodl.isOpen && (
          <HODLActionsSection
            hodl={hodl}
            onClickAdjustHODL={onClickAdjustHODL}
            onClickExtendHODL={onClickExtendHODL}
            onClickFlipHODL={onClickFlipHODL}
          />
        )}
        {hodl.isPending && <AdjustPendingOrderSection hodl={hodl} />}
        {hodl.isClosedLike && <RepeatTradeSection hodl={hodl} />}
        <InfoTableSection hodl={hodl} />
        <AgreementAndDetailsSection hodl={hodl} />
        {isExtendTpSlAvailableByVersion && (
          <>
            <Separator />
            <OpenHistorySection id={id} />
          </>
        )}
        {hodl.isPending && (
          <>
            <Separator />
            <Box px={20} py={24}>
              <HODLItemStatusButton
                hodl={hodl}
                testID="PENDING_CLOSE_AND_GET_BUTTON"
                size="large"
              />
            </Box>
          </>
        )}
      </Box>
    );
  }
);
