import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {CardItem} from '@youtoken/ui.resource-cards';
import {Box, Text} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {
  Icon,
  IconPaymentMethod,
  IconPaymentMethodName,
} from '@youtoken/ui.icons';
import {observer} from 'mobx-react';

interface ManagePaymentMethodsSurfaceProps {
  item: CardItem;
  onPressRemove: (id: string) => void;
  onPressSetDefault: (id: string) => void;
}

export const ManageCardItemComponent: React.FC<ManagePaymentMethodsSurfaceProps> =
  observer(({item, onPressRemove, onPressSetDefault}) => {
    const {t} = useTranslation();
    const {id, last4, expMonth, expYear, brand, main} = item;
    const iconName: IconPaymentMethodName =
      brand.toLowerCase() === 'visa' ? 'visa' : 'mastercard';

    return (
      <Box borderWidth={1} borderColor="$ui-01" borderRadius={6} mb={16}>
        <Box flexDirection="row" p={24} justifyContent="space-between">
          <Box height={20} flexDirection="row" alignItems="center">
            <IconPaymentMethod name={iconName} size={32} />
            <Box
              width={3}
              height={3}
              borderRadius={3}
              backgroundColor="$text-01"
              ml={16}
              mr={3}
            />
            <Text>{last4}</Text>
          </Box>
          <Text variant="$body-02" color="$text-02">
            {t('surface.wallets.checout_bank_card.expires') +
              ' ' +
              expMonth.toString() +
              '/' +
              expYear.toString()}
          </Text>
        </Box>
        <Separator />
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          p={8}
        >
          {main ? (
            <Box flexDirection="row" alignItems="center" gap={4}>
              <Icon name="success" color="$success-01" />
              <Text variant="$body-02-medium-accent" color="$success-01">
                {t('surface.wallets.bank_card.default_card')}
              </Text>
            </Box>
          ) : (
            <Button
              type="ghost"
              size="small"
              onPress={() => onPressSetDefault(id)}
              testID={`${last4}_SET_DEFAULT_CARD_BUTTON`}
            >
              {t('surface.common.set_as_default')}
            </Button>
          )}
          <Button
            icon="remove"
            type="ghost"
            size="small"
            ml={12}
            onPress={() => onPressRemove(id)}
            testID={`${last4}_CARD_REMOVE_CARD_BUTTON`}
          />
        </Box>
      </Box>
    );
  });
