import * as React from 'react';
import {observer} from 'mobx-react';
import {Link} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const CnvLink: React.FC = observer(() => {
  const {t} = useTranslation();
  return (
    <Link
      variant="$body-02"
      color="$text-01"
      url="https://www.cnv.gov.ar/SitioWeb/ProveedoresServiciosActivosVirtuales/RegistrosPSAV"
    >
      {t('company.youhodler.legal_documents.cnv.registration.name')}
    </Link>
  );
});
