import * as React from 'react';
import {NotificationsWidget} from '@youtoken/ui.notifications';
import {StoriesList} from '@youtoken/ui.insta-stories';
import {Item} from '../types';
import {DepositNow} from '../../../smart';
import {IncentivesBalance} from '../../../Incentives';
import {CoindropBanner} from '../../../Coindrop';
import {InfoMessageWidget} from '../../../InfoMessageWidget';
import {OnboardingWidget} from '../../../OnboardingWidget';
import {CarouselBanners} from '../../../CarouselBanners';
import {
  BankCardsPromoBanner,
  BankCardsWidget,
} from '@youtoken/ui.surface-wallets-bank-cards';
import {NewReferralBanner} from '@youtoken/ui.surface-new-referral';
import {
  ActionsBar,
  Summary,
  SavingsOrRewards,
  Header,
  SectionHeader,
  SectionItem,
  WidgetsBar,
} from '../components';

export const renderItem = ({item}: {item: Item}) => {
  if (item.type === 'notifications') {
    return <NotificationsWidget {...item.data} />;
  }

  if (item.type === 'summary') {
    return <Summary {...item.data} />;
  }

  if (item.type === 'stories') {
    return <StoriesList {...item.data} />;
  }

  if (item.type === 'actions-bar') {
    return <ActionsBar {...item.data} />;
  }

  if (item.type === 'onboarding-widget') {
    return <OnboardingWidget {...item.data} />;
  }

  if (item.type === 'carousel-banners') {
    return <CarouselBanners {...item.data} />;
  }

  if (item.type === 'widgets-bar') {
    return <WidgetsBar {...item.data} />;
  }

  if (item.type === 'savings-or-rewards') {
    return <SavingsOrRewards {...item.data} />;
  }

  if (item.type === 'info-message') {
    return <InfoMessageWidget {...item.data} />;
  }

  if (item.type === 'referral-banner') {
    return <NewReferralBanner {...item.data} />;
  }

  if (item.type === 'incentives') {
    return <IncentivesBalance {...item.data} />;
  }

  if (item.type === 'deposit-now') {
    return <DepositNow {...item.data} />;
  }

  if (item.type === 'coindrop') {
    return <CoindropBanner {...item.data} />;
  }

  if (item.type === 'bank-cards-promo') {
    return <BankCardsPromoBanner {...item.data} />;
  }

  if (item.type === 'bank-cards') {
    return <BankCardsWidget {...item.data} />;
  }

  if (item.type === 'header') {
    return <Header {...item.data} />;
  }

  if (item.type === 'section-header') {
    return <SectionHeader {...item.data} />;
  }

  if (item.type === 'section-item') {
    return <SectionItem {...item.data} />;
  }

  return null;
};
