import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {Button} from '@youtoken/ui.buttons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {HowIncentivesWorkContent} from './HowIncentivesWorkContent';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

interface BoostYourTradingSurfaceProps {
  onClose: () => void;
}

export const BoostYourTradingSurface: React.FC<
  BoostYourTradingSurfaceProps & BoxProps
> = cell(({onClose}) => {
  const {t} = useTranslation();

  const handleOpenDepositWizard = React.useCallback(() => {
    DATA_LAYER.trackStrict('incentives-get-bonuses-click', {
      source: 'BoostYourTradingSurface',
    });

    onClose?.();
    SHARED_ROUTER_SERVICE.navigate('DepositWizard', {});
  }, []);

  return (
    <>
      <HowIncentivesWorkContent px={24} />
      <Box flexDirection="row" pb={40} px={24} width="100%">
        <Button
          type="primary"
          icon="fiat_deposit"
          onPress={handleOpenDepositWizard}
          flexGrow={1}
        >
          {t('surface.incentives.action.get_bonuses')}
        </Button>
      </Box>
    </>
  );
});
