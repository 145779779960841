import * as React from 'react';
import {ErrorFallbackProps} from '@youtoken/ui.error-boundary';
import {Button} from '@youtoken/ui.buttons';
import {getActionButtonPropsByVariant} from '../utils';

export const WalletActionErrorFallback: React.FC<ErrorFallbackProps> = ({
  dismissError,
}) => {
  return (
    <Button
      icon="refresh"
      color="danger"
      onPress={dismissError}
      {...getActionButtonPropsByVariant()}
    />
  );
};
