import React from 'react';
import {Box, type BoxProps, Heading, Text} from '@youtoken/ui.primitives';
import {type currencyName, getCoinSymbol} from '@youtoken/ui.coin-utils';

export interface ProfitInfoElementProps {
  text: string | JSX.Element;
  currency: currencyName;
  formattedResult: string;
  resultDirection?: 'neutral' | 'up' | 'down';
}

const getSignAndAbsoluteValue = (input: string) => {
  if (input[0] === '-') {
    const value = input.substr(1);
    return ['-', value];
  }

  return ['', input];
};

export const Profit: React.FC<ProfitInfoElementProps & BoxProps> = ({
  text,
  currency,
  formattedResult = '',
  resultDirection,
  ...boxProps
}) => {
  const currencySign = getCoinSymbol(currency);

  const accentColor = React.useMemo(() => {
    switch (resultDirection) {
      case 'up':
        return '$success-01';
      case 'down':
        return '$danger-01';
      case 'neutral':
      default:
        return '$text-01';
    }
  }, [resultDirection]);

  const [valueSign, value] = getSignAndAbsoluteValue(formattedResult);

  return (
    <Box
      alignItems="flex-end"
      flex={1}
      flexShrink={0}
      testID="CURRENT_PROFIT_FIELD"
      {...boxProps}
    >
      <Box flexDirection="row" alignItems="center" minHeight={24}>
        {typeof text === 'string' ? (
          <Text color="$text-02" variant="$body-02" textAlign="right">
            {text}
          </Text>
        ) : (
          <Box flexDirection="row" alignItems="center">
            {text}
          </Box>
        )}
      </Box>

      <Heading
        variant="$heading-01-responsive"
        weight="regular"
        color={accentColor}
        textAlign="right"
        style={{width: '100%'}}
      >
        {valueSign}
        {currencySign}
        {value}
      </Heading>
    </Box>
  );
};
