import {deserialize} from 'serializr';
import qs from 'qs';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {OnboardingStepPerksResponse} from './OnboardingStepPerksResponse';
import {stepsToParams} from './utils';
import {type OnboardingStep} from './types';

type OnboardingStepPerksResourceArgs = {
  steps: OnboardingStep[];
};

export class OnboardingStepPerksResource extends createResource({
  getKey: ({steps}: OnboardingStepPerksResourceArgs) =>
    `promo-info/${steps.join('&')}`,
  getData: ({steps}: OnboardingStepPerksResourceArgs) =>
    TRANSPORT.API.get('/v1/promo/info', {
      params: {
        action: stepsToParams(steps),
      },
      paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'}),
    }).then(res => deserialize(OnboardingStepPerksResponse, res.data)),
}) {}
