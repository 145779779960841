import * as React from 'react';
import {useHover} from 'react-use-gesture';
import {observer} from 'mobx-react';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {Box, TouchableBox, useIsMobile} from '@youtoken/ui.primitives';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Separator} from '@youtoken/ui.elements';
import {ActionButtons, BaseBlock, InstrumentOverviewBlock} from '../';
import type {InstrumentListItemProps} from './types';

export const InstrumentListItem: React.FC<InstrumentListItemProps> = observer(
  React.forwardRef(
    (
      {
        item,
        shouldShowSeparator,
        onPressInstrument,
        highlighted,
        touchable = true,
        ...boxProps
      },
      ref
    ) => {
      const {toggleStarredHODLsInstrument} = HODLsTariffsFeature.use({});

      const [hovering, setHovering] = React.useState(false);

      const isMobile = useIsMobile();
      const isShowStar =
        !item.isStarDisabled && (item.isStarred || hovering || isMobile);

      const hover = useHover(
        React.useCallback(({hovering}) => {
          setHovering(hovering);
        }, [])
      );

      const memoizedHoverEvents = React.useMemo(() => {
        return hover();
      }, [hover]);

      const handlePressStar = React.useCallback(() => {
        toggleStarredHODLsInstrument(item);
      }, [item, toggleStarredHODLsInstrument]);

      const handlePress = React.useCallback(() => {
        onPressInstrument?.();

        SHARED_ROUTER_SERVICE.navigate('HODLInstrument', {
          symbol: item.id,
        });
      }, [onPressInstrument, item]);

      const BoxComponent = React.useMemo(
        () => (touchable ? TouchableBox : Box),
        [touchable]
      );

      return (
        <>
          {/* @ts-ignore */}
          <BoxComponent
            ref={ref}
            onPress={touchable ? handlePress : undefined}
            position="relative"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height={{default: 72, desktop: 82}}
            px={20}
            backgroundColor={highlighted ? '$interactive-02' : '$ui-background'}
            // this is to override cursor in search
            style={{cursor: 'pointer'}}
            {...boxProps}
            {...memoizedHoverEvents}
          >
            <Box position="absolute" left={0} right={0} top={0} bottom={0} />
            <BaseBlock
              item={item}
              onPressStar={isShowStar ? handlePressStar : undefined}
            />
            <Box flexDirection="row">
              <InstrumentOverviewBlock item={item} />
              <ActionButtons
                ml={24}
                mr={4}
                baseTicker={item.baseTicker}
                quoteTicker={item.quoteTicker}
                itIsIndex={item.itIsIndex}
              />
            </Box>
          </BoxComponent>
          {shouldShowSeparator && <Separator />}
        </>
      );
    }
  )
);
