import React from 'react';
import {
  type FiatDepositWizardProps,
  FiatDepositWizard,
} from '@youtoken/ui.surfaces-wallets';
import {modal} from '../../../stores';
import {Modal} from '../_common';

type ModalFiatDepositProps = Omit<FiatDepositWizardProps, 'onPressBack'> &
  React.ComponentProps<typeof Modal>;

export const ModalFiatDeposit: React.FC<ModalFiatDepositProps> = ({
  ticker,
  defaultStep,
  ...otherProps
}) => {
  return (
    <Modal minHeight={200} overflow="visible" {...otherProps}>
      <FiatDepositWizard
        ticker={ticker}
        defaultStep={defaultStep}
        onPressBack={modal.close}
      />
    </Modal>
  );
};
