import * as React from 'react';
import {
  Box,
  TouchableBox,
  Text,
  type BoxProps,
  TColorTokens,
} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {IconName} from '@youtoken/ui.icons/src/Icon';
import {
  NewReferralActivity,
  NewReferralActivityEvent,
} from '@youtoken/ui.resource-new-referral';

type ActivitiesListItemProps = {
  activity: NewReferralActivity;
  onPress?: (activity: NewReferralActivity) => void;
};

export const ActivitiesListItem: React.FC<
  ActivitiesListItemProps & BoxProps
> = ({activity, onPress, ...boxProps}) => {
  const {t} = useTranslation();

  const badgeColor = {
    [NewReferralActivityEvent.REGISTRATION]: '$attention-02',
    [NewReferralActivityEvent.VERIFICATION]: '$attention-02',
    [NewReferralActivityEvent.REWARD]: '$success-02',
  }[activity.event] as keyof TColorTokens;

  const icon = {
    [NewReferralActivityEvent.REGISTRATION]: 'new_referral',
    [NewReferralActivityEvent.VERIFICATION]: 'new_referral',
    [NewReferralActivityEvent.REWARD]: 'gift',
  }[activity.event] as IconName;

  const iconColor = {
    [NewReferralActivityEvent.REGISTRATION]: '$attention-01',
    [NewReferralActivityEvent.VERIFICATION]: '$attention-01',
    [NewReferralActivityEvent.REWARD]: '$success-01',
  }[activity.event] as keyof TColorTokens;

  const BoxElement = onPress ? TouchableBox : Box;

  return (
    <BoxElement
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      borderWidth={1}
      borderColor="$ui-01"
      borderRadius={12}
      px={16}
      py={12}
      onPress={() => onPress?.(activity)}
      {...boxProps}
    >
      <Box flexDirection="row" alignItems="center">
        <Box
          width={40}
          height={40}
          borderRadius={20}
          mr={12}
          backgroundColor={badgeColor}
          justifyContent="center"
          alignItems="center"
        >
          <Icon name={icon} size={24} color={iconColor} />
        </Box>
        <Box flexDirection="column">
          <Text testID="ACTIVITY_INVITEE_NAME">
            {activity.inviteeName || activity.inviteeEmail}
          </Text>
          <Text variant="$body-02" color="$text-02">
            {activity.createdAtFormatted}
          </Text>
        </Box>
      </Box>
      {[
        NewReferralActivityEvent.REGISTRATION,
        NewReferralActivityEvent.VERIFICATION,
      ].includes(activity.event) && (
        <Box flexDirection="row" alignItems="center" testID="ACTIVITY_STATUS">
          <Text variant="$body-02" color="$attention-01" mr={4}>
            {activity.event === NewReferralActivityEvent.REGISTRATION
              ? t('surface.new_referral.pending_friend.status.signed_up')
              : t('surface.new_referral.pending_friend.status.verified')}
          </Text>
          <Icon name="pending" size={20} color="$attention-01" />
        </Box>
      )}
      {activity.event === NewReferralActivityEvent.REWARD && (
        <Box flexDirection="column" alignItems="flex-end">
          <Text mb={2}>+{activity.rewardBTCFormatted} BTC</Text>
          <Text variant="$body-02" color="$text-02">
            ${activity.rewardUSDFormatted}
          </Text>
        </Box>
      )}
    </BoxElement>
  );
};
