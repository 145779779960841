import * as React from 'react';
import {BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {type ActionProps} from '../types';
import {getActionButtonPropsByVariant} from '../utils';

export const WalletActionSuspenseFallback: React.FC<ActionProps & BoxProps> = ({
  tooltip,
  variant,
  item,
  ...boxProps
}) => {
  return (
    <Button
      icon="refresh"
      loading
      {...getActionButtonPropsByVariant(variant)}
      {...boxProps}
    />
  );
};
