import {useEffect} from 'react';
import {BackHandler, Platform} from 'react-native';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

const isNative = Platform.select({
  native: true,
  web: false,
});

export const useCloseModalOnBackButton = (
  isOpen: boolean,
  closeModal: () => boolean
) => {
  const handleBack = () => {
    if (!isOpen) {
      SHARED_ROUTER_SERVICE.goBack();
      return true;
    }

    return closeModal();
  };

  useEffect(() => {
    if (!isNative) {
      return;
    }

    BackHandler.addEventListener('hardwareBackPress', handleBack);

    return () =>
      BackHandler.removeEventListener('hardwareBackPress', handleBack);
  }, [isOpen, handleBack]);
};
