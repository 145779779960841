import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {RewardBox} from '@youtoken/ui.elements';

type IncentivesResultBlockProps = {
  amount: number;
};

export const IncentivesResultBlock: React.FC<IncentivesResultBlockProps> =
  observer(({amount}) => {
    const {t} = useTranslation();

    return (
      <RewardBox
        title={t(
          'surface.wallets.first_action_modal.block_bonuses.result.title',
          {
            amount,
          }
        )}
        iconComponentName="Icon"
        iconName="first_trade"
        iconColor="$interactive-01"
        iconBgColor="$interactive-02"
        borderColor="$interactive-03"
        text={t('surface.wallets.first_action_modal.block_bonuses.subtitle')}
      />
    );
  });
