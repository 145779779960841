import * as React from 'react';
import {Box, type BoxProps, Text, TouchableBox} from '@youtoken/ui.primitives';
import {AppEnv, getDisplayUrl} from '@youtoken/ui.env-utils';
import {Icon} from '@youtoken/ui.icons';

type EnvItemProps = {
  env: AppEnv;
  selected: boolean;
  current: boolean;
  onSelect: (env: AppEnv) => void;
};

export const EnvironmentItem: React.FC<EnvItemProps & BoxProps> = ({
  onSelect,
  selected,
  current,
  env,
  ...props
}) => {
  return (
    <TouchableBox
      px={{default: 16, phone: 24}}
      py={12}
      borderBottomWidth={1}
      borderColor="$ui-01"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      backgroundColor={
        selected ? '$interactive-02' : current ? '$ui-01' : '$ui-background'
      }
      onPress={() => onSelect(env)}
      {...props}
    >
      <Box flexDirection="row" alignItems="center" gap={8}>
        <Text variant="$body-01-high-accent">{env}</Text>
        <Text>
          <Text variant="$body-01" color="$text-02">
            {getDisplayUrl(env)}
          </Text>
        </Text>
      </Box>
      <Box>
        {current && <Icon name="check" color="$interactive-01" size={16} />}
      </Box>
    </TouchableBox>
  );
};
