import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, type BoxProps, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

const handlePressAll = () => {
  SHARED_ROUTER_SERVICE.navigate('MyFriends', {});
};

export const ActivitiesListHeader: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  return (
    <Box
      {...boxProps}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        flex={1}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box flexDirection="row" alignItems="center">
          <Icon name="friends" size={24} color="$interactive-01" />
          <Text variant="$body-01-high-accent" ml={8}>
            {t('surface.new_referral.friend_list.title')}
          </Text>
        </Box>
        <TouchableBox
          flexDirection="row"
          alignItems="center"
          onPress={handlePressAll}
          testID="FRIENDS_LIST_ALL"
        >
          <Text color="$interactive-01">
            {t('surface.new_referral.friend_list.all')}
          </Text>
          <Icon name="chevron_right" size={16} color="$interactive-01" />
        </TouchableBox>
      </Box>
    </Box>
  );
});
