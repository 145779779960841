import {
  BaseTheme,
  createRestyleFunction,
  ResponsiveValue,
} from '@shopify/restyle';

const positionProperties = {
  position: true,
  top: true,
  right: true,
  bottom: true,
  left: true,
  zIndex: true,
};

export const position = (
  Object.keys(positionProperties) as Array<keyof typeof positionProperties>
).map((property: keyof typeof positionProperties) => {
  return createRestyleFunction({
    property: property,
    styleProperty: property,
    themeKey: undefined,
    transform: ({value}) => value,
  });
});

export type PositionProps<Theme extends BaseTheme> = {
  position?: ResponsiveValue<
    'absolute' | 'relative' | 'sticky' | 'fixed' | 'static',
    Theme
  >;
  top?: ResponsiveValue<number | string, Theme>;
  right?: ResponsiveValue<number | string, Theme>;
  bottom?: ResponsiveValue<number | string, Theme>;
  left?: ResponsiveValue<number | string, Theme>;
  zIndex?: ResponsiveValue<number, Theme>;
};
