import * as React from 'react';
import {observer} from 'mobx-react';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {type BoxProps} from '@youtoken/ui.primitives';
import {type ActionsByTickerProps, type ActionsProps} from './types';
import {WalletActionsAdditionalSelect} from './index.select';
import {WalletActionsAdditionalList} from './index.list';

export const WalletActionsAdditionalByTicker: React.FC<
  ActionsByTickerProps & BoxProps
> = observer(({ticker, ...props}) => {
  const {getByTicker} = WalletsResource.use({});

  const item = getByTicker(ticker);

  if (!item) {
    return null;
  }

  return <WalletActionsAdditional item={item} {...props} />;
});

export const WalletActionsAdditional: React.FC<ActionsProps & BoxProps> = ({
  variant = 'select',
  ...props
}) => {
  if (variant === 'list') {
    return <WalletActionsAdditionalList {...props} />;
  }

  return <WalletActionsAdditionalSelect {...props} />;
};
