import * as React from 'react';
import {ScrollView} from 'react-native';
import {observer} from 'mobx-react';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {
  ExchangeFormConversionPairSource,
  ExchangeFormInfoTableV2,
} from '../../../ExchangeForm';
import {ExchangeTariffsPublicOnRampResource} from '@youtoken/ui.resource-exchange-tariffs-public';
import {Box} from '@youtoken/ui.primitives';
import {ConversionPairTarget, FormFooter, NotEnoughData} from '..';
import {WidgetUnauthorizedFormState} from '../../state';

export interface BuyCryptoFormUnauthorizedOnRampProps {
  conversionTicker?: string;
}

export const BuyCryptoFormUnauthorizedOnRamp: React.FC<BuyCryptoFormUnauthorizedOnRampProps> =
  observer(({conversionTicker}) => {
    const resources = useResources({
      ratesResource: getResourceDescriptor(RatesResource, {}),
      exchangeTariffs: getResourceDescriptor(
        ExchangeTariffsPublicOnRampResource,
        {}
      ),
    });

    const form = makeForm(
      () =>
        new WidgetUnauthorizedFormState(
          {
            conversionTicker,
          },
          resources
        )
    );

    if (!conversionTicker) {
      return <NotEnoughData />;
    }

    return (
      <Form form={form}>
        <ScrollView
          keyboardShouldPersistTaps="handled"
          keyboardDismissMode="on-drag"
          style={{
            flexGrow: 1,
          }}
          contentContainerStyle={{
            flexGrow: 1,
          }}
        >
          <Box flex={1} p={24} justifyContent="space-between">
            <Box>
              <ExchangeFormConversionPairSource
                showBalance={false}
                labelKey="ramp.conversion_form.label.source"
                mb={24}
              />
              <ConversionPairTarget mb={24} />
            </Box>
            <ExchangeFormInfoTableV2 />
          </Box>
        </ScrollView>
        <FormFooter showAgreements={false} />
      </Form>
    );
  });
