import * as React from 'react';
import {ScrollView} from 'react-native';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {HodlsTariffsResource} from '@youtoken/ui.resource-hodl-tariffs';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {HODLByIdResource} from '@youtoken/ui.resource-hodl';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AgreementsList} from '@youtoken/ui.elements';
import {Box} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {FlipHODLForm} from '../form';
import {
  AmountSection,
  FooterSection,
  InfoTable,
  MultiplySection,
} from '../sections';

interface HodlFlipSurfaceProps {
  hodlId: string;
  onFormSubmit: () => void;
}

export const HodlFlipSurface: React.FC<HodlFlipSurfaceProps> = cell(
  ({hodlId, onFormSubmit}) => {
    const {t} = useTranslation();

    const resources = useResources({
      hodl: getResourceDescriptor(HODLByIdResource, {id: hodlId}),
      tariffs: getResourceDescriptor(HodlsTariffsResource, {}),
      wallets: getResourceDescriptor(WalletsResource, {}),
      ratesDefault: getResourceDescriptor(RatesResource, {product: 'default'}),
      rates: getResourceDescriptor(RatesResource, {product: 'hodl'}),
      docs: getResourceDescriptor(DocsResource, {
        country: AuthMeResource.getInstance({}).residenceOrCountry,
      }),
    });

    const form = makeForm(
      () => new FlipHODLForm(resources, {hodlId, onFormSubmit: onFormSubmit})
    );

    const {
      view: {agreementItems},
    } = form;

    return (
      <ScrollView
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        bounces={false}
      >
        <Form form={form}>
          <Box px={24} pb={24} position="relative">
            <AmountSection />
            <MultiplySection />
            <InfoTable />
            <FooterSection />
          </Box>
        </Form>
      </ScrollView>
    );
  }
);
