import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {CurvedLine} from './components/CurvedLine';
import {WalletsItemGeneralCurvedLineApr} from './components/Apr';
import {WalletsItemFeature} from '../../../../features/WalletsItemFeature';
import {
  WalletSummaryAndRate,
  WalletActions,
} from '@youtoken/ui.surfaces-wallets';

interface WalletsItemGeneralProps {
  ticker: string;
}

export const WalletsItemGeneral: React.FC<WalletsItemGeneralProps> = observer(
  ({ticker}) => {
    const {t} = useTranslation();

    const {
      wallet,
      tickerFormatted,
      hodlsInputAmountFormatted,
      dualsInputAmountFormatted,
      hasHodlsInputAmount,
      hasDualsInputAmount,
    } = WalletsItemFeature.use({
      ticker,
    });

    return (
      <Box p={20}>
        <WalletSummaryAndRate item={wallet} variant="detailed" />
        <Box my={20}>
          <WalletsItemGeneralCurvedLineApr ticker={ticker} />
          {hasHodlsInputAmount && (
            <CurvedLine>
              <Text variant="$body-02" color="$attention-01">
                {t('surface.wallets.wallet_detail.in_hodls', {
                  amount: hodlsInputAmountFormatted,
                  ticker: tickerFormatted,
                })}
              </Text>
            </CurvedLine>
          )}
          {hasDualsInputAmount && (
            <CurvedLine>
              <Text variant="$body-02" color="$success-01">
                {t('surface.wallets.wallet_detail.in_duals', {
                  amount: dualsInputAmountFormatted,
                  ticker: tickerFormatted,
                })}
              </Text>
            </CurvedLine>
          )}
        </Box>
        <WalletActions item={wallet} variant="full" />
      </Box>
    );
  }
);
