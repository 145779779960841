import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Modal} from '@youtoken/ui.modal';
import {NewInviteeModalContent} from '../components/NewInviteeModalContent';

type NewInviteeModalProps = {amount: number};

export const NewInviteeModal: React.FC<
  NewInviteeModalProps & React.ComponentProps<typeof Modal>
> = cell(({amount, isOpen: _isOpen, onClose, ...modalProps}) => {
  const [isOpen, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (_isOpen) {
      setTimeout(() => setOpen(_isOpen), 5000);
    } else {
      setOpen(_isOpen);
    }
  }, [_isOpen]);

  return (
    <Modal
      shouldUseAnimatedSnapPoints
      snapPoints={['CONTENT_HEIGHT']}
      isOpen={isOpen}
      onClose={onClose}
      {...modalProps}
    >
      <NewInviteeModalContent p={32} amount={amount} onClose={onClose} />
    </Modal>
  );
});
