import {observable} from 'mobx';
import EventEmitter from 'eventemitter3';
import {INTERCOM_SERVICE} from '@youtoken/ui.service-intercom';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {invariant} from '@youtoken/ui.utils';
import {SENTRY} from '@youtoken/ui.sentry';

interface Handlers {
  openSupportChat: (message?: string) => void;
  reloadApp: () => void;
}

const defaultHandlers: Handlers = {
  openSupportChat: message => {
    (message
      ? INTERCOM_SERVICE.displayMessageComposer(message)
      : INTERCOM_SERVICE.displayMessenger()
    ).catch(e => {
      SENTRY.capture(e, {
        source: 'SupportService.openSupportChat',
      });
    });
  },
  reloadApp: () => {
    ENVIRONMENT.reloadApp();
  },
};

export class SupportService {
  public events = new EventEmitter<
    'provideHandlers' | 'openSupportChat' | 'reloadApp'
  >();

  @observable isInitialized = false;

  private _handlers!: Handlers;

  initialize(handlers: Handlers = defaultHandlers) {
    this.isInitialized = true;
    this._handlers = handlers;
  }

  public openSupportChat = (message?: string) => {
    this.events.emit('openSupportChat');

    invariant(this.isInitialized, 'SupportService was not initialized');

    this._handlers.openSupportChat(message);
  };

  public reloadApp = () => {
    this.events.emit('reloadApp');

    invariant(this.isInitialized, 'SupportService was not initialized');

    this._handlers.reloadApp();
  };
}
