import {AppEnv} from '@youtoken/ui.env-utils';
import {SIMPLE_STORAGE} from '@youtoken/ui.service-storage';
import {invariant, warning} from '@youtoken/ui.utils';
import {APP_ENV_STORAGE_KEY} from './consts';

/** @platform web */
export const getAppEnvFromHost = () => {
  invariant(
    window?.location?.hostname,
    'trying to use getAppEnvFromHost in env that does not support `window.location`'
  );

  const host = window.location.host;

  if (host.includes('localhost')) {
    return AppEnv.local;
  }

  if (
    host.startsWith('app.youhodler') ||
    host.startsWith('ledgerlive.youhodler') ||
    host.startsWith('magicstore.youhodler')
  ) {
    return AppEnv.production;
  }

  warning(false, 'cannot extract AppEnv from window.location');

  return AppEnv.local;
};

export const __BUILD_ENV__: 'production' | 'development' =
  getAppEnvFromHost() === AppEnv.production ? 'production' : 'development';

export function getEnv(): AppEnv {
  const envFromHost = getAppEnvFromHost();

  // In production, we always use the production environment.
  if (envFromHost === AppEnv.production) {
    return AppEnv.production;
  }

  // otherwise, we use the environment stored in the device or default to local
  let envFromStorage: AppEnv = AppEnv.local;
  try {
    envFromStorage = SIMPLE_STORAGE.get(APP_ENV_STORAGE_KEY) as AppEnv;
  } catch {}

  return envFromStorage ?? AppEnv.local;
}

export function setEnv(env: AppEnv): void {
  if (__BUILD_ENV__ === 'production') {
    return;
  }

  SIMPLE_STORAGE.set(APP_ENV_STORAGE_KEY, env);
}
