import * as React from 'react';
import {ScrollView, Platform} from 'react-native';
import {observer} from 'mobx-react';
import {BottomSheetScrollView} from '@gorhom/bottom-sheet';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {AppEnv} from '@youtoken/ui.env-utils';
import {Icon} from '@youtoken/ui.icons';
import {Modal} from '@youtoken/ui.modal';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {EnvironmentItem} from './EnvironmentItem';
import {Settings} from './Settings';

const ScrollViewComponent = Platform.select<
  typeof ScrollView | typeof BottomSheetScrollView
>({
  native: BottomSheetScrollView,
  web: ScrollView,
})!;

export const EnvironmentSettings = observer(() => {
  const [isOpen, setIsOpen] = React.useState(
    () => ENVIRONMENT.APP_ENV === AppEnv.local
  );
  const [originalEnv] = React.useState(() => ENVIRONMENT.APP_ENV);
  const [originalSkipRecaptcha] = React.useState(
    () => ENVIRONMENT.SKIP_RECAPTCHA
  );

  if (ENVIRONMENT.BUILD_ENV === AppEnv.production) {
    return null;
  }

  const hasModifications =
    originalEnv !== ENVIRONMENT.APP_ENV ||
    originalSkipRecaptcha !== ENVIRONMENT.SKIP_RECAPTCHA;

  return (
    <>
      <Box flexDirection="row" alignItems="center" justifyContent="flex-start">
        <TouchableBox
          borderRadius={6}
          px={8}
          height={32}
          bg="$success-02"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          onPress={() => {
            setIsOpen(true);
          }}
        >
          <Icon name="blockexplorer" color="$success-01" size={18} />

          <Text
            variant="$body-02"
            weight="bold"
            color="$success-01"
            ml={4}
            mr={4}
          >
            {ENVIRONMENT.APP_ENV}
          </Text>
          <Box
            borderLeftWidth={1}
            borderColor="$success-03"
            pl={4}
            ml={8}
            height={32}
            justifyContent="center"
            alignItems="center"
          >
            <Icon name="trading" color="$success-01" size={18} />
          </Box>
        </TouchableBox>
      </Box>
      <Modal
        isOpen={isOpen}
        title="Env Settings"
        onClose={() => {
          setIsOpen(false);
        }}
        closeable={!hasModifications}
      >
        <ScrollViewComponent>
          <Settings />
          <Box
            py={8}
            justifyContent="space-between"
            flexDirection="row"
            borderBottomWidth={1}
            borderBottomColor="$ui-01"
            px={{default: 16, phone: 24}}
          >
            <Text variant="$body-02-high-accent" color="$text-02">
              Environment
            </Text>
          </Box>

          {(Object.keys(AppEnv) as AppEnv[]).map((env, index) => {
            if (env === AppEnv.local) {
              return null;
            }

            return (
              <EnvironmentItem
                key={env}
                env={env}
                onSelect={() => {
                  ENVIRONMENT.setAppEnv(env);
                }}
                current={originalEnv === env}
                selected={env === ENVIRONMENT.APP_ENV}
                borderBottomWidth={
                  index === Object.keys(AppEnv).length - 1 ? 0 : 1
                }
              />
            );
          })}
        </ScrollViewComponent>
        <Box
          pt={24}
          px={{
            default: 24,
            phone: 16,
          }}
          borderTopWidth={1}
          borderTopColor="$ui-01"
        >
          <Button
            disabled={!hasModifications}
            type={hasModifications ? 'primary' : 'secondary'}
            onPress={() => {
              setIsOpen(false);
              ENVIRONMENT.reloadApp();
            }}
            mb={8}
          >
            Save and Restart App
          </Button>
          <Button
            type="secondary"
            onPress={() => {
              ENVIRONMENT.setAppEnv(originalEnv);
              ENVIRONMENT.setSkipRecaptcha(originalSkipRecaptcha);
              setIsOpen(false);
            }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
});
