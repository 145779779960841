import * as React from 'react';
import {observer} from 'mobx-react';
import {cell} from '@youtoken/ui.cell';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  NewReferralActivityResource,
  NewReferralOverviewResource,
  NewReferralInvitedResource,
  NewReferralJoinedResource,
} from '@youtoken/ui.resource-new-referral';
import {Pagination} from '@youtoken/ui.elements';
import {FriendsTabs} from './components';
import {
  ActivitiesList,
  JoinedList,
  RewardSummary,
  InvitedList,
} from '../components';
import {MyFriendsSurfaceTab} from './types';

type MyFriendsSurfaceContentProps = {
  activeTab?: MyFriendsSurfaceTab;
  onTabChange?: (tab: MyFriendsSurfaceTab) => void;
};

const TabsOrderedList = [
  MyFriendsSurfaceTab.ACTIVITY,
  MyFriendsSurfaceTab.INVITED,
  MyFriendsSurfaceTab.JOINED,
];

const ActivitiesTab: React.FC<{}> = cell(() => {
  const {activities, currentPage, totalPages, setPage, refetch} =
    NewReferralActivityResource.use({});

  React.useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <ActivitiesList activities={activities} mt={24} />
      {totalPages > 1 && (
        <Pagination
          activePage={currentPage}
          totalPages={totalPages}
          onPageChange={setPage}
        />
      )}
    </>
  );
});

const InvitedFriendsTab: React.FC<{}> = cell(() => {
  const {invited, currentPage, totalPages, setPage, refetch} =
    NewReferralInvitedResource.use({});

  React.useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <InvitedList activities={invited} mt={24} />
      {totalPages > 1 && (
        <Pagination
          activePage={currentPage}
          totalPages={totalPages}
          onPageChange={setPage}
        />
      )}
    </>
  );
});

const JoinedFriendsTab: React.FC<{}> = cell(() => {
  const {joined, currentPage, totalPages, setPage, refetch} =
    NewReferralJoinedResource.use({});

  React.useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <JoinedList activities={joined} mt={24} />
      {totalPages > 1 && (
        <Pagination
          activePage={currentPage}
          totalPages={totalPages}
          onPageChange={setPage}
        />
      )}
    </>
  );
});

export const MyFriendsSurfaceContent: React.FC<
  MyFriendsSurfaceContentProps & BoxProps
> = observer(({activeTab, onTabChange, ...boxProps}) => {
  const {t} = useTranslation();

  const {
    overview: {
      rewardBTC,
      rewardBTCFormatted,
      rewardUSDFormatted,
      conditions: {stepAmountUSD},
    },
  } = NewReferralOverviewResource.use({});

  const [activeIndex, setActiveIndex] = React.useState(
    activeTab ? TabsOrderedList.indexOf(activeTab) : 0
  );

  const handleChangeTab = (_: string, index: number) => {
    onTabChange?.(
      [
        MyFriendsSurfaceTab.ACTIVITY,
        MyFriendsSurfaceTab.INVITED,
        MyFriendsSurfaceTab.JOINED,
      ][index]!
    );
    setActiveIndex(index);
  };

  return (
    <Box {...boxProps}>
      <RewardSummary
        totalBTC={rewardBTCFormatted}
        totalUSD={rewardUSDFormatted}
        amountUSD={stepAmountUSD}
        showDescription={rewardBTC.eq(0)}
        title={t('surface.new_referral.friends.total_earned')}
      />
      <FriendsTabs
        activeIndex={activeIndex}
        onChange={handleChangeTab}
        mt={20}
      />
      {activeIndex === 0 && <ActivitiesTab />}
      {activeIndex === 1 && <InvitedFriendsTab />}
      {activeIndex === 2 && <JoinedFriendsTab />}
    </Box>
  );
});
