import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {
  PromoContent,
  RegularContent,
  TitleWithLearnMore,
  Action,
  Steps,
} from './components';
import {OnboardingWidgetFeature} from './state';

export const OnboardingWidget: React.FC<BoxProps> = observer(boxProps => {
  const {isCompleted, hasPromo} = OnboardingWidgetFeature.use({});

  if (isCompleted) {
    return null;
  }

  return (
    <Box
      borderWidth={1}
      borderColor="$ui-01"
      borderRadius={12}
      p={16}
      testID="ONBOARDING_WIDGET"
      {...boxProps}
    >
      {hasPromo ? <PromoContent /> : <RegularContent />}
      <TitleWithLearnMore />
      <Steps my={16} />
      <Action />
    </Box>
  );
});
