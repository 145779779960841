import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {Modal} from '@youtoken/ui.modal';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Link} from '@youtoken/ui.elements';
import {MinerPreview} from '@youtoken/ui.video-preview';
import {useCloseModalOnBackButton} from '@youtoken/ui.hooks';

type HowItWorksModalProps = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
};

export const HowItWorksModal: React.FC<HowItWorksModalProps> = observer(
  ({title, isOpen, onClose}) => {
    const {t} = useTranslation();

    useCloseModalOnBackButton(isOpen, () => {
      onClose();
      return true;
    });

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        snapPoints={['CONTENT_HEIGHT']}
        shouldUseAnimatedSnapPoints
        shouldBeScrollable
        title={title}
      >
        <Box p={24}>
          <Box justifyContent="center" alignItems="center">
            <MinerPreview />
          </Box>

          <Text variant="$body-01-high-accent" color="$text-01" mt={24}>
            {t('surface.miner.how_it_works.p1.title')}
          </Text>
          <Text variant="$body-01" color="$text-02" mt={8}>
            {t('surface.miner.how_it_works.p1.description')}
          </Text>
          <Text variant="$body-01-high-accent" color="$text-01" mt={24}>
            {t('surface.miner.how_it_works.p2.title')}
          </Text>
          <Text variant="$body-01" color="$text-02" mt={8}>
            {t('surface.miner.how_it_works.p2.descsription')}
          </Text>
          <Text variant="$body-01-high-accent" color="$text-01" mt={24}>
            {t('surface.miner.how_it_works.p3.title')}
          </Text>
          <Text variant="$body-01" color="$text-02" mt={8}>
            {t('surface.miner.how_it_works.p3.descsription')}
          </Text>
          <Text variant="$body-01" color="$text-02" mt={24}>
            <Trans
              t={t}
              components={{
                Link: (
                  <Link url="https://help.youhodler.com/en/articles/6853283" />
                ),
              }}
              i18nKey="surface.miner.how_it_works.read_more"
            />
          </Text>
        </Box>
      </Modal>
    );
  }
);
