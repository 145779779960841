import {__SERVICE_REGISTRY__} from '@youtoken/ui.service-registry';
import {TransportService} from './TransportService';
export {assignSecurityHeaders} from './rest/assignSecurityHeaders';

// exported const is reference to global service, not scoped const;
export const TRANSPORT = __SERVICE_REGISTRY__.registerService(
  'TRANSPORT',
  TransportService,
  require('../package.json').version
);
