import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {ExtendTpSlHODLFeature} from '../features';
import {ExtendTpSlModals, DepositForExtendTpSlModal} from './modals';

interface ExtendTpSlBottomSheetProps {
  hodlId: string;
  onClose: () => void;
}

export const ExtendTpSlBottomSheet: React.FC<ExtendTpSlBottomSheetProps> = cell(
  ({hodlId, onClose}) => {
    const {hasBalance, hasIncentives} = ExtendTpSlHODLFeature.use({id: hodlId});

    return (
      <>
        {hasBalance || hasIncentives ? (
          <ExtendTpSlModals hodlId={hodlId} onClose={onClose} />
        ) : (
          <DepositForExtendTpSlModal hodlId={hodlId} onClose={onClose} />
        )}
      </>
    );
  }
);
