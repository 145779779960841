import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import type {ProgressComponent} from '../types';
import {wrapperBackgroundColor} from './consts';

export const Wrapper: React.FC<Omit<ProgressComponent, 'progress'>> = ({
  children,
  size,
  color,
  isIndeterminate,
}) => {
  return (
    <Box
      position="relative"
      height={size === 'tiny' ? 4 : 24}
      overflow="hidden"
      // @ts-ignore reason: we need to ignore animated element it alway generates diff - https://www.chromatic.com/docs/ignoring-elements
      dataSet={
        isIndeterminate
          ? {
              chromatic: 'ignore',
            }
          : undefined
      }
      borderRadius={size === 'tiny' ? 2 : 6}
      testID="PROGRESS_BAR"
    >
      <Box
        flex={1}
        backgroundColor={wrapperBackgroundColor[color]}
        opacity={color === 'inactive' ? 0.1 : 1}
      />
      {children}
    </Box>
  );
};
