import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {
  AllInstrumentsSection,
  SectorsSection,
  StarredSection,
  TopCapSection,
  TopMoversSection,
  InstrumentsSearchBar,
} from '@youtoken/ui.surface-hodl-instruments';

export const HODLsInstrumentsPageContent: React.FC = cell(() => {
  return (
    <>
      <InstrumentsSearchBar px={{default: 20, desktop: 0}} mb={20} zIndex={1} />
      <StarredSection mb={24} />
      <TopCapSection mb={24} />
      <TopMoversSection mb={24} />
      <SectorsSection />
      <AllInstrumentsSection />
    </>
  );
});
