import * as React from 'react';
import {Button} from '@youtoken/ui.buttons';
import {Box, Text} from '@youtoken/ui.primitives';
import {Modal} from '@youtoken/ui.modal';
import {MinerOverviewResource} from '@youtoken/ui.resources-miner';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SparkPriceBasic} from '../SparkPriceBasic';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {observer} from 'mobx-react';

type ConfirmationModalProps = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ConfirmationModal: React.FC<ConfirmationModalProps> = observer(
  ({title, isOpen, onClose}) => {
    const {resetMiner, resetCost} = MinerOverviewResource.use({});
    const {t} = useTranslation();

    const [isLoading, setLoading] = React.useState(false);

    const handleResetPress = () => {
      DATA_LAYER.trackStrict('miner-board-unlock-attempt', {
        category: 'miner',
        type: 'board-unlock',
      });

      setLoading(true);
      resetMiner()
        .then(() => {
          onClose();
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        snapPoints={['CONTENT_HEIGHT']}
        shouldUseAnimatedSnapPoints
        title={title}
        webMinHeight={0}
      >
        <Box alignItems="center" justifyContent="center" p={8}>
          <Text variant="$body-01" color="$text-02">
            {t('surface.miner.unlock_blocks.used_all')}
          </Text>
        </Box>

        <Box
          px={24}
          py={8}
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Text variant="$body-01">
            {t('surface.miner.unlock_blocks.unlock_for')}
          </Text>
          <SparkPriceBasic amount={resetCost} />
          <Text ml={4}>{t('surface.miner.sparks')}</Text>
        </Box>

        <Box px={24} pt={8} pb={24} flexDirection="row">
          <Button
            size="large"
            type="primary"
            flex={1}
            loading={isLoading}
            onPress={handleResetPress}
            testID="MINER_BOARD_UNLOCK_BUTTON"
          >
            {t('surface.miner.unblock_blocks.button')}
          </Button>
        </Box>
      </Modal>
    );
  }
);
