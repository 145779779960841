import * as React from 'react';
import {
  Text,
  Box,
  TouchableBox,
  type TouchableBoxProps,
} from '@youtoken/ui.primitives';
import {Icon, type BaseIconName} from '@youtoken/ui.icons';

export type TouchableBannerVariant =
  | 'interactive'
  | 'interactiveSecondary'
  | 'success'
  | 'successSecondary'
  | 'danger'
  | 'dangerSecondary'
  | 'attention'
  | 'attentionSecondary';

export interface TouchableBannerBaseProps
  extends Omit<TouchableBoxProps, 'onPress'> {
  onPress: () => void;
  text?: string;
  variant?: TouchableBannerVariant;
  testID?: string;
}

type TIconName = {
  type: 'icon';
  iconName: React.ComponentProps<typeof Icon>['name'];
};

type TIconComponent = {
  type: 'component';
  IconComponent: React.ReactNode;
};

export type TouchableBannerProps = TouchableBannerBaseProps &
  (TIconName | TIconComponent);

const getIconColorByVariant = (variant: TouchableBannerVariant) => {
  switch (variant) {
    case 'interactive':
    case 'success':
    case 'danger':
    case 'attention':
      return '$text-04';
    case 'successSecondary':
      return '$success-01';
    case 'dangerSecondary':
      return '$danger-04';
    case 'attentionSecondary':
      return '$attention-04';
    case 'interactiveSecondary':
    default:
      return '$text-05';
  }
};

const getBgColorByVariant = (variant: TouchableBannerVariant) => {
  switch (variant) {
    case 'success':
      return '$success-01';
    case 'successSecondary':
      return '$success-02';
    case 'danger':
      return '$danger-01';
    case 'dangerSecondary':
      return '$danger-02';
    case 'attention':
      return '$attention-01';
    case 'attentionSecondary':
      return '$attention-02';
    case 'interactive':
      return '$interactive-01';
    case 'interactiveSecondary':
    default:
      return '$interactive-02';
  }
};

const getTextColorByVariant = (variant: TouchableBannerVariant) => {
  switch (variant) {
    case 'interactive':
    case 'success':
    case 'danger':
    case 'attention':
      return '$text-04';
    case 'successSecondary':
      return '$success-01';
    case 'dangerSecondary':
      return '$danger-04';
    case 'attentionSecondary':
      return '$attention-04';
    case 'interactiveSecondary':
    default:
      return '$text-05';
  }
};

export const TouchableBanner: React.FC<TouchableBannerProps> = React.memo(
  ({
    onPress,
    text,
    variant = 'interactiveSecondary',
    testID,
    ...otherProps
  }) => {
    const iconColor = getIconColorByVariant(variant);
    const bgColor = getBgColorByVariant(variant);
    const textColor = getTextColorByVariant(variant);

    return (
      <TouchableBox
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        p={16}
        backgroundColor={bgColor}
        onPress={onPress}
        borderRadius={12}
        testID={testID}
        {...otherProps}
      >
        {otherProps.type === 'component' &&
          React.isValidElement(otherProps.IconComponent) &&
          otherProps.IconComponent}

        {otherProps.type === 'icon' && (
          <Box flexShrink={0}>
            <Icon name={otherProps.iconName} color={iconColor} size={24} />
          </Box>
        )}
        <Box ml={12} mr={8} flexShrink={1} flexGrow={1}>
          <Text color={textColor}>{text}</Text>
        </Box>
        <Box flexShrink={0}>
          <Icon name="chevron_right" color={iconColor} />
        </Box>
      </TouchableBox>
    );
  }
);
