import React from 'react';
import {observer} from 'mobx-react';
import {Separator} from '@youtoken/ui.elements';
import {Box, Text} from '@youtoken/ui.primitives';
import {i18n, useTranslation} from '@youtoken/ui.service-i18n';
import {BankWireFeature} from '../../../../states/BankWireFeature';

interface PaymentInfoProps {
  ticker: string;
  isAlternative?: boolean;
}

export const PaymentInfo: React.FC<PaymentInfoProps> = observer(
  ({ticker, isAlternative}) => {
    const {t} = useTranslation();
    const {tickerFormatted, account, alternativeToIBANAccount} =
      BankWireFeature.use({ticker});

    const {type, minAmount, feeAmount} = isAlternative
      ? alternativeToIBANAccount
      : account;

    const paymentInfoMessage = React.useMemo(() => {
      switch (type) {
        case 'swift':
          if (ticker === 'eur') {
            return i18n.t('surface.bank_wire.payment_info.eu_sepa.outside', {
              ticker: tickerFormatted,
            });
          }
          if (ticker === 'gbp') {
            return i18n.t('surface.bank_wire.payment_info.uk.outside', {
              ticker: tickerFormatted,
            });
          }
          break;
        case 'sepa':
        case 'wirex':
          if (ticker === 'eur') {
            return i18n.t('surface.bank_wire.payment_info.eu_sepa.inside', {
              ticker: tickerFormatted,
            });
          }
          if (ticker === 'gbp') {
            return i18n.t('surface.bank_wire.payment_info.eu_uk.inside', {
              ticker: tickerFormatted,
            });
          }
          break;
        default:
          return '';
      }
    }, [ticker, tickerFormatted, type]);

    return (
      <>
        {Boolean(paymentInfoMessage) && (
          <Box px={24} my={24}>
            <Text
              variant="$body-01"
              color="$text-02"
              testID="FIAT_DEPOSIT_BANK_WIRE_PAYMENT_INFO"
            >
              {paymentInfoMessage}
            </Text>
          </Box>
        )}

        {(minAmount || feeAmount) && (
          <>
            <Box px={24} pb={16} mt={!Boolean(paymentInfoMessage) ? 24 : 0}>
              {Boolean(minAmount) && (
                <Box flexDirection="row" mb={8}>
                  <Text variant="$body-02" color="$text-02">
                    {t('surface.wallets.fiat_deposit_wire.min_amount_label')}{' '}
                  </Text>
                  <Text variant="$body-02-medium-accent">
                    <Text testID="FIAT_DEPOSIT_BANK_WIRE_MIN_AMOUNT">
                      {minAmount}
                    </Text>{' '}
                    {tickerFormatted}
                  </Text>
                </Box>
              )}
              {Boolean(feeAmount) && (
                <Box flexDirection="row" mb={8}>
                  <Text variant="$body-02" color="$text-02">
                    {t('surface.wallets.fiat_deposit_wire.fee_amount_label')}{' '}
                  </Text>
                  <Text variant="$body-02-medium-accent">
                    <Text testID="FIAT_DEPOSIT_BANK_WIRE_FEE_AMOUNT">
                      {feeAmount}
                    </Text>{' '}
                    {tickerFormatted}
                  </Text>
                </Box>
              )}
            </Box>

            <Separator mb={24} />
          </>
        )}
      </>
    );
  }
);
