import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Tabs, type ITab} from '@youtoken/ui.tabs';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {defaultTicker} from '@youtoken/ui.resources-miner';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {BaseTab} from './components';
import {type TradeToUpgradeModalContentProps} from './types';

export const TradeToUpgradeModalContent: React.FC<
  TradeToUpgradeModalContentProps & BoxProps
> = cell(({onClose, variant, ...boxProps}) => {
  const {t} = useTranslation();

  const TABS: ITab[] = React.useMemo(
    () => [
      {
        type: 'text',
        label: t('surface.upgrade_modal.tab.hodl.title'),
        value: 'TRADE',
        testID: `UPGRADE_LEVEL_MODAL_TAB_TRADE`,
      },
      {
        type: 'text',
        label: t('surface.upgrade_modal.tab.convert.title'),
        value: 'CONVERT',
        testID: `UPGRADE_LEVEL_MODAL_TAB_CONVERT`,
      },
    ],
    [t]
  );

  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const handleChangeTab = React.useCallback(
    (value: string, activeIndex: number) => {
      setActiveTabIndex(activeIndex);
    },
    [setActiveTabIndex]
  );

  const handleClickHodlBenefit = (amount?: number, multiplier?: number) => {
    onClose();
    SHARED_ROUTER_SERVICE.navigate(
      'NewMultiHODL',
      {},
      {
        inputTicker: defaultTicker,
        inputAmount: amount
          ?.toFixed(getCoinDecimalPrecision(defaultTicker))
          .toString(),
        multiplier: multiplier?.toString(),
      }
    );
  };

  const handleClickConvertBenefit = (amount?: number) => {
    onClose();
    SHARED_ROUTER_SERVICE.navigate('Exchange', {
      fromTicker: defaultTicker,
      initialAmount: amount
        ?.toFixed(getCoinDecimalPrecision(defaultTicker))
        .toString(),
    });
  };

  return (
    <Box {...boxProps}>
      <Tabs
        size="small"
        activeIndex={activeTabIndex}
        onChange={handleChangeTab}
        tabs={TABS}
      />
      {activeTabIndex === 0 && (
        <BaseTab
          variant={variant}
          product="hodl"
          onActionPress={handleClickHodlBenefit}
          pt={24}
        />
      )}
      {activeTabIndex === 1 && (
        <BaseTab
          variant={variant}
          product="convert"
          onActionPress={handleClickConvertBenefit}
          pt={24}
        />
      )}
    </Box>
  );
});
