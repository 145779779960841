import {
  getSectionWalletGroupBoxProps,
  getSectionWalletGroupItemBoxProps,
} from './index';
import {ISections, ISectionsVisibilities, Item, ISection} from '../types';

export const createWalletsItems = (
  sections: ISections,
  sectionsVisibilities: ISectionsVisibilities
) => {
  let data: Item[] = [];

  data.push({
    type: 'header',
    data: {
      mx: {default: 16, desktop: 0},
    },
  });

  const sectionsList = Object.keys(sections) as ISection[];

  sectionsList.forEach((section, sectionIndex) => {
    const sectionVisibility = sectionsVisibilities[section];
    const sectionItems = sections[section];

    data.push({
      type: 'section-header',
      data: {
        section,
        ...getSectionWalletGroupBoxProps(
          sectionsList,
          section,
          sectionVisibility,
          sectionItems,
          sectionIndex
        ),
      },
    });

    if (sectionVisibility) {
      sectionItems.forEach((item, sectionItemIndex) => {
        data.push({
          type: 'section-item',
          data: {
            item,
            ...getSectionWalletGroupItemBoxProps(
              sectionsList,
              section,
              sectionItems,
              sectionIndex,
              sectionItemIndex
            ),
          },
        });
      });
    }
  });

  return data;
};
