import * as React from 'react';
import {FlatList} from 'react-native';
import {observer} from 'mobx-react';
import {renderItem, keyExtractor} from './utils';
import {useData} from './hooks';

export const WalletsList: React.FC = observer(() => {
  const data = useData();

  return (
    <FlatList
      style={{
        flex: 1,
        overflow: 'visible',
      }}
      data={data}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      initialNumToRender={data.length}
    />
  );
});
