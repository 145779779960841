import * as React from 'react';
import {observer} from 'mobx-react';
import {Path} from 'react-native-svg';
import {FEBlockState} from '@youtoken/ui.resources-miner';
import {useLegacyOpacityAnimation, useMinerColors} from '../../hooks';

type InnerHexagonProps = {
  hexState: FEBlockState;
};

const OPACITIES: Record<FEBlockState, number> = {
  DISABLED: 0,
  AVAILABLE_NOT_ENOUGH_SPARKS: 1,
  AVAILABLE_ENOUGH_SPARKS: 1,
  MINING_STARTING: 1,
  MINING: 0,
  READY: 0,
  CLAIMING_STARTING: 0,
  CLAIMING: 0,
  CLAIMED_COLORED: 0,
  CLAIMED_GREY: 0,
  CLAIMED_INFO: 0,
};

export const InnerHexagon: React.FC<InnerHexagonProps> = observer(
  ({hexState}) => {
    const {textColor} = useMinerColors(hexState);

    const style = useLegacyOpacityAnimation(OPACITIES, hexState);

    if (style.opacity === 0) {
      return null;
    }

    return (
      <Path
        opacity={style.opacity}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2158 28.772L31.2158 21.647C32.3156 20.9939 33.6844 20.9939 34.7842 21.647L46.7842 28.772C47.8447 29.4016 48.4948 30.5436 48.4948 31.7769V46.2231C48.4948 47.4564 47.8447 48.5984 46.7842 49.228L34.7842 56.353C33.6844 57.0061 32.3156 57.0061 31.2158 56.353L19.2158 49.228C18.1553 48.5984 17.5052 47.4564 17.5052 46.2231V31.7769C17.5052 30.5436 18.1553 29.4016 19.2158 28.772Z"
        stroke={textColor}
        transform={'translate(-1 -2)'}
        fillOpacity={0}
      />
    );
  }
);
