export const banks = {
  NexPay: [
    {
      priority: 300,
      type: 'bankCode',
      title: 'Bank SWIFT Code',
      value: 'NEUALT21',
    },
    {
      priority: 500,
      type: 'bankName',
      title: 'Name of the bank',
      value: 'NexPay, UAB',
    },
    {
      priority: 600,
      type: 'bankAddress',
      title: 'Address of the bank',
      value: 'Olimpieciu g. 1-36, LT-09235 Vilnius, Lithuania',
    },
  ],
  EqualsMoney: [
    {
      priority: 300,
      type: 'bankCode',
      title: 'Bank SWIFT Code',
      value: 'RBOSGB2L',
    },
    {
      priority: 500,
      type: 'bankName',
      title: 'Name of the bank',
      value: 'EQUALS MONEY PLC',
    },
    {
      priority: 600,
      type: 'bankAddress',
      title: 'Address of the bank',
      value: '3rd Floor, Vintners’ Place, 68 Upper Thames St, London, EC4V 3BJ',
    },
  ],
  EqualsMoneyFasterPayment: [
    {
      priority: 600,
      type: 'accountNumber',
      title: 'Account number',
      value: '32905474',
    },
    {
      priority: 400,
      type: 'sortCode',
      title: 'Sort Code',
      value: '15-10-00',
    },
    {
      priority: 500,
      type: 'bankName',
      title: 'Name of the bank',
      value: 'THE ROYAL BANK OF SCOTLAND PLC',
    },
  ],
  Intergiro: [
    {
      priority: 300,
      type: 'bankCode',
      title: 'Bank SWIFT Code',
      value: 'FTCSSESS',
    },
    {
      priority: 500,
      type: 'bankName',
      title: 'Name of the bank',
      value: 'Intergiro Intl AB',
    },
    {
      priority: 600,
      type: 'bankAddress',
      title: 'Address of the bank',
      value: 'Intergiro Intl AB (publ) Box 3093 10361 - Stockholm Sweden',
    },
  ],
};
