import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ModalDynamic} from '@youtoken/ui.modal';
import {TpSlInitialPrice} from '@youtoken/ui.surface-hodl-positions';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {ExtendTpSlSurface, HowExtendTpSlWorksSurface} from '../../surfaces';

interface ExtendTpSlModalsProps {
  hodlId: string;
  onClose?: () => void;
}

export const ExtendTpSlModals: React.FC<ExtendTpSlModalsProps> = observer(
  ({hodlId, onClose}) => {
    const {t} = useTranslation();

    const [isHowItWorksOpen, setIsHowItWorksOpen] = React.useState(false);
    const [isExtendTpSlOpen, setExtendTpSlOpen] = React.useState(true);

    const handleCloseExtendTpSl = React.useCallback(() => {
      setExtendTpSlOpen(false);

      if (onClose) {
        onClose();
      }
    }, [onClose]);

    const handleCloseHowItWorks = React.useCallback(() => {
      setIsHowItWorksOpen(false);
    }, []);

    const handleOpenHowItWorks = React.useCallback(() => {
      setIsHowItWorksOpen(true);
    }, []);

    const handleDepositPress = React.useCallback(() => {
      setExtendTpSlOpen(false);
      setTimeout(
        () => {
          SHARED_ROUTER_SERVICE.navigate('DepositWizard', {});
        },
        Platform.OS !== 'web' ? 600 : 0
      );
    }, []);

    return (
      <>
        <ModalDynamic
          onClose={handleCloseExtendTpSl}
          isOpen={isExtendTpSlOpen}
          webMinHeight={502}
          maxWidth={590}
          webOverlayBackgroundColor="$transparent"
          shouldShowHeaderSeparator={Platform.OS !== 'web'}
          title={t('surface.hodls.extend_tp.action_title')}
          subtitle={<TpSlInitialPrice hodlId={hodlId} />}
          // NOTE: Without two props below, the slider inside the bottom sheet will not move by horizontal gesture
          nativeActiveOffsetY={[-1, 1]}
          nativeFailOffsetX={[-5, 5]}
          shouldBeScrollable
        >
          <ExtendTpSlSurface
            hodlId={hodlId}
            onClose={handleCloseExtendTpSl}
            onPressDeposit={handleDepositPress}
            onPressHowItWorks={handleOpenHowItWorks}
            px={{default: 16, desktop: 24}}
            mt={Platform.select({default: 16, web: 0})}
          />
        </ModalDynamic>

        <ModalDynamic
          isOpen={isHowItWorksOpen}
          onClose={handleCloseHowItWorks}
          shouldShowHeaderSeparator={false}
          title={t('surface.hodls.extend_tp.how_works')}
          nativeStackBehavior="push"
          maxWidth={590}
        >
          <HowExtendTpSlWorksSurface />
        </ModalDynamic>
      </>
    );
  }
);
