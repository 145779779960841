import * as React from 'react';
import {observer} from 'mobx-react';
import {Text, type BoxProps, TouchableBox} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Icon} from '@youtoken/ui.icons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {NewReferralOverviewResource} from '@youtoken/ui.resource-new-referral';
import {MinerImageSmall} from './MinerImageSmall';

type NewReferralBannerProps = {};

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('InviteFriends');
};

export const NewReferralBanner: React.FC<NewReferralBannerProps & BoxProps> =
  observer(boxProps => {
    const {t} = useTranslation();

    const {setNewReferralBannerHidden} = AuthMeResource.use({});

    const {
      overview: {
        conditions: {percentFormatted, available},
      },
    } = NewReferralOverviewResource.use({});

    if (!available) {
      return null;
    }

    return (
      <TouchableBox
        pl={16}
        pr={32}
        py={12}
        mx={0}
        flex={1}
        backgroundColor="$interactive-02"
        borderRadius={12}
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        onPress={handlePress}
        testID="MINER_NEW_REFERRAL_BANNER"
        {...boxProps}
      >
        <MinerImageSmall />
        <Text variant="$body-02-high-accent" ml={8}>
          {t('surface.new_referral.banner.text', {percent: percentFormatted})}
        </Text>
        <TouchableBox
          position="absolute"
          right={8}
          top={8}
          onPress={setNewReferralBannerHidden}
          testID="MINER_NEW_REFERRAL_BANNER_CLOSE"
        >
          <Icon name="close" size={16} color="$text-02" />
        </TouchableBox>
      </TouchableBox>
    );
  });
