import * as React from 'react';
import {observer} from 'mobx-react';
import {TouchableBox, Box} from '@youtoken/ui.primitives';
import {useActiveThemeType} from '@youtoken/ui.primitives';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {ReactComponent as LogoSvg} from './assets/logo.svg';
import {ReactComponent as LogoDarkSvg} from './assets/logo-dark.svg';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('WalletsList', {});
};

export const Logo: React.FC = observer(() => {
  const themeType = useActiveThemeType();

  const LogoComponent = themeType === 'dark' ? LogoDarkSvg : LogoSvg;

  return (
    <TouchableBox onPress={handlePress}>
      <Box
        width={{default: 38, desktop: 168}}
        height={36}
        overflow="hidden"
        ml={8}
      >
        <LogoComponent />
      </Box>
    </TouchableBox>
  );
});
