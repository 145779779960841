import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon} from '@youtoken/ui.icons';
import {
  type TouchableBoxProps,
  TouchableBox,
  Text,
} from '@youtoken/ui.primitives';

export const TopBarProfileButton: React.FC<TouchableBoxProps> = observer(
  touchableBoxProps => {
    const {t} = useTranslation();

    return (
      <TouchableBox
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        backgroundColor="$ui-01"
        width={32}
        height={32}
        borderRadius={16}
        {...touchableBoxProps}
      >
        <Icon name="profile" size={20} color="$text-01" />
      </TouchableBox>
    );
  }
);
