import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  NewReferralActivity,
  NewReferralActivityResource,
  NewReferralOverviewResource,
} from '@youtoken/ui.resource-new-referral';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {RewardSummary, ActivitiesList} from '../components';

export type FriendRewardsSurfaceProps = {friendId: string};

const handlePressActivity = (activity: NewReferralActivity) => {
  SHARED_ROUTER_SERVICE.navigate('SingleFriendRewardModal', {activity});
};

export const FriendRewardsSurface: React.FC<
  FriendRewardsSurfaceProps & BoxProps
> = ({friendId, ...boxProps}) => {
  const {
    overview: {
      overview: {
        conditions: {stepAmountUSD},
      },
    },
    activity: {rewards, totalEarnedBtcFormatted, totalEarnedUsdFormatted},
  } = useResources({
    overview: getResourceDescriptor(NewReferralOverviewResource, {}),
    activity: getResourceDescriptor(NewReferralActivityResource, {
      inviteeId: friendId,
    }),
  });

  const {t} = useTranslation();

  return (
    <Box {...boxProps}>
      <RewardSummary
        totalBTC={totalEarnedBtcFormatted}
        totalUSD={totalEarnedUsdFormatted}
        amountUSD={stepAmountUSD}
        title={t('surface.new_referral.friend.earned')}
        mb={24}
      />
      <ActivitiesList
        onPressActivity={handlePressActivity}
        activities={rewards}
      />
    </Box>
  );
};

export const FriendRewardsSurfaceCell = cell(FriendRewardsSurface);
