import {ViewProps} from 'react-native';
import {
  border,
  type BorderProps,
  createRestyleComponent,
  layout,
  type LayoutProps,
  opacity,
  type OpacityProps,
  shadow,
  type ShadowProps,
  visible,
  type VisibleProps,
} from '@shopify/restyle';

import {
  backdropFilter,
  type BackdropFilterProps,
  backgroundColor,
  type BackgroundColorProps,
  backgroundColorShorthand,
  type BackgroundColorShorthandProps,
  spacing,
  type SpacingProps,
  spacingShorthand,
  type SpacingShorthandProps,
  position,
  type PositionProps,
  type Theme,
} from '../../system';

import React from 'react';

export type BoxProps = BackgroundColorProps<Theme> &
  BackgroundColorShorthandProps<Theme> &
  BackdropFilterProps<Theme> &
  BorderProps<Theme> &
  LayoutProps<Theme> &
  OpacityProps<Theme> &
  PositionProps<Theme> &
  ShadowProps<Theme> &
  SpacingProps<Theme> &
  SpacingShorthandProps<Theme> &
  VisibleProps<Theme> & {testID?: string; children?: React.ReactNode};

export type WithBoxProps<ComponentProps> = ComponentProps & BoxProps;

/** patched up `createBox` from `restyle` */
export const createBox = <
  Component extends React.ComponentType<any> = React.ComponentType<ViewProps>
>(
  BaseComponent: Component
) => {
  return createRestyleComponent<
    BoxProps &
      Omit<React.ComponentProps<Component>, keyof BoxProps> & {
        children?: React.ReactNode;
      },
    Theme
  >(
    [
      backdropFilter,
      backgroundColor,
      backgroundColorShorthand,
      opacity,
      visible,
      layout,
      spacing,
      spacingShorthand,
      border,
      shadow,
      position,
    ],
    BaseComponent
  );
};
