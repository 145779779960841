import {deserialize} from 'serializr';
import {computed, action, runInAction} from 'mobx';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {NewReferralOverviewResponse} from './NewReferralOverviewResponse';

export {NewReferralOverviewResponse};

export interface NewReferralOverviewResourceArgs {}

export class NewReferralOverviewResource extends createStaticResource({
  getKey: ({}: NewReferralOverviewResourceArgs) =>
    `newReferralOverviewResource`,
  getData: ({}: NewReferralOverviewResourceArgs) =>
    TRANSPORT.API.get('/v3/referral/overview').then(res =>
      deserialize(NewReferralOverviewResponse, res.data)
    ),
}) {
  @computed get overview() {
    return this.data;
  }

  @action
  setAsRead = () => {
    return TRANSPORT.API.post('/v3/referral/mark-shown').then(() => {
      runInAction(() => (this.data.new = 0));
    });
  };

  @computed get available() {
    return this.data.conditions.available;
  }
}
