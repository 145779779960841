import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {RewardBox} from '@youtoken/ui.elements';
import {
  getLevelName,
  getLevelIconName,
  getLevelColor,
} from '@youtoken/ui.loyalty-miner-utils';

type SavingsResultBlockProps = {
  level: number;
  maxApr: number;
  tickers: string[];
};

export const SavingsResultBlock: React.FC<SavingsResultBlockProps> = observer(
  ({level, maxApr, tickers}) => {
    const {t} = useTranslation();

    const levelName = getLevelName(level);
    const levelIconName = getLevelIconName(level);
    const levelColor = getLevelColor(level);

    if (!levelIconName) {
      return null;
    }

    return (
      <RewardBox
        title={t('surface.wallets.first_action_modal.block_level.title', {
          levelName,
        })}
        iconComponentName="LoyaltyIcon"
        iconName={levelIconName}
        iconBgColor={levelColor}
        borderColor={levelColor}
        bgColor={levelColor}
        text={t(
          'surface.wallets.first_action_modal.block_level.result.subtitle',
          {
            percent: maxApr,
            tickers: tickers.map(t => t.toUpperCase()).join(', '),
          }
        )}
      />
    );
  }
);
