import {observable, action, computed} from 'mobx';
import {i18n} from '@youtoken/ui.service-i18n';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {LedgerResource} from '@youtoken/ui.resource-ledger';
import type {ITab} from '@youtoken/ui.tabs';
import type {CryptoDepositProps} from '../../types';

const whiteList = ['btc', 'eth'];

export class FeatureCryptoDepositLedger extends createFeature({
  getKey: (args: CryptoDepositProps) => `CryptoDepositLedger(${args.ticker})`,
  getResources: (_args: CryptoDepositProps) => {
    return {
      authMeResource: getResourceDescriptor(AuthMeResource, {}),
      walletsResource: getResourceDescriptor(WalletsResource, {}),
      ledgerResource: getResourceDescriptor(LedgerResource, {}),
    };
  },
}) {
  @observable
  methodIndex = 0;

  // wallet start

  @computed
  public get wallet() {
    return this.resources.walletsResource.getByTicker(this.args.ticker)!;
  }

  @computed
  public get walletTicker() {
    return this.wallet.ticker;
  }

  @computed.struct
  public get walletVersions() {
    return this.wallet.versionsByLedger;
  }

  // wallet end

  // ledger start

  @computed
  get hasLedgerCurrencyVersionAccount() {
    if (!whiteList.includes(this.walletTicker)) {
      return false;
    }

    return this.walletVersions.some(({version}) => {
      return (
        this.resources.ledgerResource.getAccountsByCurrencyWithBalance(
          this.walletTicker,
          version
        ).length > 0
      );
    });
  }

  // ledger end

  @computed.struct
  get methods() {
    let methods: ITab[] = [];
    if (this.hasLedgerCurrencyVersionAccount) {
      methods.push({
        type: 'text',
        label: i18n.t('surface.wallets.crypto_deposit.methods.ledger'),
        value: 'ledger',
      });
    }
    methods.push({
      type: 'text',
      label: i18n.t('surface.wallets.crypto_deposit.methods.external'),
      value: 'external',
    });

    return methods;
  }

  @computed.struct
  get method() {
    return this.methods[this.methodIndex]!;
  }

  @action
  setMethod = (_: string, activeIndex: number) => {
    this.methodIndex = activeIndex;
  };
}
