import * as React from 'react';
import {Wallet} from '@youtoken/ui.resource-wallets';
import {
  type BoxProps,
  useIsDesktop,
  TouchableBox,
  Box,
} from '@youtoken/ui.primitives';
import {
  WalletSummaryAndRate,
  WalletActions,
  WalletActionsAdditional,
} from '../../../../smart';
import {useSectionItemHandlePress} from './hooks';

export interface SectionItemProps {
  item: Wallet;
}

export const SectionItem: React.FC<SectionItemProps & BoxProps> = ({
  item,
  ...boxProps
}) => {
  const isDesktop = useIsDesktop();

  const handlePress = useSectionItemHandlePress(item);

  return (
    <TouchableBox
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={20}
      px={20}
      height={{default: 64, desktop: 72}}
      onPress={handlePress}
      testID={`WALLETS_LIST_ITEM_${item.ticker.toUpperCase()}`}
      {...boxProps}
    >
      <WalletSummaryAndRate showSavings item={item} flex={1} />
      {isDesktop && (
        <Box flexDirection="row" mr={-20}>
          <WalletActions item={item} />
          <WalletActionsAdditional item={item} />
        </Box>
      )}
    </TouchableBox>
  );
};
