import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading, Text, type BoxProps} from '@youtoken/ui.primitives';
import {CollapsedArea} from './CollapsedArea';
import {ExpandedArea} from './ExpandedArea';
import {type ScrollableAreaProps} from './types';

export function ScrollableArea<T extends any = any>({
  items,
  renderItem,
  title,
  wrapperPadding,
  wrapperMargin,
  collapsedViewItemLimit,
  scrollingScale,
  isExpandRestricted,
  onPressShowAll,
  collapsedAreaBoxProps,
  expandedAreaBoxProps,
  arrowIconColor,
  arrowBoxProps,
  ...boxProps
}: ScrollableAreaProps<T> & BoxProps): React.ReactElement {
  const {t} = useTranslation();
  const shouldContentBeExpandable =
    collapsedViewItemLimit && items.length > collapsedViewItemLimit;

  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isViewChangerNeeded, setIsViewChangerNeeded] = React.useState(
    isExpandRestricted ? false : shouldContentBeExpandable
  );

  const handleScrollableAreaResize = React.useCallback(
    (isWrapperBiggerThanContent: boolean) => {
      setIsViewChangerNeeded(!isWrapperBiggerThanContent);
    },
    []
  );

  const handleExpand = React.useCallback(() => {
    if (onPressShowAll) {
      onPressShowAll(!isExpanded);
    }
    setIsExpanded(!isExpanded);
  }, [isExpanded, onPressShowAll]);

  return (
    <Box width="100%" {...boxProps}>
      <Box
        width="100%"
        mb={title || isViewChangerNeeded ? 8 : undefined}
        px={{desktop: 0, default: 20}}
        flexDirection="row"
        alignItems="center"
        justifyContent={title ? 'space-between' : 'flex-end'}
      >
        {title && <Heading variant="$heading-02-responsive">{title}</Heading>}
        {isViewChangerNeeded && (
          <Text color="$interactive-01" onPress={handleExpand}>
            {isExpanded
              ? t('surface.hodls.portfolio.show_less')
              : t('surface.hodls.portfolio.show_all')}
          </Text>
        )}
      </Box>
      {!isExpanded && (
        <CollapsedArea
          items={items}
          renderItem={renderItem}
          wrapperPadding={wrapperPadding}
          wrapperMargin={wrapperMargin}
          scrollingScale={scrollingScale}
          itemLimit={collapsedViewItemLimit}
          arrowIconColor={arrowIconColor}
          arrowBoxProps={arrowBoxProps}
          onResize={
            shouldContentBeExpandable || isExpandRestricted
              ? undefined
              : handleScrollableAreaResize
          }
          {...collapsedAreaBoxProps}
        />
      )}
      {isExpanded && (
        <ExpandedArea
          items={items}
          renderItem={renderItem}
          wrapperPadding={wrapperPadding}
          wrapperMargin={wrapperMargin}
          onResize={
            shouldContentBeExpandable || isExpandRestricted
              ? undefined
              : handleScrollableAreaResize
          }
          {...expandedAreaBoxProps}
        />
      )}
    </Box>
  );
}
