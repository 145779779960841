import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useResources, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {ExchangeTariffsResource} from '@youtoken/ui.resource-exchange-tariffs';
import {Box, type BoxProps, Text, useResponsive} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {cell} from '@youtoken/ui.cell';
import {Illustration} from '@youtoken/ui.elements';
import {DepositNowInfo} from './components';

const variantDefaultProps = {
  'never-deposited': {
    p: {default: 0, phone: 16},
    borderColor: '$ui-01' as const,
    borderWidth: {default: 0, phone: 1},
    borderRadius: 12,
  },
};

interface DepositNowBaseProps {
  variant?: 'never-deposited' | 'deposited';
  ticker?: string;
}

export const DepositNow: React.FC<DepositNowBaseProps & BoxProps> = cell(
  props => {
    const {
      products: {
        depositBuyCrypto: {isEnabled},
      },
    } = AuthMeResource.use({});

    if (!isEnabled) {
      return null;
    }

    return <DepositNowWithExchangeTariffs {...props} />;
  },
  {
    SuspenseFallback: () => null,
  }
);

const DepositNowWithExchangeTariffs: React.FC<DepositNowBaseProps & BoxProps> =
  observer(({variant = 'deposited', ticker, ...boxProps}) => {
    const {t} = useTranslation();

    const {
      authMe: {checkProductAvailability},
      wallets: {getByTicker},
      exchangeTariffs: {tickers, tariffs},
    } = useResources({
      authMe: getResourceDescriptor(AuthMeResource, {}),
      wallets: getResourceDescriptor(WalletsResource, {}),
      exchangeTariffs: getResourceDescriptor(ExchangeTariffsResource, {}),
    });

    const showIcon =
      useResponsive({
        default: 0,
        tablet: 1,
      })! > 0;

    const handlePressDeposit = React.useCallback(() => {
      DATA_LAYER.trackStrict('buy-wizard-preview-deposit-click', {
        ticker,
      });

      SHARED_ROUTER_SERVICE.navigate('DepositWizard', {
        source: ticker ? 'WALLET' : 'WALLETS',
        ticker,
      });
    }, [ticker]);

    const handlePressBuy = React.useCallback(() => {
      if (!checkProductAvailability('depositBuyCrypto')) {
        return;
      }

      DATA_LAYER.trackStrict('buy-wizard-preview-buy-click', {
        ticker,
      });

      SHARED_ROUTER_SERVICE.navigate('CryptoBuy', {
        source: ticker ? 'WALLET' : 'WALLETS',
        ticker,
      });
    }, [ticker, checkProductAvailability]);

    if (!tickers.length) {
      return null;
    }

    if (ticker) {
      const walletItem = getByTicker(ticker);

      if (!walletItem?.marketToEnabled || !tariffs[ticker]) {
        return null;
      }
    }

    return (
      <Box
        testID="DEPOSIT_NOW"
        {...(variantDefaultProps[variant as keyof typeof variantDefaultProps] ??
          {})}
        {...boxProps}
      >
        {variant === 'never-deposited' && (
          <Box alignItems="center" testID="DEPOSIT_NOW_IMAGE" mb={24}>
            <Illustration
              name="illustration-14"
              resizeMode="contain"
              style={Platform.select({
                default: {
                  width: 96,
                  height: 96,
                },
                web: {
                  width: 140,
                  height: 140,
                },
              })}
            />
            <Text variant="$body-01-high-accent" mt={8}>
              {t('surface.wallets.deposit_now.title')}
            </Text>
          </Box>
        )}
        {ticker ? (
          <Box flexDirection="row" justifyContent="space-around">
            <Button
              testID="DEPOSIT_NOW_BUY_CRYPTO_BUTTON"
              icon="card"
              onPress={handlePressBuy}
              px={16}
              flex={1}
            >
              {t('surface.menu.buy_crypto')}
            </Button>
          </Box>
        ) : (
          <Box
            flexDirection="row"
            justifyContent="space-around"
            mx={{default: -4, tablet: -8}}
          >
            <Button
              testID="DEPOSIT_NOW_DEPOSIT_CRYPTO_BUTTON"
              icon={showIcon ? 'fiat_deposit' : undefined}
              onPress={handlePressDeposit}
              px={16}
              mx={{default: 4, tablet: 8}}
              flex={1}
            >
              {t('surface.wallets.deposit_now.button')}
            </Button>
            <Button
              testID="DEPOSIT_NOW_BUY_CRYPTO_BUTTON"
              type="secondary"
              icon={showIcon ? 'card' : undefined}
              onPress={handlePressBuy}
              px={16}
              mx={{default: 4, tablet: 8}}
              flex={1}
            >
              {t('surface.menu.buy_crypto')}
            </Button>
          </Box>
        )}
        <DepositNowInfo mt={24} />
      </Box>
    );
  });
