import * as React from 'react';
import {TextInputProps} from 'react-native';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ValidationMessageComponent} from '@youtoken/ui.validation-messages';
import {Box, type BoxProps, Text, useIsMobile} from '@youtoken/ui.primitives';
import {toBig} from '@youtoken/ui.formatting-utils';
import {Link, Switch} from '@youtoken/ui.elements';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {Icon} from '@youtoken/ui.icons';
import {Input} from '../../elements';
import {FeatureTurboCreateForm} from '../stores';

export const TakeProfitFieldSet: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {
    form: {instance},
  } = FeatureTurboCreateForm.use({});

  return (
    <Box p={20} {...props}>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box flexGrow={1} flexShrink={1} mr={10}>
          <Text variant="$body-02" color="$text-02">
            {t('surface.loans.create_turbo_form.ftp')}
          </Text>
        </Box>
        <Switch
          value={instance.$('useFTP').value}
          onValueChange={instance.$('useFTP').onChange}
          disabled={instance.$('useFTP').disabled}
          hasError={instance.$('useFTP').error}
        />
      </Box>
      {instance.$('useFTP').error && (
        <Text variant="$body-02" color="$danger-01">
          <ValidationMessageComponent error={instance.$('useFTP').error}>
            {instance.$('useFTP').error}
          </ValidationMessageComponent>
        </Text>
      )}
      {instance.$('useFTP').value && <TakeProfitFTP />}
    </Box>
  );
});

const TakeProfitFTP: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const isMobile = useIsMobile();

  const {
    form: {instance},
  } = FeatureTurboCreateForm.use({});

  const min = toBig(instance.$('FTP').extra.min)
    .mul(instance.$('initialPrice').value)
    .round(getCoinDecimalPrecision(instance.$('borrowedTicker').value));

  const max = toBig(instance.$('FTP').extra.max)
    .mul(instance.$('initialPrice').value)
    .round(getCoinDecimalPrecision(instance.$('borrowedTicker').value));

  const style = React.useMemo(() => {
    return {
      paddingRight: 80,
    } as TextInputProps['style'];
  }, []);

  return (
    <Box width="100%" {...props}>
      <Box
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent={isMobile ? 'flex-start' : 'space-between'}
        alignItems={isMobile ? 'flex-start' : 'center'}
        //
        width="100%"
        mt={20}
      >
        <Box
          flexGrow={1}
          flexShrink={1}
          flexBasis={isMobile ? 'auto' : 0}
          width="100%"
          mb={isMobile ? 20 : 0}
        >
          <Box position="relative">
            <Input
              value={instance.$('FTP').value}
              onChangeText={instance.$('FTP').onChange}
              style={style}
              keyboardType="decimal-pad"
            />
            <Box height="100%" position="absolute" right={15} top={3} mt={6}>
              <Text color="$text-02">
                {instance.$('collateralTicker').value.toUpperCase()}/
                {instance.$('borrowedTicker').value.toUpperCase()}
              </Text>
            </Box>
          </Box>
        </Box>
        {!isMobile && <Box width={1} height={40} mx={20} bg="$ui-03" />}
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          flexGrow={1}
          flexShrink={1}
          flexBasis={isMobile ? 'auto' : 0}
          width="100%"
        >
          <Box>
            <Text variant="$body-02" color="$text-02">
              {t('surface.loans.create_turbo_form.ftp_min', {
                amount: min.toString(),
                ticker: instance.$('borrowedTicker').value.toUpperCase(),
              })}
            </Text>
            <Text variant="$body-02" color="$text-02">
              {t('surface.loans.create_turbo_form.ftp_max', {
                amount: max.toString(),
                ticker: instance.$('borrowedTicker').value.toUpperCase(),
              })}
            </Text>
          </Box>
          <Box height={24}>
            <Link
              url={t('agreement.youhodler.loan.take_profit.how_works.link')}
              variant="$body-02"
            >
              <Box flexDirection="row" alignItems="center">
                <Box mr={4}>
                  <Icon name="question" color="$interactive-01" />
                </Box>
                <Text>
                  {t('surface.loans.create_turbo_form.ftp_how_it_works')}
                </Text>
              </Box>
            </Link>
          </Box>
        </Box>
      </Box>
      {instance.$('FTP').error && (
        <Box mt={10}>
          <Text variant="$body-02" color="$danger-01">
            <ValidationMessageComponent error={instance.$('FTP').error}>
              {instance.$('FTP').error}
            </ValidationMessageComponent>
          </Text>
        </Box>
      )}
    </Box>
  );
});
