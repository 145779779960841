import {useMemo} from 'react';
import {
  countriesListSignUp,
  getCountriesListLocalized,
} from '@youtoken/ui.countries-utils';
import {i18n} from '@youtoken/ui.service-i18n';

export const useCountryItems = ({withOther}: {withOther: boolean}) => {
  return useMemo(() => {
    const list = getCountriesListLocalized(countriesListSignUp);
    return list
      .map(country => ({
        value: country.code,
        label: country.name,
        key: country.code,
      }))
      .concat(
        // @ts-ignore
        withOther
          ? [
              {
                key: 'other',
                label: i18n.t('surface.sign_up.country.other'),
                value: null,
              },
            ]
          : []
      );
  }, []);
};
