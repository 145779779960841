import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {type BoxProps} from '@youtoken/ui.primitives';
import {TradingInstruments} from '../../components';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

const handlePressItem = () => {
  DATA_LAYER.trackStrict('hodl-open-instrument', {
    openedFrom: 'top_movers',
  });
};

export const TopMoversSection: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {instrumentsTopMovers: items} = HODLsTariffsFeature.use({});

  if (!items.length) {
    return null;
  }

  return (
    <TradingInstruments
      title={t('surface.hodls.portfolio.top_movers_title')}
      items={items}
      onPressItem={handlePressItem}
      mobileEstimatedListHeight={152}
      isExpandRestricted
      testID="TOP_MOVERS_SECTION"
      {...boxProps}
    />
  );
});
