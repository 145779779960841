import * as React from 'react';
import {FlatList, Platform} from 'react-native';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {LoansListHeader as LoansListRegularHeader} from '../components';
import {LoansListRegularCreate} from './Create';
import {LoansListRegularItem} from './Item';
import {LoansListRegularPagination} from './Pagination';
import {LoansListRegularEmpty} from './Empty';
import {LoansListRegularFeature} from './LoansListRegularFeature';

type LoanList = {type?: string; data?: any}[];

export const LoansListRegularSurface: React.FC<
  Omit<
    React.ComponentProps<typeof FlatList<LoanList[number]>>,
    'data' | 'renderItem' | 'keyExtractor'
  >
> = cell(props => {
  const {t} = useTranslation();

  const {hasRows, rowsActive, rowsClosed, activePage, refetch} =
    LoansListRegularFeature.use({});

  // #region scroll on page change

  const flatListRef = React.useRef<FlatList>(null);
  const loansPrevPage = React.useRef(activePage);

  React.useEffect(() => {
    if (loansPrevPage.current !== activePage) {
      Platform.select({
        web: () => {
          window.scrollTo({top: 0, behavior: 'smooth'});
        },
        native: () => {
          flatListRef.current?.scrollToOffset({offset: 0, animated: true});
        },
      })!();

      loansPrevPage.current = activePage;
    }
  }, [activePage]);

  // #endregion scroll on page change

  const data: LoanList = React.useMemo(() => {
    let items = [] as Array<{type: string; data?: any}>;

    items.push({
      type: 'create',
    });

    if (hasRows) {
      if (rowsActive.length) {
        items.push({
          type: 'header',
          data: {
            name: 'surface.loans.active',
          },
        });

        rowsActive.forEach((item, index, list) => {
          items.push({
            type: 'item',
            data: getItemProps(item, index, list),
          });
        });
      }

      if (rowsClosed.length) {
        items.push({
          type: 'header',
          data: {
            name: 'surface.loans.closed',
          },
        });

        rowsClosed.forEach((item, index, list) => {
          items.push({
            type: 'item',
            data: getItemProps(item, index, list),
          });
        });
      }

      items.push({
        type: 'pagination',
      });
    } else {
      items.push({
        type: 'empty',
      });
    }

    return items;
  }, [hasRows, rowsActive, rowsClosed]);

  const handleRenderItem = React.useCallback(
    ({item}: {item: LoanList[number]}) => {
      if (item.type === 'create') {
        return <LoansListRegularCreate />;
      }

      if (item.type === 'empty') {
        return <LoansListRegularEmpty />;
      }

      if (item.type === 'header') {
        return (
          <LoansListRegularHeader>{t(item.data.name)}</LoansListRegularHeader>
        );
      }

      if (item.type === 'item') {
        return <LoansListRegularItem {...item.data} onCancelled={refetch} />;
      }

      if (item.type === 'pagination') {
        return <LoansListRegularPagination />;
      }

      return null;
    },
    [t, refetch]
  );

  const handleKeyExtractor = React.useCallback(
    ({type}: LoanList[number], index: number) => {
      return `${type}-${index}`;
    },
    []
  );

  return (
    <FlatList
      ref={flatListRef}
      testID="LOAN_LIST"
      data={data}
      renderItem={handleRenderItem}
      keyExtractor={handleKeyExtractor}
      {...props}
    />
  );
});

const getItemProps = (
  item: LoansListRegularFeature['rows'][number],
  index: number,
  list: LoansListRegularFeature['rows']
) => {
  const first = index === 0;
  const last = index === list.length - 1;

  return {
    item,
    borderColor: '$ui-01',
    borderTopWidth: 1,
    borderBottomWidth: last ? 1 : 0,
    borderLeftWidth: {
      desktop: 1,
      default: 0,
    },
    borderRightWidth: {
      desktop: 1,
      default: 0,
    },
    borderTopLeftRadius: {
      desktop: first ? 10 : 0,
      default: 0,
    },
    borderTopRightRadius: {
      desktop: first ? 10 : 0,
      default: 0,
    },
    borderBottomLeftRadius: {
      desktop: last ? 10 : 0,
      default: 0,
    },
    borderBottomRightRadius: {
      desktop: last ? 10 : 0,
      default: 0,
    },
  };
};
