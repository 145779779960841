import {__SERVICE_REGISTRY__} from '@youtoken/ui.service-registry';
import {GlobalService} from './GlobalService';
import {AppearanceServiceModule} from './Appearance';

export const GLOBAL = __SERVICE_REGISTRY__.registerService(
  'Global',
  GlobalService,
  require('../package.json').version
);

export const APPEARANCE = __SERVICE_REGISTRY__.registerService(
  'Appearance',
  AppearanceServiceModule,
  require('../package.json').version
);
