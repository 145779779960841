import * as React from 'react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

type RewardSummaryProps = {
  title: string;
  totalBTC: string;
  totalUSD: string;
  amountUSD: number;
  showDescription?: boolean;
};

export const RewardSummary: React.FC<RewardSummaryProps & BoxProps> = ({
  title,
  totalBTC,
  totalUSD,
  amountUSD,
  showDescription,
  ...boxProps
}) => {
  const {t} = useTranslation();

  return (
    <Box flexDirection="column" alignItems="center" {...boxProps}>
      <Text color="$text-02" mb={8}>
        {title}
      </Text>
      <Text variant="$heading-01" color="$text-01" mb={6}>
        {totalBTC} BTC
      </Text>
      <Text color="$text-02" mb={24}>
        ${totalUSD}
      </Text>
      {showDescription && (
        <Text color="$text-02" textAlign="center">
          {t('surface.new_referral.friends.empty.description', {
            amount: amountUSD,
          })}
        </Text>
      )}
    </Box>
  );
};
