import * as React from 'react';
import {FeatureList, Link, Separator} from '@youtoken/ui.elements';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon} from '@youtoken/ui.icons';

export const HowExtendTpSlWorksSurface: React.FC<BoxProps> = boxProps => {
  const {t} = useTranslation();

  return (
    <Box {...boxProps}>
      <FeatureList
        mx={32}
        items={[
          {
            icon: 'fiat_deposit',
            title: t('surface.hodls.extend_tp.add_more_funds_title'),
            children: t('surface.hodls.extend_tp.add_more_funds_text'),
          },
          {
            icon: 'extend',
            title: t('surface.hodls.extend_tp.max_loss_title'),
            children: t('surface.hodls.extend_tp.max_loss_text'),
          },
          {
            icon: 'increase',
            title: t('surface.hodls.extend_tp.max_profit_title'),
            children: t('surface.hodls.extend_tp.max_profit_text'),
          },
          {
            icon: 'pending',
            title: t('surface.hodls.extend_tp.hodl_remains_title'),
            children: t('surface.hodls.extend_tp.hodl_remains_text'),
          },
        ]}
      />
      <Separator mt={24} />
      <Link
        url={t('agreement.youhodler.hodl.increase_position.how_works.link')}
        my={24}
        mx={32}
      >
        <Box flexDirection="row">
          <Box mr={4}>
            <Icon name="question" color="$interactive-01" />
          </Box>

          <Text color="$interactive-01" variant="$body-02-medium-accent">
            {t('common.actions.learn_more')}
          </Text>
        </Box>
      </Link>
    </Box>
  );
};
