import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const NewReferralIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg width={size} height={size} viewBox="0 0 20 21" fill="none" {...props}>
        <Path
          d="M10.335 12.567h-3.3c-1.024 0-1.536 0-1.953.127a2.934 2.934 0 00-1.956 1.955C3 15.066 3 15.579 3 16.602m12.47 0v-4.4m-2.201 2.2h4.401M12.169 6.7a3.3 3.3 0 11-6.602 0 3.3 3.3 0 016.602 0z"
          stroke={color}
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    );
  }
);
