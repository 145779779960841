import * as React from 'react';
import {Item} from '../../../types';
import {WalletsFeature} from '../../../state';
import {createWalletsItems} from '../../../utils';

export const useDataV1 = () => {
  const {
    enableNotifications,
    enableStories,
    enableCoindrops,
    enableNewReferral,
    depositNowVariant,
    sections,
    sectionsVisibilities,
  } = WalletsFeature.use({});

  return React.useMemo<Item[]>(() => {
    let data: Item[] = [];

    if (enableNotifications) {
      data.push({
        type: 'notifications',
        data: {
          mb: 16,
        },
      });
    }

    data.push({
      type: 'summary',
      data: {
        mb: 20,
        px: {default: 16, desktop: 0},
        borderBottomWidth: {default: 1, desktop: 0},
        borderColor: '$ui-01',
      },
    });

    if (enableStories) {
      data.push({
        type: 'stories',
        data: {
          mb: 4,
        },
      });
    }

    data.push({
      type: 'savings-or-rewards',
      data: {
        mt: 16,
        mx: {default: 16, desktop: 0},
      },
    });

    if (enableCoindrops) {
      data.push({
        type: 'coindrop',
        data: {
          mt: 16,
          mx: {default: 16, desktop: 0},
        },
      });
    }

    data.push({
      type: 'info-message',
      data: {
        mt: 16,
        mx: {default: 16, desktop: 0},
      },
    });

    if (enableNewReferral) {
      data.push({
        type: 'referral-banner',
        data: {
          mt: 16,
          mx: {default: 16, desktop: 0},
        },
      });
    }

    data.push({
      type: 'incentives',
      data: {
        mt: 16,
        mx: {default: 16, desktop: 0},
      },
    });

    data.push({
      type: 'deposit-now',
      data: {
        variant: depositNowVariant,
        mt: 16,
        mx: {default: 16, desktop: 0},
      },
    });

    data.push({
      type: 'bank-cards-promo',
      data: {
        mt: 16,
        mx: {default: 16, desktop: 0},
      },
    });

    data.push({
      type: 'bank-cards',
      data: {
        mt: 16,
        mx: {default: 16, desktop: 0},
      },
    });

    data.push(...createWalletsItems(sections, sectionsVisibilities));

    return data;
  }, [
    enableNotifications,
    enableStories,
    enableCoindrops,
    enableNewReferral,
    depositNowVariant,
    sections,
    sectionsVisibilities,
  ]);
};
