import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {SectionHeaderContentProps} from '../../types';
import {KEYS} from '../constants';

export const Hidden: React.FC<SectionHeaderContentProps> = observer(
  ({section, visibility, tickers}) => {
    const {t} = useTranslation();

    return (
      <Box
        flex={1}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text color="$ui-04">
          {t(KEYS[section])} ({tickers.length})
        </Text>
        <Box
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          minWidth={60}
        >
          <Text color="$ui-04" mr={4}>
            {visibility
              ? t('surface.wallets.wallets_list.hide')
              : t('surface.wallets.wallets_list.show')}
          </Text>
          <Icon
            color="$ui-04"
            name={visibility ? 'hide' : 'show'}
            width={20}
            height={20}
          />
        </Box>
      </Box>
    );
  }
);
