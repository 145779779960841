import {useMemo} from 'react';
import {LoyaltyLevel} from '@youtoken/ui.resource-loyalty';
import {LoyaltyIconName} from '@youtoken/ui.icons';
import {type TColorTokens} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import type {CardVisualProps, CardContentParameters} from './types';

export const HEIGHTS = {
  PREV_AND_CURRENT: 64,
  NEXT: 144,
  FUTURE: 131,
};

export const GAP = 8;

export const useCards = (
  levels: LoyaltyLevel[],
  currentLevel: number
): CardVisualProps[] => {
  const {t} = useTranslation();

  return useMemo(() => {
    return levels.map((level, index) => {
      const levelNumber = index + 1;

      if (levelNumber < currentLevel) {
        return {
          height: HEIGHTS.PREV_AND_CURRENT,
          offsetTopStart: 0,
          offsetTopEnd: index * HEIGHTS.PREV_AND_CURRENT + GAP * index,
          zIndex: 1,
          scale: 1,
          icon: `${level.iconName}-grayscale` as LoyaltyIconName,
          backgroundColor: '$ui-01',
          textColor: '$text-03',
          levelTitle: `${t('surface.miner.cards.level_of', {
            number: levelNumber,
            total: levels.length,
          })}`,
        };
      }

      if (levelNumber === currentLevel) {
        return {
          height: HEIGHTS.PREV_AND_CURRENT,
          offsetTopStart: 0,
          offsetTopEnd: index * HEIGHTS.PREV_AND_CURRENT + GAP * index,
          zIndex: 1,
          scale: 1,
          icon: `${level.iconName}` as LoyaltyIconName,
          backgroundColor: level.cardColor,
          textColor: '$text-01',
          levelTitle: t('surface.miner.loyalty.your_level'),
        };
      }

      if (levelNumber === currentLevel + 1) {
        return {
          height: HEIGHTS.NEXT,
          offsetTopStart: 0,
          offsetTopEnd: index * HEIGHTS.PREV_AND_CURRENT + GAP * index,
          zIndex: 10,
          scale: 1,
          icon: level.iconName,
          backgroundColor: level.cardColor,
          textColor: '$text-01',
          levelTitle: t('surface.miner.cards.next_level'),
        };
      }

      return {
        height: HEIGHTS.FUTURE,
        offsetTopStart:
          GAP * (index - currentLevel) + (HEIGHTS.NEXT - HEIGHTS.FUTURE),
        offsetTopEnd:
          currentLevel * HEIGHTS.PREV_AND_CURRENT +
          HEIGHTS.NEXT +
          (index - currentLevel - 1) * HEIGHTS.FUTURE +
          GAP * index,
        zIndex: 10 - index,
        scale: 1 - (index - currentLevel) * 0.02,
        icon: level.iconName,
        backgroundColor: level.cardColor,
        textColor: '$text-01',
        levelTitle: `${t('surface.miner.cards.level_of', {
          number: levelNumber,
          total: levels.length,
        })}`,
      };
    });
  }, [levels, currentLevel, t]);
};

export const useCardContentParameters = (
  level: LoyaltyLevel
): CardContentParameters | null => {
  const {t} = useTranslation();
  const {currentVolume, currentVolumeFormatted, currentLevel} =
    LoyaltyResource.use({});

  return useMemo(() => {
    if (currentLevel >= level.level) {
      // do not show card content for current and previous levels
      return null;
    }

    // for Basic level, show required deposit
    if (level.level === 2) {
      return {
        title: t('surface.miner.cards.required_deposit'),
        description: t('surface.miner.cards.required_volume.value.basic', {
          currentAmount: currentVolumeFormatted || 0,
          maxAmount: level.requiredDepositVisibleFormatted,
        }),
        progress: currentVolume / level.requiredDeposit,
        withTooltip: true,
      };
    }

    // for Jumpstart level, show required actions
    if (level.level === 3) {
      return {
        title: t('surface.miner.cards.required_actions'),
        description: t('surface.miner.cards.required_actions.value'),
      };
    }

    // for next level, which is neither Basic nor Jumpstart, show required volume with progress
    if (level.level === currentLevel + 1) {
      return {
        title: t('surface.miner.cards.required_volume'),
        description: t('surface.miner.cards.required_volume.value', {
          currentAmount: currentVolumeFormatted || 0,
          maxAmount: level.requiredVolumeFormatted,
        }),
        progress: level.requiredVolume
          ? currentVolume / level.requiredVolume
          : 0,
        withTooltip: true,
      };
    }

    // for future levels, show required volume
    return {
      title: t('surface.miner.cards.required_volume'),
      description: level.requiredVolumeFormatted,
      withTooltip: true,
    };
  }, [level, currentLevel, t]);
};

export const useAccountLevelInfo = () => {
  const {t} = useTranslation();

  const {
    currentLevel,
    getLevel,
    willBeDowngradedThisPeriod,
    data: {timeLeftUntilLevelDowngradeFormatted, downgradeLevel},
    currentVolume,
    currentVolumeFormatted,
    currentLevelData: {requiredVolume, requiredVolumeFormatted},
    levelProgress,
  } = LoyaltyResource.use({});

  if (currentLevel <= 2) {
    return null;
  }

  const downgradeLevelName = getLevel(downgradeLevel)?.name;
  const isDowngradeToBasic = downgradeLevel === 2;

  // special text for Basic level
  const description = t(
    isDowngradeToBasic
      ? 'surface.miner.account_level_info.description_downgrade_basic'
      : willBeDowngradedThisPeriod
      ? 'surface.miner.account_level_info.description_downgrade'
      : 'surface.miner.account_level_info.description',
    {
      interval: timeLeftUntilLevelDowngradeFormatted,
    }
  );

  return {
    title: t(
      willBeDowngradedThisPeriod
        ? 'surface.miner.account_level_info.title_downgrade'
        : 'surface.miner.account_level_info.title',
      {level: downgradeLevelName}
    ),
    description,
    textColor: willBeDowngradedThisPeriod
      ? '$attention-01'
      : ('$text-02' as keyof TColorTokens),
    progress: levelProgress,
    progressText: levelProgress
      ? t('surface.miner.cards.required_volume.value', {
          currentAmount: currentVolumeFormatted,
          maxAmount: requiredVolumeFormatted,
        })
      : null,
  };
};
