import * as React from 'react';
import {useTheme} from '@youtoken/ui.primitives';
import {FEBlockState} from '@youtoken/ui.resources-miner';
import {OPACITY_DURATION} from './constants';

export const useLegacyOpacityAnimation = (
  opacities: Record<FEBlockState, number>,
  hexState: FEBlockState,
  duration: number = OPACITY_DURATION
) => {
  // turned opacity animation off for now for performace reasons

  // const opacity = React.useRef(new Animated.Value(0));

  const style = React.useMemo(() => {
    return {
      opacity: opacities[hexState] ?? 0,
    };
  }, [hexState]);

  // React.useEffect(() => {
  //   const timing = Animated.timing(opacity.current, {
  //     toValue: opacities[hexState] ?? 0,
  //     duration,
  //     useNativeDriver: true,
  //   });

  //   timing.start();
  // }, [opacities, hexState, duration]);

  return style;
};

export const useMinerColors = (variant: FEBlockState) => {
  const {colors} = useTheme();

  const BORDER_COLORS = React.useMemo<Record<FEBlockState, string>>(
    () => ({
      DISABLED: colors['$ui-01'],
      AVAILABLE_NOT_ENOUGH_SPARKS: colors['$ui-03'],
      AVAILABLE_ENOUGH_SPARKS: colors['$attention-03'],
      MINING_STARTING: colors['$attention-03'],
      MINING: colors['$success-03'],
      READY: colors['$attention-01'],
      CLAIMING_STARTING: 'transparent',
      CLAIMING: 'transparent',
      CLAIMED_COLORED: 'transparent',
      CLAIMED_GREY: colors['$ui-03'],
      CLAIMED_INFO: colors['$ui-background'],
    }),
    [colors]
  );

  const BG_COLORS = React.useMemo<Record<FEBlockState, string>>(
    () => ({
      DISABLED: colors['$ui-overflow'],
      AVAILABLE_NOT_ENOUGH_SPARKS: colors['$ui-01'],
      AVAILABLE_ENOUGH_SPARKS: colors['$attention-02'],
      MINING_STARTING: colors['$attention-02'],
      MINING: colors['$success-02'],
      READY: colors['$attention-01'],
      CLAIMING_STARTING: colors['$attention-01'],
      CLAIMING: colors['$attention-01'],
      CLAIMED_COLORED: colors['$attention-03'],
      CLAIMED_GREY: 'transparent',
      CLAIMED_INFO: colors['$ui-01'],
    }),
    [colors]
  );

  const TEXT_COLORS = React.useMemo<Record<FEBlockState, string>>(
    () => ({
      DISABLED: 'transparent',
      AVAILABLE_NOT_ENOUGH_SPARKS: colors['$text-02'],
      AVAILABLE_ENOUGH_SPARKS: colors['$attention-01'],
      MINING_STARTING: colors['$attention-01'],
      MINING: colors['$success-01'],
      READY: 'transparent',
      CLAIMING_STARTING: 'transparent',
      CLAIMING: colors['$text-04'],
      CLAIMED_COLORED: 'transparent',
      CLAIMED_GREY: 'transparent',
      CLAIMED_INFO: colors['$text-02'],
    }),
    [colors]
  );

  return {
    textColor: TEXT_COLORS[variant],
    bgColor: BG_COLORS[variant],
    borderColor: BORDER_COLORS[variant],
  };
};
