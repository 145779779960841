let script: HTMLElementTagNameMap['script'] | null = null;

declare global {
  interface Window {
    _sift: Array<any>;
  }
}

export function getSift() {
  return (window._sift = window._sift || []);
}

export const initialize = (key: string) => {
  if (!script) {
    script = document.createElement('script');
    script.src = 'https://cdn.sift.com/s.js';

    document.body.appendChild(script);
  }

  const sift = getSift();

  sift.push(['_setAccount', key]);

  return sift;
};

export const identify = ({
  id,
  sessionId,
}: {
  id?: string;
  sessionId?: string;
}) => {
  const sift = getSift();

  sift.push(['_setUserId', id ?? '']); // Set to the user's ID, username, or email address, or '' if not yet known.
  sift.push(['_setSessionId', sessionId ?? '']); // Set to a unique session ID for the visitor's current browsing session.
};

export const trackPageView = () => {
  const sift = getSift();

  sift.push(['_trackPageview']);
};
