import * as React from 'react';
import {Animated} from 'react-native';
import {observer} from 'mobx-react';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {type CardProps} from '../../types';
import {CardInner} from './CardInner';

export const AnimatedCard: React.FC<CardProps> = observer(
  ({isExpanded, offsetTopStart, offsetTopEnd, zIndex, scale, ...restProps}) => {
    const animatedOffsetTop = React.useRef(new Animated.Value(0)).current;
    const animatedScale = React.useRef(new Animated.Value(1)).current;

    const {currentLevel} = LoyaltyResource.use({});

    const toggle = React.useCallback(() => {
      if (isExpanded) {
        Animated.timing(animatedOffsetTop, {
          toValue: offsetTopEnd,
          duration: 300,
          useNativeDriver: false,
        }).start();
        Animated.timing(animatedScale, {
          toValue: 1,
          duration: 300,
          useNativeDriver: false,
        }).start();
      } else {
        Animated.timing(animatedOffsetTop, {
          toValue: offsetTopStart,
          duration: 300,
          useNativeDriver: false,
        }).start();
        Animated.timing(animatedScale, {
          toValue: scale,
          duration: 300,
          useNativeDriver: false,
        }).start();
      }
    }, [
      isExpanded,
      offsetTopEnd,
      offsetTopStart,
      scale,
      animatedOffsetTop,
      animatedScale,
    ]);

    React.useEffect(() => {
      toggle();
    }, [isExpanded]);

    return (
      <Animated.View
        style={{
          position: 'absolute',
          left: 0,
          width: '100%',
          top: animatedOffsetTop,
          zIndex,
          transform: [
            {
              scale: animatedScale,
            },
          ],
        }}
        testID={`MINER_LEVEL_CARD_${restProps.level.level}`}
      >
        <CardInner currentLevel={currentLevel} {...restProps} />
      </Animated.View>
    );
  }
);
