import * as React from 'react';
import {observer} from 'mobx-react';
import {BoxProps} from '@youtoken/ui.primitives';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {CardInner} from './CardInner';
import {type CardProps} from '../../types';

export const StaticCard: React.FC<CardProps & BoxProps> = observer(props => {
  const {currentLevel} = LoyaltyResource.use({});

  return <CardInner currentLevel={currentLevel} {...props} />;
});
