import * as React from 'react';
import {observer} from 'mobx-react';
import {getCoinSymbol, type currencyName} from '@youtoken/ui.coin-utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {SavingsPayoutIn} from '../PayoutIn';

export interface SavingsEarnedProps {
  ticker: currencyName;
  amountFormatted: string;
  timeLeft: number;
  onTimerEnd?: () => void;
}

export const SavingsEarned: React.FC<SavingsEarnedProps & BoxProps> = observer(
  ({ticker, amountFormatted, timeLeft, onTimerEnd, ...boxProps}) => {
    const {t} = useTranslation();

    const tickerSymbol = getCoinSymbol(ticker);

    return (
      <Box
        testID="SAVINGS_EARNED"
        justifyContent="space-between"
        alignItems="flex-end"
        {...boxProps}
      >
        <Text variant="$body-02" color="$text-02">
          {t('surface.savings.earned')}
        </Text>
        <Text variant="$heading-02">
          <Text testID="SAVINGS_EARNED_SYMBOL">{tickerSymbol}</Text>
          <Text testID="SAVINGS_EARNED_AMOUNT">{amountFormatted}</Text>
        </Text>
        <SavingsPayoutIn
          variant="$body-03"
          color="$text-02"
          textAlign="right"
          timerProps={{
            variant: '$body-03-medium-accent',
            color: '$text-01',
            timeLeft,
            onTimerEnd: onTimerEnd,
          }}
        />
      </Box>
    );
  }
);
