import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {ActionsProps} from './types';
import {
  Deposit as DepositBase,
  Withdraw as WithdrawBase,
  Convert as ConvertBase,
  Trade as TradeBase,
} from './components';

const variant = 'short';

export const WalletActionsShort: React.FC<
  Omit<ActionsProps, 'variant'> & BoxProps
> = observer(({item, ...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={10}
      {...boxProps}
    >
      <DepositBase
        tooltip={t('surface.wallets.common.deposit_button')}
        variant={variant}
        item={item}
      />
      <WithdrawBase
        tooltip={t('surface.wallets.common.withdraw_button')}
        variant={variant}
        item={item}
      />
      <ConvertBase
        tooltip={t('surface.wallets.common.convert_button')}
        variant={variant}
        item={item}
      />
      <TradeBase
        tooltip={t('surface.wallets.common.boost_button')}
        variant={variant}
        item={item}
      />
    </Box>
  );
});
