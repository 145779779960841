import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {ModalDynamic} from '@youtoken/ui.modal';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {DepositForExtendTpSlSurface} from '../../surfaces';

interface DepositForExtendTpSlModalProps {
  hodlId: string;
  onClose: () => void;
}

const IS_NATIVE = Platform.select({web: false, default: true});

const handleNavigateToDeposit = () => {
  SHARED_ROUTER_SERVICE.navigate('DepositWizard', {});
};

export const DepositForExtendTpSlModal: React.FC<DepositForExtendTpSlModalProps> =
  observer(({hodlId, onClose}) => {
    const [isBottomSheetOpen, setIsBottomSheetOpen] = React.useState(true);

    const handleDeposit = React.useCallback(() => {
      setIsBottomSheetOpen(false);
      if (IS_NATIVE) {
        setTimeout(handleNavigateToDeposit, 500);
      } else {
        handleNavigateToDeposit();
      }
    }, [handleNavigateToDeposit]);

    return (
      <ModalDynamic
        onClose={onClose}
        isOpen={isBottomSheetOpen}
        webMinHeight={292}
        maxWidth={590}
        webOverlayBackgroundColor="$transparent"
      >
        <DepositForExtendTpSlSurface
          hodlId={hodlId}
          onDepositPress={handleDeposit}
        />
      </ModalDynamic>
    );
  });
