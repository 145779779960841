import {action, computed} from 'mobx';
import big from 'big.js';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {invariant} from '@youtoken/ui.utils';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {HistoryListResource} from '@youtoken/ui.resource-history-new';
import {BankCardsResource} from '@youtoken/ui.resource-bank-cards';
import {formatBigNumber, formatPercent} from '@youtoken/ui.formatting-utils';
import {RatesSettingsResource} from '@youtoken/ui.resource-rates-settings';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {
  formatInstrumentsItems,
  getDefaultInstrument,
} from '@youtoken/ui.hodls-utils';
import {HodlsTariffsResource} from '@youtoken/ui.resource-hodl-tariffs';

interface WalletsItemFeatureArgs {
  ticker: string;
}

export class WalletsItemFeature extends createFeature({
  getKey: (args: WalletsItemFeatureArgs) => {
    return `feature:walletsItem(${JSON.stringify(args)})`;
  },
  getResources: (args: WalletsItemFeatureArgs) => {
    const {mainCurrency} = AuthMeResource.getInstance({});

    return {
      wallets: getResourceDescriptor(WalletsResource, {}),
      rates: getResourceDescriptor(RatesResource, {}),
      rateSettings: getResourceDescriptor(RatesSettingsResource, {
        ticker: mainCurrency,
      }),
      authme: getResourceDescriptor(AuthMeResource, {}),
      verification: getResourceDescriptor(VerificationResource, {}),
      tariffs: getResourceDescriptor(HodlsTariffsResource, {}),
      historyList: getResourceDescriptor(HistoryListResource, {
        key: 'wallet',
        ticker: [args.ticker],
      }),
      bankCards: getResourceDescriptor(BankCardsResource, {}),
    };
  },
}) {
  @computed
  public get wallet() {
    const ticker = this.args.ticker;
    const wallet = this.resources.wallets.getByTicker(ticker);

    invariant(
      wallet,
      `you are trying to use WalletsItemFeature for ticker="${ticker}" but this wallet does not exist`
    );

    return wallet;
  }

  @computed
  public get ticker() {
    return this.wallet.ticker;
  }

  @computed
  public get precision(): number {
    return getCoinDecimalPrecision(this.ticker);
  }

  @computed
  public get equivalentPrecision(): number {
    return this.resources.rateSettings.getPrecision(
      this.ticker,
      this.equivalentTicker,
      getCoinDecimalPrecision(this.ticker)
    );
  }

  @computed
  public get isFiatTicker() {
    return this.wallet.isFiat;
  }

  @computed
  public get equivalent() {
    return big(this.equivalentRate.mul(this.amount));
  }

  @computed
  public get equivalentTicker() {
    return this.resources.authme.mainCurrency || '';
  }

  @computed
  public get equivalentRate() {
    return big(
      this.resources.rates.getRate(this.ticker, this.equivalentTicker)
    );
  }

  @computed
  public get equivalentRateDiff() {
    const diff = this.resources.rates.getDiff24(
      this.ticker,
      this.equivalentTicker
    );
    const rate24hAgo = big(this.equivalentRate).minus(diff);
    return big(diff).div(rate24hAgo);
  }

  @computed
  public get amount() {
    return this.wallet.amount;
  }

  @computed
  public get earned() {
    return this.wallet.payout?.toEarn?.amount || 0;
  }

  @computed
  public get apr() {
    return this.extraApr;
  }

  @computed
  public get extraApr() {
    return big(this.wallet.referralReward?.apr || 0);
  }

  @computed
  public get hodlsInputAmount() {
    return this.wallet.hodlsInputAmount;
  }

  @computed
  public get dualsInputAmount() {
    return this.wallet.dualsInputAmount;
  }

  @computed
  public get hasApr() {
    return this.apr.gt(0);
  }

  @computed
  public get hasExtraApr() {
    return this.extraApr.gt(0);
  }

  @computed
  public get hasSavings() {
    return this.resources.authme.hasSavings;
  }

  @computed
  public get shouldSignSavings() {
    return this.resources.authme.shouldSignSavings;
  }

  @computed
  public get hasRate() {
    return ['yusd', 'ybtc'].includes(this.ticker)
      ? false
      : this.equivalentTicker !== this.ticker;
  }

  @computed
  public get hasChart() {
    return (
      this.hasRate &&
      !this.isFiatTicker &&
      !this.wallet.tags.includes('stableCoin') &&
      this.wallet.chartEnabled
    );
  }

  @computed
  public get hasHodlsInputAmount() {
    return this.hodlsInputAmount.gt(0);
  }

  @computed
  public get hasDualsInputAmount() {
    return this.dualsInputAmount.gt(0);
  }

  @computed
  public get isTransactionHistoryEmpty() {
    return !this.resources.historyList.sectionsByDate.length;
  }

  @computed
  public get tickerFormatted() {
    return this.ticker.toUpperCase();
  }

  @computed
  public get equivalentFormatted() {
    return formatBigNumber(this.equivalent, 2);
  }

  @computed
  public get equivalentTickerFormatted() {
    return this.equivalentTicker.toUpperCase();
  }

  @computed
  public get equivalentRateFormatted() {
    return formatBigNumber(this.equivalentRate, this.equivalentPrecision, true);
  }

  @computed
  public get equivalentRateDiffFormatted() {
    return formatPercent(this.equivalentRateDiff);
  }

  @computed
  public get amountFormatted() {
    return formatBigNumber(this.amount, this.precision, true);
  }

  @computed
  public get earnedFormatted() {
    return formatBigNumber(this.earned, this.precision);
  }

  @computed
  public get aprFormatted() {
    return formatPercent(this.apr);
  }

  @computed
  public get hodlsInputAmountFormatted() {
    return formatBigNumber(this.hodlsInputAmount, this.precision);
  }

  @computed
  public get dualsInputAmountFormatted() {
    return formatBigNumber(this.dualsInputAmount, this.precision);
  }
}
