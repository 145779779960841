import * as React from 'react';
import {ScrollView} from 'react-native';
import {Listbox} from '@headlessui/react';
import {Icon} from '@youtoken/ui.icons';
import {
  type TouchableBoxProps,
  Text,
  Box,
  TouchableBox,
  useTheme,
} from '@youtoken/ui.primitives';
import {SelectActionButton} from '../SelectActionButton';
import type {SelectProps} from './types';
import {type BaseItemOption} from './types';
import {Option} from './Option.web';
import {
  getLabelByValue,
  getSelectHeight,
  getLabelVariant,
  getBorderColor,
} from './utils';

interface ListboxButtonProps extends TouchableBoxProps {
  onClick: TouchableBoxProps['onPress'];
}

const ListboxButton: React.FC<ListboxButtonProps> = ({
  onClick,
  ...touchableBoxProps
}) => {
  return <TouchableBox onPress={onClick} {...touchableBoxProps} />;
};

export const Select: React.FC<SelectProps> = ({
  items,
  onSelect,
  selected,
  size = 'medium',
  width,
  dropdownWidth,
  placeholder,
  dropdownDirection = 'bottom',
  dropdownAlign = 'right',
  hasError,
  disabled,
  testID,
  withBorder = true,
  withPadding = true,
  labelComponent: LabelComponent,
  expandComponent: ExpandComponent,
  withExpandIcon = true,
}) => {
  const height = getSelectHeight(size);
  const {shadow} = useTheme();
  const selectedLabel = React.useMemo(
    () => getLabelByValue(items, placeholder, selected),
    [items, placeholder, selected]
  );
  const padding = !withPadding ? 0 : size === 'small' ? 12 : 16;
  const paddingRight = size === 'small' && withExpandIcon ? 8 : padding;

  const icon = (
    items.find(
      item => item.type !== 'action-button' && item.value === selected
    ) as BaseItemOption
  )?.icon;

  return (
    <Listbox value={selected} onChange={onSelect} disabled={disabled}>
      {({open}) => (
        <Box
          position="relative"
          zIndex={10}
          width={width}
          flex={1}
          flexDirection="row"
        >
          <Listbox.Button as={ListboxButton} width={width}>
            <Box
              borderColor={getBorderColor(hasError, open)}
              backgroundColor={disabled ? '$ui-01' : undefined}
              borderRadius={6}
              borderWidth={withBorder ? 1 : 0}
              height={height}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              paddingLeft={padding}
              paddingRight={paddingRight}
              // @ts-ignore
              style={{cursor: disabled ? 'default' : 'pointer'}}
              testID={testID}
            >
              {LabelComponent ? (
                <LabelComponent open={open} />
              ) : (
                <Box flexDirection="row" gap={8} alignItems="center">
                  {icon}
                  <Text
                    variant={getLabelVariant(!!selected, size)}
                    color={
                      disabled ? '$text-03' : selected ? '$text-01' : '$text-03'
                    }
                  >
                    {selectedLabel ? selectedLabel : placeholder}
                  </Text>
                </Box>
              )}
              {withExpandIcon ? (
                ExpandComponent ? (
                  <ExpandComponent open={open} />
                ) : (
                  <Box testID={`${testID}-TOGGLE`} ml={8}>
                    <Icon
                      name={open ? 'collapse' : 'expand'}
                      color="$text-01"
                      size={size === 'small' ? 16 : 20}
                    />
                  </Box>
                )
              ) : null}
            </Box>
          </Listbox.Button>
          <Listbox.Options as={React.Fragment}>
            <Box
              position="absolute"
              top={dropdownDirection === 'bottom' ? height + 2 : 'auto'}
              bottom={dropdownDirection === 'top' ? height + 2 : 'auto'}
              right={dropdownAlign === 'right' ? 0 : 'auto'}
              left={dropdownAlign === 'left' ? 0 : 'auto'}
              backgroundColor="$ui-background"
              width={dropdownWidth ? dropdownWidth : '100%'}
              paddingTop={8}
              paddingBottom={8}
              borderRadius={8}
              zIndex={10}
              // @ts-ignore
              style={{boxShadow: shadow}}
            >
              <ScrollView
                style={{maxHeight: 350}}
                showsVerticalScrollIndicator={false}
              >
                {items.map(item => {
                  if (item.type === 'action-button') {
                    return (
                      <SelectActionButton
                        key={item.label}
                        onPress={item.onPress}
                      >
                        {item.label}
                      </SelectActionButton>
                    );
                  }

                  return (
                    <Option
                      key={item.value}
                      value={item.value}
                      height={height}
                      label={item.label}
                      icon={item.icon}
                      padding={padding}
                    />
                  );
                })}
              </ScrollView>
            </Box>
          </Listbox.Options>
        </Box>
      )}
    </Listbox>
  );
};
