import {Platform} from 'react-native';
import {assignHeader} from './utils';
import {
  getDeviceID,
  getOSVersion,
  getApplicationVersion,
  getDeviceModel,
  getDeviceBrand,
  getDeviceNameSanitized,
} from '@youtoken/ui.security-utils';
import {GLOBAL} from '@youtoken/ui.service-global';
import {ENVIRONMENT} from '@youtoken/ui.environment';

export function assignSecurityHeaders(headers: Record<string, string>) {
  assignHeader(headers, 'Authorization', () => GLOBAL.token);
  assignHeader(
    headers,
    'x-device-type',
    () => Platform.select({ios: 'ios', android: 'android', web: 'web'})!
  );
  assignHeader(headers, 'x-use-i18n-errors', () => 'true');
  assignHeader(headers, 'x-device-uuid', getDeviceID);
  assignHeader(headers, 'x-os-version', getOSVersion);
  assignHeader(headers, 'x-app-version', getApplicationVersion);
  assignHeader(headers, 'x-device-model', getDeviceModel);
  assignHeader(headers, 'x-device-brand', getDeviceBrand);
  assignHeader(headers, 'x-device-name', getDeviceNameSanitized);
}
