import {computed, observable} from 'mobx';
import {serializable, primitive, date, optional, list, object} from 'serializr';
import {Big} from 'big.js';
import {bigNumber} from '@youtoken/ui.utils-serializr';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {formatRelative} from '@youtoken/ui.date-fns';

export enum NewReferralActivityEvent {
  REGISTRATION = 'REGISTRATION',
  VERIFICATION = 'VERIFICATION',
  REWARD = 'REWARD',
}

export class NewReferralActivity {
  @observable
  @serializable(date())
  createdAt!: Date;

  @computed
  get createdAtFormatted() {
    const formatted = formatRelative(this.createdAt, Date.now());
    return formatted.charAt(0).toUpperCase() + formatted.slice(1);
  }

  @observable
  @serializable(primitive())
  event!: NewReferralActivityEvent;

  @observable
  @serializable(primitive())
  inviteeName!: string;

  @observable
  @serializable(primitive())
  inviteeEmail!: string;

  @observable
  @serializable(primitive())
  inviteeId!: string;

  @observable
  @serializable(optional(bigNumber()))
  rewardBTC?: Big;

  @computed get rewardBTCFormatted() {
    return formatByTicker(this.rewardBTC, 'btc');
  }

  @observable
  @serializable(optional(bigNumber()))
  rewardUSD?: Big;

  @computed get rewardUSDFormatted() {
    return formatByTicker(this.rewardUSD, 'usd');
  }
}

export class NewReferralActivityResponse {
  @observable
  @serializable(list(object(NewReferralActivity)))
  activities!: NewReferralActivity[];

  @observable
  @serializable(primitive())
  total!: number;
}
