import * as React from 'react';
import {observer} from 'mobx-react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Separator, useWizard} from '@youtoken/ui.elements';
import {useForm} from '@youtoken/ui.form-elements';
import {BuyCryptoFormAuthorizedOnRampState} from '../../../../state';
import {HeaderBase} from '../../../../../../components';
import {InstantMethods} from '../../../PayWith';
import {BuyLaterMethods} from '../../../PayWith';
import type {BuyCryptoFormAuthorizedOnRampWizardNavigatorParams} from '..';

export const BuyCryptoFormAuthorizedOnRampChooseMethod: React.FC = observer(
  () => {
    const {t} = useTranslation();

    const {goBack} = useWizard<
      BuyCryptoFormAuthorizedOnRampWizardNavigatorParams,
      'ChooseMethod'
    >();

    const {
      form: {
        changeDepositMethod,
        possibleInstantDepositMethods,
        possibleLaterDepositMethods,
      },
    } = useForm<BuyCryptoFormAuthorizedOnRampState>();

    const showSeparator =
      possibleInstantDepositMethods.length > 0 &&
      possibleLaterDepositMethods.length > 0;

    const handleMethodSelected = (method: string) => {
      DATA_LAYER.trackStrict('ramp-widget-method', {
        direction: 'on-ramp',
      });

      changeDepositMethod(method);

      goBack();
    };

    return (
      <>
        <HeaderBase onPressBack={goBack}>
          {t('ramp.conversion_form.label.pay_with')}
        </HeaderBase>
        <InstantMethods onMethodSelected={handleMethodSelected} />
        {showSeparator && <Separator />}
        <BuyLaterMethods onMethodSelected={handleMethodSelected} />
      </>
    );
  }
);
