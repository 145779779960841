import {action, computed, observable} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {Form} from './Form';
import {
  type FrameCardValidationChangedEventProps,
  type FrameValidationChangedEventProps,
} from '../types';

export class View {
  @observable
  public instance: MobxReactForm;

  @observable
  public form: Form;

  public constructor(form: Form) {
    this.form = form;
    this.instance = form.instance;
  }

  @computed
  public get cardHolderName() {
    return this.instance.$('cardHolderName').get('value');
  }

  @computed
  public get cardHolderNameError() {
    return this.instance.$('cardHolderName').error;
  }

  @action
  public onCardHolderNameBlur = () => {
    this.instance.$('cardHolderName').set('value', this.cardHolderName.trim());
  };

  @computed
  public get countryCode() {
    return this.instance.$('countryCode').get('value');
  }

  @computed
  public get city() {
    return this.instance.$('city').get('value');
  }

  @computed
  public get zipCode() {
    return this.instance.$('zipCode').get('value');
  }

  @computed
  public get addressLine1() {
    return this.instance.$('addressLine1').get('value');
  }

  @action
  public onCardValidationChanged = (
    data: FrameCardValidationChangedEventProps
  ) => {
    this.form.isCardDataValid = data.isValid;
  };

  @action
  public onFrameValidationChanged = ({
    isValid,
    element,
  }: FrameValidationChangedEventProps) => {
    switch (element) {
      case 'expiry-date': {
        this.form.isValidExpiryDate = isValid;
        break;
      }
      case 'card-number': {
        this.form.isValidCardNumber = isValid;
        break;
      }
      case 'cvv': {
        this.form.isValidCvv = isValid;
        break;
      }
    }
  };
}
