import * as React from 'react';
import {observer} from 'mobx-react';
import {Button} from '@youtoken/ui.buttons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {NewReferralOverviewResource} from '@youtoken/ui.resource-new-referral';

export const ActionButton: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    overview: {referralCode},
  } = NewReferralOverviewResource.use({});

  const handlePress = () => {
    DATA_LAYER.trackStrict('referral-miner-referral-link-shown', {
      inviteMethod: 'list',
      referralCode,
    });
    SHARED_ROUTER_SERVICE.navigate('InviteFriendsModal');
  };

  return (
    <Button
      size="large"
      onPress={handlePress}
      mt={24}
      testID="INVITE_FRIENDS_ACTION_BUTTON"
    >
      {t('surface.new_referral.friend_list.action')}
    </Button>
  );
});
