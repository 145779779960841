import {observable} from 'mobx';
import {
  serializable,
  primitive,
  list,
  object,
  optional,
  custom,
  deserialize,
} from 'serializr';

type TPromoItem = TCoindropPromo;

export class CoindropPromoParams {
  @observable
  @serializable(list(primitive()))
  coins!: string[];
}

export class TCoindropPromo {
  @observable
  @serializable(primitive())
  type!: 'COINDROP';

  @observable
  @serializable(object(CoindropPromoParams))
  params!: CoindropPromoParams;
}

export class OnboardingWidgetResponse {
  @observable
  @serializable(
    optional(
      custom(
        item => item,
        item => {
          switch (item.type) {
            case 'COINDROP':
              return deserialize(TCoindropPromo, item);
            default:
              return undefined;
          }
        }
      )
    )
  )
  promo?: TPromoItem;
}
