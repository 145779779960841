import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Modal} from '../_common';
import {SingleFriendRewardModalContent} from '@youtoken/ui.surface-new-referral';
import {NewReferralActivity} from '@youtoken/ui.resource-new-referral';

export const modalSingleFriendRewardName = 'modalSingleFriendReward';

interface ModalSingleFriendRewardProps
  extends React.ComponentProps<typeof Modal> {
  activity: NewReferralActivity;
}

export const ModalSingleFriendReward: React.FC<ModalSingleFriendRewardProps> =
  cell(({activity, ...modalProps}) => {
    return (
      <Modal {...modalProps}>
        <SingleFriendRewardModalContent p={24} activity={activity} />
      </Modal>
    );
  });
