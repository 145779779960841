import * as React from 'react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {
  TouchableBanner,
  type TouchableBannerVariant,
} from '@youtoken/ui.elements';
import {observer} from 'mobx-react';
import {type BaseIconName} from '@youtoken/ui.icons';
import {i18n} from '@youtoken/ui.service-i18n';
import {invariant} from '@youtoken/ui.utils';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {
  VerificationResource,
  verificationCodeActions,
} from '@youtoken/ui.resource-verification';
import {OnboardingWidgetFeature, type ActionName} from '../state';

type TActionComponentName = 'button' | 'banner';

export const Action: React.FC<BoxProps> = observer(boxProps => {
  const {actionName, currentAccountVerification} = OnboardingWidgetFeature.use(
    {}
  );

  if (!actionName) {
    return null;
  }

  const getActionParameters = (name: ActionName) => {
    const actionParameters: Record<
      ActionName,
      {
        componentName: TActionComponentName;
        text: string;
        iconName?: BaseIconName;
        variant?: TouchableBannerVariant;
        onPressHandler: () => void;
        bannerProps?: BoxProps;
      }
    > = {
      VERIFY_IDENTITY_BUTTON: {
        componentName: 'button',
        text: i18n.t('surface.onboarding_widget.action.verify_identity'),
        onPressHandler: VerificationResource.launchIdentityVerification,
      },
      CONTINUE_VERIFICATION_BUTTON: {
        componentName: 'button',
        text: i18n.t('surface.onboarding_widget.action.continue_verification'),
        onPressHandler: verificationCodeActions[currentAccountVerification!],
      },
      DOCS_UNDER_REVIEW_BANNER: {
        componentName: 'banner',
        text: i18n.t('surface.onboarding_widget.action.docs_under_review'),
        iconName: 'passport',
        variant: 'attentionSecondary',
        onPressHandler: verificationCodeActions[currentAccountVerification!],
      },
      VERIFICATION_FAILED_BANNER: {
        componentName: 'banner',
        text: i18n.t('surface.onboarding_widget.action.verification_failed'),
        iconName: 'doc_scan',
        variant: 'dangerSecondary',
        onPressHandler: verificationCodeActions[currentAccountVerification!],
      },
      TOP_UP_BALANCE_BUTTON: {
        componentName: 'button',
        text: i18n.t('surface.onboarding_widget.action.top_up_balance'),
        onPressHandler: () =>
          SHARED_ROUTER_SERVICE.navigate('DepositWizard', {}),
      },
    };

    return actionParameters[name];
  };

  const params = getActionParameters(actionName);

  if (!params) {
    return null;
  }

  const {componentName, text, iconName, variant, onPressHandler, bannerProps} =
    params;

  invariant(
    componentName !== 'banner' || (componentName === 'banner' && iconName),
    'Icon name is required for banner component'
  );

  return (
    <Box testID="BONUSES_WIDGET_ACTION" {...boxProps}>
      {componentName === 'button' && (
        <Button size="large" onPress={onPressHandler}>
          {text}
        </Button>
      )}
      {componentName === 'banner' && (
        <TouchableBanner
          type="icon"
          variant={variant}
          text={text}
          iconName={iconName!}
          onPress={onPressHandler}
          {...bannerProps}
        />
      )}
    </Box>
  );
});
