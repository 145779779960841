import * as React from 'react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {Tooltip} from '@youtoken/ui.tooltip';
import {type ActionProps} from '../types';

export const WalletActionCellWrap: React.FC<ActionProps & BoxProps> = ({
  tooltip,
  children,
}) => {
  if (tooltip) {
    return (
      <Tooltip content={tooltip} contentProps={{alignItems: 'center'}}>
        {children}
      </Tooltip>
    );
  }

  return children;
};
