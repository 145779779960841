import * as React from 'react';
import axios from 'axios';
import {observer} from 'mobx-react';
import {useGoogleLogin} from '@react-oauth/google';
import {Button} from '@youtoken/ui.buttons';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {handleGeneralErrorTranslated} from '@youtoken/ui.validation-messages';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useGoogleAuth} from './hooks';
import {SENTRY} from '@youtoken/ui.sentry';
import {type GoogleAuthButtonProps} from './types';

export const GoogleAuthButton: React.FC<GoogleAuthButtonProps & BoxProps> =
  observer(({residence, inviteeCode, ...boxProps}) => {
    const [isLoading, setLoading] = React.useState(false);
    const signInWithGoogle = useGoogleAuth();
    const {signInConfirmSuccess} = AuthMeResource.use({});

    const {t} = useTranslation();

    const handleSuccess = React.useCallback(
      (res: any) => {
        setLoading(true);
        signInWithGoogle(res.access_token)
          .then(signInConfirmSuccess)
          .catch(error => {
            if (error?.response?.status === 404) {
              axios
                .get('https://www.googleapis.com/oauth2/v3/userinfo', {
                  headers: {
                    Authorization: `Bearer ${res.access_token}`,
                  },
                })
                .then(({data: {email}}) => {
                  SHARED_ROUTER_SERVICE.navigate('SignUpAdditional', {
                    email,
                    provider: 'google',
                    accessCode: res.access_token,
                    residence,
                    inviteeCode,
                  });
                });
            } else {
              SENTRY.capture(error, {
                source: 'Google SSO',
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      },
      [residence, signInConfirmSuccess, signInWithGoogle]
    );

    const login = useGoogleLogin({
      onSuccess: handleSuccess,
      onError: error => {
        if (error.error === 'access_denied') {
          // user closed the popup without giving consent
          return;
        }
        handleGeneralErrorTranslated(error);
      },
    });

    return (
      <>
        <Box {...boxProps}>
          <Button
            size="medium"
            onPress={login}
            loading={isLoading}
            icon="google"
            iconPosition="left"
            color="external-auth"
            testID="GOOGLE_AUTH_BUTTON"
          >
            {t('surface.sign_up.continue.google')}
          </Button>
        </Box>
      </>
    );
  });
