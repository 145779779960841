import {observable} from 'mobx';
import {
  alias,
  custom,
  date,
  object,
  optional,
  primitive,
  serializable,
} from 'serializr';
import {number} from '@youtoken/ui.utils-serializr';
import {CloseData} from './CloseData';
import {TradingOrderType} from './types';
import {HodlLoanChainResponse} from './HodlLoanChainResource/HodlLoanChainResponse';

/** custom chart data serializer to match line chart data call
 * ```typescript
 *    [...] => {type: 'line', data: [...]}
 * ```
 */
const _chartData = () =>
  custom(
    outValue => outValue,
    (inValue: Array<{rate: number; date: string}> = []) => ({
      type: 'line',
      data:
        !inValue || !Array.isArray(inValue)
          ? []
          : inValue.map(d => ({rate: d.rate, date: new Date(d.date)})),
    })
  );

export type IncomingData = any;

export class HODLItemData {
  @observable
  @serializable(primitive())
  id!: string;

  @observable
  @serializable(primitive())
  accountId!: string;

  @observable
  @serializable(primitive())
  isShort!: boolean;

  @observable
  @serializable(number())
  multiplier!: number;

  @observable
  @serializable(number())
  apr!: number;

  @observable
  @serializable(number())
  openingFeeAmount!: number;

  @observable
  @serializable(optional(number()))
  openingFee?: number;

  @observable
  @serializable(optional(primitive()))
  settlementPeriod?: string; // NOTE: v3 +, v2 +, v1 -

  @observable
  @serializable(number())
  settlementFee!: number;

  @observable
  @serializable(number())
  settlementFeeAmount!: number;

  @observable
  @serializable(number())
  revShareFee!: number;

  @observable
  @serializable(primitive())
  inputTicker!: string;

  @observable
  @serializable(primitive())
  outputTicker!: string;

  @observable
  @serializable(primitive())
  baseTicker!: string;

  @observable
  @serializable(primitive())
  quoteTicker!: string;

  @observable
  @serializable(primitive())
  collateralTicker!: string;

  @observable
  @serializable(primitive())
  borrowedTicker!: string;

  @observable
  @serializable(primitive())
  overdraftTicker!: string; // NOTE: check in V1

  @observable
  @serializable(alias('decimals', primitive()))
  precision!: number;

  @observable
  @serializable(number())
  inputAmount!: number;

  @observable
  @serializable(optional(number()))
  outputAmount?: number;

  @observable
  @serializable(optional(number()))
  ownAmount?: number;

  @observable
  @serializable(optional(number()))
  borrowedAmount?: number;

  @observable
  @serializable(optional(number()))
  totalAmount?: number;

  @observable
  @serializable(optional(number()))
  overdraftAmount!: number; // NOTE: check in V1

  @observable
  @serializable(optional(number()))
  ifPrice?: number;

  @observable
  @serializable(optional(number()))
  initialPrice?: number; // NOTE: v3 +, v2 +, v1 -

  // @observable
  @serializable(optional(number()))
  triggerPrice?: number;

  @observable
  @serializable(optional(number()))
  closedPrice?: number;

  @observable
  @serializable(primitive())
  orderType!: TradingOrderType;

  @observable
  @serializable(optional(number()))
  mcFee?: number; // NOTE: v3 -, v2 +, v1 -

  @observable
  @serializable(optional(number()))
  unifiedFee?: number;

  @observable
  @serializable(optional(number()))
  mcPrice?: number;

  @observable
  @serializable(optional(number()))
  slPrice?: number;

  @observable
  @serializable(optional(number()))
  mcFeeAmount?: number;

  @observable
  @serializable(optional(number()))
  tpPrice?: number;

  @observable
  @serializable(optional(number()))
  ftpPrice?: number;

  @observable
  @serializable(optional(number()))
  maxProfit?: number; // NOTE: v3 +, v2 -, v1 -

  @observable
  @serializable(optional(number()))
  maxLoss?: number; // NOTE: v3 +, v2 -, v1 -

  @observable
  @serializable(_chartData())
  chartData!: IncomingData;

  @observable
  @serializable(optional(object(CloseData)))
  closed?: CloseData;

  @observable
  @serializable(optional(object(CloseData)))
  closeCalculate?: CloseData;

  @observable
  @serializable(date())
  createdAt!: Date;

  @observable
  @serializable(optional(date()))
  startedAt?: Date;

  @observable
  @serializable(optional(date()))
  finishedAt?: Date;

  @observable
  @serializable(optional(date()))
  expireAt?: Date; // NOTE: this id the date of pending order expiration: for expired it will be in the past, for active - in future

  // for active hodls
  @serializable(optional(number()))
  @observable
  hodlExpireAt?: Date;

  @observable
  @serializable(number())
  version!: number;

  @observable
  @serializable(primitive())
  status!: string;

  @observable
  @serializable(optional(primitive()))
  reason?: string = '';

  @observable
  @serializable(primitive())
  loanId!: string;

  @observable
  @serializable(number())
  maxFTP!: number;

  @observable
  @serializable(number())
  minFTP!: number;

  @observable
  @serializable(optional(object(HodlLoanChainResponse)))
  loan?: HodlLoanChainResponse;

  @observable
  @serializable(primitive())
  isClientMC?: boolean;

  @observable
  @serializable(number())
  /**
   * in minutes
   */
  hftFeeTradeTime: number = 10;

  @observable
  @serializable(date())
  nextChargeAt?: Date;

  @observable
  @serializable(primitive())
  tradingVolumeUsd!: number;

  @observable
  @serializable(optional(primitive()))
  additionalInputTicker?: string;

  @observable
  @serializable(number())
  mainInputAmount!: number;

  @observable
  @serializable(optional(number()))
  additionalInputAmount?: number;

  @observable
  @serializable(primitive())
  beforeKyc?: boolean;
}
