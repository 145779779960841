import React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {ItemProps} from '../../types';

export const Rate: React.FC<ItemProps & BoxProps> = observer(
  ({item, ...boxProps}) => {
    const {
      tickerFormatted,
      equivalentTickerFormatted,
      rateFormatted,
      rateDiffPercentFormatted,
      isRateDiffPositive,
    } = item;

    return (
      <Box alignItems="flex-end" {...boxProps}>
        <Text
          testID={`${tickerFormatted}_TO_${equivalentTickerFormatted}_RATE`}
        >
          {rateFormatted}{' '}
          <Text color="$text-02">{equivalentTickerFormatted}</Text>
        </Text>
        <Text
          variant="$body-02"
          color={isRateDiffPositive ? '$success-01' : '$danger-01'}
          testID={`${tickerFormatted}_TO_${equivalentTickerFormatted}_RATE_DIFF`}
        >
          {rateDiffPercentFormatted}%
        </Text>
      </Box>
    );
  }
);
