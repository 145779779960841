import * as React from 'react';
import {observer} from 'mobx-react';
import {Text, type BoxProps, TouchableBox} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon} from '@youtoken/ui.icons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {NewReferralOverviewResource} from '@youtoken/ui.resource-new-referral';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('InviteFriends');
};

export const ReferralBanner: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    overview: {
      conditions: {percentFormatted},
    },
    available,
  } = NewReferralOverviewResource.use({});

  if (!available) {
    return null;
  }

  return (
    <TouchableBox
      py={12}
      mx={-20}
      flex={1}
      borderTopLeftRadius={{default: 0, tablet: 16}}
      borderTopRightRadius={{default: 0, tablet: 16}}
      backgroundColor="$attention-02"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      alignSelf="stretch"
      onPress={handlePress}
      testID="MINER_REFERRAL_BANNER"
      {...boxProps}
    >
      <Text variant="$body-02-high-accent" ml={8} color="$attention-01">
        {t('surface.new_referral.banner.text', {percent: percentFormatted})}
      </Text>
      <Icon name="chevron_right" size={16} color="$attention-01" />
    </TouchableBox>
  );
});
