import * as React from 'react';
import {observer} from 'mobx-react';
import {Platform} from 'react-native';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useForm} from '@youtoken/ui.form-elements';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {Slider} from '@youtoken/ui.legacy-slider';
import {TextInput} from '@youtoken/ui.inputs';
import {ValidationMessageComponent} from '@youtoken/ui.validation-messages';
import {normalizeAmountWithPrecision} from '@youtoken/ui.normalizers';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {CreateHODLFormClass} from '@youtoken/ui.surface-hodl-create';
import {additionalInputTickerUIFormatted} from '@youtoken/ui.incentives-utils';
import {ExtendTpSlForm} from '../../ExtendTpSlBottomSheet/form';

export const BonusesInputWithSlider: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    form: {
      minBonusesAmount,
      maxBonusesAmount,
      maxBonusesPercent,
      additionalInputTickerBE,
      additionalInputAmount,
      setAdditionalInputAmount,
      additionalInputAmountError,
    },
  } = useForm<CreateHODLFormClass | ExtendTpSlForm>();

  const handleSliderChange = (value: number) => {
    const precision = getCoinDecimalPrecision(additionalInputTickerBE);
    setAdditionalInputAmount(
      normalizeAmountWithPrecision(value.toString(), precision)
    );
  };

  return (
    <Box {...boxProps}>
      <TextInput
        RightPartComponent={
          <Box justifyContent="center" alignItems="center" flex={1} px={20}>
            <Text variant="$body-02" color="$text-02">
              {additionalInputTickerUIFormatted}
            </Text>
          </Box>
        }
        value={additionalInputAmount}
        onChangeText={setAdditionalInputAmount}
        keyboardType="decimal-pad"
        placeholder="0.00"
      />
      {Boolean(additionalInputAmountError) && (
        <Box mt={10}>
          <Text color="$danger-01" variant="$body-02">
            <ValidationMessageComponent error={additionalInputAmountError} />
          </Text>
        </Box>
      )}

      <Box mt={8}>
        <Text variant="$body-02" color="$text-02">
          {t('surface.hodls.item.use_bonuses_section.limit_description', {
            percent: maxBonusesPercent,
          })}
        </Text>
      </Box>

      {/* mb to counteract greater height of native slider */}
      <Box mb={Platform.select({native: -8, default: 0})}>
        <Slider
          value={Number(additionalInputAmount)}
          minimumValue={minBonusesAmount}
          step={0.01}
          maximumValue={Number(maxBonusesAmount)}
          showLegend={false}
          onChange={handleSliderChange}
          key="bonuses-slider"
        />
      </Box>
    </Box>
  );
});
