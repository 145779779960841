import type {Theme} from '@youtoken/ui.primitives';
import type {Color} from '../types';

type BackgroundColor = Record<Color, keyof Theme['colors']>;

export const backgroundColor: BackgroundColor = {
  interactive: '$interactive-01',
  attention: '$attention-01',
  success: '$success-01',
  danger: '$danger-01',
  inactive: '$text-01',
};

export const wrapperBackgroundColor: BackgroundColor = {
  interactive: '$interactive-03',
  attention: '$attention-03',
  success: '$success-03',
  danger: '$danger-03',
  inactive: '$text-01',
};
