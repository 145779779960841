import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {IconPaymentMethod} from '@youtoken/ui.icons';
import {
  Box,
  Text,
  useActiveThemeType,
  useIsDesktop,
} from '@youtoken/ui.primitives';
import {AuthMeResource, LegalEntity} from '@youtoken/ui.resource-auth-me';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  Copyright,
  FollowUs,
  YouhodlerInfo,
} from '@youtoken/ui.surface-legal-info';
import {Link} from '@youtoken/ui.elements';
import {RegistrationLink} from './RegistrationLink';
import {CommonInfo} from './CommonInfo';
import {LegalInfoLink} from './LegalInfoLink';
import {CnvLink} from './CnvLink';
import {GLOBAL} from '@youtoken/ui.service-global';
import {
  AppStoreBlack,
  AppStoreWhite,
  GooglePlayBlack,
  GooglePlayWhite,
} from './assets';

export type FooterContentProps = {};

export const FooterContent: React.FC<FooterContentProps> = cell(() => {
  const themeType = useActiveThemeType();
  const {isAuthorized} = GLOBAL;
  const {residenceOrCountry} = AuthMeResource.use({});
  const {t} = useTranslation();
  const isDesktop = useIsDesktop();

  const showCommonInfo = !['ITA', 'CHE'].includes(residenceOrCountry);
  const showYouhodlerITAInfoForAll = residenceOrCountry !== 'ITA';
  const showRegistrationLink = residenceOrCountry === 'ITA';

  return (
    <Box
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width={{default: '100%', desktop: 960}}
      my={0}
      mx="auto"
      pt={{default: 30, desktop: 32}}
      pb={{default: 32, desktop: 36}}
    >
      <Box
        flexDirection={{default: 'column', desktop: 'row'}}
        width="100%"
        justifyContent="space-between"
      >
        {isDesktop && (
          <Box flexDirection="column" width={708} mt={0}>
            {showCommonInfo && <CommonInfo />}

            <Box
              flexDirection="row"
              width="100%"
              justifyContent="space-between"
            >
              <Box width={342}>
                <YouhodlerInfo country={residenceOrCountry} />
                {showRegistrationLink && <RegistrationLink />}

                <CnvLink />

                <Text variant="$body-02" color="$text-02" mt={16}>
                  {t('surface.legal.naumard_info')}
                </Text>

                <LegalInfoLink />
              </Box>

              <Box width={342}>
                {showYouhodlerITAInfoForAll && (
                  <>
                    <YouhodlerInfo country={LegalEntity.ITA} />
                    <RegistrationLink />
                  </>
                )}
              </Box>
            </Box>
          </Box>
        )}

        {!isDesktop && (
          <Box flexDirection="column" mt={0} mb={15}>
            {showCommonInfo && <CommonInfo />}

            <Box>
              <YouhodlerInfo country={residenceOrCountry} />
              {showYouhodlerITAInfoForAll && (
                <YouhodlerInfo country="ITA" mt={24} />
              )}
              {(showRegistrationLink || showYouhodlerITAInfoForAll) && (
                <RegistrationLink />
              )}

              <CnvLink />
            </Box>

            <Text variant="$body-02" color="$text-02" mt={16}>
              {t('surface.legal.naumard_info')}
            </Text>

            <LegalInfoLink />
          </Box>
        )}

        <Box
          flexDirection="column"
          width={{default: '100%', desktop: 220}}
          ml={{default: 0, desktop: 20}}
          pt={{default: 15, desktop: 0}}
        >
          <Text
            textTransform="uppercase"
            mb={15}
            variant="$body-02"
            fontWeight="bold"
          >
            {t('surface.more.legal.subtitle.apps')}
          </Text>
          <Box flexDirection="row" mb={20} width={{default: 278, desktop: 230}}>
            <Link
              url="https://apps.apple.com/app/youhodler-bitcoin-wallet/id1469351696"
              mr={10}
            >
              <Box
                width={{default: 134, desktop: 105}}
                height={{default: 40, desktop: 31}}
              >
                {themeType === 'dark' ? (
                  <AppStoreWhite width="100%" height="100%" />
                ) : (
                  <AppStoreBlack width="100%" height="100%" />
                )}
              </Box>
            </Link>
            <Link url="https://play.google.com/store/apps/details?id=com.youhodler.youhodler&hl=en">
              <Box
                width={{default: 134, desktop: 105}}
                height={{default: 40, desktop: 31}}
              >
                {themeType === 'dark' ? (
                  <GooglePlayWhite width="100%" height="100%" />
                ) : (
                  <GooglePlayBlack width="100%" height="100%" />
                )}
              </Box>
            </Link>
          </Box>

          <FollowUs mb={32} />

          {isAuthorized && (
            <>
              <Text
                textTransform="uppercase"
                mb={15}
                variant="$body-02"
                fontWeight="bold"
              >
                {t('surface.more.legal.subtitle.email')}
              </Text>
              <Box>
                <Link
                  color="$text-01"
                  url="mailto:support@youhodler.com"
                  mb={{default: 10, desktop: 15}}
                >
                  <Text variant="$body-02" color="$text-02" mb={4}>
                    support@youhodler.com
                  </Text>
                </Link>
              </Box>
            </>
          )}
        </Box>
      </Box>

      <Box
        flexDirection={{default: 'column', desktop: 'row'}}
        width="100%"
        justifyContent="space-between"
        mt={{default: 0, desktop: 32}}
      >
        <Box my={{default: 25, desktop: 0}}>
          <Copyright />
        </Box>
        <Box width={220} flexDirection="row">
          <Link url="https://www.visa.com/" mr={10}>
            <IconPaymentMethod
              name="visa"
              size={20}
              // https://spponeimages.azureedge.net/prod/f41265a8-46fd-46ce-8941-843435f7b0a4Visa%20Digital%20Brand%20Requirements%20-%20March%202022.pdf?v=0
              // @ts-ignore: special case for visa icon
              color={themeType === 'dark' ? '#FFFFFF' : '#1434CB'}
            />
          </Link>
          <Link url="https://www.mastercard.com/" mr={10}>
            <IconPaymentMethod name="mastercard" size={20} />
          </Link>
          <Link url="https://www.mastercard.com/" mr={10}>
            <IconPaymentMethod name="maestro" size={20} />
          </Link>
        </Box>
      </Box>
    </Box>
  );
});
