import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const MinerBlockIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg width={size} height={size} fill="none" {...props}>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.897 2.62l-3.6 2.024A2.25 2.25 0 003.15 6.605v4.79a2.25 2.25 0 001.147 1.96l3.6 2.026a2.25 2.25 0 002.206 0l3.6-2.025a2.25 2.25 0 001.147-1.961v-4.79a2.25 2.25 0 00-1.147-1.96l-3.6-2.026a2.25 2.25 0 00-2.206 0zm-4.262.848l3.6-2.025a3.6 3.6 0 013.53 0l3.6 2.025A3.6 3.6 0 0116.2 6.605v4.79a3.6 3.6 0 01-1.835 3.137l-3.6 2.025a3.6 3.6 0 01-3.53 0l-3.6-2.025A3.6 3.6 0 011.8 11.395v-4.79c0-1.3.701-2.5 1.835-3.137z"
          fill={color}
        />
      </Svg>
    );
  }
);
