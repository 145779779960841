import * as o from 'date-fns';
import {wrap} from './wrap';

export * from 'date-fns';
export const format = wrap(o.format);
export const formatDistance = wrap(o.formatDistance);
export const formatDistanceStrict = wrap(o.formatDistanceStrict);
export const formatDistanceToNow = wrap(o.formatDistanceToNow);
export const formatDistanceToNowStrict = wrap(o.formatDistanceToNowStrict);
export const formatRelative = wrap(o.formatRelative);
export const formatDuration = wrap(o.formatDuration);
export const intervalToDuration = wrap(o.intervalToDuration);
