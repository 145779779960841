import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {
  type TPerkItem,
  type OnboardingStep,
} from '@youtoken/ui.resource-onboarding-step-perks';
import {
  CoindropResultBlock,
  CongratsTitleWithIcon,
  IncentivesResultBlock,
  MinerResultBlock,
  SavingsResultBlock,
} from './components';

type OnboardingStepPerksCongratsSurfaceProps = {
  items: TPerkItem[];
  variant: OnboardingStep;
  onGoToPortfolioPress?: (ticker?: string) => void;
  onGoToMinerPress?: () => void;
};

export const OnboardingStepPerksCongratsSurface: React.FC<
  OnboardingStepPerksCongratsSurfaceProps & BoxProps
> = cell(
  ({items, variant, onGoToPortfolioPress, onGoToMinerPress, ...boxProps}) => {
    const nextLevel = {
      verification: undefined, // nextLevel for verification have no meaning now
      deposit: 2,
      trade: 3,
    }[variant];

    return (
      <Box {...boxProps}>
        <Box gap={20}>
          <CongratsTitleWithIcon />

          {items.map(({type, params}) => {
            switch (type) {
              case 'COINDROP':
                return (
                  onGoToPortfolioPress && (
                    <CoindropResultBlock
                      coins={params.coins}
                      onButtonPress={onGoToPortfolioPress}
                    />
                  )
                );

              case 'SPARKS':
                return (
                  onGoToMinerPress && (
                    <MinerResultBlock
                      sparks={params.amount}
                      onButtonPress={onGoToMinerPress}
                    />
                  )
                );

              case 'INCENTIVES':
                return params.amount ? (
                  <IncentivesResultBlock amount={params.amount} />
                ) : null;

              case 'SAVINGS':
                return nextLevel ? (
                  <SavingsResultBlock
                    level={nextLevel}
                    maxApr={params.maxApr}
                    tickers={params.tickers}
                  />
                ) : null;

              default:
                return null;
            }
          })}
        </Box>
      </Box>
    );
  }
);
