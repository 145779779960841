import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Modal} from '@youtoken/ui.modal';
import {type OnboardingStep} from '@youtoken/ui.resource-onboarding-step-perks';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Separator} from '@youtoken/ui.elements';
import {OnboardingStepPerksSurface} from './OnboardingStepPerksSurface';

type OnboardingStepPerksModalProps = {
  steps: OnboardingStep[];
  footerButtonLabel?: string;
  onFooterButtonPress?: () => void;
  isOpen: boolean;
  onClose: () => void;
};

export const OnboardingStepPerksModal: React.FC<OnboardingStepPerksModalProps> =
  observer(
    ({steps, footerButtonLabel, onFooterButtonPress, isOpen, onClose}) => {
      const {t} = useTranslation();

      const isNative = Platform.OS !== 'web';

      return (
        <Modal
          title={t('surface.onboarding_widget.regular_content.title')}
          isOpen={isOpen}
          onClose={onClose}
          shouldShowHeaderSeparator={false}
          shouldBeScrollable
          testID="FIRST_ACTION_PERKS_MODAL"
        >
          {isNative && <Separator />}
          <OnboardingStepPerksSurface
            steps={steps}
            footerButtonLabel={footerButtonLabel}
            onFooterButtonPress={onFooterButtonPress}
            p={24}
            pt={isNative ? 12 : 0}
          />
        </Modal>
      );
    }
  );
