import {computed, observable} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {messages} from '@youtoken/ui.validation-messages';
import {
  countriesListSignUp,
  getCountriesListLocalized,
} from '@youtoken/ui.countries-utils';
import {__GLOBAL_RECAPTCHA__} from '@youtoken/ui.two-factor-auth-and-recaptcha';
import {i18n} from '@youtoken/ui.service-i18n';
import {getAnalyticsData} from '@youtoken/ui.utils';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {type SignUpFormArgs, type SignUpFormResources} from './types';
import {AxiosError} from '@youtoken/ui.errors';

const emailRegExp = new RegExp(/^[aA-zZ0-9.@!#$%&'*+-/=?^_`{|}~]+$/);
const passwordRegExp = new RegExp(/^(?=.*[a-z-A-Z])(?=.*[0-9]).{8,}/);

export class Form {
  @observable
  public args: SignUpFormArgs;

  @observable
  public resources: SignUpFormResources;

  @observable
  public instance: MobxReactForm;

  public constructor(args: SignUpFormArgs, resources: SignUpFormResources) {
    this.args = args;
    this.resources = resources;

    const fields = {
      country: {
        name: 'country',
        label: 'Country',
      },
      email: {
        name: 'email',
        label: 'Email',
      },
      password: {
        name: 'password',
        label: 'Password',
      },
      showReferralCodeField: {
        name: 'showReferralCodeField',
        label: 'Show Referral Code',
        value: Boolean(this.args.referralCode),
      },
      referralCode: {
        name: 'referralCode',
        label: 'Referral Code',
        value: this.args.referralCode,
      },
      agree: {
        name: 'agree',
        label: 'Agreements',
        value: false,
      },
    };

    const hooks = {
      onSuccess: () => {
        const {requestToken} = __GLOBAL_RECAPTCHA__;

        return Promise.all([requestToken('sign_up'), getAnalyticsData()]).then(
          ([token, analytics]) => {
            return this.resources.authMe
              .signUp({
                residence: this.countryValue,
                receiveUpdates: true,
                inviteeCode: this.showReferralCodeField
                  ? this.referralCodeValue
                  : undefined,
                email: this.emailValue,
                password: this.passwordValue,
                agree: this.agreeValue,
                language: i18n.language,
                token,
                analytics,
              })
              .catch((error: AxiosError) => {
                handleFormSubmitError(
                  this.instance,
                  error,
                  {
                    inviteeCode: 'referralCode',
                  },
                  undefined,
                  false
                );
              });
          }
        );
      },
    };

    const plugins = {
      yup: yupValidator({
        package: yupPackage,
        schema: (yup: typeof yupPackage) =>
          yup.lazy(() => {
            return yup.object().shape({
              email: yup
                .string()
                .max(128)
                .required(messages.REQUIRED)
                .matches(emailRegExp, messages.SIGNUP_EMAIL_REGEXP)
                .email(),
              password: yup
                .string()
                .max(128)
                .required(messages.REQUIRED)
                .test(
                  'passwordRegExp',
                  messages.SIGNUP_PASSWORD_REGEXP,
                  value => {
                    if (!value) {
                      return false;
                    }

                    return passwordRegExp.test(value);
                  }
                ),
              country:
                this.countryValue === null
                  ? yup.string().nullable()
                  : yup.string().required(messages.REQUIRED),
              showReferralCodeField: yup.boolean(),
              referralCode: this.showReferralCodeField
                ? yup.string().required()
                : yup.string(),
              agree: yup
                .boolean()
                .oneOf([true], messages.AGREE_TO_PRIVACY_NOTICE),
            });
          }),
      }),
    };

    const options = {
      validateOnBlur: false,
      validateOnChange: false,
      validateOnChangeAfterSubmit: true,
      showErrorsOnReset: false,
    };

    this.instance = new MobxReactForm({fields}, {plugins, hooks, options});
  }

  //#region country
  @computed
  public get countryItems() {
    const list = getCountriesListLocalized(countriesListSignUp);
    return list
      .map(country => ({
        value: country.code,
        label: country.name,
        key: country.code,
      }))
      .concat([
        {
          key: 'other',
          label: i18n.t('surface.sign_up.country.other'),
          // @ts-ignore
          value: null,
        },
      ]);
  }

  @computed
  public get countryField() {
    return this.instance?.$('country'); // might not be initialized when called
  }

  @computed
  public get countryValue() {
    return this.countryField?.value;
  }

  @computed
  public get onCountryChange() {
    return this.countryField.get('onChange');
  }

  @computed
  public get hasCountryError() {
    return Boolean(this.countryField.error);
  }

  @computed
  public get countryError() {
    return this.countryField.error;
  }
  //#endregion country

  //#region email
  @computed
  public get emailField() {
    return this.instance.$('email');
  }

  @computed
  public get emailValue() {
    return this.emailField.value;
  }

  @computed
  public get hasEmailError() {
    return Boolean(this.emailField.error);
  }

  @computed
  public get emailError() {
    return this.emailField.error;
  }

  @computed
  public get onEmailChange() {
    return this.emailField.get('onChange');
  }
  //#endregion email

  //#region password
  @computed
  public get passwordField() {
    return this.instance.$('password');
  }

  @computed
  public get passwordValue() {
    return this.passwordField.value;
  }

  @computed
  public get hasPasswordError() {
    return Boolean(this.passwordField.error);
  }

  @computed
  public get passwordError() {
    return this.passwordField.error;
  }

  @computed
  public get onPasswordChange() {
    return this.passwordField.get('onChange');
  }
  //#endregion password

  //#region referral
  @computed
  public get showReferralCodeField() {
    return this.instance?.$('showReferralCodeField').value;
  }

  @computed
  public get onShowReferralCodeFieldChange() {
    return this.instance.$('showReferralCodeField').get('onChange');
  }

  @computed
  public get referralCodeField() {
    return this.instance.$('referralCode');
  }

  @computed
  public get referralCodeValue() {
    return this.referralCodeField.value;
  }

  @computed
  public get referralCodeError() {
    return this.referralCodeField.error;
  }

  @computed
  public get hasReferralCodeError() {
    return Boolean(this.referralCodeError);
  }

  @computed
  public get onReferralCodeChange() {
    return this.referralCodeField.get('onChange');
  }
  //#endregion referral

  //#region agree
  @computed
  public get agreeField() {
    return this.instance.$('agree');
  }

  @computed
  public get agreeValue() {
    return this.agreeField.value;
  }

  @computed
  public get agreeError() {
    return this.agreeField.error;
  }

  @computed
  public get hasAgreeError() {
    return Boolean(this.agreeError);
  }

  @computed
  public get onAgreeChange() {
    return this.agreeField.get('onChange');
  }
  //#endregion agree
}
