import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {
  type OnboardingStep,
  OnboardingStepPerksResource,
} from '@youtoken/ui.resource-onboarding-step-perks';
import {PerksSection} from './components';

type OnboardingStepPerksSurfaceProps = {
  steps: OnboardingStep[];
  footerButtonLabel?: string;
  onFooterButtonPress?: () => void;
};

export const OnboardingStepPerksSurface: React.FC<
  OnboardingStepPerksSurfaceProps & BoxProps
> = observer(({steps, footerButtonLabel, onFooterButtonPress, ...boxProps}) => {
  const {
    data: {verificationPass, firstDeposit, firstTrade},
  } = OnboardingStepPerksResource.use({steps});

  return (
    <Box gap={24} {...boxProps}>
      {verificationPass && (
        <PerksSection variant="verification" items={verificationPass} />
      )}
      {firstDeposit && <PerksSection variant="deposit" items={firstDeposit} />}
      {firstTrade && <PerksSection variant="trade" items={firstTrade} />}

      {footerButtonLabel && onFooterButtonPress && (
        <Button
          type="primary"
          size="large"
          onPress={onFooterButtonPress}
          testID="FIRST_ACTION_PERKS_FOOTER_BUTTON"
        >
          {footerButtonLabel}
        </Button>
      )}
    </Box>
  );
});
