import * as React from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {cell} from '@youtoken/ui.cell';
import {SwitchWithNotFound} from './SwitchWithNotFound';
import {authRequired} from './authRequired';
import {
  InviteFriendsPage,
  MyFriendsPage,
  FriendRewardsPage,
  NotFoundPage,
} from '../pages';

const InviteFriendsPageWrapped = authRequired(InviteFriendsPage);
const MyFriendsPageWrapped = authRequired(MyFriendsPage);
const FriendRewardsPageWrapped = authRequired(FriendRewardsPage);

const ReferralRoutes: React.FC<RouteComponentProps> = cell(
  ({match: {path}}) => {
    const {newReferralEnabled} = AuthMeResource.use({});

    if (!newReferralEnabled) {
      return <NotFoundPage />;
    }

    return (
      <SwitchWithNotFound>
        <Route
          exact
          path={`${path}/invite-friends`}
          component={InviteFriendsPageWrapped}
        />
        <Route
          exact
          path={`${path}/my-friends`}
          component={MyFriendsPageWrapped}
        />
        <Route
          exact
          path={`${path}/friend-rewards`}
          component={FriendRewardsPageWrapped}
        />
      </SwitchWithNotFound>
    );
  }
);

export const ReferralRoutesWrapped = authRequired(ReferralRoutes);
