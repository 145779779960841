import {observable, computed, action} from 'mobx';
import EventEmitter from 'eventemitter3';
import {
  AppEnv,
  WebAppEnv,
  getBackendUrl,
  getSocketUrl,
  getDisplayUrl,
  getWebAppUrl,
} from '@youtoken/ui.env-utils';
import {SIMPLE_STORAGE} from '@youtoken/ui.service-storage';
import {__BUILD_ENV__, getEnv, setEnv} from './utils';
import {getWebAppEnv} from './utils/getWebEnv';
import {reloadApp} from './utils/reloadApp';
import {
  getOrRegenerateSessionId,
  regenerateSessionId,
} from './utils/getSessionId';
import {getSiftConfig} from './utils/getSiftConfig';

declare global {
  interface __GLOBAL__ {
    __ENV__?: {[key: string]: any};
  }
}

export class EnvironmentService {
  @observable isInitialized = false;

  events = new EventEmitter<'set' | 'unauthorizedError'>();

  initialize() {
    this.isInitialized = true;

    this.APP_ENV = getEnv();
    this.WEB_APP_ENV = getWebAppEnv();
    this.BUILD_ENV = __BUILD_ENV__;

    this.DEV = __BUILD_ENV__ === 'production' ? false : true;

    this.getOrRegenerateSessionId();

    this, this.events.emit('set', this.APP_ENV);
  }

  @action setAppEnv(env: AppEnv) {
    this.APP_ENV = env;
    setEnv(env);
    this.events.emit('set', env);
  }

  @action setSkipRecaptcha(value: boolean) {
    this.SKIP_RECAPTCHA = value;
    SIMPLE_STORAGE.set('SKIP_RECAPTCHA', value.toString());
  }

  @observable WEB_APP_ENV!: WebAppEnv;

  @computed get APPLE_PAY_MERCHANT_ID() {
    if (this.BUILD_ENV === 'production') {
      return 'merchant.youhodler.youhodler';
    }

    return 'merchant.youhodler.youhodler.dev';
  }

  @observable DEV: boolean = false;

  @observable APP_ENV!: AppEnv;

  @observable BUILD_ENV!: 'production' | 'development';

  @computed get DISPLAY_URL() {
    return getDisplayUrl(this.APP_ENV);
  }

  @computed get WEB_APP_URL() {
    return getWebAppUrl(this.APP_ENV);
  }

  @computed get BACKEND_URL() {
    return getBackendUrl(this.APP_ENV);
  }

  @computed get SOCKET_URL() {
    return getSocketUrl(this.APP_ENV);
  }

  @computed get SIFT_CONFIG() {
    return getSiftConfig(this.DEV);
  }

  @observable SESSION_ID?: string;

  @observable RAMP_SESSION_ID?: string;

  @action getOrRegenerateSessionId() {
    this.SESSION_ID = getOrRegenerateSessionId();
  }

  @action regenerateSessionId() {
    this.SESSION_ID = regenerateSessionId();
  }

  @observable SKIP_RECAPTCHA: boolean =
    SIMPLE_STORAGE.get('SKIP_RECAPTCHA') === 'true';

  @observable IN_MAINTENANCE: boolean = false;

  @observable APPSFLYER_ID: string | null = null;

  @observable ADVERTISING_ID: string | null = null;

  @observable DEVICE_TYPE: 'ios' | 'android' | 'web' | 'unknown' = 'unknown';

  unauthorizedError() {
    this.events.emit('unauthorizedError');
  }

  enableMaintenanceMode() {
    this.IN_MAINTENANCE = true;
  }

  disableMaintenanceMode() {
    this.IN_MAINTENANCE = false;
  }

  reloadApp() {
    reloadApp();
  }
}
