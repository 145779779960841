import * as React from 'react';
import {observer} from 'mobx-react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps} from '@youtoken/ui.primitives';
import {MenuItemBase} from './MenuItemBase';

export const PaymentMethods: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {checkProductAvailability} = AuthMeResource.use({});

  const handlePress = React.useCallback(() => {
    if (!checkProductAvailability('depositFiatCheckoutBankCardFrame')) {
      return;
    }

    SHARED_ROUTER_SERVICE.navigate('PaymentMethods');
  }, [checkProductAvailability]);

  return (
    <MenuItemBase
      icon="card"
      title={t('surface.wallets.payment_methods')}
      onPress={handlePress}
      {...boxProps}
    />
  );
});
