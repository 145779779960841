import {orderBy, uniqBy, flatMap, countBy, map} from 'lodash';
import {action, computed, observable} from 'mobx';
import {computedFn} from 'mobx-utils';
import {HODLPublicInstrumentResource} from '@youtoken/ui.resource-hodl-public-instruments';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {
  HodlsTariffsResource,
  DEFAULT_SORTING_ITEMS,
} from '@youtoken/ui.resource-hodl-tariffs';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {GLOBAL} from '@youtoken/ui.service-global';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  LocaliseResourceNamespace,
  LocaliseResource,
} from '@youtoken/ui.resource-lokalise';
import {cardsData} from '../sections/SectorsSection/data';

export type SectorsData = Array<{
  name: string;
  count: number;
}>;

// NOTE: It's temporary solution. The order of the sectors will be fetched from the backend soon.
const SECTOR_ORDER = [
  'memes',
  'stable',
  'nft',
  'metaverse',
  'defi',
  'solana',
  'ai',
  'bnb',
  'avalanche',
  'polkadot',
];

const DEFAULT_SORTING_ITEM_VALUE = DEFAULT_SORTING_ITEMS[3]!.value;

export class HODLsInstrumentsSectorsFeature extends createFeature({
  getKey: args => `feature:hodls_popular_categories:${JSON.stringify(args)}`,
  getResources: () => {
    if (GLOBAL.isAuthorized) {
      return {
        authme: getResourceDescriptor(AuthMeResource, {}),
        localise: getResourceDescriptor(LocaliseResource, {
          namespace: LocaliseResourceNamespace.HODL,
        }),
        tariffs: getResourceDescriptor(HodlsTariffsResource, {}),
        rates: getResourceDescriptor(RatesResource, {product: 'hodl'}),
      };
    }

    return {
      localise: getResourceDescriptor(LocaliseResource, {
        namespace: LocaliseResourceNamespace.HODL,
        language: i18n.language,
      }),
      tariffs: getResourceDescriptor(HODLPublicInstrumentResource, {}),
      rates: getResourceDescriptor(RatesResource, {product: 'hodl'}),
    };
  },
}) {
  @observable sectorsState = {};

  @observable chosenSector: string | null = null;

  @observable excludeSectors = ['index'];

  @observable sortingItems = DEFAULT_SORTING_ITEMS;

  @observable activeSortingItemValue = DEFAULT_SORTING_ITEM_VALUE;

  @computed.struct
  get allInstruments() {
    return uniqBy(this.resources.tariffs.data, 'id');
  }

  @computed.struct get hodlsSectorsData() {
    // Create a new array containing all the sectors from each tariff
    const sectors = flatMap(this.allInstruments, tariff => {
      return tariff.sectors || [];
    });

    // Filter sectors marketing doesn't want to show for now
    const sectorsFiltered = sectors.filter(
      sector => !this.excludeSectors.includes(sector) && cardsData[sector]
    );

    // Create an object with sector names as keys and their counts as values
    const sectorCounts = countBy(sectorsFiltered);

    // Transform the sectorCounts object into an array of objects with name and count properties
    // example: {stable: 2, bnb: 1} => [{name: 'stable', count: 2}, {name: 'bnb', count: 1}]
    const sectorsData = map(sectorCounts, (count, name) => ({name, count}));

    // Sort the array by the order of the sectors in the SECTOR_ORDER array which was established by designer
    return sectorsData.sort(
      (a, b) => SECTOR_ORDER.indexOf(a.name) - SECTOR_ORDER.indexOf(b.name)
    );
  }

  @computed
  get shouldShowHodlsSectors() {
    return this.hodlsSectorsData.length > 0;
  }

  @computed
  public get starred() {
    return (
      this.resources.authme?.starred.filter((i: string) => i.includes('_')) ??
      []
    );
  }

  @computed.struct
  get sectorInstruments() {
    return this.allInstruments.filter(el =>
      el.sectors?.includes(this.chosenSector!)
    );
  }

  @computed get activeSortingItem() {
    return this.sortingItems.find(
      item => item.value === this.activeSortingItemValue
    );
  }

  @computed get activeSortingItemType() {
    return this.activeSortingItem?.type;
  }

  @computed get activeSortingItemDirection() {
    return this.activeSortingItem?.direction;
  }

  @computed
  get sectorInstrumentsSorted() {
    return orderBy(
      this.sectorInstruments,
      item => {
        if (this.activeSortingItemValue === 'name') {
          return item.baseTicker;
        }

        if (this.activeSortingItemValue === 'price') {
          return item.rate;
        }

        if (this.activeSortingItemValue === 'dif24') {
          return item.diff24Percent;
        }
      },
      this.activeSortingItemDirection
    );
  }

  @computed
  get sectorInfo() {
    return this.getSectorDataByName(this.chosenSector!);
  }

  @computed
  get shouldShowSectorInfoTitle() {
    return Boolean(this.sectorInfo.title || this.sectorInfo.description);
  }

  @computed
  get shouldShowInfoSectorAttraction() {
    return Boolean(
      this.sectorInfo.attractionsTitle && this.sectorInfo.attractions
    );
  }

  @computed
  get shouldShowInfoSectorTakeaways() {
    return Boolean(this.sectorInfo.takeawaysTitle && this.sectorInfo.takeaways);
  }

  @action setActiveSortingItemValue = (value: string) => {
    this.activeSortingItemValue = value;
  };

  @action removeChosenSector = () => {
    this.chosenSector = null;
    this.sortingItems = DEFAULT_SORTING_ITEMS;
    this.activeSortingItemValue = DEFAULT_SORTING_ITEM_VALUE;
  };

  @action setChosenSector = (sectorName: string) => {
    this.chosenSector = sectorName;
  };

  getSectorItemCount = computedFn((sectorName: string) => {
    return this.hodlsSectorsData.find(el => el.name === sectorName)?.count ?? 0;
  });

  getSectorDataByName = computedFn((name: string) => {
    const {translate} = this.resources.localise;

    return {
      attractionsTitle: translate('hodls.sectors.title_attractions'),
      takeawaysTitle: translate('hodls.sectors.title_takeaways'),
      attractions: this.translateBySector(name, 'attractions'),
      description: this.translateBySector(name, 'description'),
      takeaways: this.translateBySector(name, 'takeaways'),
      title: this.translateBySector(name, 'title'),
    };
  });

  translateBySector = computedFn((sector: string, key: string) => {
    return this.resources.localise.translate(`hodls.sectors.${sector}.${key}`);
  });
}
