import {computed, action} from 'mobx';
import {computedFn} from 'mobx-utils';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {invariant} from '@youtoken/ui.utils';
import {SENTRY} from '@youtoken/ui.sentry';
import {
  ExchangeType,
  FeesLevel,
  LEDGER_LIVE,
  type Account,
  type Currency,
} from '@youtoken/ui.service-ledger-live';
import {createResource} from '@youtoken/ui.data-storage';
import {WebAppEnv} from '@youtoken/ui.env-utils';

export class LedgerResource extends createResource({
  skipRefreshOnVisible: false,
  getKey: () => 'ledger',
  getData: () => {
    invariant(
      ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ledger-app'],
      `You are trying to use LedgerResource from ENVIRONMENT.WEB_APP_ENV="${ENVIRONMENT.WEB_APP_ENV}", which is not supported`
    );

    LEDGER_LIVE.connect();
    return Promise.all([
      LEDGER_LIVE.listCurrencies({includeTokens: true}),
      LEDGER_LIVE.listAccounts({includeTokens: true}),
    ]).then(([listCurrencies, listAccounts]) => {
      return {
        listCurrencies,
        listAccounts,
      };
    });
  },
}) {
  @computed get listCurrencies() {
    return this.data.listCurrencies;
  }

  @computed get listAccounts() {
    return this.data.listAccounts;
  }

  getCurrencyById = computedFn((id: Currency['id']) => {
    return this.listCurrencies.find(c => {
      return c.id === id;
    });
  });

  getCurrencyByTicker = computedFn((ticker: string) => {
    const currency = ledgerCurrency[ticker];

    return this.listCurrencies.find(c => {
      return c.id === currency;
    });
  });

  getAccountsByCurrency = computedFn((ticker: string, version?: string) => {
    const currency = ledgerCurrency[ticker];

    const accountsCondition = ledgerAccountsCondition[ticker];

    return this.listAccounts.filter(a => {
      return a.currency === currency && accountsCondition?.(a, version);
    });
  });

  getAccountsByCurrencyWithBalance = computedFn(
    (ticker: string, version?: string) => {
      return this.getAccountsByCurrency(ticker, version).filter(a =>
        a.balance.gt(0)
      );
    }
  );

  @action deposit = async (
    fromAccount: Account,
    amount: number,
    feesStrategy: FeesLevel,
    payinUser: string,
    payinAddressName: string,
    payinAddress: string
  ) => {
    try {
      const currency = this.getCurrencyById(fromAccount.currency);

      invariant(currency, 'currency not found');

      const exchangeId = await LEDGER_LIVE.startExchange({
        exchangeType: ExchangeType.FUND,
      });

      const exchangeSigned = await LEDGER_LIVE.signExchangeFund(exchangeId, {
        fromAccountId: fromAccount.id,
        currency,
        amount,
        payinUser,
        payinAddressName,
        payinAddress,
        feesStrategy,
      });

      return await LEDGER_LIVE.completeExchange(exchangeSigned);
    } catch (error) {
      SENTRY.capture(error as Error, {
        source: 'LedgerResource.deposit',
      });

      throw error;
    }
  };
}

const ledgerCurrency: Record<string, string> = {
  btc: 'bitcoin',
  eth: 'ethereum',
  xrp: 'ripple',
  xlm: 'stellar',
  usdt: 'ethereum/erc20/usd_tether__erc20_',
};

const ledgerAccountsCondition: Record<
  string,
  (account: Account, version?: string) => boolean
> = {
  btc: (account, version) => {
    return version === 'origin' && account.id.endsWith(':native_segwit');
  },
  eth: (account, version) => {
    return version === 'erc20';
  },
  xrp: (account, version) => {
    return version === 'xrp';
  },
  xlm: (account, version) => {
    return version === 'xlm';
  },
  usdt: (account, version) => {
    return version === 'erc20';
  },
};
