import * as React from 'react';
import {observer} from 'mobx-react';
import {jwtDecode} from 'jwt-decode';
import 'core-js/stable/atob';
import {TRANSPORT} from '@youtoken/ui.transport';
import {SENTRY} from '@youtoken/ui.sentry';
import {GLOBAL} from '@youtoken/ui.service-global';
import {__GLOBAL_RECAPTCHA__} from '@youtoken/ui.two-factor-auth-and-recaptcha';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Box} from '@youtoken/ui.primitives';
import {ActivityIndicator} from '@youtoken/ui.elements';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {AxiosError} from '@youtoken/ui.errors';
import {getAnalyticsData} from '@youtoken/ui.utils';
import {invariant} from '@youtoken/ui.utils';
import {SIMPLE_STORAGE} from '@youtoken/ui.service-storage';
import {
  countriesListSignUp,
  getCountriesListLocalized,
} from '@youtoken/ui.countries-utils';
import {Modals} from '@web-app/src/components/layout/Shell/AppShell/Modals';

const [accessCode, idToken] = window.location.hash
  .slice(1)
  .split('&')
  .map(item => item.split('=')[1]);

const getDefaultCountry = async () => {
  return TRANSPORT.API.get(`/v1/auth/country`, {
    timeout: 10000,
  })
    .then(res => {
      const countryByIp = res.data?.country_code_iso3;

      if (!countryByIp) {
        return '';
      }

      const item = getCountriesListLocalized(countriesListSignUp).find(
        item => item.code === countryByIp
      );

      if (!item) {
        return '';
      }

      return item.code;
    })
    .catch(() => {
      return '';
    });
};

export const AppleAuthRedirectPage: React.FC = observer(() => {
  const {signInConfirmSuccess, handleSignInError} = AuthMeResource.use({});

  let email = '';

  invariant(idToken, 'idToken is not defined');

  try {
    email = jwtDecode<{email: string} & any>(idToken).email;
  } catch (error) {
    SENTRY.capture(error as Error, {
      source: 'AppleAuthRedirect',
      extra:
        typeof idToken === 'string' && idToken.length > 0
          ? {}
          : {value: idToken},
    });
  }

  const inviteeCode = SIMPLE_STORAGE.get('inviteeCode');
  const residence = SIMPLE_STORAGE.get('residence');

  React.useEffect(() => {
    window.location.hash = '';
    GLOBAL.removeToken();

    if (!email) {
      SHARED_ROUTER_SERVICE.navigate('SignIn');
      return;
    }

    const {requestToken} = __GLOBAL_RECAPTCHA__;

    Promise.all([requestToken('sign_in'), getAnalyticsData()]).then(
      ([token, analytics]) => {
        return TRANSPORT.API.post('/auth/apple/signin', {
          idToken,
          accessCode,
          token,
          analytics,
        })
          .then(signInConfirmSuccess)
          .catch((error: AxiosError) => {
            if (error?.response?.status === 404) {
              getDefaultCountry().then(defaultCountry => {
                SHARED_ROUTER_SERVICE.navigate('SignUpAdditional', {
                  email,
                  provider: 'apple',
                  idToken,
                  accessCode,
                  residence: residence ?? defaultCountry,
                  inviteeCode,
                });
              });
            } else {
              return handleSignInError(error);
            }
          });
      }
    );
  }, []);

  return (
    <>
      <Box
        width="100vw"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <ActivityIndicator />
      </Box>
      <Modals />
    </>
  );
});
