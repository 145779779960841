import * as React from 'react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {cell} from '@youtoken/ui.cell';
import {type BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {type ActionProps} from '../types';
import {getActionButtonPropsByVariant} from '../utils';
import {WalletActionCellWrap} from './WalletActionCellWrap';
import {WalletActionSuspenseFallback} from './WalletActionSuspenseFallback';
import {WalletActionErrorFallback} from './WalletActionErrorFallback';

export const Convert: React.FC<ActionProps & BoxProps> = cell(
  ({
    tooltip,
    variant,
    item: {ticker, tickerFormatted, marketEnabled},
    ...boxProps
  }) => {
    const handlePressConvert = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('Exchange', {fromTicker: ticker});
    }, [ticker]);

    return (
      <Button
        testID={`${tickerFormatted}_CONVERT_BUTTON`}
        icon="exchange"
        disabled={!marketEnabled}
        onPress={handlePressConvert}
        {...getActionButtonPropsByVariant(variant)}
        {...boxProps}
      />
    );
  },
  {
    CellWrap: WalletActionCellWrap,
    SuspenseFallback: WalletActionSuspenseFallback,
    ErrorFallback: WalletActionErrorFallback,
  }
);
