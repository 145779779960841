import * as React from 'react';
import {Separator} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps, Heading, Text} from '@youtoken/ui.primitives';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';

interface NonWorkingHoursSectionProps {
  instrumentId: string;
}

export const NonWorkingHoursSection: React.FC<
  NonWorkingHoursSectionProps & BoxProps
> = ({instrumentId, ...boxProps}) => {
  const {t} = useTranslation();
  const {getInstrumentById} = HODLsTariffsFeature.use({});
  const instrument = getInstrumentById(instrumentId)!;

  if (!('daysOffFormatted' in instrument) || !instrument.daysOffFormatted) {
    return null;
  }

  return (
    <>
      <Separator />
      <Box pt={20} pb={24} px={24} {...boxProps}>
        <Heading variant="$heading-02" mb={12}>
          {t('surface.hodls.instrument.non_working_hours_title')}
        </Heading>
        <Text variant="$body-01">
          {instrument.daysOffFormatted.map((item, index) => (
            <Box flexDirection="row" key={index}>
              <Text>
                {item.dayFrom} {item.timeFrom} - {item.dayTo} {item.timeTo} GMT
              </Text>
            </Box>
          ))}
        </Text>
      </Box>
    </>
  );
};
