import {computed, observable, action} from 'mobx';
import {deserialize} from 'serializr';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {NewReferralInvitedResponse} from './NewReferralInvitedResponse';
import {DEFAULT_LIMIT, DEFAULT_OFFSET} from '../constants';

export interface NewReferralInvitedResourceArgs {
  limit?: number;
  offset?: number;
}

export class NewReferralInvitedResource extends createStaticResource({
  getKey: ({}: NewReferralInvitedResourceArgs) => `newReferralInvitedResource`,
  getData: ({
    limit = DEFAULT_LIMIT,
    offset = DEFAULT_OFFSET,
  }: NewReferralInvitedResourceArgs) =>
    TRANSPORT.API.get('/v3/referral/invited', {params: {limit, offset}}).then(
      res => deserialize(NewReferralInvitedResponse, res.data)
    ),
}) {
  @computed get invited() {
    return this.data.invited;
  }

  @computed get total() {
    return this.data.total;
  }

  // Pagination

  @computed
  public get pageSize() {
    return this.args.limit || DEFAULT_LIMIT;
  }

  @computed
  public get totalPages() {
    return Math.ceil(this.total / this.pageSize);
  }

  @observable
  currentPage: number = 1;

  @action setPage = (page: number) => {
    const offset = this.pageSize * (page - 1);
    this.currentPage = page;

    if (offset >= this.total) {
      return;
    }

    this.updateArgs({offset});
  };

  // Pagination end

  @action updateArgs = (newArgs: Partial<NewReferralInvitedResourceArgs>) => {
    this.args = {
      ...this.args,
      ...newArgs,
    };
  };
}
