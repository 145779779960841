import React from 'react';
import {Platform} from 'react-native';
import {IBANAccountsResource} from '@youtoken/ui.resource-iban-accounts';
import {cell} from '@youtoken/ui.cell';
import {Tooltip} from '@youtoken/ui.tooltip';
import {BadgeContent} from './components/BadgeContent';

const isTooltipAvailable = Platform.select({default: true, native: false});

interface IBANBadgeProps {
  ticker: string;
  onShowTooltip?: () => void;
  onPress?: () => void;
}

export const IBANBadge: React.FC<IBANBadgeProps> = cell(
  ({ticker, onShowTooltip, onPress}) => {
    const {isBadgeEnabled, getBadgeData} = IBANAccountsResource.use({});

    if (!isBadgeEnabled(ticker)) {
      return null;
    }

    const {variant, tooltipText} = getBadgeData(ticker);

    if (isTooltipAvailable) {
      return (
        <Tooltip
          strictPosition
          position="top"
          content={tooltipText}
          contentProps={{alignItems: 'center'}}
          width={200}
          onShow={onShowTooltip}
        >
          <BadgeContent ticker={ticker} variant={variant} onPress={onPress} />
        </Tooltip>
      );
    }

    return <BadgeContent ticker={ticker} variant={variant} onPress={onPress} />;
  }
);
