import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {KeyboardScrollView, useWizard} from '@youtoken/ui.elements';
import {useForm} from '@youtoken/ui.form-elements';
import {Box} from '@youtoken/ui.primitives';
import type {BuyCryptoFormAuthorizedYouHodlerWizardNavigatorParams} from '..';
import {BuyCryptoFormAuthorizedYouHodlerState} from '../../../../state';
import {CardSelector, HeaderBase} from '../../../../../../components';
import {IncentivesBonuses} from '../../../IncentivesBonuses';
import {DepositMethodRampEnum} from '../../../../types';
import {PayWithField} from '../../../PayWith';
import {getTitle} from '../../utils';
import {
  ConversionPairSourceAuthed,
  ConversionPairTarget,
  FormFooter,
  InfoTableV2,
  TwoFA,
} from '../../..';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const BuyCryptoFormAuthorizedYouHodlerMain: React.FC = observer(() => {
  const {navigate, goBack} = useWizard<
    BuyCryptoFormAuthorizedYouHodlerWizardNavigatorParams,
    'Main'
  >();

  const {
    form: {
      conversionTicker,
      depositMethod,
      cardId,
      cardsList,
      shouldHideFooter,
      setCardId,
    },
  } = useForm<BuyCryptoFormAuthorizedYouHodlerState>();

  const handlePress = React.useCallback(() => {
    navigate('ChooseMethod');
  }, [navigate]);

  const handlePressManage = React.useCallback(() => {
    navigate('PaymentMethods');
  }, [navigate]);

  const handlePressAddCard = React.useCallback(() => {
    navigate('AddCard');
  }, [navigate]);

  return (
    <>
      {showHeader && (
        <HeaderBase onPressBack={goBack}>
          {getTitle(conversionTicker)}
        </HeaderBase>
      )}
      <KeyboardScrollView
        insideTabs={false}
        wrapInSafeArea
        scrollViewProps={{
          contentContainerStyle: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            overflow: 'visible',
          },
        }}
      >
        <Box pt={24} height="100%" justifyContent="space-between">
          <Box px={24} zIndex={10}>
            <Box zIndex={10}>
              <ConversionPairSourceAuthed />
              <ConversionPairTarget mt={24} />
              <IncentivesBonuses mt={16} />
              <PayWithField onPress={handlePress} mt={24} />
              {depositMethod === DepositMethodRampEnum.BANK_CARD_FRAME && (
                <CardSelector
                  cardsList={cardsList}
                  onSelectCard={setCardId}
                  selectedCardId={cardId}
                  onPressAddNewCard={handlePressAddCard}
                  onPressManage={handlePressManage}
                  mt={12}
                  mb={shouldHideFooter ? 24 : 0}
                />
              )}
            </Box>
            {!shouldHideFooter && (
              <Box>
                {depositMethod !== DepositMethodRampEnum.BANK_WIRE && (
                  <InfoTableV2 mt={24} />
                )}
                <TwoFA mt={24} />
              </Box>
            )}
          </Box>
          {!shouldHideFooter && (
            <FormFooter
              onPressSubmit={
                depositMethod === DepositMethodRampEnum.BANK_CARD_FRAME &&
                cardsList.length === 0
                  ? handlePressAddCard
                  : undefined
              }
            />
          )}
        </Box>
      </KeyboardScrollView>
    </>
  );
});
