import * as React from 'react';
import {LoyaltyLevel, LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {Box, useIsMobile} from '@youtoken/ui.primitives';
import {Slider} from '@youtoken/ui.elements';
import {Level} from './Level';
import {observer} from 'mobx-react';
import {
  AccountLevelsHowItWorks,
  AccountLevelsMarketAnalytics,
} from '../../smarts';

const ACCOUNT_LEVELS_LIST_ITEM_OFFSET = 12;

interface AccountLevelsProps {
  initialIndex?: number;
}

export const AccountLevels: React.FC<AccountLevelsProps> = ({initialIndex}) => {
  const isMobile = useIsMobile();

  return (
    <Box width="100%" pt={{default: 16, phone: 24}} bg="$ui-background">
      <AccountLevelsHowItWorks
        mx={{default: 16, phone: 20, desktop: 0}}
        mb={{default: 16, phone: 24}}
      />
      <AccountLevelsMarketAnalytics
        mx={{default: 16, phone: 24, desktop: 0}}
        mb={{default: 16, phone: 24}}
      />
      <Box testID="LEVELS">
        {isMobile ? (
          <AccountLevelsSlider initialIndex={initialIndex} />
        ) : (
          <AccountLevelsList />
        )}
      </Box>
    </Box>
  );
};

export const AccountLevelsSlider: React.FC<AccountLevelsProps> = observer(
  ({initialIndex}) => {
    const {
      data: {levels, currentLevel, nextLevel},
    } = LoyaltyResource.use({});

    const renderItem = React.useCallback(({item}: {item: LoyaltyLevel}) => {
      return <Level id={item.level} />;
    }, []);

    return (
      <Box testID="ACCOUNT_LEVELS_SLIDER" width="100%" height={520}>
        <Slider
          data={levels}
          renderItem={renderItem}
          initialIndex={initialIndex ?? (nextLevel ?? currentLevel) - 1}
          cardWidthMax={360}
        />
      </Box>
    );
  }
);

export const AccountLevelsList: React.FC = observer(() => {
  const {
    data: {levels},
  } = LoyaltyResource.use({});

  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="flex-start"
      flexWrap="wrap"
      px={{default: 24, desktop: 0}}
      m={-ACCOUNT_LEVELS_LIST_ITEM_OFFSET}
    >
      {levels.map(item => {
        return (
          <Box width="50%" p={ACCOUNT_LEVELS_LIST_ITEM_OFFSET} key={item.level}>
            <Level id={item.level} />
          </Box>
        );
      })}
    </Box>
  );
});
