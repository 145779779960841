import {
  BaseTheme,
  createRestyleFunction,
  ResponsiveValue,
} from '@shopify/restyle';

export type BackdropFilterProps<Theme extends BaseTheme> = {
  backdropFilter?: ResponsiveValue<string, Theme>;
};

export const backdropFilter = createRestyleFunction({
  property: 'backdropFilter',
  styleProperty: undefined,
});
