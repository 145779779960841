import * as React from 'react';
import {observer} from 'mobx-react';
import {Heading, TouchableBox} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon} from '@youtoken/ui.icons';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {TitleTooltip} from './TitleTooltip';

export const MinerTitleWeb: React.FC<{}> = observer(() => {
  const {t} = useTranslation();

  const {
    data: {
      clientSettings: {hasSeenMinerTooltipFirstTime},
    },
    setHasSeenMinerTooltipFirstTime,
  } = AuthMeResource.use({});

  const [isTooltipOpen, setTooltipOpen] = React.useState(
    !hasSeenMinerTooltipFirstTime
  );

  const handleToggleTooltip = React.useCallback(() => {
    setTooltipOpen(state => !state);
  }, []);

  const handleCloseTooltip = React.useCallback(() => {
    setTooltipOpen(false);
    setHasSeenMinerTooltipFirstTime();
  }, [setHasSeenMinerTooltipFirstTime]);

  return (
    <>
      <Heading variant="$heading-01-responsive" mb={30}>
        {t('surface.menu.cloud_miner')}
        <TouchableBox
          ml={8}
          mt={-2}
          // @ts-ignore
          style={{verticalAlign: 'middle'}}
          onPress={handleToggleTooltip}
        >
          <Icon
            name="question_outlined"
            color={isTooltipOpen ? '$ui-04' : '$text-01'}
            size={28}
            testID="MINER_TITLE_QUESTION"
          />
        </TouchableBox>
        <TitleTooltip isOpen={isTooltipOpen} handleClose={handleCloseTooltip} />
      </Heading>
    </>
  );
});
