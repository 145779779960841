import * as React from 'react';
import {FlatList} from 'react-native';
import {Box, Text} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {Link} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon, IconSmall} from '@youtoken/ui.icons';
import {ComissionsTableFeature} from './ComissionsTableFeature';

const Cell: React.FC<React.ComponentProps<typeof Box>> = React.memo(props => {
  return (
    <Box
      justifyContent="center"
      flexBasis={0}
      flexGrow={1}
      px={{
        default: 16,
        desktop: 24,
      }}
      py={8}
      mr={1}
      {...props}
    />
  );
});

const Row: React.FC<React.ComponentProps<typeof Box>> = React.memo(props => {
  return <Box flexDirection="row" {...props} />;
});

export const ComissionsTable: React.FC = cell(() => {
  const feature = ComissionsTableFeature.use({});
  const {t} = useTranslation();

  const renderItem = React.useCallback(
    ({item}: {item: (typeof feature.tableData)[0]}) => {
      if (item.type === 'profit-share-row') {
        return (
          <>
            <Row py={20} px={24}>
              <Cell py={0} px={0}>
                <Box flexDirection="row" alignItems="center" mb={8}>
                  <Box mr={4}>
                    <Icon name="question" />
                  </Box>
                  <Text variant="$body-02" weight="medium">
                    {t(
                      'surface.profile.modal_hodl_specification.footer.subtitle.profit_fee'
                    )}
                  </Text>
                </Box>
                <Box mb={8}>
                  <Text variant="$body-02">
                    {t(
                      'surface.profile.modal_hodl_specification.footer.profit_fee.description'
                    )}
                  </Text>
                </Box>
                <Box mb={8}>
                  <Text variant="$body-02" weight="medium">
                    {t(
                      'surface.profile.modal_hodl_specification.footer.profit_fee.formula_long.title'
                    )}
                  </Text>
                  <Text variant="$body-02">
                    {t(
                      'surface.profile.modal_hodl_specification.footer.profit_fee.formula_long.text'
                    )}
                  </Text>
                </Box>
                <Box mb={8}>
                  <Text variant="$body-02" weight="medium">
                    {t(
                      'surface.profile.modal_hodl_specification.footer.profit_fee.formula_short.title'
                    )}
                  </Text>
                  <Text variant="$body-02">
                    {t(
                      'surface.profile.modal_hodl_specification.footer.profit_fee.formula_short.text'
                    )}
                  </Text>
                </Box>
                <Box>
                  <Link
                    variant="$body-02"
                    url={t(
                      'url.profile.modal_hodl_specification.footer.profit_fee.calculation.link'
                    )}
                  >
                    {t(
                      'surface.profile.modal_hodl_specification.footer.profit_fee.calculation.link.name'
                    )}
                  </Link>
                </Box>
              </Cell>
            </Row>
            <Row height={1} bg="$ui-01" />
          </>
        );
      }

      if (item.type === 'rollover-row') {
        return (
          <>
            <Row py={20} px={24}>
              <Cell py={0} px={0}>
                <Box flexDirection="row" alignItems="center" mb={8}>
                  <Box mr={4}>
                    <Icon name="question" />
                  </Box>
                  <Text variant="$body-02" weight="medium">
                    {t(
                      'surface.profile.modal_hodl_specification.footer.subtitle.rollover'
                    )}
                  </Text>
                </Box>
                <Text variant="$body-02">
                  {t(
                    'surface.profile.modal_hodl_specification.footer.rollover.description'
                  )}
                </Text>
              </Cell>
            </Row>
          </>
        );
      }

      if (item.type === 'table-header') {
        return (
          <Row mb={1}>
            <Cell bg={'$interactive-02'} flexGrow={1.5}>
              <Text variant="$body-02">
                {t(
                  'surface.profile.modal_hodl_specification.table.header.instrument'
                )}
              </Text>
            </Cell>
            <Cell bg={'$interactive-02'}>
              <Text variant="$body-02">
                {t(
                  'surface.profile.modal_hodl_specification.table.header.profit_fee'
                )}
              </Text>
            </Cell>
            <Cell bg={'$interactive-02'}>
              <Text variant="$body-02">
                {t(
                  'surface.profile.modal_hodl_specification.table.header.rollover'
                )}
              </Text>
            </Cell>
            <Cell bg={'$interactive-02'} mr={0}>
              <Text variant="$body-02">
                {t(
                  'surface.profile.modal_hodl_specification.table.header.multiplier'
                )}
              </Text>
            </Cell>
          </Row>
        );
      }

      if (item.type !== 'data-row') {
        return null;
      }

      const rowBg =
        item.key === 'header'
          ? '$interactive-02'
          : item.short
          ? '$danger-02'
          : '$success-02';

      return (
        <Row mb={1}>
          <Cell bg={rowBg} flexGrow={1.5}>
            <Box flexDirection="row">
              <Box flexGrow={1}>
                <Text variant="$body-02">{item.instrument}</Text>
              </Box>
              {item.key !== 'header' && (
                <Box alignSelf="center">
                  <IconSmall
                    name={item.short ? 'down' : 'up'}
                    color={item.short ? '$danger-01' : '$success-01'}
                  />
                </Box>
              )}
            </Box>
          </Cell>
          <Cell bg={rowBg}>
            <Text variant="$body-02">{item.profitShareFee}</Text>
          </Cell>
          <Cell bg={rowBg}>
            <Text variant="$body-02">{item.rolloverFee}</Text>
          </Cell>
          <Cell bg={rowBg} mr={0}>
            <Text variant="$body-02">{item.maxMultiplier}</Text>
          </Cell>
        </Row>
      );
    },
    []
  );

  return (
    <FlatList
      stickyHeaderIndices={[0]}
      data={feature.tableData}
      renderItem={renderItem}
    />
  );
});
