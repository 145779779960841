import {getDataLayer} from '../getDataLayer';

declare global {
  interface Window {
    gtag?: Function;
  }
}

const googleTagManagerAnalyticsId = 'G-ZK8VXRYN4W';

type GoogleTagManagerAnalyticsData = {
  session_id: string | null;
  session_number: number | null;
};

export const getGoogleTagManagerAnalyticsParams = (
  timeout = 1000
): Promise<GoogleTagManagerAnalyticsData> => {
  window.gtag =
    window.gtag ??
    function gtag() {
      getDataLayer().push(arguments);
    };

  const _timeout = new Promise(resolve => {
    setTimeout(() => resolve(null), timeout);
  });

  return Promise.all([
    Promise.race([
      _timeout,
      new Promise(resolve => {
        window?.gtag!(
          'get',
          googleTagManagerAnalyticsId,
          'session_id',
          resolve
        );
      }),
    ]),
    Promise.race([
      _timeout,
      new Promise(resolve => {
        window?.gtag!(
          'get',
          googleTagManagerAnalyticsId,
          'session_number',
          resolve
        );
      }),
    ]),
  ]).then(([session_id, session_number]) => {
    return {
      session_id,
      session_number,
    };
  }) as Promise<GoogleTagManagerAnalyticsData>;
};
