import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Illustration} from '@youtoken/ui.elements';

export const RegularContent: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <Box alignItems="center">
      <Illustration name="illustration-14" style={{width: 112, height: 112}} />
    </Box>
  );
});
