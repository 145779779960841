import * as React from 'react';
import {type BoxProps, Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon, BaseIconName} from '@youtoken/ui.icons';

type TouchableWidgetBoxProps = {
  title: string;
  iconName: BaseIconName;
  onPress: () => void;
  children: React.ReactNode;
};

export const TouchableWidgetBox: React.FC<
  TouchableWidgetBoxProps & BoxProps
> = ({title, iconName, onPress, children, ...boxProps}) => {
  return (
    <TouchableBox
      borderWidth={1}
      borderColor="$ui-01"
      borderRadius={12}
      p={16}
      onPress={onPress}
      {...boxProps}
    >
      <Box flexDirection="row" alignItems="center" mb={8} gap={4}>
        <Icon name={iconName} color="$interactive-01" />
        <Text variant="$body-02">{title}</Text>
      </Box>
      {children}
    </TouchableBox>
  );
};
