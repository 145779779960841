import {action, computed, observable} from 'mobx';
//@ts-ignore
import MobxReactForm from 'mobx-react-form';
//@ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {i18n} from '@youtoken/ui.service-i18n';
import {FeesLevel} from '@youtoken/ui.service-ledger-live';
import {
  getTranslatedValidationMessage,
  messages,
} from '@youtoken/ui.validation-messages';

import type {
  CryptoDepositLedgerFormStateArgs,
  CryptoDepositLedgerFormStateResource,
} from './index';
import {toBig} from '@youtoken/ui.formatting-utils';
import {warning} from '@youtoken/ui.utils';
import {normalizeAmountWithPrecision} from '@youtoken/ui.normalizers';
import {convertLedgerBalanceToAmountString} from '../../utils';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';

export class Form {
  @observable
  public args: CryptoDepositLedgerFormStateArgs;

  @observable
  public resources: CryptoDepositLedgerFormStateResource;

  @observable
  public instance: MobxReactForm;

  @computed
  public get ticker() {
    return this.args.ticker;
  }

  @computed
  public get tickerFormatted() {
    return this.ticker.toUpperCase();
  }

  //#region wallet

  @computed
  public get wallet() {
    return this.resources.walletsResource.getByTicker(this.ticker)!;
  }

  @computed
  public get walletVersions() {
    return this.wallet.versionsByLedger;
  }

  @computed
  public get walletVersion() {
    return this.walletVersions[0];
  }

  @computed
  public get walletVersionValue() {
    return this.walletVersion?.version;
  }

  @computed
  public get walletVersionAddress(): string {
    return this.walletVersion?.address ?? '';
  }

  //#endregion wallet

  //#region ledgerAccount

  @computed
  public get ledgerCurrencyVersions() {
    return this.resources.ledgerResource.listCurrencies.filter(currency => {
      return currency.ticker === this.ticker.toUpperCase();
    });
  }

  @computed
  public get ledgerCurrency() {
    return this.resources.ledgerResource.getCurrencyByTicker(this.ticker);
  }

  @computed
  public get ledgerAccounts() {
    return this.resources.ledgerResource.getAccountsByCurrencyWithBalance(
      this.ticker,
      this.walletVersionValue
    );
  }

  @computed
  public get ledgerAccountsItems() {
    return this.ledgerAccounts.map(a => {
      // Note: warning because I'm not if it's a problem
      warning(
        this.ledgerCurrency!.units[0]!.magnitude,
        'the value for magnitude in convertLedgerBalanceToAmountString is not set'
      );

      return {
        label: `${a.name}`,
        value: a.id,
        ticker: this.ticker,
        tickerFormatted: this.tickerFormatted,
        amount: convertLedgerBalanceToAmountString(
          this.ticker,
          a.balance,
          this.ledgerCurrency!.units[0]!.magnitude
        ),
        key: a.id,
        keys: a.name,
      };
    });
  }

  @computed
  public get ledgerAccountId() {
    warning(
      this.instance?.$('ledgerAccountId').value ?? this.ledgerAccountsItems[0],
      'Cannot get ledger account id',
      {},
      {ledgerAccountsItems: this.ledgerAccountsItems}
    );

    return (
      this.instance?.$('ledgerAccountId').value ??
      this.ledgerAccountsItems[0]!.value
    );
  }

  @computed
  public get ledgerAccount() {
    return this.ledgerAccounts.find(a => a.id === this.ledgerAccountId)!;
  }

  @computed
  public get ledgerAccountBalance() {
    warning(
      this.ledgerCurrency!.units[0]?.magnitude!,
      'the value for magnitude in convertLedgerBalanceToAmountString is not set'
    );

    return convertLedgerBalanceToAmountString(
      this.ticker,
      this.ledgerAccount!.balance,
      this.ledgerCurrency!.units[0]!.magnitude
    );
  }

  @computed
  public get ledgerAccountAddress() {
    return this.ledgerAccount?.address;
  }

  @computed
  public get ledgerAccountIdOnChange() {
    return this.instance?.$('ledgerAccountId').onChange;
  }

  //#endregion ledgerAccount

  //#region amount

  @computed
  public get amountLabel() {
    return i18n.t('surface.wallets.crypto_deposit.amount');
  }

  @computed
  get amountPlaceholder() {
    return toBig(0).toFixed(getCoinDecimalPrecision(this.ticker));
  }

  @computed
  public get amount() {
    return this.instance?.$('amount').get('value') ?? this.ledgerAccountBalance;
  }

  @computed
  public get amountError() {
    return getTranslatedValidationMessage(
      this.instance.$('ledgerAccountId').get('error') ??
        this.instance.$('amount').get('error')
    );
  }

  @computed
  public get amountHasError() {
    return Boolean(this.amountError);
  }

  @computed
  public get amountOnChange() {
    return (value: string) =>
      this.instance.$('amount').get('onChange')(
        normalizeAmountWithPrecision(value, 8) // ledger has 8 precision
      );
  }

  @action
  amountOnPressAll = () => {
    this.amountOnChange(this.ledgerAccountBalance);
  };

  //#endregion amount

  //#region fee

  @computed
  public get fees() {
    return [
      {
        key: FeesLevel.Slow,
        label: FeesLevel.Slow,
        name: FeesLevel.Slow,
        value: FeesLevel.Slow,
      },
      {
        key: FeesLevel.Medium,
        label: FeesLevel.Medium,
        name: FeesLevel.Medium,
        value: FeesLevel.Medium,
      },
      {
        key: FeesLevel.Fast,
        label: FeesLevel.Fast,
        name: FeesLevel.Fast,
        value: FeesLevel.Fast,
      },
    ];
  }

  @computed
  public get feeLabel() {
    return i18n.t('surface.wallets.crypto_deposit.fee');
  }

  @computed
  public get fee() {
    return this.instance?.$('fee').get('value') ?? FeesLevel.Medium;
  }

  @computed
  public get feeError() {
    return getTranslatedValidationMessage(this.instance.$('fee').get('error'));
  }

  @computed
  public get feeHasError() {
    return Boolean(this.feeError);
  }

  @computed
  public get feeOnChange() {
    return this.instance.$('fee').get('onChange');
  }

  //#endregion fee

  @action
  public submit = () => {
    try {
      return this.resources.ledgerResource
        .deposit(
          this.ledgerAccount,
          this.amount,
          this.fee,
          this.resources.authMeResource.data.fullName ??
            this.resources.authMeResource.data.email,
          this.walletVersionAddress,
          this.walletVersionAddress
        )
        .then(() => {
          SHARED_ROUTER_SERVICE.navigate('__CloseModal');
        });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public constructor(
    args: CryptoDepositLedgerFormStateArgs,
    resources: CryptoDepositLedgerFormStateResource
  ) {
    this.args = args;
    this.resources = resources;
    this.instance = new MobxReactForm(
      {
        fields: {
          ledgerAccountId: {
            value: this.ledgerAccountId,
            hooks: {
              onChange: () => {
                this.amountOnPressAll();
              },
            },
          },
          amount: {
            value: this.amount,
          },
          fee: {
            value: this.fee,
          },
        },
      },
      {
        hooks: {
          onSuccess: () => {
            return this.submit();
          },
          onError: () => {
            LOCAL_NOTIFICATIONS.error({
              text: i18n.t('validation.VALIDATION'),
            });
          },
        },
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) => {
              return yup.lazy(() => {
                let amountSchema = yup.big().gt(0);
                if (this.ledgerAccountBalance) {
                  amountSchema = amountSchema.lte(
                    this.ledgerAccountBalance,
                    messages.FUNDS_INSUFFICIENT
                  );
                }

                const schema = {
                  ledgerAccountId: yup.string().required(),
                  amount: amountSchema,
                  fee: yup.string().required(),
                };

                return yup.object().shape(schema);
              });
            },
          }),
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
        },
      }
    );
  }
}
