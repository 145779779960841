import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {
  InstrumentsFilter,
  FavoritesFilter,
  InstrumentsSorter,
} from './components';

export const FiltersBar: React.FC<BoxProps> = ({...boxProps}) => {
  return (
    <Box flexDirection="row" gap={8} zIndex={10} {...boxProps}>
      <FavoritesFilter />
      <Box flex={1} flexDirection="row" justifyContent="space-between">
        <InstrumentsFilter />
        <InstrumentsSorter ml="auto" />
      </Box>
    </Box>
  );
};
