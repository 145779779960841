import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Select} from '@youtoken/ui.select';
import {
  HODLsTariffsFeature,
  type CategoryFilter,
} from '@youtoken/ui.resource-hodl-tariffs';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

export const InstrumentsFilter: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {
    showCategoryFilter,
    categoryFilterItems,
    activeCategoryFilter,
    setCategoryFilter,
  } = HODLsTariffsFeature.use({});

  const handleSelect = React.useCallback(
    (category: string) => {
      DATA_LAYER.trackStrict('hodl-instruments-filtering', {category});
      setCategoryFilter(category as CategoryFilter);
    },
    [setCategoryFilter]
  );

  if (!showCategoryFilter) {
    return null;
  }

  return (
    <Box {...boxProps}>
      <Select
        items={categoryFilterItems}
        onSelect={handleSelect}
        selected={activeCategoryFilter}
        size="small"
        dropdownWidth={144}
        dropdownAlign="left"
        bottomSheetTitle={t('surface.hodls.instruments.filter_title')}
        testID="INSTRUMENTS_FILTER_SELECT"
      />
    </Box>
  );
});
