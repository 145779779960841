import * as React from 'react';
import {observer} from 'mobx-react';
import {ScrollableArea, type ScrollableAreaProps} from '@youtoken/ui.elements';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {TradingInstrumentItem, TradingInstrumentModalItem} from './components';
import {TradingInstrumentItemData, type TradingInstrumentsProps} from './types';

export const TradingInstruments: React.FC<TradingInstrumentsProps & BoxProps> =
  observer(
    ({
      title,
      items,
      collapsedViewItemLimit,
      isExpandRestricted,
      itemSubtitleVariant = 'rate',
      showCharts = true,
      mobileEstimatedListHeight,
      allowRolloversIcon = false,
      onPressItem,
      onPressShowAll,
      testID,
      ...boxProps
    }) => {
      const handlePressItem = React.useCallback(
        (id: string, isParentListExpanded?: boolean) => {
          onPressItem?.(id, isParentListExpanded);

          SHARED_ROUTER_SERVICE.navigate('HODLInstrument', {
            symbol: id,
          });
        },
        [onPressItem]
      );

      const renderItem: ScrollableAreaProps<TradingInstrumentItemData>['renderItem'] =
        React.useCallback(
          ({item, index, isParentListExpanded}) => {
            return (
              <Box px={4} key={item.id + index}>
                <TradingInstrumentItem
                  item={item}
                  index={index}
                  isParentListExpanded={isParentListExpanded}
                  subtitleVariant={itemSubtitleVariant}
                  showCharts={showCharts}
                  allowRolloversIcon={allowRolloversIcon}
                  onPressItem={handlePressItem}
                  testIdBase={testID}
                />
              </Box>
            );
          },
          [itemSubtitleVariant, showCharts, handlePressItem, testID]
        );

      const renderModalItem = React.useCallback(
        ({
          item,
          index,
          closeModal,
          isParentListExpanded,
        }: {
          item: TradingInstrumentItemData;
          index: number;
          closeModal?: () => void;
          isParentListExpanded?: boolean;
        }) => {
          return (
            <TradingInstrumentModalItem
              item={item}
              index={index}
              isParentListExpanded={isParentListExpanded}
              showCharts={showCharts}
              closeModal={closeModal}
              onPressItem={handlePressItem}
              testIdBase={testID}
            />
          );
        },
        [showCharts, handlePressItem, testID]
      );

      return (
        <ScrollableArea
          title={title}
          items={items}
          renderItem={renderItem}
          renderModalItem={renderModalItem}
          collapsedViewItemLimit={collapsedViewItemLimit}
          mobileEstimatedListHeight={mobileEstimatedListHeight}
          isExpandRestricted={isExpandRestricted}
          onPressShowAll={onPressShowAll}
          testID={testID}
          {...boxProps}
        />
      );
    }
  );
