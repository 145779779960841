import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon} from '@youtoken/ui.icons';
import {
  Box,
  Text,
  TouchableBox,
  type TouchableBoxProps,
} from '@youtoken/ui.primitives';

export const HowExtendTpSlWorkTouchable: React.FC<TouchableBoxProps> = ({
  ...touchableBoxProps
}) => {
  const {t} = useTranslation();

  return (
    <TouchableBox
      flexDirection="row"
      alignItems="center"
      height={32}
      width={120}
      {...touchableBoxProps}
    >
      <Box mr={4}>
        <Icon name="question" color="$interactive-01" />
      </Box>

      <Text color="$interactive-01" variant="$body-02-medium-accent">
        {t('surface.hodls.extend_tp.how_works')}
      </Text>
    </TouchableBox>
  );
};
