import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

interface HODLDetailsButtonProps {
  onPress: () => void;
  text?: string;
  testID?: string;
}

export const HODLDetailsButton: React.FC<HODLDetailsButtonProps> = ({
  onPress,
  text,
  testID,
}) => {
  const {t} = useTranslation();

  return (
    <TouchableBox
      flexDirection="row"
      alignItems="center"
      onPress={onPress}
      testID={testID}
    >
      <Box mr={4}>
        <Icon name="question" color="$interactive-01" />
      </Box>

      <Text color="$interactive-01" variant="$body-02-medium-accent">
        {text ?? t('surface.hodls.mh_details')}
      </Text>
    </TouchableBox>
  );
};
