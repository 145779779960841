import * as React from 'react';
import {Platform} from 'react-native';
import {Box, Text, useIsMobile} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {IncentivesFeature} from '@youtoken/ui.resource-wallets';
import {cell} from '@youtoken/ui.cell';
import {Illustration, Trans} from '@youtoken/ui.elements';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Modal} from '@youtoken/ui.modal';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {HowIncentivesWorkContent} from '../BoostYourTradingSurface/HowIncentivesWorkContent';
import {BonusExpirationSurface} from '../BonusExpirationSurface';
import {ActionButtons, ExpirationButton, HowItWorksButton} from './components';

interface TradingBonusesSurfaceProps {
  onClose: () => void;
}

export const TradingBonusesSurface: React.FC<TradingBonusesSurfaceProps> = cell(
  ({onClose}) => {
    const {t} = useTranslation();
    const isMobile = useIsMobile();

    const imageSize = isMobile ? 160 : 200;

    const {incentivesBalanceFormatted, incentivesBurningInNoPrefix} =
      IncentivesFeature.use({});

    const [isExpirationModalOpened, setExpirationModalOpened] =
      React.useState(false);
    const [isHowItWorksModalOpened, setHowItWorksModalOpened] =
      React.useState(false);

    const handleOpenExpirationModal = React.useCallback(() => {
      DATA_LAYER.trackStrict('incentives-prolong-click', {
        source: 'TradingBonusesSurface',
      });

      setExpirationModalOpened(true);
    }, []);

    const handleCloseExpirationModal = React.useCallback(() => {
      setExpirationModalOpened(false);
    }, []);

    const handleOpenDepositWizard = React.useCallback(() => {
      DATA_LAYER.trackStrict('incentives-get-bonuses-click', {
        source: 'TradingBonusesSurface',
      });
      handleCloseExpirationModal();
      onClose();

      SHARED_ROUTER_SERVICE.navigate('DepositWizard', {});
    }, []);

    const handleOpenMultiHODL = React.useCallback(() => {
      DATA_LAYER.trackStrict('incentives-use-bonuses-click', {
        source: 'TradingBonusesSurface',
      });

      onClose();
      SHARED_ROUTER_SERVICE.navigate(
        'NewMultiHODL',
        {},
        {additionalInputAmount: incentivesBalanceFormatted}
      );
    }, []);

    const handleOpenHowItWorksModal = React.useCallback(() => {
      DATA_LAYER.trackStrict('incentives-how-it-works-click', {
        source: 'TradingBonusesSurface',
      });

      setHowItWorksModalOpened(true);
    }, []);

    const handleCloseHowItWorksModal = React.useCallback(() => {
      setHowItWorksModalOpened(false);
    }, []);

    return (
      <>
        <Box flex={1} alignItems="center" pb={40}>
          <Box alignItems="center" mt={24} px={24} width="100%">
            <Box mb={24}>
              <Illustration
                name="illustration-4"
                style={{
                  width: imageSize,
                  height: imageSize,
                  resizeMode: 'contain',
                }}
              />
            </Box>
            <Box mb={4}>
              <Text variant="$body-02" color="$text-02">
                {t('surface.incentives.text.balance')}
              </Text>
            </Box>
            <Box mb={8}>
              <Text variant="$heading-01" color="$text-01">
                ${incentivesBalanceFormatted}{' '}
              </Text>
            </Box>
          </Box>

          <Box width="100%" alignItems="center">
            <ExpirationButton onPress={handleOpenExpirationModal} mt={16} />
            <ActionButtons
              onGetMorePress={handleOpenDepositWizard}
              onUseBonusesPress={handleOpenMultiHODL}
              mt={32}
            />
            <HowItWorksButton onPress={handleOpenHowItWorksModal} mt={32} />
          </Box>
        </Box>

        <Modal
          isOpen={isHowItWorksModalOpened}
          title={t('surface.incentives.how_it_works.title')}
          shouldBeScrollable
          onClose={handleCloseHowItWorksModal}
          nativeStackBehavior="push"
        >
          <HowIncentivesWorkContent px={24} mb={40} />
        </Modal>

        <Modal
          isOpen={isExpirationModalOpened}
          title={t('surface.incentives.expiration.title')}
          subtitle={
            <Trans
              values={{period: incentivesBurningInNoPrefix}}
              parent={props => (
                <Text
                  variant="$body-01"
                  textAlign="center"
                  color="$text-01"
                  {...props}
                />
              )}
              components={{
                Strong: (
                  <Text variant="$body-01-medium-accent" color="$text-01" />
                ),
              }}
              i18nKey="surface.incentives.expiration.text.keep_from_expiring"
            />
          }
          shouldUseAnimatedSnapPoints
          snapPoints={['CONTENT_HEIGHT']}
          onClose={handleCloseExpirationModal}
          nativeStackBehavior="push"
          shouldShowHeaderSeparator={Platform.OS === 'web'}
        >
          <BonusExpirationSurface
            onTradePress={onClose}
            onDepositPress={onClose}
            onClose={handleCloseExpirationModal}
          />
        </Modal>
      </>
    );
  }
);
