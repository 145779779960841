import {StaticResourceDescription} from './types';
import {BaseResource} from './BaseResource';
import {useResource} from '../useResources';
import {wrapDescriptor} from './utils';
import {DATA_STORAGE} from '../DataStorage';
import {invariant} from '@youtoken/ui.utils';

/** createStaticResource is the same as createResource but:
 * - will not be garbage collected;
 * - will not be refetched or refreshed;
 * - will not subscribe to sockets;
 *
 * useful for one-time fetch resources like `PermissionsResource` or `DeviceInfoResource` on native apps;
 * they are basically take initial data from async `create` or `getData` and thats it;
 *
 * (async get permissions status, async get IDFA etc and handling lifecycle in logic):
 * - no need for refetch (subscription to permission events)
 * - no need for garbage collection (there is no expiration date on data)
 * - no need to use `TRANSPORT.SOCKET`
 */
export function createStaticResource<Args extends object, Result extends any>(
  descriptor: StaticResourceDescription<Args, Result>
) {
  const {getKey, create, getData, mergeArgs} = wrapDescriptor<Args, Result>(
    descriptor
  );

  return class StaticResource extends BaseResource<Args, Result> {
    static getKey(args: Args) {
      return getKey(mergeArgs(args));
    }

    static create(args: Args) {
      return create(mergeArgs(args));
    }

    static getData(args: Args) {
      return getData(mergeArgs(args));
    }

    static use<T extends typeof StaticResource>(
      this: T,
      args: Args
    ): InstanceType<T> {
      return useResource(this, mergeArgs(args));
    }

    /** getting resource directly from data storage;
     *
     * basically its
     *
     * ```
     * DATA_STORAGE.get(getKey(args))
     * ```
     *
     * **will** throw if resource is not found, pending or in error state - will return undefined instead;
     */
    static getInstance<T extends typeof StaticResource>(
      this: T,
      args: Args
    ): InstanceType<T> {
      const key = this.getKey(args);

      const instance = DATA_STORAGE.get(key);

      invariant(
        instance,
        `Resource with key "${key}" was not found! make sure to initialize it before using it`
      );

      invariant(
        // @ts-ignore, reason we don't have types for that :(
        !instance.promise,
        `Resource with key "${key}" is loading and not ready for use! make sure it's loaded`
      );

      invariant(
        // @ts-ignore, reason we don't have types for that :(
        !instance.error,
        `Resource with key "${key}" is in error state! make sure to handle it before using it`
      );

      return instance as InstanceType<T>;
    }

    /** getting resource directly from data storage;
     *
     * basically its
     *
     * ```
     * DATA_STORAGE.get(getKey(args))
     * ```
     *
     * will **NOT** throw if resource is not found, pending or in error state - will return undefined instead;
     */
    static getInstanceSafely<T extends typeof StaticResource>(
      this: T,
      args: Args
    ): InstanceType<T> | undefined {
      const key = this.getKey(args);

      const instance = DATA_STORAGE.get(key);

      if (!instance || instance.promise || instance.error) {
        return undefined;
      }

      return instance as InstanceType<T>;
    }

    /**
     * @deprecated use `getInstance` and `getInstanceSafely` instead
     */
    static __DANGEROUSLY__getInstanceStatically<
      T extends typeof StaticResource
    >(this: T, args: Args): InstanceType<T> {
      const key = this.getKey(args);

      const instance = DATA_STORAGE.get(key);

      invariant(
        instance,
        `Resource with key "${key}" was not found! make sure to initialize it before using it`
      );

      invariant(
        // @ts-ignore, reason we don't have types for that :(
        !instance.promise,
        `Resource with key "${key}" is loading and not ready for use! make sure it's loaded`
      );

      invariant(
        // @ts-ignore, reason we don't have types for that :(
        !instance.error,
        `Resource with key "${key}" is in error state! make sure to handle it before using it`
      );

      return instance as InstanceType<T>;
    }

    onInit() {
      super.onInit();
      this.logger('onInit', 'from createStaticResource');
    }

    onDestroy() {
      super.onDestroy();
      this.logger('onDestroy', 'from createStaticResource');
    }

    constructor(args: Args, data: Result) {
      super(
        mergeArgs(args),
        data,
        getKey,
        getData,
        descriptor.refetchErrorPolicy,
        descriptor.skipRefreshOnVisible,
        descriptor.shouldBeDeletedOnCleanup
      );
    }
  };
}
