import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Tabs, type ITab, type ITabsProps} from '@youtoken/ui.tabs';
import {useTranslation} from '@youtoken/ui.service-i18n';

type FriendsTabsProps = Pick<ITabsProps, 'activeIndex' | 'onChange'>;

export const FriendsTabs: React.FC<FriendsTabsProps & BoxProps> = observer(
  ({activeIndex, onChange, ...boxProps}) => {
    const {t} = useTranslation();

    const tabs: ITab[] = [
      {
        type: 'text',
        label: t('surface.new_referral.tab.activity'),
        value: 'Activity',
        testID: 'ACTIVITY_TAB',
      },
      {
        type: 'text',
        label: t('surface.new_referral.tab.invited'),
        value: 'Invited',
        testID: 'INVITED_TAB',
      },
      {
        type: 'text',
        label: t('surface.new_referral.tab.joined'),
        value: 'Joined',
        testID: 'JOINED_TAB',
      },
    ];

    return (
      <Box {...boxProps}>
        <Tabs tabs={tabs} activeIndex={activeIndex} onChange={onChange} />
      </Box>
    );
  }
);
