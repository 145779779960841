import {action, computed, observable} from 'mobx';
import {ClosedHODLsResource} from '@youtoken/ui.resource-hodl';
import {HODLOverviewResource} from '@youtoken/ui.resource-hodl-overview';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {computedFn} from 'mobx-utils';

interface ClosedHODLsPaginationAndFilterFeatureArgs {
  closedLimit?: number;
}

export class ClosedHODLsPaginationAndFilterFeature extends createFeature({
  getKey: (args: ClosedHODLsPaginationAndFilterFeatureArgs) =>
    `feature:closed_hodls_pagination_filter:${JSON.stringify(args)}`,
  getResources: ({closedLimit}) => {
    return {
      overview: getResourceDescriptor(HODLOverviewResource, {}),
      closed: getResourceDescriptor(ClosedHODLsResource, {
        limit: closedLimit,
        filter: [],
        offset: 0,
      }),
    };
  },
}) {
  @computed get argLimit() {
    return this.resources.closed.argLimit;
  }

  @computed get argOffset() {
    return this.resources.closed.argOffset;
  }

  @computed get closedTotal() {
    return this.resources.closed.data.total;
  }

  //#region pagination
  @computed get totalPages() {
    return Math.ceil(this.closedTotal / this.argLimit);
  }

  @computed get pages() {
    return Array.from({length: this.totalPages}).map((_, i) => Number(i));
  }

  // activePage starts from 1, but offset starts from 0
  @computed get activePage() {
    return this.argOffset / this.argLimit + 1;
  }

  getOffset = computedFn(
    (nextPage: number) => {
      const offset = this.argLimit * (nextPage - 1);

      if (offset <= 0) {
        return 0;
      }

      if (offset >= this.closedTotal) {
        return (this.totalPages - 1) * this.argLimit;
      }

      return offset;
    },
    {keepAlive: true}
  );
  //#endregion pagination

  //#region filters
  @action setPage = (nextPage: number) => {
    const offset = this.getOffset(nextPage);

    this.resources.closed.updateArgs({
      offset,
    });
  };

  @computed
  get closedHodlsTickerFilters() {
    return this.resources.overview.data.baseTickers;
  }

  @observable selectedTickers: string[] = [];

  @action applyFilters = ({selectedTickers}: {selectedTickers: string[]}) => {
    this.selectedTickers = selectedTickers;
    this.resources.closed.updateArgs({
      filter: selectedTickers,
    });
  };
  //#endregion filters
}
