import * as React from 'react';
import {InstaStoriesResource} from '@youtoken/ui.resource-insta-stories';
import {type BoxProps, useResponsive} from '@youtoken/ui.primitives';
import {observer} from 'mobx-react';
import {Platform} from 'react-native';
import {StorySmartContainer} from './components';

export const StoriesList: React.FC<BoxProps> = observer(({...props}) => {
  const {haveStories} = InstaStoriesResource.use({});

  const previewSize = useResponsive({
    default: 80,
    desktop: 102,
  });

  if (!haveStories) {
    return null;
  }

  return (
    <StorySmartContainer
      duration={5}
      previewSize={previewSize}
      previewWrapperStyle={{
        width: previewSize! + 8,
        height: previewSize! + 8,
        borderRadius: 23,
      }}
      previewImageStyle={{
        borderRadius: 20,
      }}
      unPressedBorderColor="$interactive-01"
      unPressedPreviewTextColor="$text-04"
      pressedBorderColor="$transparent"
      pressedPreviewTextColor="$text-04"
      {...props}
    />
  );
});
