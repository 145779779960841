import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Box, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {type ActionsProps} from './types';
import {useWalletActionsAdditionalProps} from './hooks';

export const WalletActionsAdditionalList: React.FC<ActionsProps & BoxProps> =
  observer(({item, ...boxProps}) => {
    // NOTE: useWalletActionsAdditionalProps uses mobx
    const [items, itemsAction] = useWalletActionsAdditionalProps(item);

    return (
      <Box flexDirection="row" alignItems="center" {...boxProps}>
        {items.map(({icon, value}) => {
          return (
            <TouchableBox
              key={value}
              justifyContent="center"
              alignItems="center"
              width={32}
              height={32}
              onPress={() => itemsAction(value)}
            >
              {icon ?? <Icon name="warning" color="$text-01" size={16} />}
            </TouchableBox>
          );
        })}
      </Box>
    );
  });
