/**
 * __AppEnv__ constant
 *
 * contains all corresponding environments for all apps
 * */
export enum AppEnv {
  /**
   * Represents app running in _localhost_ env; otherwise same as _development_
   * */
  local = 'local',
  development = 'development',
  stage = 'stage',
  stage2 = 'stage2',
  rnd = 'rnd',
  production = 'production',
  alpha = 'alpha',
  beta = 'beta',
  gamma = 'gamma',
  delta = 'delta',

  // new contours
  lambda = 'lambda',
  epsilon = 'epsilon',
  zeta = 'zeta',
  iota = 'iota',

  // newer contours
  'core-test' = 'core-test',
  'core-uat' = 'core-uat',

  // b2b contours
  'b2b-sandbox' = 'b2b-sandbox',

  // localhost
  localhost = 'localhost',
}
