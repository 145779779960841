import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {MinerFeature} from '@youtoken/ui.resources-miner';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {OnboardingStepPerksModal} from '@youtoken/ui.surfaces-wallets';
import {FeaturesResource} from '@youtoken/ui.resource-features';
import {AccountLevelProgressAction} from './Action';
import {AccountLevelProgressWidgetAuthorized} from '..';

export {AccountLevelProgressNoMiner} from './AccountLevelProgressNoMiner';

export interface AccountLevelProgressProps {
  TradeToUpgrade?: React.FC;
}

export const AccountLevelProgress: React.FC<
  AccountLevelProgressProps & BoxProps
> = observer(({TradeToUpgrade, ...boxProps}) => {
  const {t} = useTranslation();

  const {
    resources: {
      loyalty: {
        data: {currentLevel},
        getLevel,
      },
    },
  } = MinerFeature.use({});

  const {isNewOnboardingEnabled} = FeaturesResource.use({});

  const {requiredDepositVisibleFormatted} = getLevel(2)!;

  const openUpgradeLevelModal = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('UpgradeYourLevel');
  }, [isNewOnboardingEnabled]);

  const openFirstTradeModal = React.useCallback(() => {
    setFirstTradeModalOpen(true);
  }, []);

  const [isFirstTradeModalOpen, setFirstTradeModalOpen] = React.useState(false);

  const closeFirstTradeModal = () => {
    setFirstTradeModalOpen(false);
  };

  const handleMakeFirstTradePress = () => {
    closeFirstTradeModal();
    return SHARED_ROUTER_SERVICE.navigate('MultiHODL');
  };

  return (
    <Box
      testID="ACCOUNT_LEVEL_DETAILED_WIDGET"
      p={{default: 16, tablet: 24}}
      pb={currentLevel > 2 ? 0 : undefined}
      bg="$ui-background"
      borderWidth={1}
      borderColor="$ui-01"
      borderRadius={16}
      {...boxProps}
    >
      {currentLevel === 1 && (
        <AccountLevelProgressAction
          onPress={openUpgradeLevelModal}
          title={t('surface.miner.loyalty.upgrade')}
        >
          {t('surface.loyalty.next_level_requirement.2', {
            amount: requiredDepositVisibleFormatted,
          })}
        </AccountLevelProgressAction>
      )}
      {currentLevel === 2 && (
        <>
          <AccountLevelProgressAction
            onPress={
              isNewOnboardingEnabled
                ? openFirstTradeModal
                : openUpgradeLevelModal
            }
            title={t('surface.miner.loyalty.upgrade')}
          >
            {t('surface.loyalty.next_level_requirement.3')}
          </AccountLevelProgressAction>
          {isNewOnboardingEnabled && (
            <OnboardingStepPerksModal
              steps={['trade']}
              isOpen={isFirstTradeModalOpen}
              onClose={closeFirstTradeModal}
              footerButtonLabel={t(
                'surface.wallets.first_action_modal.action.first_trade'
              )}
              onFooterButtonPress={handleMakeFirstTradePress}
            />
          )}
        </>
      )}
      {currentLevel > 2 && (
        <AccountLevelProgressWidgetAuthorized TradeToUpgrade={TradeToUpgrade} />
      )}
    </Box>
  );
});
