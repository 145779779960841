import * as React from 'react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  NewReferralActivity,
  NewReferralActivityEvent,
  NewReferralOverviewResource,
} from '@youtoken/ui.resource-new-referral';
import {cell} from '@youtoken/ui.cell';
import {PendingFriendStep} from './PendingFriendStep';
import {RewardExplanation} from './RewardExplanation';

type PendingFriendModalContentProps = {
  activity: NewReferralActivity;
};

const StepLink: React.FC = () => {
  return (
    <Box
      width={1}
      height={16}
      alignSelf="center"
      my={8}
      backgroundColor="$ui-02"
    />
  );
};

export const PendingFriendModalContent: React.FC<
  PendingFriendModalContentProps & BoxProps
> = cell(({activity, ...boxProps}) => {
  const {t} = useTranslation();

  const {
    overview: {
      conditions: {percentFormatted, stepAmountUSD},
    },
  } = NewReferralOverviewResource.use({});

  return (
    <Box flexDirection="column" alignItems="center" {...boxProps}>
      <Text variant="$body-02" color="$text-02" mb={16}>
        {activity.createdAtFormatted}
      </Text>

      <Box
        justifyContent="center"
        alignItems="center"
        width={40}
        height={40}
        borderRadius={40}
        backgroundColor="$attention-02"
        mb={8}
      >
        <Icon name="new_referral" size={24} color="$attention-01" />
      </Box>

      <Text variant="$heading-02" mb={20}>
        {activity.inviteeName || activity.inviteeEmail}
      </Text>

      {Boolean(activity.inviteeName) && (
        <Box px={8} py={4} backgroundColor="$ui-01" borderRadius={22} mb={28}>
          <Text variant="$body-02">{activity.inviteeEmail}</Text>
        </Box>
      )}

      <Text mb={24}>
        {t('surface.new_referral.pending_friend.steps_description')}
      </Text>

      <Box alignSelf="stretch" mb={16}>
        <PendingFriendStep
          index={1}
          title={t('surface.new_referral.pending_friend.steps.sign_up')}
          completed={
            activity.event === NewReferralActivityEvent.REGISTRATION ||
            activity.event === NewReferralActivityEvent.VERIFICATION
          }
        />
        <StepLink />
        <PendingFriendStep
          index={2}
          title={t('surface.new_referral.pending_friend.steps.verification')}
          completed={activity.event === NewReferralActivityEvent.VERIFICATION}
        />
        <StepLink />
        <PendingFriendStep
          index={3}
          title={t('surface.new_referral.pending_friend.steps.miner', {
            amount: stepAmountUSD,
          })}
          completed={false}
        />
        <StepLink />
        <RewardExplanation />
      </Box>

      <Text variant="$body-02" color="$text-02" textAlign="center">
        {t('surface.new_referral.pending_friend.your_reward.explanation', {
          amount: stepAmountUSD,
          percent: percentFormatted,
        })}
      </Text>
    </Box>
  );
});
