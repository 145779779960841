import * as React from 'react';
import {
  Box,
  Text,
  type TColorTokensNames,
  type TColorTokens,
} from '@youtoken/ui.primitives';
import {
  type BaseIconName,
  Icon,
  LoyaltyIcon,
  LoyaltyIconName,
} from '@youtoken/ui.icons';

type RewardBoxProps = {
  iconComponentName: 'Icon' | 'LoyaltyIcon';
  iconName: BaseIconName | LoyaltyIconName;
  iconColor?: keyof TColorTokens;
  iconBgColor: keyof TColorTokens;
  borderColor: keyof TColorTokens;
  bgColor?: keyof TColorTokens;
  title?: string;
  text?: string;
  children?: React.ReactNode;
};

const iconComponents = {
  Icon,
  LoyaltyIcon,
};
const wrapSize = 40;

export const RewardBox: React.FC<RewardBoxProps> = ({
  iconComponentName,
  iconName,
  iconColor,
  iconBgColor,
  borderColor,
  bgColor = '$transparent',
  title,
  text,
  children,
}) => {
  const IconComponent = iconComponents[iconComponentName] as React.ElementType<{
    name: BaseIconName | LoyaltyIconName;
    color?: keyof TColorTokens;
    size: number;
  }>;

  return (
    <Box position="relative" pt={wrapSize / 2}>
      <Box
        borderWidth={1}
        borderColor={borderColor}
        backgroundColor={bgColor}
        borderRadius={12}
        p={24}
        alignItems="center"
      >
        {Boolean(title) && (
          <Text variant="$body-01-high-accent" mb={4}>
            {title}
          </Text>
        )}
        {Boolean(text) && (
          <Text variant="$body-02" textAlign="center">
            {text}
          </Text>
        )}
        {children}
      </Box>
      <Box
        width="100%"
        position="absolute"
        top={0}
        left={0}
        right={0}
        alignItems="center"
      >
        <Box
          justifyContent="center"
          alignItems="center"
          width={wrapSize}
          height={wrapSize}
          bg={iconBgColor}
          borderWidth={4}
          borderRadius={wrapSize / 2}
          borderColor="$ui-background"
        >
          <IconComponent name={iconName} color={iconColor} size={24} />
        </Box>
      </Box>
    </Box>
  );
};
