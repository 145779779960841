import {observable, action, computed} from 'mobx';
import {AppearanceServiceModule} from './Appearance';
import {SECURE_STORAGE} from '@youtoken/ui.service-storage';

export class GlobalService {
  public appearance: AppearanceServiceModule = new AppearanceServiceModule();

  @observable
  token: string | null;

  @observable
  initialUrl: string | null = null;

  @action
  setToken(newToken?: string) {
    if (newToken) {
      SECURE_STORAGE.set('token', newToken);
      this.token = newToken;
    } else {
      this.removeToken();
    }
  }

  @action
  setInitialUrl = (path: string | null) => {
    this.initialUrl = path;
  };

  @action
  removeToken() {
    SECURE_STORAGE.delete('token');
    this.token = null;
  }

  constructor() {
    const token = SECURE_STORAGE.get('token') || null;
    this.token = token;
    // very special case (need to remove later as we will not need that anymore)
    // in case we dont have token but have email and passcode in secure storage somehow;
    // we need to remove them explicitly;
    if (!token) {
      try {
        if (SECURE_STORAGE.get('youhodlerEmailKey')) {
          SECURE_STORAGE.delete('youhodlerEmailKey');
        }

        if (SECURE_STORAGE.get('youhodlerPasscodeKey')) {
          SECURE_STORAGE.delete('youhodlerPasscodeKey');
        }
      } catch (e) {}
    }
  }

  @computed
  get isAuthorized() {
    return Boolean(this.token);
  }
}
