import {action, computed, observable} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {i18n} from '@youtoken/ui.service-i18n';
import {__GLOBAL_RECAPTCHA__} from '@youtoken/ui.two-factor-auth-and-recaptcha';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {getAnalyticsData} from '@youtoken/ui.utils';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {AuthMeResponse} from '@youtoken/ui.resource-auth-me';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {type SignInFormArgs, type SignInFormResources} from './types';

const onSuccessSignIn = (
  authMeResourceData: AuthMeResponse,
  verificationResource: VerificationResource
) => {
  // NOTE: verificationResource without fallback can be used only when user is signed in
  verificationResource.args.withFallback = false;

  return verificationResource.refetch().then(() => {
    if (
      authMeResourceData.challenges?.some(
        challenge => challenge.type === 'disclosuresSignature'
      )
    ) {
      return SHARED_ROUTER_SERVICE.navigate('ReSignDisclosures', {});
    }

    const redirectDestination = verificationResource.accountVerificationComplete
      ? 'WalletsList'
      : 'PleaseVerify';

    SHARED_ROUTER_SERVICE.navigate(redirectDestination, {});
  });
};

export class Form {
  @observable
  public args: SignInFormArgs;

  @observable
  public resources: SignInFormResources;

  @observable
  public instance: MobxReactForm;

  public constructor(args: SignInFormArgs, resources: SignInFormResources) {
    this.args = args;
    this.resources = resources;

    const fields = {
      email: {
        name: 'email',
        label: 'Email',
      },
      password: {
        name: 'password',
        label: 'Password',
      },
    };

    const hooks = {
      onSuccess: () => {
        return this.submit();
      },
    };

    const plugins = {
      yup: yupValidator({
        package: yupPackage,
        schema: (yup: typeof yupPackage) =>
          yup.lazy(() => {
            return yup.object().shape({
              email: yup.string().max(128).email().required(),
              password: yup.string().max(128).required(),
            });
          }),
      }),
    };

    const options = {
      validateOnBlur: false,
      validateOnChange: false,
      validateOnChangeAfterSubmit: true,
      showErrorsOnReset: false,
    };

    this.instance = new MobxReactForm({fields}, {plugins, hooks, options});
  }

  @action
  public submit = () => {
    const {requestToken} = __GLOBAL_RECAPTCHA__;

    return Promise.all([requestToken('sign_in'), getAnalyticsData()]).then(
      ([token, analytics]) => {
        return this.resources.authMe
          .signIn({
            token,
            email: this.emailValue,
            password: this.passwordValue,
            language: i18n.language,
            analytics,
          })
          .then(data => {
            if (data) {
              if (ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ramp-app']) {
                return onSuccessSignIn(data, this.resources.verification);
              }
              return this.resources.authMe.signInConfirmSuccess({data});
            }
          })
          .catch(error => {
            if (error?.response?.status !== 403) {
              handleFormSubmitError(this.instance, error);
            }
          });
      }
    );
  };

  //#region email
  @computed
  public get emailField() {
    return this.instance.$('email');
  }

  @computed
  public get emailValue() {
    return this.emailField.value;
  }

  @computed
  public get hasEmailError() {
    return Boolean(this.emailField.error);
  }

  @computed
  public get emailError() {
    return this.emailField.error;
  }

  @computed
  public get onEmailChange() {
    return this.emailField.get('onChange');
  }
  //#endregion email

  //#region password
  @computed
  public get passwordField() {
    return this.instance.$('password');
  }

  @computed
  public get passwordValue() {
    return this.passwordField.value;
  }

  @computed
  public get hasPasswordError() {
    return Boolean(this.passwordField.error);
  }

  @computed
  public get passwordError() {
    return this.passwordField.error;
  }

  @computed
  public get onPasswordChange() {
    return this.passwordField.get('onChange');
  }

  //#endregion password
}
