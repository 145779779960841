import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {AccountLevelInfoItemTitle} from '../../../components/AccountLevelInfoItem/Title';

export const LevelInfoMarketAnalytics: React.FC<BoxProps> = observer(
  ({testID = 'LEVEL_INFO_MARKET_ANALYTICS', ...boxProps}) => {
    const {t} = useTranslation();

    return (
      <Box testID={testID} gap={8} {...boxProps}>
        <AccountLevelInfoItemTitle icon="hodl">
          {t('surface.loyalty.market_analytics')}
        </AccountLevelInfoItemTitle>
        <Text
          testID={`${testID}_DESCRIPTION`}
          variant="$body-02"
          color="$text-02"
        >
          {t('surface.loyalty.market_analytics.description')}
        </Text>
      </Box>
    );
  }
);
