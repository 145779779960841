import * as React from 'react';
import {
  type TouchableBoxProps,
  Text,
  Box,
  TouchableBox,
} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {Listbox} from '@headlessui/react';
import {OptionPropsWeb} from './types';
import {getItemBG} from './utils';

interface ListboxOptionProps extends TouchableBoxProps {
  onClick: TouchableBoxProps['onPress'];
}

const ListboxOption: React.FC<ListboxOptionProps> = ({
  onClick,
  ...touchableBoxProps
}) => {
  return <TouchableBox onPress={onClick} {...touchableBoxProps} />;
};

export const Option: React.FC<OptionPropsWeb> = ({
  value,
  height,
  label,
  icon,
  padding = 16,
}) => {
  return (
    // @ts-ignore
    <Listbox.Option value={value} as={ListboxOption}>
      {({active, selected}) => (
        <Box
          height={height}
          style={{cursor: 'pointer'}}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          paddingLeft={padding}
          paddingRight={padding}
          backgroundColor={getItemBG(active, selected)}
          testID={`SELECT_ITEM_${value.toUpperCase()}`}
        >
          <Box
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            gap={8}
          >
            {icon}
            <Text color="$text-01">{label}</Text>
          </Box>
          {selected && <Icon name="check" color="$text-05" />}
        </Box>
      )}
    </Listbox.Option>
  );
};
