import {createResource} from '@youtoken/ui.data-storage';
import {observable, computed, action, comparer} from 'mobx';
import {parse} from 'query-string';
import {type RouteComponentProps} from 'react-router-dom';

export class RouterResource extends createResource({
  getKey: () => 'Router',
  getData: () => Promise.resolve(),
}) {
  @observable
  private routerProps!: RouteComponentProps;

  @action setRouterProps = (props: RouteComponentProps) => {
    this.routerProps = props;
  };

  @computed get location() {
    return this.routerProps?.location;
  }

  @computed({equals: comparer.shallow}) get pathname() {
    return this.location?.pathname || '';
  }

  @computed get key() {
    return this.location?.key;
  }

  @computed get search() {
    return this.location?.search;
  }

  @computed get queryParams() {
    if (this.search === '') {
      return {} as {[key: string]: any};
    }

    return parse(this.search) as {[key: string]: any};
  }

  @action setQueryParams = (params: {[key: string]: any}) => {
    const newParams = {...this.queryParams, ...params};
    const newSearch = Object.keys(newParams)
      .map(key => `${key}=${newParams[key]}`)
      .join('&');
    this.routerProps.history.replace({
      search: `?${newSearch}`,
    });
  };

  // this is needed because we can't use `this.routerProps.history.push` directly
  // this is basically accepts path as string or {pathname: string, search: string}
  @action push = (
    ...args: Parameters<RouteComponentProps['history']['push']>
  ) => {
    this.routerProps?.history?.push(...args);
  };

  @action goBack = () => {
    this.routerProps?.history.goBack();
  };
}
