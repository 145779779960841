import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Trans} from '@youtoken/ui.service-i18n';
import {Box, Heading, Text, useIsMobile} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {Link, ToggleableSection} from '@youtoken/ui.elements';

const isNative = Platform.OS !== 'web';

const Spark: React.FC = () => {
  return (
    <Box
      width={10}
      height={20}
      justifyContent="center"
      alignItems="center"
      // @ts-ignore web-specific style format clashes with native
      style={{verticalAlign: 'bottom'}}
      pt={isNative ? 10 : undefined}
    >
      <Icon name="spark" size={20} color="$text-02" />
    </Box>
  );
};

const EmptyLink: React.FC = () => null;

export const HowItWorksBanner: React.FC<{}> = observer(() => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();

  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleExpand = React.useCallback(() => {
    setIsExpanded(true);
  }, []);

  const handleCollapse = React.useCallback(() => {
    setIsExpanded(false);
  }, []);

  return (
    <Box testID="MINER_HOW_IT_WORKS">
      <Heading variant="$heading-02" color="$text-02" mb={8}>
        {t('surface.miner.how_it_works')}
      </Heading>

      <Trans
        t={t}
        i18nKey="surface.miner.how_it_works.description_short"
        style={{flexWrap: 'wrap'}}
        parent={props => <Text color="$text-02" {...props} />}
        components={{
          Spark: <Spark />,
          ShowMoreLink:
            isExpanded || !isMobile ? (
              <EmptyLink />
            ) : (
              <Link onPress={handleExpand}></Link>
            ),
        }}
      />
      {!isMobile && (
        <Trans
          t={t}
          i18nKey="surface.miner.how_it_works.description_rest"
          style={{flexWrap: 'wrap'}}
          parent={props => <Text color="$text-02" {...props} />}
          components={{
            ShowLessLink: <EmptyLink />,
          }}
        />
      )}

      {isMobile && (
        <Box mt={isExpanded ? 8 : 0}>
          <ToggleableSection visible={isExpanded}>
            <Trans
              t={t}
              i18nKey="surface.miner.how_it_works.description_rest"
              style={{flexWrap: 'wrap'}}
              parent={props => <Text color="$text-02" {...props} />}
              components={{
                ShowLessLink: <Link onPress={handleCollapse}></Link>,
              }}
            />
          </ToggleableSection>
        </Box>
      )}
    </Box>
  );
});
