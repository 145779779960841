import {computed, observable} from 'mobx';
import {serializable, primitive, date, optional, alias} from 'serializr';
import Big from 'big.js';
import {bigNumber} from '@youtoken/ui.utils-serializr';
import {format} from '@youtoken/ui.date-fns';
import {getCoinDecimalPrecision, getExplorerUrl} from '@youtoken/ui.coin-utils';
import {i18n} from '@youtoken/ui.service-i18n';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {HistoryItemStatus, HistoryItemType} from './types';
import {statusKeys, typeKeys} from './constants';
import {LogoColoredIconName} from '@youtoken/ui.icons';

export class HistoryItemData {
  @serializable(primitive())
  @observable
  id!: string;

  @serializable(primitive())
  @observable
  transactionId!: string;

  @serializable(alias('ticker', primitive()))
  @observable
  _ticker!: string;

  @serializable(optional(primitive()))
  @observable
  conversionTicker?: string;

  @serializable(bigNumber())
  @observable
  amount!: Big;

  @serializable(optional(bigNumber()))
  @observable
  conversionAmount?: Big;

  @serializable(date())
  @observable
  createdAt!: Date;

  @serializable(primitive())
  @observable
  status!: HistoryItemStatus;

  @serializable(alias('type', primitive()))
  @observable
  _type!: Omit<HistoryItemType, HistoryItemType.DEPOSIT_BONUS>;

  @serializable(primitive())
  @observable
  fee?: string;

  @serializable(primitive())
  @observable
  txHash?: string;

  @serializable(primitive())
  @observable
  version?: string;

  // #region incentives hacks
  // https://yhdl.slack.com/archives/C05D9FRKJMP/p1714145044169789?thread_ts=1714140030.073199&cid=C05D9FRKJMP
  @computed get isIncentiveTransaction(): boolean {
    return this._ticker === 'bonus';
  }

  @computed get ticker(): string {
    return this.isIncentiveTransaction ? 'usd' : this._ticker;
  }

  @computed get type(): HistoryItemType {
    if (this.isIncentiveTransaction && this._type === HistoryItemType.DEPOSIT) {
      return HistoryItemType.DEPOSIT_BONUS;
    }
    return this._type as HistoryItemType;
  }

  @computed get iconName(): LogoColoredIconName {
    return this.isIncentiveTransaction
      ? 'bonus'
      : (this._ticker as LogoColoredIconName);
  }
  // #endregion incentives hacks

  /** Ticker in uppercase
   * @example
   * 'btc' => 'BTC'
   * */
  @computed get tickerFormatted(): string {
    return this.ticker.toUpperCase();
  }

  /** Conversion ticker in uppercase
   * @example
   * 'btc' => 'BTC'
   * */
  @computed get conversionTickerFormatted(): string | undefined {
    return this.conversionTicker?.toUpperCase();
  }

  /** Suitable decimal precision for a ticker
   * @example
   * 1.23000000 => 1.23 (for USD)
   */
  @computed get amountStringFormatted() {
    return this.amount.toFixed(getCoinDecimalPrecision(this.ticker));
  }

  /** Suitable decimal precision for conversion ticker
   * @example
   * 1.23000000 => 1.23 (for USD)
   */
  @computed get conversionAmountStringFormatted() {
    return this.conversionAmount && this.conversionTicker
      ? this.conversionAmount.toFixed(
          getCoinDecimalPrecision(this.conversionTicker)
        )
      : '';
  }

  /** Was the creation day this year
   * @example
   * '2022-09-07T09:59:36.907Z' => true
   * '2021-09-07T09:59:36.907Z' => false
   */
  @computed get isCreatedThisYear() {
    return this.createdAt.getFullYear() === new Date().getFullYear();
  }

  /** Formatted date: day, month, year (if not current)
   * @example
   * '2022-09-07T09:59:36.907Z' => '7 September'
   * '2021-09-07T09:59:36.907Z' => '7 September 2021'
   */
  @computed get createdAtFormatted() {
    return this.isCreatedThisYear
      ? format(this.createdAt, 'd MMMM')
      : format(this.createdAt, 'd MMMM yyyy');
  }

  /** Formatted date: day, month, year (if not current), time
   * @example
   * '2022-09-07T09:59:36.907Z' => '7 September, 09:41'
   * '2022-09-07T09:59:36.907Z' => '7 September 2021, 09:41'
   */
  @computed get createdAtWithTimeFormatted() {
    return this.isCreatedThisYear
      ? format(this.createdAt, 'd MMMM, HH:mm')
      : format(this.createdAt, 'd MMMM yyyy, HH:mm');
  }

  /** '+' or '-' sign depending on transaction type
   * @example
   * 'DEPOSIT' => '+'
   * 'WITHDRAWAL' => '-'
   */
  @computed get typeSign(): '+' | '-' {
    if (
      [
        'WITHDRAWAL',
        'HOLD',
        'SPENT',
        'FINE',
        'DAILY_INTEREST',
        'EXCHANGE',
        'LOAN_PAYMENT',
        'REOPEN',
        'CARD_FEE',
        'BURNT',
      ].includes(this.type)
    ) {
      return '-';
    }

    return '+';
  }

  /** Translated transaction type label
   * @example
   * 'DEPOSIT' => 'Deposit'
   */
  @computed get typeLabel(): string {
    if (!this.type) {
      return '';
    }

    // @ts-ignore
    return i18n.t(typeKeys[this.type], {defaultValue: this.type.toLowerCase()});
  }

  /** Translated transaction status label
   * @example
   * 'FAIL' => 'Failed'
   */
  @computed get statusLabel(): string {
    if (!this.status) {
      return '';
    }

    // @ts-ignore
    return i18n.t(statusKeys[this.status], {
      defaultValue: this.status.toLowerCase(),
    });
  }

  /** A boolean value indicating whether the transaction is of a conversion type
   * @example
   * type === 'EXCHANGE' ? true : false
   */
  @computed get isConvert(): boolean {
    return this.type === 'EXCHANGE';
  }

  @computed get badgeVariant(): 'attention' | 'danger' | undefined {
    if (!['PENDING', 'PROCESSING', 'FAIL', 'DECLINED'].includes(this.status)) {
      return undefined;
    }

    return ['PENDING', 'PROCESSING'].includes(this.status)
      ? 'attention'
      : 'danger';
  }

  /** Generated agreement url
   * @example
   * '/v1/docks/agreement/conversion-agreement/c27bfccb-8a4c-4c5a-829b-d0bb01e76ba5'
   */
  @computed get agreementUrl(): string {
    return `/v2/docks/exchange/agreement/${this.transactionId}`;
  }

  /** Generated invoice url
   * @example
   * '/v1/docks/invoice/loan/c27bfccb-8a4c-4c5a-829b-d0bb01e76ba5'
   */
  @computed get invoiceUrl(): string {
    return `/v2/docks/loan/agreement/${this.transactionId}`;
  }

  @computed get explorerUrl(): string | undefined {
    return this._explorer?.url;
  }

  @computed get explorerName(): string {
    return this._explorer?.name || 'Explorer';
  }

  @computed get hasExplorerUrl(): boolean {
    return ['DEPOSIT', 'WITHDRAWAL'].includes(this.type) && !!this.explorerUrl;
  }

  @computed get _explorer(): {name: string; url: string} | null {
    if (!this.txHash) {
      return null;
    }

    return (
      getExplorerUrl(
        ENVIRONMENT.APP_ENV,
        this.ticker,
        this.txHash,
        this.version
      ) ?? null
    );
  }
}
