import {observable} from 'mobx';
import {serializable, primitive, list, object} from 'serializr';
import {NewReferralActivity} from '../NewReferralActivityResource';

export class NewReferralJoinedResponse {
  @observable
  @serializable(list(object(NewReferralActivity)))
  joined!: NewReferralActivity[];

  @observable
  @serializable(primitive())
  total!: number;
}
