import * as React from 'react';
import {observer} from 'mobx-react';
import {Path} from 'react-native-svg';
import {FEBlockState} from '@youtoken/ui.resources-miner';
import {useLegacyOpacityAnimation, useMinerColors} from '../../hooks';

type BorderProps = {
  hexState: FEBlockState;
};

const OPACITIES: Record<FEBlockState, number> = {
  DISABLED: 0,
  AVAILABLE_NOT_ENOUGH_SPARKS: 1,
  AVAILABLE_ENOUGH_SPARKS: 1,
  MINING_STARTING: 1,
  MINING: 1,
  READY: 0,
  CLAIMING_STARTING: 0,
  CLAIMING: 0,
  CLAIMED_COLORED: 0,
  CLAIMED_GREY: 1,
  CLAIMED_INFO: 1,
};

export const Border: React.FC<BorderProps> = observer(({hexState}) => {
  const {borderColor} = useMinerColors(hexState);

  const style = useLegacyOpacityAnimation(OPACITIES, hexState);

  if (style.opacity === 0) {
    return null;
  }

  return (
    <Path
      opacity={style.opacity}
      fillRule="evenodd"
      clipRule="evenodd"
      fillOpacity={0}
      stroke={borderColor}
      d="M4.42623 16.5349L28.4262 2.28491C30.6292 0.976892 33.3708 0.976892 35.5738 2.28491L59.5738 16.5349C61.6979 17.7961 63 20.0836 63 22.5539V51.4461C63 53.9164 61.6979 56.2039 59.5738 57.4651L35.5738 71.7151C33.3708 73.0231 30.6292 73.0231 28.4262 71.7151L4.42623 57.4651C2.30209 56.2039 1 53.9164 1 51.4461V22.5539C1 20.0836 2.30209 17.7961 4.42623 16.5349Z"
    />
  );
});
