import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon} from '@youtoken/ui.icons';
import {NewReferralOverviewResource} from '@youtoken/ui.resource-new-referral';

const StepIconWrapper: React.FC<BoxProps> = ({children, ...boxProps}) => {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      width={32}
      height={32}
      borderRadius={32}
      backgroundColor="$interactive-02"
      {...boxProps}
    >
      {children}
    </Box>
  );
};

const Link: React.FC<BoxProps> = boxProps => {
  return (
    <Box
      position="absolute"
      left={15}
      bottom={-14}
      width={2}
      height={12}
      backgroundColor="$interactive-02"
      {...boxProps}
    />
  );
};

export const Steps: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    overview: {
      conditions: {percentFormatted},
    },
  } = NewReferralOverviewResource.use({});

  return (
    <Box {...boxProps}>
      <Box flexDirection="row" alignItems="center" mb={16}>
        <StepIconWrapper mr={8}>
          <Text variant="$body-01-high-accent" color="$interactive-01">
            1
          </Text>
        </StepIconWrapper>
        <Text variant="$body-02" style={{flex: 1}}>
          {t('surface.new_referral.invite_friends.step_1')}
        </Text>
        <Link />
      </Box>
      <Box flexDirection="row" alignItems="center" mb={16}>
        <StepIconWrapper mr={8}>
          <Text variant="$body-01-high-accent" color="$interactive-01">
            2
          </Text>
        </StepIconWrapper>
        <Text variant="$body-02" style={{flex: 1}}>
          {t('surface.new_referral.invite_friends.step_2')}
        </Text>
        <Link />
      </Box>
      <Box flexDirection="row" alignItems="center">
        <StepIconWrapper mr={8}>
          <Icon name="gift" color="$interactive-01" />
        </StepIconWrapper>
        <Text variant="$body-02" style={{flex: 1}}>
          {t('surface.new_referral.invite_friends.step_3', {
            percent: percentFormatted,
          })}
        </Text>
      </Box>
    </Box>
  );
});
