import * as React from 'react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';

type PendingFriendStepProps = {
  index: number;
  title: string;
  completed: boolean;
};

export const PendingFriendStep: React.FC<PendingFriendStepProps & BoxProps> = ({
  index,
  title,
  completed,
  ...boxProps
}) => {
  const {t} = useTranslation();
  const color = completed ? '$success-01' : '$attention-01';

  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      borderWidth={1}
      borderColor="$ui-01"
      borderRadius={12}
      px={16}
      py={12}
      {...boxProps}
    >
      <Box flexDirection="column" flexShrink={1}>
        <Text
          variant="$body-02"
          color="$text-02"
          mb={2}
          testID={`STEP_${index}`}
        >
          {t('surface.new_referral.pending_friend.step')} {index}
        </Text>
        <Text>{title}</Text>
      </Box>

      <Box
        flexDirection="row"
        alignItems="center"
        testID={`STEP_${index}_STATUS_${completed ? 'COMPLETED' : 'PENDING'}`}
        flexGrow={1}
        ml={6}
        justifyContent="flex-end"
      >
        <Text color={color} mr={4}>
          {completed
            ? t('surface.new_referral.pending_friend.status.completed')
            : t('surface.new_referral.pending_friend.status.pending')}
        </Text>
        <Icon name={completed ? 'check' : 'pending'} color={color} />
      </Box>
    </Box>
  );
};
