import {computed, observable} from 'mobx';
import {serializable, primitive, object} from 'serializr';
import {Big} from 'big.js';
import {bigNumber} from '@youtoken/ui.utils-serializr';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {ENVIRONMENT} from '@youtoken/ui.environment';

export class Conditions {
  @observable
  @serializable(primitive())
  available!: boolean;

  @observable
  @serializable(primitive())
  percent!: number;

  @computed get percentFormatted() {
    return (this.percent * 100).toFixed();
  }

  @observable
  @serializable(primitive())
  stepAmountUSD!: number;

  @observable
  @serializable(primitive())
  welcomeSparks!: number;
}

export class NewReferralOverviewResponse {
  @observable
  @serializable(object(Conditions))
  conditions!: Conditions;

  @observable
  @serializable(primitive())
  new!: number;

  @observable
  @serializable(primitive())
  referralCode!: string;

  @computed get referralLink() {
    return `${ENVIRONMENT.WEB_APP_URL}/sign-up?ref=${this.referralCode}`;
  }

  @observable
  @serializable(bigNumber())
  rewardBTC!: Big;

  @computed get rewardBTCFormatted() {
    return formatByTicker(this.rewardBTC, 'btc');
  }

  @observable
  @serializable(bigNumber())
  rewardUSD!: Big;

  @computed get rewardUSDFormatted() {
    return formatByTicker(this.rewardUSD, 'usd');
  }
}
