import * as React from 'react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Wallet} from '@youtoken/ui.resource-wallets';

export const useSectionItemHandlePress = ({ticker}: Wallet) => {
  return React.useCallback(() => {
    DATA_LAYER.trackStrict('wallet-open', {ticker});

    SHARED_ROUTER_SERVICE.navigate('WalletsItem', {
      ticker,
    });
  }, [ticker]);
};
