import {i18n, type TKey} from '@youtoken/ui.service-i18n';
import {Version as WalletVersion} from '@youtoken/ui.resource-wallets';
import {BigNumber} from 'bignumber.js';

import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {toBig} from '@youtoken/ui.formatting-utils';

export type Version = string | null;

export const mapVersionToText: {[key: string]: string} = {
  trx: 'TRON',
  'trx:origin': 'TRON',
  'btc:origin': 'Main',
  bech32: 'SegWit',
  omni: 'OMNI',
  erc20: 'ERC20',
  bep20: 'BEP20',
  trc20: 'TRC20',
};

export const pluralMap: Record<number, TKey> = {
  1: 'surface.common.number_of_ways.count.one',
  2: 'surface.common.number_of_ways.count.two',
  3: 'surface.common.number_of_ways.count.three',
};

export const numberOfWays = (
  versions: (Version | undefined)[],
  t: typeof i18n.t
) => {
  const cleanVersions = versions.filter(Boolean);

  const number_key = pluralMap[cleanVersions.length];

  return t('surface.common.number_of_ways.template', {
    count: cleanVersions.length,
    count_value: number_key ? t(number_key) : cleanVersions.length,
  });
};

export const getVersionText = (ticker: string, version: Version) => {
  if (version === null) {
    return 'null';
  }

  return (
    mapVersionToText[`${ticker}:${version}`] ||
    mapVersionToText[version] ||
    version.toUpperCase()
  );
};

export const getSupportedVersionsText = (
  versionsNames: (Version | undefined)[] = [],
  t: typeof i18n.t
) => {
  const fullVersions = versionsNames.filter(Boolean);

  if (!fullVersions.length) {
    return '';
  }

  if (fullVersions.length === 1) {
    return fullVersions[0];
  }

  return `${fullVersions.slice(0, -1).join(', ')} ${t(
    'surface.common.supported_versions.and'
  )} ${fullVersions.slice(-1)}`;
};

export const getExtraIdNameByTicker = (ticker: string) => {
  switch (ticker) {
    case 'eos':
      return 'memo';
    case 'xlm':
    case 'bnb':
      return 'memo_id';
    case 'ton':
      return 'destination_tag_comment';
    case 'xrp':
    default:
      return 'destination_tag';
  }
};

export const convertLedgerBalanceToAmountString = (
  ticker: string,
  balance: BigNumber,
  magnitude: number
) => {
  return toBig(balance)
    .div(toBig(10).pow(magnitude))
    .round(getCoinDecimalPrecision(ticker))
    .toFixed();
};

export const walletVersionsToItems = (
  versions: Array<WalletVersion>,
  testIdTemplate = 'VERSION_ITEM_{{version}}'
) => {
  return versions.map(v => ({
    type: 'item' as const,
    label: v.name,
    value: v.version,
    address: v.address,
    extraId: v.extraId,
    testID: testIdTemplate.replace(
      '{{version}}',
      v.name?.toUpperCase() ?? 'null'
    ),
  }));
};
