import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, useIsDesktop} from '@youtoken/ui.primitives';
import {ActionsProps} from './types';
import {
  Deposit as DepositBase,
  Withdraw as WithdrawBase,
  Convert as ConvertBase,
  Trade as TradeBase,
} from './components';

const variant = 'full';

export const WalletActionsFull: React.FC<
  Omit<ActionsProps, 'variant'> & BoxProps
> = observer(({item, ...boxProps}) => {
  const {t} = useTranslation();

  const isDesktop = useIsDesktop();

  const Deposit = (
    <Box flex={1}>
      <DepositBase variant={variant} item={item}>
        {t('surface.wallets.common.deposit_button')}
      </DepositBase>
    </Box>
  );
  const Withdraw = (
    <Box flex={1}>
      <WithdrawBase variant={variant} item={item}>
        {t('surface.wallets.common.withdraw_button')}
      </WithdrawBase>
    </Box>
  );
  const Convert = (
    <Box flex={1}>
      <ConvertBase variant={variant} item={item}>
        {t('surface.wallets.common.convert_button')}
      </ConvertBase>
    </Box>
  );
  const Trade = (
    <Box flex={1}>
      <TradeBase variant={variant} item={item}>
        {t('surface.wallets.common.boost_button')}
      </TradeBase>
    </Box>
  );

  if (isDesktop) {
    return (
      <Box flexDirection="row" alignItems="center" gap={10} {...boxProps}>
        {Deposit}
        {Withdraw}
        {Convert}
        {Trade}
      </Box>
    );
  }

  return (
    <Box gap={10} {...boxProps}>
      <Box flexDirection="row" alignItems="center" gap={10}>
        {Deposit}
        {Withdraw}
      </Box>
      <Box flexDirection="row" alignItems="center" gap={10}>
        {Convert}
        {Trade}
      </Box>
    </Box>
  );
});
