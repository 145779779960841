import {type BoxProps} from '@youtoken/ui.primitives';
import {ISection, ISections} from '../types';
import {getSectionColor} from './getSectionColor';

export const getSectionWalletGroupItemBoxProps = (
  sections: ISection[],
  section: ISection,
  sectionItems: ISections[keyof ISections],
  sectionIndex: number,
  sectionItemIndex: number
): BoxProps => {
  const isLastSection = sectionIndex === sections.length - 1;

  const isFirstSectionItem = sectionItemIndex === 0;
  const isLastSectionItem = sectionItemIndex === sectionItems.length - 1;

  return {
    bg: getSectionColor(section),
    borderColor: '$ui-01',
    borderTopWidth: isFirstSectionItem ? 0 : 1,
    borderBottomWidth: isLastSection && isLastSectionItem ? 1 : 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomLeftRadius: {
      default: 0,
      desktop: isLastSection && isLastSectionItem ? 10 : 0,
    },
    borderBottomRightRadius: {
      default: 0,
      desktop: isLastSection && isLastSectionItem ? 10 : 0,
    },
  };
};
