export function assignHeader(
  headers: Record<string, string>,
  name: string,
  getValue: () => string | undefined | null
): void {
  const value = getValue();

  if (value) {
    headers[name] = value;
  }
}
