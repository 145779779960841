import React from 'react';
import {Icon} from '@youtoken/ui.icons';
import {Text} from '@youtoken/ui.primitives';

export const RequisiteCopyIcon: React.FC = () => {
  return (
    <Text style={{verticalAlign: 'middle'}}>
      <Icon name="copy" color="$text-05" />
    </Text>
  );
};
