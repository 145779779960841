import {computed} from 'mobx';
import {deserialize} from 'serializr';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {OnboardingWidgetResponse} from './OnboardingWidgetResponse';

export type {CoindropPromoParams} from './OnboardingWidgetResponse';
export type {StepItemType} from './types';

export class OnboardingWidgetResource extends createResource({
  getKey: () => 'onboardingWidget',
  getData: () =>
    TRANSPORT.API.get('/v1/onboarding/widget').then(({data}) => {
      return deserialize(OnboardingWidgetResponse, data);
    }),
  skipRefreshOnVisible: false,
}) {
  @computed get promo() {
    return this.data.promo;
  }
}
