import * as React from 'react';
import {observer} from 'mobx-react';
import {Label, useForm} from '@youtoken/ui.form-elements';
import {Box, type BoxProps, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon, LogoColored} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Logo} from '@youtoken/ui.elements';
import {
  BuyCryptoFormAuthorizedOnRampState,
  BuyCryptoFormAuthorizedYouHodlerState,
} from '../../state';

export interface PayWithFieldProps {
  isActive?: boolean;
  onPress: () => void;
}

export const PayWithField: React.FC<PayWithFieldProps & BoxProps> = observer(
  ({onPress, isActive = false, ...boxProps}) => {
    const {t} = useTranslation();
    const {
      view: {
        currentDepositMethod,
        shouldShowDepositMethodSelector,
        shouldDisableDepositMethodSelector,
      },
    } = useForm<
      BuyCryptoFormAuthorizedOnRampState | BuyCryptoFormAuthorizedYouHodlerState
    >();

    if (!currentDepositMethod || !shouldShowDepositMethodSelector) {
      return null;
    }

    const {title, iconName} = currentDepositMethod;

    const IconElement = React.useMemo(() => {
      switch (iconName) {
        case 'yh_balance':
          return (
            <Box height={20} width={28} ml={-8}>
              <Logo hideTextPermanently />
            </Box>
          );
        case 'advcash':
          return <LogoColored name={iconName} size={20} />;
        case 'bank_wire':
        case 'card':
          return <Icon name={iconName} />;
        default:
          return null;
      }
    }, [iconName]);

    return (
      <Box {...boxProps}>
        <Label>{t('ramp.conversion_form.label.pay_with')}</Label>
        <TouchableBox
          mt={10}
          px={16}
          height={48}
          flexDirection="row"
          borderWidth={1}
          borderRadius={6}
          borderColor="$ui-02"
          alignItems="center"
          onPress={shouldDisableDepositMethodSelector ? undefined : onPress}
          testID="WIZARD_PAY_WITH_BUTTON"
          disabled={shouldDisableDepositMethodSelector}
        >
          <Box mr={8}>{IconElement}</Box>
          <Box flexDirection="column" flexGrow={1} flexBasis={0}>
            <Text variant="$body-01-medium-accent" color="$text-01">
              {title}
            </Text>
          </Box>
          {!shouldDisableDepositMethodSelector && (
            <Box justifyContent="center">
              <Icon name={isActive ? 'collapse' : 'expand'} color="$text-02" />
            </Box>
          )}
        </TouchableBox>
      </Box>
    );
  }
);
