import * as React from 'react';
import {observer} from 'mobx-react';
import {LoyaltyIcon, LoyaltyIconName, Icon} from '@youtoken/ui.icons';
import {
  Box,
  Text,
  type TColorTokens,
  useIsMobile,
} from '@youtoken/ui.primitives';
import {LoyaltyLevel} from '@youtoken/ui.resource-loyalty';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {CardParameter} from './CardParameter';

interface CardLevelProps {
  icon: LoyaltyIconName;
  levelTitle: string;
  level: LoyaltyLevel;
  textColor: keyof TColorTokens;
}

export const CardHeader: React.FC<CardLevelProps> = observer(
  ({icon, levelTitle, level, textColor}) => {
    const {t} = useTranslation();

    const isMobile = useIsMobile();
    const parameterTextVariant = React.useMemo(
      () => (isMobile ? '$heading-02' : '$body-01-high-accent'),
      [isMobile]
    );

    return (
      <Box flexDirection={{default: 'column', tablet: 'row'}}>
        <Box flexDirection="row" mb={{default: 20, tablet: 0}}>
          <Box
            justifyContent="center"
            alignItems="center"
            width={{default: 42, tablet: 36}}
            height={{default: 42, tablet: 36}}
            backgroundColor="$ui-background"
            borderRadius={30}
            mr={8}
          >
            <LoyaltyIcon name={icon} size={24} />
          </Box>
          {isMobile && (
            <CardParameter textColor={textColor} title={levelTitle}>
              <Text
                color={textColor}
                variant={parameterTextVariant}
                testID={`MINER_LEVEL_${level.level}_NAME`}
              >
                {level.name}
              </Text>
            </CardParameter>
          )}
        </Box>
        <Box
          flexDirection={{default: 'column', tablet: 'row'}}
          justifyContent="space-between"
          flex={{default: undefined, tablet: 1}}
          gap={{default: 16, tablet: 0}}
        >
          {!isMobile && (
            <CardParameter textColor={textColor} title={levelTitle}>
              <Text
                testID={`MINER_LEVEL_${level.level}_NAME`}
                color={textColor}
                variant={parameterTextVariant}
              >
                {level.name}
              </Text>
            </CardParameter>
          )}
          <CardParameter
            textColor={textColor}
            title={t('surface.miner.cards.monthly_profit')}
          >
            <Text
              color={textColor}
              variant={parameterTextVariant}
              testID={`MINER_LEVEL_${level.level}_EARN_LIMIT`}
            >
              {t('surface.miner.cards.up_to', {
                amount: level.miner.earnLimitUSDFormatted,
              })}
            </Text>
          </CardParameter>
          <CardParameter
            textColor={textColor}
            title={t('surface.miner.loyalty.welcome-pack')}
          >
            <Box flexDirection="row" alignItems="center">
              <Icon color={textColor} name="spark" size={18} />
              <Text
                color={textColor}
                variant={parameterTextVariant}
                ml={2}
                testID={`MINER_LEVEL_${level.level}_WELCOME_PACK`}
              >
                {level.miner.welcomePack}
              </Text>
            </Box>
          </CardParameter>
          <CardParameter
            textColor={textColor}
            title={t('surface.miner.cards.free_sparks')}
          >
            <Box flexDirection="row" alignItems="center">
              <Icon color={textColor} name="spark" size={18} />
              <Text
                color={textColor}
                variant={parameterTextVariant}
                ml={2}
                testID={`MINER_LEVEL_${level.level}_FREE_SPARKS_PERIOD`}
              >
                {level.miner.freeSparksPeriodLokalised}
              </Text>
            </Box>
          </CardParameter>
          <CardParameter
            textColor={textColor}
            title={t('surface.miner.cards.blocks')}
          >
            <Box flexDirection="row" alignItems="center">
              <Icon color={textColor} name="miner_block" />
              <Text
                color={textColor}
                variant={parameterTextVariant}
                ml={2}
                testID={`MINER_LEVEL_${level.level}_BLOCKS`}
              >
                {level.miner.blocksAvailable}
              </Text>
            </Box>
          </CardParameter>
        </Box>
      </Box>
    );
  }
);
