import * as React from 'react';
import {observer} from 'mobx-react';
import {Text, TextProps} from 'react-native-svg';
import {useCountdown} from '@youtoken/ui.hooks';
import {FEBlockState} from '@youtoken/ui.resources-miner';
import {useLegacyOpacityAnimation, useMinerColors} from '../../hooks';
import {formatMilliseconds} from '../utils';
import {useTheme} from '@youtoken/ui.primitives';

export type TimerProps = {
  hexState: FEBlockState;
  timeLeft: number;
  onTimerEnd: () => void;
} & TextProps;

const OPACITIES: Record<FEBlockState, number> = {
  DISABLED: 0,
  AVAILABLE_NOT_ENOUGH_SPARKS: 0,
  AVAILABLE_ENOUGH_SPARKS: 0,
  MINING_STARTING: 0,
  MINING: 1,
  READY: 0,
  CLAIMING_STARTING: 0,
  CLAIMING: 0,
  CLAIMED_COLORED: 0,
  CLAIMED_GREY: 0,
  CLAIMED_INFO: 0,
};

const FONT_SIZE = 12;

export const Timer: React.FC<TimerProps> = observer(
  ({hexState, timeLeft, onTimerEnd, ...rest}) => {
    const {textColor} = useMinerColors(hexState);
    const [number, {start}] = useCountdown(timeLeft, 1000, onTimerEnd);

    React.useEffect(() => {
      if (hexState === 'MINING' && timeLeft > 0) {
        start(timeLeft);
      }
    }, [timeLeft, hexState]);

    const style = useLegacyOpacityAnimation(OPACITIES, hexState);

    const {fonts} = useTheme();

    if (style.opacity === 0) {
      return null;
    }

    return (
      <Text
        opacity={style.opacity}
        x={0}
        y={FONT_SIZE / 3.3}
        fill={textColor}
        textAnchor="middle"
        fontSize={FONT_SIZE}
        fontFamily={fonts.normal.regular}
        fontWeight={500}
        {...rest}
      >
        {formatMilliseconds(number)}
      </Text>
    );
  }
);
