import * as React from 'react';
import {FlashList, ListRenderItemInfo} from '@shopify/flash-list';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {NewReferralActivity} from '@youtoken/ui.resource-new-referral';
import {ActivitiesListItem} from './ActivitiesListItem';

type ActivitiesListProps = {
  activities: NewReferralActivity[];
  onPressActivity?: (activity: NewReferralActivity) => void;
};

export const ActivitiesList: React.FC<ActivitiesListProps & BoxProps> = ({
  activities,
  onPressActivity,
  ...boxProps
}) => {
  const renderItem = ({
    item,
    index,
  }: ListRenderItemInfo<NewReferralActivity>) => {
    return (
      <ActivitiesListItem
        activity={item}
        onPress={onPressActivity}
        mb={8}
        testID={`ACTIVITY_ITEM_${index}`}
      />
    );
  };

  return (
    <Box {...boxProps}>
      <FlashList
        data={activities}
        renderItem={renderItem}
        estimatedListSize={{height: 740, width: 670}}
        estimatedItemSize={64}
      />
    </Box>
  );
};
