import {invariant} from '@youtoken/ui.utils';
import {ScopeContext} from '@sentry/types';
import {
  AxiosError as OriginalAxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  Cancel,
} from 'axios';
import {WithCustomErrorProps} from '../CustomError';
import {ERROR_TYPE} from '../ERROR_TYPE';
import {
  getQueryParams,
  isRecaptchaError,
  pickErrorType,
  sanitizeUrl,
} from './utils';
export {isRecaptchaError, pickErrorType};

function getErrorMessage(
  message: string,
  request?: any,
  response?: AxiosResponse,
  config?: AxiosRequestConfig
): string {
  const status = response?.status ?? message;
  const method = config?.method?.toUpperCase() ?? 'UNKNOWN_METHOD';
  const url = sanitizeUrl(request?.url || config?.url);
  return `[${status}] ${method} ${url}`;
}

export class AxiosError<T extends any = any>
  extends Error
  implements WithCustomErrorProps, OriginalAxiosError<T>
{
  __isCustomError!: boolean;
  __type!: ERROR_TYPE;
  __sentry!: Partial<ScopeContext>;

  config!: AxiosRequestConfig;
  code?: string;
  request?: any;
  response?: AxiosResponse<T>;

  // original toJSON
  toJSON() {
    return {
      // Standard
      message: this.message,
      name: this.name,
      // Microsoft
      // @ts-ignore
      description: this.description,
      // @ts-ignore
      number: this.number,
      // Mozilla
      // @ts-ignore
      fileName: this.fileName,
      // @ts-ignore
      lineNumber: this.lineNumber,
      // @ts-ignore
      columnNumber: this.columnNumber,
      stack: this.stack,
      // Axios
      config: this.config,
      code: this.code,
    };
  }

  // axios error check from original type
  isAxiosError: true = true;

  constructor(
    message: string,
    type: ERROR_TYPE = ERROR_TYPE.NETWORK_ERROR,
    sentryContext: Partial<ScopeContext> = {}
  ) {
    super(message);
    Object.setPrototypeOf(this, AxiosError.prototype); // restore prototype chain
    this.name = `AxiosError`;
    this.__isCustomError = true;
    this.__type = type;
    this.__sentry = sentryContext;
  }
}

export const wrapAxiosError = <T extends any>(
  originalAxiosError: OriginalAxiosError<T> | Cancel
) => {
  if (originalAxiosError.message === '__CANCELLED_REQUEST__') {
    return new AxiosError(
      originalAxiosError.message,
      ERROR_TYPE.CANCELLED_REQUEST_ERROR,
      {
        tags: {
          isAxiosError: true,
          isRequestError: true,
        },
      }
    );
  }

  invariant(
    originalAxiosError instanceof Error,
    `Cant create AxiosError. Must provide an error object. Value: ${JSON.stringify(
      originalAxiosError
    )}`
  );

  const {
    message: originalMessage,
    code,
    stack,
    config,
    request,
    response,
  } = originalAxiosError as OriginalAxiosError<T>;

  const message = getErrorMessage(originalMessage, request, response, config);

  const fullUrl = request?.responseURL || config?.url;
  const sanitizedUrl = sanitizeUrl(fullUrl);

  const newAxiosError = new AxiosError<T>(
    message,
    pickErrorType(originalAxiosError as OriginalAxiosError<T>),
    {
      tags: {
        isAxiosError: true,
        isRequestError: !Boolean(response),
        isResponseError: Boolean(response),
        url: sanitizedUrl,
        status: response?.status,
      },
      extra: {
        url: fullUrl,
        query: getQueryParams(request?.url || config?.url),
      },
      fingerprint: [
        String(config.method),
        sanitizedUrl,
        String(response?.status) ?? 'no-status',
      ],
      level: response?.status && response.status >= 500 ? 'error' : 'warning',
    }
  );
  newAxiosError.name = `AxiosError`;
  newAxiosError.stack = stack;
  newAxiosError.code = code;
  newAxiosError.config = config;
  newAxiosError.request = request;
  newAxiosError.response = response;

  return newAxiosError;
};

// catches, enhances AxiosError and throws it back;
export function enhanceAndRethrowError(error: OriginalAxiosError) {
  throw wrapAxiosError(error);
}
