import {observable} from 'mobx';
import {serializable, primitive, list, object} from 'serializr';
import {NewReferralActivity} from '../NewReferralActivityResource';

export class NewReferralInvitedResponse {
  @observable
  @serializable(list(object(NewReferralActivity)))
  invited!: NewReferralActivity[];

  @observable
  @serializable(primitive())
  total!: number;
}
