import * as React from 'react';
import {observer} from 'mobx-react';
import {
  Box,
  TouchableBox,
  type BoxProps,
  Text,
  Heading,
} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {OnboardingStepPerksModal} from '../../OnboardingStepPerks';
import {OnboardingWidgetFeature} from '../state';
import {OnboardingStep} from '@youtoken/ui.resource-onboarding-step-perks';
import {StepItemType} from '@youtoken/ui.resource-onboarding-widget';

const stepToActions: Partial<Record<StepItemType, OnboardingStep[]>> = {
  VERIFICATIONS: ['verification', 'deposit'],
  DEPOSIT: ['deposit'],
};

export const TitleWithLearnMore: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {activeStep} = OnboardingWidgetFeature.use({});

  const [isModalOpen, setModalOpen] = React.useState(false);

  const openModal = React.useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = React.useCallback(() => {
    setModalOpen(false);
  }, []);

  const steps: OnboardingStep[] = React.useMemo(() => {
    if (!activeStep?.type) {
      return [];
    }
    return stepToActions[activeStep.type] || [];
  }, [activeStep]);

  return (
    <>
      <Box
        alignItems="center"
        justifyContent="center"
        width="100%"
        {...boxProps}
      >
        <Heading variant="$heading-02" mb={8}>
          {t('surface.onboarding_widget.regular_content.title')}
        </Heading>
        <TouchableBox
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          py={4}
          px={8}
          onPress={openModal}
        >
          <Text variant="$body-01-high-accent" color="$text-05">
            {t('common.actions.learn_more')}
          </Text>
        </TouchableBox>
      </Box>
      <OnboardingStepPerksModal
        steps={steps}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
});
