import * as React from 'react';
import {Box, TouchableBox, Text, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  NewReferralActivity,
  NewReferralActivityEvent,
} from '@youtoken/ui.resource-new-referral';

type InvitedListItemProps = {
  activity: NewReferralActivity;
  onPress?: (activity: NewReferralActivity) => void;
};

export const InvitedListItem: React.FC<InvitedListItemProps & BoxProps> = ({
  activity,
  onPress,
  ...boxProps
}) => {
  const {t} = useTranslation();

  return (
    <TouchableBox
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      borderWidth={1}
      borderColor="$ui-01"
      borderRadius={12}
      px={16}
      py={12}
      onPress={() => onPress?.(activity)}
      {...boxProps}
    >
      <Box flexDirection="row" alignItems="center">
        <Box
          width={40}
          height={40}
          borderRadius={20}
          mr={12}
          backgroundColor="$attention-02"
          justifyContent="center"
          alignItems="center"
        >
          <Icon name="new_referral" size={24} color="$attention-01" />
        </Box>
        <Box flexDirection="column">
          <Text testID="ACTIVITY_INVITEE_NAME">
            {activity.inviteeName || activity.inviteeEmail}
          </Text>
          <Text variant="$body-02" color="$text-02">
            {activity.createdAtFormatted}
          </Text>
        </Box>
      </Box>

      <Box flexDirection="row" alignItems="center" testID="ACTIVITY_STATUS">
        <Text variant="$body-02" color="$attention-01" mr={4}>
          {activity.event === NewReferralActivityEvent.REGISTRATION
            ? t('surface.new_referral.pending_friend.status.signed_up')
            : t('surface.new_referral.pending_friend.status.verified')}
        </Text>
        <Icon name="pending" size={20} color="$attention-01" />
      </Box>
    </TouchableBox>
  );
};
