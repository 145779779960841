import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {Select} from '@youtoken/ui.select';
import {type ActionsProps} from './types';
import {useWalletActionsAdditionalProps} from './hooks';

export const WalletActionsAdditionalSelect: React.FC<ActionsProps & BoxProps> =
  observer(({item, ...boxProps}) => {
    // NOTE: useWalletActionsAdditionalProps uses mobx
    const [items, itemsAction] = useWalletActionsAdditionalProps(item);

    return (
      <Box {...boxProps}>
        <Select
          size="small"
          items={items}
          onSelect={itemsAction}
          dropdownWidth={200}
          dropdownDirection="top"
          withBorder={false}
          withExpandIcon={false}
          labelComponent={({open}) => {
            return (
              <Box mx={4} flex={1} justifyContent="center" alignItems="center">
                <Icon
                  name="actions"
                  color={open ? '$text-01' : '$text-03'}
                  size={16}
                />
              </Box>
            );
          }}
          width={32}
        />
      </Box>
    );
  });
