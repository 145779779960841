import {useCallback} from 'react';
import {createResource, useResource} from '@youtoken/ui.data-storage';
import {computedFn} from 'mobx-utils';
import {deserialize} from 'serializr';
import {
  VerificationItem,
  VerificationItemAccountCode,
  VerificationItemBasicCode,
  type VerificationItemCode,
  VerificationItemStatus,
  VerificationItemStatusesNegative,
  VerificationResponse,
} from './VerificationResponse';
import {isNegativeVerificationStatus} from './utils';
import {TRANSPORT} from '@youtoken/ui.transport';
import {computed, IReactionDisposer, reaction, runInAction} from 'mobx';
import {SIMPLE_STORAGE} from '@youtoken/ui.service-storage';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {i18n} from '@youtoken/ui.service-i18n';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {invariant} from '@youtoken/ui.utils';
import {GLOBAL} from '@youtoken/ui.service-global';
import {__GLOBAL_SUM_SUB_VERIFICATION__} from '@youtoken/ui.two-factor-auth-and-recaptcha';

export const ASYNC_STORAGE_KEY_FORM_A = 'FormA';

export const ASYNC_STORAGE_KEY_FORM_A_VERSION = '1.1';

export {
  VerificationItemBasicCode,
  VerificationItemAccountCode,
  VerificationItemStatus,
  VerificationItemStatusesNegative,
  isNegativeVerificationStatus,
};

export type {VerificationItemCode};

export interface VerificationResourceArgs {
  withFallback?: boolean;
}

export class VerificationResource extends createResource({
  skipRefreshOnVisible: false,
  cacheTime: 24 * 60 * 60 * 1000,
  getKey: ({withFallback}: VerificationResourceArgs) =>
    `verification${withFallback ? ':fallback' : ''}`,
  getData: ({withFallback}: VerificationResourceArgs) => {
    if (withFallback) {
      return Promise.resolve(
        deserialize(VerificationResponse, {
          verifications: [],
          data: {},
        })
      );
    }

    return TRANSPORT.API.get('/v3/kyc/verifications')
      .then(res => {
        return deserialize(VerificationResponse, res.data);
      })
      .then(response => {
        return Promise.resolve(
          SIMPLE_STORAGE.get(ASYNC_STORAGE_KEY_FORM_A)
        ).then(result => {
          try {
            const _data = JSON.parse(result || '');

            if (
              _data.version !== ASYNC_STORAGE_KEY_FORM_A_VERSION ||
              _data.email !== response.data.email ||
              _data.status !==
                getVerificationStatusByCode(
                  VerificationItemAccountCode.FORM_A,
                  response.verifications
                )
            ) {
              SIMPLE_STORAGE.delete(ASYNC_STORAGE_KEY_FORM_A);
            }
          } catch (e) {}

          return response;
        });
      })
      .catch(error => {
        if (withFallback) {
          return {
            verifications: [],
            // TODO: this is so ugly
            // fuck this shit
            data: {
              email: '',
              addressType: null,
            },
          } as VerificationResponse;
        } else {
          throw error;
        }
      });
  },
}) {
  static launchVerification = (levelName: string) => {
    __GLOBAL_SUM_SUB_VERIFICATION__.launch(levelName);
  };

  static launchIdentityVerification = () => {
    DATA_LAYER.trackStrict('verification-identity-attempt', {});

    this.launchVerification(
      VerificationResource.getInstance({}).getVerificationByCode(
        VerificationItemAccountCode.IDENTITY
      ).level!
    );
  };

  static launchAddressVerification = () => {
    DATA_LAYER.trackStrict('verification-address-attempt', {});

    this.launchVerification(
      VerificationResource.getInstance({}).getVerificationByCode(
        VerificationItemAccountCode.ADDRESS
      ).level!
    );
  };

  static launchFormAVerification = () => {
    SHARED_ROUTER_SERVICE.navigate('FormAVerificationModal');
  };

  disposers: Array<IReactionDisposer> = [];

  constructor(args: VerificationResourceArgs, data: VerificationResponse) {
    super(args, data);

    this.disposers = [
      reaction(
        () => TRANSPORT.SOCKET,
        () => {
          this.unsubscribeSocketVerificationState();
          if (GLOBAL.isAuthorized) {
            this.subscribeSocketVerificationState();
          }
        },
        {
          fireImmediately: true,
        }
      ),
    ];
  }

  onDestroy(): void {
    super.onDestroy();

    this.disposers?.forEach(disposer => disposer?.());
  }

  //#regionstart SocketVerificationState

  protected subscribeSocketVerificationState = () => {
    this.connectSocketVerificationState();
    TRANSPORT.SOCKET.on('connect', this.connectSocketVerificationState);
    TRANSPORT.SOCKET.on(
      'verificationState',
      this.handleMessageSocketVerificationState
    );
  };

  protected unsubscribeSocketVerificationState = () => {
    this.disconnectSocketVerificationState();
    TRANSPORT.SOCKET.off('connect', this.connectSocketVerificationState);
    TRANSPORT.SOCKET.off(
      'verificationState',
      this.handleMessageSocketVerificationState
    );
  };

  protected connectSocketVerificationState = () => {
    TRANSPORT.SOCKET.emit('sub', {
      name: 'verificationState',
    });
  };

  protected disconnectSocketVerificationState = () => {
    TRANSPORT.SOCKET.emit('unsub', {
      name: 'verificationState',
    });
  };

  protected handleMessageSocketVerificationState = (data: any) => {
    runInAction(() => {
      this.data = deserialize(VerificationResponse, data);
    });
  };

  //#endregion SocketVerificationState

  @computed
  public get accountVerificationCodes(): VerificationItemAccountCode[] {
    return [
      VerificationItemAccountCode.IDENTITY,
      VerificationItemAccountCode.ADDRESS,
    ];
  }

  @computed
  public get accountVerificationCurrent():
    | VerificationItemAccountCode
    | undefined {
    let actualTier = undefined;

    for (let i = 0; i < this.accountVerificationCodes.length; i++) {
      const status = this.getVerificationStatusByCode(
        this.accountVerificationCodes[i]!
      );
      if (status === VerificationItemStatus.SKIP) {
        continue;
      }
      if (status !== VerificationItemStatus.ACCEPT) {
        actualTier = this.accountVerificationCodes[i];
        break;
      }
    }

    return actualTier;
  }

  @computed
  public get accountVerificationComplete() {
    return this.accountVerificationCurrent === undefined;
  }

  @computed
  public get hasToReverify() {
    return this.data.verifications.some(
      v => v.status === VerificationItemStatus.RE_VERIFY
    );
  }

  @computed
  public get hasKycOrFormABlocker() {
    const verificationIdentityResult = this.getVerificationStatusByCode(
      VerificationItemAccountCode.IDENTITY
    );
    const verificationFormAResult = this.getVerificationStatusByCode(
      VerificationItemAccountCode.FORM_A
    );

    return !(
      verificationIdentityResult === VerificationItemStatus.ACCEPT &&
      [VerificationItemStatus.ACCEPT, VerificationItemStatus.SKIP].includes(
        verificationFormAResult as VerificationItemStatus
      )
    );
  }

  public getVerificationByCode = computedFn((code: VerificationItemCode) => {
    return getVerificationByCode(code, this.data.verifications);
  });

  public getVerificationStatusByCode = computedFn(
    (code: VerificationItemCode) => {
      return getVerificationStatusByCode(code, this.data.verifications);
    }
  );
}

export const verificationCodeActions = {
  [VerificationItemAccountCode.IDENTITY]:
    VerificationResource.launchIdentityVerification,
  [VerificationItemAccountCode.ADDRESS]:
    VerificationResource.launchAddressVerification,
  [VerificationItemAccountCode.FORM_A]:
    VerificationResource.launchFormAVerification,
};

export const getVerificationByCode = computedFn(
  (code: VerificationItemCode, verifications: VerificationItem[]) => {
    const item = verifications.find(item => item.code === code);

    invariant(
      item,
      `Verification item with code ${code} not found`,
      {
        code,
      },
      {
        code,
        verifications,
      }
    );

    return item;
  }
);

export const getVerificationStatusByCode = (
  code: VerificationItemCode,
  verifications: VerificationItem[]
) => {
  return getVerificationByCode(code, verifications)?.status;
};

export const getFirstAccountVerificationWithError = (
  verificationResource: VerificationResource,
  verificationCodes: VerificationItemAccountCode[]
): false | VerificationItem => {
  return verificationCodes.reduce((acc, code) => {
    if (!acc) {
      const verification = verificationResource.getVerificationByCode(code);

      if (
        verification &&
        ![
          VerificationItemStatus.ACCEPT,
          VerificationItemStatus.RE_VERIFY,
          VerificationItemStatus.RESUBMIT_ITA,
          VerificationItemStatus.SKIP,
        ].includes(
          verificationResource.getVerificationStatusByCode(
            verification.code
          ) as VerificationItemStatus
        )
      ) {
        const missingRequirements = verification.missingRequirements.filter(
          codeParent => {
            return Object.values(VerificationItemAccountCode).includes(
              codeParent as VerificationItemAccountCode
            );
          }
        ) as VerificationItemAccountCode[];

        const firstVerificationErrorParent =
          getFirstAccountVerificationWithError(
            verificationResource,
            missingRequirements
          );

        if (firstVerificationErrorParent !== false) {
          return firstVerificationErrorParent;
        }

        return verification;
      }
    }

    return acc;
  }, false as ReturnType<typeof getFirstAccountVerificationWithError>);
};

export const checkAccountVerificationsFallback = (
  verification: VerificationItem
) => {
  const codeMessages = {
    [VerificationItemAccountCode.FORM_A]: i18n.t(
      'validation.MISSING_FORM_A_SIGNED'
    ),
    [VerificationItemAccountCode.IDENTITY]: i18n.t(
      'surface.profile.verification.identity.fallback_msg'
    ),
    [VerificationItemAccountCode.ADDRESS]: i18n.t(
      'surface.profile.verification.address.fallback_msg'
    ),
  } as unknown as {[key in VerificationItemCode]: string};

  SHARED_ROUTER_SERVICE.navigate('__CloseModal');
  LOCAL_NOTIFICATIONS.error({text: codeMessages[verification.code]});

  if (
    [VerificationItemStatus.NOT_SET, VerificationItemStatus.NONE].includes(
      verification.status
    )
  ) {
    return verificationCodeActions[
      verification.code as VerificationItemAccountCode
    ]();
  }

  SHARED_ROUTER_SERVICE.navigate('Verification');
};

export const checkAccountVerifications = (
  verificationResource: VerificationResource,
  verificationCodes: VerificationItemAccountCode[]
) => {
  const verification = getFirstAccountVerificationWithError(
    verificationResource,
    verificationCodes
  );

  if (verification) {
    checkAccountVerificationsFallback(verification);

    return false;
  }

  return true;
};

export const useAccountVerificationChecker = (
  verificationCodes: VerificationItemAccountCode[],
  cb: (next: () => void) => void
) => {
  const verificationResource = useResource(VerificationResource, {});

  return useCallback(
    (next = () => {}) => {
      if (!checkAccountVerifications(verificationResource, verificationCodes)) {
        return Promise.resolve();
      }

      return cb(next);
    },
    [verificationResource, verificationCodes, cb]
  );
};
