import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box} from '@youtoken/ui.primitives';
import {CoindropBadge, CoinIconsBig} from '../../Coindrop';
import {type CoindropPromoParams} from '@youtoken/ui.resource-onboarding-widget';
import {warning} from '@youtoken/ui.utils';

type CoindropPromoProps = {
  params: CoindropPromoParams;
};

export const CoindropPromo: React.FC<CoindropPromoProps> = observer(
  ({params: {coins}}) => {
    const {t} = useTranslation();

    warning(coins.length, 'coins for coindrop are not set');

    return (
      <Box alignItems="center">
        <CoindropBadge mb={16} />
        {Boolean(coins.length) && <CoinIconsBig coins={coins} mb={16} />}
      </Box>
    );
  }
);
