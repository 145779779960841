import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HODLMarketStatsResource} from '@youtoken/ui.resource-hodl-market-stats';
import {TradingInstruments} from '../../components';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

const handlePressItem = () => {
  DATA_LAYER.trackStrict('hodl-open-instrument', {
    openedFrom: 'top_capitalization',
  });
};

export const TopCapSection: React.FC<BoxProps> = cell(
  boxProps => {
    const {t} = useTranslation();
    const {
      data: {data: marketStats},
    } = HODLMarketStatsResource.use({count: 10}); // Only 10 cards are needed according to the design

    if (marketStats.length === 0) {
      return null;
    }

    return (
      <TradingInstruments
        title={t('surface.hodls.portfolio.top_capitalization_title')}
        items={marketStats}
        onPressItem={handlePressItem}
        isExpandRestricted
        itemSubtitleVariant="capitalization"
        showCharts={false}
        mobileEstimatedListHeight={64}
        testID="TOPS_CAP_SECTION"
        {...boxProps}
      />
    );
  },
  {
    CellWrap: ({children, ...boxProps}) => {
      return <Box maxHeight={236}>{children}</Box>;
    },
  }
);
