import * as React from 'react';
import {observer} from 'mobx-react';
import {cell} from '@youtoken/ui.cell';
import {
  TouchableBanner,
  type TouchableBannerProps,
} from '@youtoken/ui.elements';
import {
  verificationCodeActions,
  VerificationItemAccountCode,
  VerificationItemStatus,
  VerificationResource,
} from '@youtoken/ui.resource-verification';
import type {BaseIconName} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';

export interface InfoProps
  extends Omit<
    TouchableBannerProps,
    'iconName' | 'variant' | 'text' | 'onPress' | 'type'
  > {}

export const InfoMessageWidget: React.FC<InfoProps> = cell(
  props => {
    const {accountVerificationCurrent} = VerificationResource.use({});

    if (!accountVerificationCurrent) {
      return null;
    }

    return <InfoMessageWidgetContent {...props} />;
  },
  {
    SuspenseFallback: () => null,
  }
);

export const InfoMessageWidgetContent: React.FC<InfoProps> = observer(props => {
  const {t} = useTranslation();

  const {getVerificationStatusByCode, accountVerificationCurrent} =
    VerificationResource.use({});

  // getVerificationStatusByCode has invariant if accountVerificationCurrent is undefined
  const status = getVerificationStatusByCode(accountVerificationCurrent!);

  const variant = React.useMemo(() => {
    if (
      [
        VerificationItemStatus.NONE,
        VerificationItemStatus.NOT_SET,
        VerificationItemStatus.IN_PROGRESS,
        VerificationItemStatus.RE_VERIFY,
      ].includes(status)
    ) {
      return 'interactiveSecondary';
    }
    if (
      [
        VerificationItemStatus.SEND,
        VerificationItemStatus.RE_VERIFY,
        VerificationItemStatus.RESUBMIT_ITA,
        VerificationItemStatus.MANUAL_REVIEW,
      ].includes(status)
    ) {
      return 'attentionSecondary';
    }
    if (
      [
        VerificationItemStatus.DENY,
        VerificationItemStatus.KYC_RESTRICTION,
        VerificationItemStatus.CHANGE_CITIZENSHIP_REQUIRED,
        VerificationItemStatus.ADDITIONAL_DOCS_REQUIRED,
      ].includes(status)
    ) {
      return 'dangerSecondary';
    }
    return 'interactive';
  }, [status]);

  const icon = React.useMemo(() => {
    if (status === VerificationItemStatus.KYC_RESTRICTION) {
      return 'lock';
    }
    return (
      (
        {
          [VerificationItemAccountCode.IDENTITY]: 'id_card',
          [VerificationItemAccountCode.ADDRESS]: 'doc_scan',
        } as {[key in VerificationItemAccountCode]?: BaseIconName}
      )[accountVerificationCurrent!] || 'doc_address'
    );
  }, [status, accountVerificationCurrent]);

  const text = React.useMemo(() => {
    let message;

    if (accountVerificationCurrent === VerificationItemAccountCode.IDENTITY) {
      message = (
        {
          [VerificationItemStatus.NONE]: t('info_message.verify_your_identity'),
          [VerificationItemStatus.NOT_SET]: t(
            'info_message.verify_your_identity'
          ),
          [VerificationItemStatus.IN_PROGRESS]: t(
            'info_message.identity.in_progress'
          ),
        } as {[key in VerificationItemStatus]?: string}
      )[status]!;
    }

    if (accountVerificationCurrent === VerificationItemAccountCode.ADDRESS) {
      message = (
        {
          [VerificationItemStatus.NONE]: t('info_message.verify_your_address'),
          [VerificationItemStatus.NOT_SET]: t(
            'info_message.verify_your_address'
          ),
          [VerificationItemStatus.IN_PROGRESS]: t(
            'info_message.address.in_progress'
          ),
        } as {[key in VerificationItemStatus]?: string}
      )[status];
    }

    if (!message) {
      message = (
        {
          [VerificationItemStatus.SEND]: t('info_message.under_review'),
          [VerificationItemStatus.RE_VERIFY]: t('info_message.reverify', {
            date: '1.02.2023',
          }),
          [VerificationItemStatus.RESUBMIT_ITA]: t('info_message.resubmit_ita'),
          [VerificationItemStatus.MANUAL_REVIEW]: t(
            'info_message.under_review'
          ),
          [VerificationItemStatus.DENY]: t('info_message.failed.support'),
          [VerificationItemStatus.KYC_RESTRICTION]: t(
            'info_message.kyc_restriction'
          ),
          [VerificationItemStatus.CHANGE_CITIZENSHIP_REQUIRED]: t(
            'info_message.failed.try_again'
          ),
          [VerificationItemStatus.ADDITIONAL_DOCS_REQUIRED]: t(
            'info_message.failed.try_again'
          ),
        } as {[key in VerificationItemStatus]?: string}
      )[status];
    }

    return message;
  }, [status, accountVerificationCurrent]);

  const testID = `INFO_MESSAGE_${accountVerificationCurrent!.toUpperCase()}_${status.toUpperCase()}`;

  return (
    <TouchableBanner
      type="icon"
      testID={testID}
      variant={variant}
      iconName={icon}
      text={text}
      onPress={verificationCodeActions[accountVerificationCurrent!]}
      {...props}
    />
  );
});
