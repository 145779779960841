import * as React from 'react';
import {observer} from 'mobx-react';
import {useHistory} from 'react-router';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {initialize, identify, trackPageView} from './utils';

export const SiftInitializer = observer(() => {
  const {beaconKey} = ENVIRONMENT.SIFT_CONFIG;

  const {id, sessionId} = AuthMeResource.use({});

  const {listen} = useHistory();

  React.useEffect(() => {
    initialize(beaconKey);
  }, [beaconKey]);

  React.useEffect(() => {
    identify({
      id,
      sessionId,
    });
  }, [id, sessionId]);

  React.useEffect(() => {
    trackPageView();

    const unregister = listen(trackPageView);

    return () => {
      unregister();
    };
  }, []);

  return null;
});
