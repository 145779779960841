import * as React from 'react';
import {observer} from 'mobx-react';
import {
  type BoxProps,
  useIsMobile,
  Box,
  Heading,
  Text,
} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LevelsSlider} from './LevelsSlider';
import {LevelsStack} from './LevelsStack';

export const LevelsNew: React.FC<BoxProps> = observer(boxProps => {
  const isMobile = useIsMobile();
  const {t} = useTranslation();

  return (
    <Box {...boxProps} testID="MINER_LEVELS">
      <Box mb={20} testID="MINER_LEVELS_DESCRIPTION">
        <Heading variant="$heading-02" color="$text-02" mb={8}>
          {t('surface.miner.cards.title')}
        </Heading>
        <Text color="$text-02">{t('surface.miner.cards.description')}</Text>
      </Box>

      {isMobile ? <LevelsSlider /> : <LevelsStack />}
    </Box>
  );
});
