import * as React from 'react';
import {useEffect} from 'react';
import {Box, Text} from '@youtoken/ui.primitives';
import {TextInput} from '@youtoken/ui.inputs';
import {useCombobox} from 'downshift';
import {SearchIcon} from './SearchIcon';
import {ToggleIcon} from './ToggleIcon';
import {ComboboxItemsList} from './ItemsList/index.web';
import {
  type IComboboxProps,
  type IComboboxItem,
  type ItemComponent,
} from './types';
import {matchSort} from './matchSort';
import {EmptyState} from './EmptyState';

const DefaultItemComponent: ItemComponent = React.forwardRef<
  unknown,
  React.ComponentProps<ItemComponent>
>(({highlighted, label, ...props}, ref) => {
  return (
    <Box
      {...props}
      height={32}
      justifyContent="center"
      bg={highlighted ? '$interactive-02' : '$ui-background'}
      px={16}
      ref={ref}
    >
      <Text accessible={false}>{label}</Text>
    </Box>
  );
});

export const Combobox: React.FC<IComboboxProps> = ({
  disabled,
  hasError,
  items,
  itemHeight,
  maxItemsAtATime,
  placeholder,
  initialIsOpen = false,
  ItemComponent = DefaultItemComponent,
  onChange,
  onFocus,
  onBlur,
  onOpen,
  onClose,
  value,
  nothingFoundMessage,
  nothingFoundItemKey,
  zIndex,
  size,
  testID = 'COMBOBOX',
  variant = 'default',
}) => {
  const [inputItems, setInputItems] = React.useState(items);

  useEffect(() => {
    setInputItems(
      matchSort(items, textInputValue, ['value', 'label', 'keywords'])
    );
  }, [items]);

  const handleSearch = React.useCallback(
    ({inputValue}: {inputValue?: string}) => {
      const newItems = matchSort(items, inputValue, [
        'value',
        'label',
        'keywords',
      ]);

      if (newItems.length > 0 || !nothingFoundItemKey) {
        setInputItems(newItems);
        return;
      }

      if (newItems.length <= 0 && nothingFoundItemKey) {
        const itemsToSet = [];
        const nothingFoundItem = items.find(i => i.key === nothingFoundItemKey);
        if (nothingFoundItem) {
          itemsToSet.push(nothingFoundItem);
        }
        setInputItems(itemsToSet);
        return;
      }
    },
    [items, nothingFoundItemKey]
  );

  const handleIsOpenChange = React.useCallback(
    ({isOpen}: {isOpen?: boolean}) => {
      if (isOpen) {
        onOpen?.();
      } else {
        onClose?.();
      }
    },
    [onOpen, onClose]
  );

  const {
    isOpen,
    openMenu,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    toggleMenu,
  } = useCombobox<IComboboxItem>({
    items: inputItems,
    selectedItem: items.find(i => i.value === value) ?? null,
    getItemId: index => items[index]!.key,
    itemToString: item => item?.label ?? '',
    onInputValueChange: handleSearch,
    onSelectedItemChange: ({selectedItem}) => {
      onChange?.(selectedItem?.value);
      setTextInputValue(selectedItem?.label);
    },
    onIsOpenChange: handleIsOpenChange,
    initialIsOpen: initialIsOpen,
  });

  const [textInputValue, setTextInputValue] = React.useState<
    string | undefined
  >('');

  const getCurrentLabel = () => {
    return items.find(i => i.value === value)?.label ?? '';
  };

  React.useEffect(() => {
    setTextInputValue(getCurrentLabel());
  }, [setTextInputValue]);

  const onTextInputChange = React.useCallback(
    (newValue: string) => {
      setTextInputValue(newValue);
    },
    [setTextInputValue]
  );

  const handleFocus = React.useCallback(() => {
    if (!isOpen) {
      handleSearch({inputValue: textInputValue}); // https://youhodler.atlassian.net/browse/USER-934
      openMenu();
    }
    onFocus?.();
  }, [isOpen, onFocus, handleSearch, textInputValue]);

  const {onKeyDown, ...inputProps} = getInputProps(
    {
      onFocus: handleFocus,
      onBlur: onBlur,
    },
    {suppressRefError: true}
  );

  const handleBlur = React.useCallback(() => {
    onChange?.(value);
    setTextInputValue(getCurrentLabel());
    onBlur?.();
  }, [value, onBlur, onChange, getCurrentLabel, setTextInputValue]);

  return (
    <Box zIndex={zIndex} width="100%">
      <Box {...getComboboxProps()} testID={testID}>
        <TextInput
          {...inputProps}
          value={textInputValue}
          onChangeText={onTextInputChange}
          onBlur={handleBlur}
          disabled={disabled}
          hasError={hasError}
          placeholder={placeholder}
          size={size}
          onKeyPress={onKeyDown}
          LeftPartComponent={isOpen ? <SearchIcon /> : null}
          testID={`${testID}_INPUT`}
          variant={variant}
          RightPartComponent={
            <ToggleIcon
              isOpen={isOpen}
              toggleMenu={toggleMenu}
              disabled={disabled}
              closeButtonTestID={`${testID}_CLOSE_BUTTON`}
              variant={variant}
            />
          }
        />
      </Box>

      <ComboboxItemsList
        {...getMenuProps()}
        isOpen={isOpen}
        itemHeight={itemHeight}
        maxItemsAtATime={maxItemsAtATime}
      >
        {isOpen && (
          <>
            {inputItems.length > 0 &&
              inputItems.map((item, index) => (
                <ItemComponent
                  highlighted={highlightedIndex == index}
                  testID={`${testID}_ITEM_${index}`}
                  {...getItemProps({item, index})}
                  {...item}
                />
              ))}
            {inputItems.length <= 0 && (
              <EmptyState message={nothingFoundMessage} />
            )}
          </>
        )}
      </ComboboxItemsList>
    </Box>
  );
};
