import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Modal} from '../_common';
import {PendingFriendModalContent} from '@youtoken/ui.surface-new-referral';
import {NewReferralActivity} from '@youtoken/ui.resource-new-referral';

export const modalPendingFriendName = 'modalPendingFriend';

interface ModalPendingFriendProps extends React.ComponentProps<typeof Modal> {
  activity: NewReferralActivity;
}

export const ModalPendingFriend: React.FC<ModalPendingFriendProps> = cell(
  ({activity, ...modalProps}) => {
    return (
      <Modal {...modalProps}>
        <PendingFriendModalContent p={24} activity={activity} />
      </Modal>
    );
  }
);
