import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {PromoBanner} from '@youtoken/ui.elements';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('AccountLevelAllBenefits', {
    level: 5,
  });
};

export const AccountLevelsMarketAnalytics: React.FC<BoxProps> = observer(
  boxProps => {
    const {t} = useTranslation();

    const {currentLevel} = LoyaltyResource.use({});

    if (currentLevel > 4) {
      return null;
    }

    return (
      <Box {...boxProps}>
        <PromoBanner
          testID="EXTENSIVE_MARKET_ANALYTICS_BANNER"
          imageName="marketAnalytics"
          text={t('surface.loyalty.market_analytics_banner.text')}
          onPressBanner={handlePress}
        />
      </Box>
    );
  }
);
