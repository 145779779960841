import React from 'react';
import {observer} from 'mobx-react';
import {
  NotificationType,
  type NotificationTypeId,
} from '@youtoken/ui.resource-notifications';
import {RewardsWelcomeModal} from '@youtoken/ui.surfaces-referral-program';
import {UpgradeToLevelModal} from '@youtoken/ui.surface-account-levels';
import {OnboardingStepPerksCongratsModal} from '@youtoken/ui.surfaces-wallets';
import {
  type TPerkItem,
  type OnboardingStep,
} from '@youtoken/ui.resource-onboarding-step-perks';
import {NewInviteeModal} from '@youtoken/ui.surface-new-referral';
import {type ModalNotificationProps} from './types';

export const ModalNotification: React.FC<ModalNotificationProps> = observer(
  ({data, onShow, onClose}) => {
    const {notificationId, type, payload, typeId} = data;
    const handleShow = React.useCallback(() => {
      onShow?.(notificationId);
    }, [notificationId, onShow]);

    const handleClose = React.useCallback(() => {
      onClose?.(notificationId);
    }, [notificationId, onClose]);

    React.useEffect(() => {
      handleShow();
    }, [handleShow]);

    if (type === NotificationType.REWARDS) {
      return <RewardsWelcomeModal isOpen={true} onClose={handleClose} />;
    }

    if (type === NotificationType.LOYALTY) {
      if (!payload || !('level' in payload)) {
        return null;
      }

      return (
        <UpgradeToLevelModal
          levelNumber={payload.level!}
          isOpen={true}
          onClose={handleClose}
        />
      );
    }

    if (type === NotificationType.CONGRATULATIONS && typeId) {
      const typeIdToVariant: Record<NotificationTypeId, OnboardingStep> = {
        'VERIFICATION.PASS': 'verification',
        'DEPOSIT.FIRST.REWARDS': 'deposit',
        'FIRST.TRADE': 'trade',
      };

      return (
        <OnboardingStepPerksCongratsModal
          data={payload as TPerkItem[]}
          variant={typeIdToVariant[typeId]}
          notificationId={notificationId}
          onClose={handleClose}
        />
      );
    }

    if (type === NotificationType.REFERRAL) {
      if (
        !payload ||
        !('amount' in payload) ||
        typeof payload.amount === 'undefined'
      ) {
        return null;
      }

      return (
        <NewInviteeModal
          amount={payload.amount}
          isOpen={true}
          onClose={handleClose}
        />
      );
    }

    return null;
  }
);
