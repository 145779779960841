import {computed, observable, action} from 'mobx';
import {deserialize} from 'serializr';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {NewReferralJoinedResponse} from './NewReferralJoinedResponse';
import {DEFAULT_LIMIT, DEFAULT_OFFSET} from '../constants';

export interface NewReferralJoinedResourceArgs {
  limit?: number;
  offset?: number;
}

export class NewReferralJoinedResource extends createStaticResource({
  getKey: ({}: NewReferralJoinedResourceArgs) => `newReferralJoinedResource`,
  getData: ({
    limit = DEFAULT_LIMIT,
    offset = DEFAULT_OFFSET,
  }: NewReferralJoinedResourceArgs) =>
    TRANSPORT.API.get('/v3/referral/joined', {params: {limit, offset}}).then(
      res => deserialize(NewReferralJoinedResponse, res.data)
    ),
}) {
  @computed get joined() {
    return this.data.joined;
  }

  @computed get total() {
    return this.data.total;
  }

  // Pagination

  @computed
  public get pageSize() {
    return this.args.limit || DEFAULT_LIMIT;
  }

  @computed
  public get totalPages() {
    return Math.ceil(this.total / this.pageSize);
  }

  @observable
  currentPage: number = 1;

  @action setPage = (page: number) => {
    const offset = this.pageSize * (page - 1);
    this.currentPage = page;

    if (offset > this.total) {
      return;
    }

    this.updateArgs({offset});
  };

  // Pagination end

  @action updateArgs = (newArgs: Partial<NewReferralJoinedResourceArgs>) => {
    this.args = {
      ...this.args,
      ...newArgs,
    };
  };
}
