import {WebAppEnv} from '@youtoken/ui.env-utils';

export const getWebAppEnv = (): WebAppEnv => {
  const host = window.location.host;

  if (host.includes('ledgerlive.youhodler')) {
    return WebAppEnv['ledger-app'];
  }

  if (host.includes('ramp')) {
    return WebAppEnv['ramp-app'];
  }

  return WebAppEnv['web-app'];
};
