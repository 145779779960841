import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {RewardBox} from '@youtoken/ui.elements';
import {Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';

type MinerResultBlockProps = {
  sparks: number;
  onButtonPress: () => void;
};

export const MinerResultBlock: React.FC<MinerResultBlockProps> = observer(
  ({sparks, onButtonPress}) => {
    const {t} = useTranslation();

    return (
      <RewardBox
        title={t('surface.wallets.first_action_modal.block_miner.title', {
          count: sparks,
        })}
        iconComponentName="Icon"
        iconName="spark"
        iconColor="$attention-01"
        iconBgColor="$attention-02"
        borderColor="$attention-03"
        text={t('surface.wallets.first_action_modal.block_miner.subtitle')}
      >
        <Box mt={16} width="100%">
          <Button
            onPress={onButtonPress}
            testID="FIRST_ACTION_PERKS_CONGRATS_MINER_RESULT_BUTTON"
          >
            {t('surface.wallets.first_action_modal.block_miner.result.button')}
          </Button>
        </Box>
      </RewardBox>
    );
  }
);
