import {invariant} from '../../invariant';

declare global {
  interface Window {
    dataLayer?: Array<any>;
  }
}

export const getDataLayer = () => {
  invariant(window, 'window object is not available');

  window.dataLayer = window.dataLayer ?? [];

  return window.dataLayer;
};
