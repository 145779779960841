import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box, Text, Heading, type BoxProps} from '@youtoken/ui.primitives';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {MinerImage} from './MinerImage';

type NewInviteeModalContentProps = {amount: number; onClose: () => void};

export const NewInviteeModalContent: React.FC<
  NewInviteeModalContentProps & BoxProps
> = cell(({amount, onClose, ...boxProps}) => {
  const {t} = useTranslation();
  const handlePress = () => {
    onClose();
    SHARED_ROUTER_SERVICE.navigate('Miner');
  };

  return (
    <Box flexDirection="column" alignItems="center" {...boxProps}>
      <MinerImage />

      <Heading variant="$heading-01" mt={16} mb={8} textAlign="center">
        <Trans
          t={t}
          i18nKey="surface.new_referral.invitee_notification.title"
          values={{amount}}
          components={{
            Strong: (
              <Heading
                color="$attention-01"
                variant="$heading-01"
                textAlign="center"
              />
            ),
          }}
        />
      </Heading>
      <Text color="$text-02" mb={32}>
        {t('surface.new_referral.invitee_notification.description')}
      </Text>

      <Button
        icon="gift"
        size="large"
        alignSelf="stretch"
        onPress={handlePress}
        testID="CLAIM_REWARD_BUTTON"
      >
        {t('surface.new_referral.invitee_notification.action')}
      </Button>
    </Box>
  );
});
