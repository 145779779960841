import * as React from 'react';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {MarketsResource} from '@youtoken/ui.resource-markets';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {ExchangeTariffsOnRampResource} from '@youtoken/ui.resource-exchange-tariffs';
import {FeeAllResource} from '@youtoken/ui.resource-fee-all';
import {CardsResource} from '@youtoken/ui.resource-cards';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {createWizard} from '@youtoken/ui.elements';
import {makeForm, Form} from '@youtoken/ui.form-elements';
import {BuyCryptoFormAuthorizedOnRampState} from '../../../state';
import {NotEnoughData} from '../../NotEnoughData';
import {BuyCryptoFormAuthorizedOnRampMain} from './Main';
import {BuyCryptoFormAuthorizedOnRampChooseMethod} from './ChooseMethod';
import {CardDepositAddCard, CardDepositPaymentMethods} from '../common';
import {BuyCryptoFormAuthorizedOnRampLater} from './Later';

export type BuyCryptoFormAuthorizedOnRampWizardNavigatorParams = {
  Main: {};
  ChooseMethod: {};
  Later: {
    ticker: string;
    method: string;
  };
  PaymentMethods: {};
  AddCard: {};
};

const Wizard =
  createWizard<BuyCryptoFormAuthorizedOnRampWizardNavigatorParams>();

interface BuyCryptoFormAuthorizedOnRampFormProps {
  showHeader?: boolean;
  conversionTicker?: string;
  wallet?: string;
  network?: string;
  memoId?: string;
  onYHBalanceConvertSuccess: () => void;
  onPressBack?: () => void;
}

const BuyCryptoFormAuthorizedOnRampForm: React.FC<
  BuyCryptoFormAuthorizedOnRampFormProps & BoxProps
> = cell(
  ({
    conversionTicker,
    wallet,
    network,
    memoId,
    onYHBalanceConvertSuccess,
    onPressBack,
  }) => {
    const {t} = useTranslation();
    const {residenceOrCountry} = AuthMeResource.use({});

    const resources = useResources({
      authMeResource: getResourceDescriptor(AuthMeResource, {}),
      verificationResource: getResourceDescriptor(VerificationResource, {}),
      docsResource: getResourceDescriptor(DocsResource, {
        country: residenceOrCountry,
      }),
      walletsResource: getResourceDescriptor(WalletsResource, {}),
      marketsResource: getResourceDescriptor(MarketsResource, {}),
      ratesResource: getResourceDescriptor(RatesResource, {}),
      exchangeTariffs: getResourceDescriptor(ExchangeTariffsOnRampResource, {}),
      fees: getResourceDescriptor(FeeAllResource, {}), // for deposit method
      cardsResource: getResourceDescriptor(CardsResource, {}),
    });

    const form = makeForm(
      () =>
        new BuyCryptoFormAuthorizedOnRampState(
          {
            conversionTicker,
            wallet,
            network,
            memoId,
            onYHBalanceConvertSuccess,
          },
          resources
        )
    );

    return (
      <Form form={form}>
        <Wizard.Navigator initialName="Main" goBack={onPressBack}>
          <Wizard.Screen
            name="Main"
            component={BuyCryptoFormAuthorizedOnRampMain}
          />
          <Wizard.Screen
            name="ChooseMethod"
            component={BuyCryptoFormAuthorizedOnRampChooseMethod}
          />
          <Wizard.Screen
            name="Later"
            component={BuyCryptoFormAuthorizedOnRampLater}
          />
          <Wizard.Screen
            name="PaymentMethods"
            options={{title: t('surface.wallets.payment_methods')}}
            component={CardDepositPaymentMethods}
          />
          <Wizard.Screen
            name="AddCard"
            options={{title: t('surface.wallets.add_card')}}
            component={CardDepositAddCard}
          />
        </Wizard.Navigator>
      </Form>
    );
  },
  {
    CellWrap: props => {
      return <Box flex={1} minHeight={420} {...props} />;
    },
  }
);

export const BuyCryptoFormAuthorizedOnRamp: typeof BuyCryptoFormAuthorizedOnRampForm =
  props => {
    if (!props.conversionTicker || !props.wallet || !props.network) {
      return <NotEnoughData />;
    }

    return <BuyCryptoFormAuthorizedOnRampForm {...props} />;
  };
