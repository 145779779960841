import {Platform} from 'react-native';

const getSiftCredentials = (dev: boolean) => {
  if (dev) {
    return {
      accountId: '66d8685813f9f94706af96f9',
      beaconKey: '7353f26681',
    };
  }

  return {
    accountId: '66d8685813f9f94706af96f6',
    beaconKey: '1b853af9f3',
  };
};

export const getSiftConfig = (dev: boolean) => {
  const {accountId, beaconKey} = getSiftCredentials(dev);

  return {
    accountId,
    beaconKey,
    disallowCollectingLocationData: true,
    serverUrlFormat:
      Platform.OS === 'ios'
        ? 'https://api3.siftscience.com/v3/accounts/%@/mobile_events'
        : 'https://api3.siftscience.com/v3/accounts/%s/mobile_events',
  };
};
