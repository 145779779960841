import React from 'react';
import {modal} from '../../../stores';
import {Modal} from '../_common';
import {IncentivesDetailedModal} from '@youtoken/ui.surfaces-wallets';

export const modalIncentivesDetailsName = 'incentivesDetails';

export const ModalIncentivesDetails: React.FC<
  React.ComponentProps<typeof Modal>
> = ({...modalProps}) => {
  const {opened, close} = modal;

  return (
    <IncentivesDetailedModal
      isOpen={opened?.name === modalIncentivesDetailsName}
      onClose={close}
      {...modalProps}
    />
  );
};
