import {FeaturesResource} from '@youtoken/ui.resource-features';
import {useDataV1} from './v1';
import {useDataV2} from './v2';

export const useData = () => {
  const {isNewOnboardingEnabled} = FeaturesResource.use({});

  if (isNewOnboardingEnabled) {
    return useDataV2();
  }

  return useDataV1();
};
