import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, useIsMobile} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Select} from '@youtoken/ui.select';
import {type currencyName, getCoinName} from '@youtoken/ui.coin-utils';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {MenuItemBase} from '../MenuItemBase';
import {MainCurrencyFeature} from '../../state/MainCurrencyFeature';

export const SelectCurrency: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();

  const {mainCurrency, setMainCurrency} = MainCurrencyFeature.use({});

  const currencyItems = [
    {value: 'eur', label: getCoinName('eur')},
    {value: 'usd', label: getCoinName('usd')},
  ];

  const handleSelect = (ticker: string) => {
    DATA_LAYER.trackStrict('wallet-set-main-currency-attempt', {ticker});
    setMainCurrency(ticker as currencyName);
  };

  const Selector = React.useMemo(
    () => (
      <Box>
        <Select
          size={isMobile ? 'small' : 'medium'}
          width={{default: 120, tablet: 140}}
          items={currencyItems}
          onSelect={handleSelect}
          selected={mainCurrency}
          testID="CURRENCY_SELECT"
        />
      </Box>
    ),
    [currencyItems, handleSelect, mainCurrency]
  );

  return (
    <MenuItemBase
      zIndex={11}
      title={t('surface.more.item_name.currency')}
      icon="currency"
      RightPartComponentWeb={Selector}
      RightPartComponentNative={Selector}
      {...boxProps}
    />
  );
});
