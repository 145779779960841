import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {CardItem} from '@youtoken/ui.resource-cards';
import {Button} from '@youtoken/ui.buttons';
import {Select, type SelectItem} from '@youtoken/ui.select';
import {Icon, IconPaymentMethod} from '@youtoken/ui.icons';

interface CardSelectorProps {
  onPressAddNewCard: () => void;
  cardsList: CardItem[];
  onSelectCard: (id: string) => void;
  onPressManage: () => void;
  selectedCardId?: string;
}

export const CardSelector: React.FC<CardSelectorProps & BoxProps> = observer(
  ({
    onPressAddNewCard,
    cardsList,
    onSelectCard,
    onPressManage,
    selectedCardId,
    ...boxProps
  }) => {
    const {t} = useTranslation();
    const isListEmpty = cardsList.length === 0;

    const selectItems: SelectItem[] = React.useMemo(
      () => [
        // NOTE: Main items
        ...cardsList.map(card => {
          const cardBrandFormatted = card.brand.toLowerCase() as
            | 'visa'
            | 'mastercard';

          return {
            value: card.id,
            label: `• ${card.last4}`,
            icon: (
              <Box height={20} flexDirection="row" alignItems="center">
                <IconPaymentMethod name={cardBrandFormatted} size={32} />
              </Box>
            ),
          };
        }),
        // NOTE: Action button (Manage cards)
        {
          type: 'action-button',
          label: t('surface.wallets.manage'),
          onPress: onPressManage,
        },
      ],
      [cardsList]
    );

    return (
      <Box pb={10} {...boxProps}>
        {/* Render empty state */}
        {isListEmpty && (
          <>
            <Box
              p={16}
              borderWidth={1}
              borderRadius={6}
              borderColor="$ui-01"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              testID="EMPTY_CARDS_STATE"
            >
              <Icon name="card" color="$text-02"></Icon>
              <Text ml={8} variant="$body-02" color="$text-02">
                {t('surface.wallets.checout_bank_card.no_cards_disclosure')}
              </Text>
            </Box>

            <Box
              pt={16}
              flexDirection="row"
              justifyContent={isListEmpty ? 'center' : 'space-between'}
            >
              <Button
                icon="plus"
                width={isListEmpty ? '100%' : 'auto'}
                type={isListEmpty ? 'primary' : 'secondary'}
                size={isListEmpty ? 'large' : 'small'}
                pr={isListEmpty ? 20 : 12}
                onPress={onPressAddNewCard}
                testID="ADD_CARD_BUTTON"
              >
                {t('surface.wallets.checout_bank_card.add_new_card_action')}
              </Button>
            </Box>
          </>
        )}

        {/* Render card selector */}
        {!isListEmpty && (
          <Select
            bottomSheetTitle={t(
              'surface.wallets.checout_bank_card.select_card_action'
            )}
            items={selectItems}
            onSelect={onSelectCard}
            selected={selectedCardId}
            size="large"
          />
        )}
      </Box>
    );
  }
);
