import * as React from 'react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {cell} from '@youtoken/ui.cell';
import {type BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {type ActionProps} from '../types';
import {getActionButtonPropsByVariant} from '../utils';
import {WalletActionCellWrap} from './WalletActionCellWrap';
import {WalletActionSuspenseFallback} from './WalletActionSuspenseFallback';
import {WalletActionErrorFallback} from './WalletActionErrorFallback';

export const Deposit: React.FC<ActionProps & BoxProps> = cell(
  ({
    tooltip,
    variant,
    item: {isFiat, ticker, tickerFormatted, depositEnabled},
    ...boxProps
  }) => {
    const {walletsDisclosureSigned, checkProductAvailability} =
      AuthMeResource.use({});

    const handlePressFiat = React.useCallback(() => {
      return SHARED_ROUTER_SERVICE.navigate('FiatDeposit', {ticker});
    }, [ticker]);

    const handlePressCrypto = React.useCallback(() => {
      if (!checkProductAvailability('depositCrypto')) {
        return;
      }

      if (!walletsDisclosureSigned) {
        return SHARED_ROUTER_SERVICE.navigate('DisclosureWalletsModal', {});
      }

      SHARED_ROUTER_SERVICE.navigate('CryptoDeposit', {ticker});
    }, [ticker, walletsDisclosureSigned, checkProductAvailability]);

    const handlePress = React.useCallback(() => {
      if (isFiat) {
        return handlePressFiat();
      }

      return handlePressCrypto();
    }, [isFiat, handlePressFiat, handlePressCrypto]);

    return (
      <Button
        testID={`${tickerFormatted}_DEPOSIT_BUTTON`}
        icon={isFiat ? 'fiat_deposit' : 'crypto_deposit'}
        disabled={!depositEnabled}
        onPress={handlePress}
        {...getActionButtonPropsByVariant(variant)}
        {...boxProps}
      />
    );
  },
  {
    CellWrap: WalletActionCellWrap,
    SuspenseFallback: WalletActionSuspenseFallback,
    ErrorFallback: WalletActionErrorFallback,
  }
);
