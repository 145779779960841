import * as React from 'react';
import {Box, TouchableBox} from '@youtoken/ui.primitives';
import {FreeBitcoinsContent} from '../components/FreeBitcoins/FreeBitcoinsContent';

interface TitleTooltipProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const TitleTooltip: React.FC<TitleTooltipProps> = ({
  isOpen,
  handleClose,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <>
      <TouchableBox
        width="100vw"
        height="100vh"
        // @ts-ignore web only
        style={{position: 'fixed'}}
        left={0}
        top={0}
        onPress={handleClose}
      />
      <Box
        position="absolute"
        top={45}
        left={74}
        backgroundColor="$interactive-01"
        width={280}
        p={20}
        borderRadius={16}
        zIndex={10}
        testID="MINER_TITLE_TOOLTIP"
      >
        <Box
          backgroundColor="$interactive-01"
          position="absolute"
          top={-6}
          left={132}
          width={16}
          height={16}
          borderRadius={4}
          style={{
            transform: [{rotate: '45deg'}],
          }}
        />
        <FreeBitcoinsContent onClose={handleClose} />
      </Box>
    </>
  );
};
