import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {Switch} from '@youtoken/ui.elements';
import {ENVIRONMENT} from '@youtoken/ui.environment';

export const Settings = observer(() => {
  return (
    <>
      <Box
        py={8}
        justifyContent="space-between"
        flexDirection="row"
        borderBottomWidth={1}
        borderBottomColor="$ui-01"
        px={{default: 16, phone: 24}}
      >
        <Text variant="$body-02-high-accent" color="$text-02">
          Settings
        </Text>
      </Box>

      <Box
        py={16}
        justifyContent="space-between"
        flexDirection="row"
        borderBottomWidth={1}
        borderBottomColor="$ui-01"
        px={{default: 16, phone: 24}}
      >
        <Text variant="$body-01-high-accent">Skip Recaptcha</Text>
        <Switch
          value={ENVIRONMENT.SKIP_RECAPTCHA}
          onValueChange={value => {
            ENVIRONMENT.setSkipRecaptcha(value);
          }}
        />
      </Box>
    </>
  );
});
