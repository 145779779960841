import * as React from 'react';
import {observer} from 'mobx-react';
import {Text, TouchableBox, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {MinerOverviewResource} from '@youtoken/ui.resources-miner';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {GetMoreSparksModal, ConfirmationModal} from '.';

type ResetButtonProps = {} & BoxProps;

export const ResetButton: React.FC<ResetButtonProps> = observer(() => {
  const {t} = useTranslation();

  const {finalSparkBalance, resetCost} = MinerOverviewResource.use({});

  const [isMoreSparksModalOpen, setMoreSparksModalOpen] = React.useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] =
    React.useState(false);

  const openMoreSparksModal = React.useCallback(() => {
    setMoreSparksModalOpen(true);
  }, [setMoreSparksModalOpen]);

  const onMoreSparksModalClose = React.useCallback(() => {
    setMoreSparksModalOpen(false);
  }, [setMoreSparksModalOpen]);

  const openConfirmationModal = React.useCallback(() => {
    DATA_LAYER.trackStrict('miner-board-unlock-preview', {
      category: 'miner',
      type: 'board-unlock',
    });
    setConfirmationModalOpen(true);
  }, [setConfirmationModalOpen]);

  const onConfirmationModalClose = React.useCallback(() => {
    setConfirmationModalOpen(false);
  }, [setConfirmationModalOpen]);

  const handlePress = React.useCallback(() => {
    if (finalSparkBalance < resetCost) {
      openMoreSparksModal();
    } else {
      openConfirmationModal();
    }
  }, [
    finalSparkBalance,
    resetCost,
    openMoreSparksModal,
    openConfirmationModal,
  ]);

  return (
    <>
      <TouchableBox
        testID="UNLOCK_ALL_BLOCKS_BUTTON"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        py={10}
        pl={16}
        pr={20}
        borderRadius={20}
        backgroundColor="$attention-02"
        onPress={handlePress}
      >
        <Icon name="spark" color="$attention-01" size={20} />
        <Text variant="$body-01-medium-accent" color="$attention-01" ml={4}>
          {t('surface.miner.unlock_blocks_button')}
        </Text>
      </TouchableBox>

      <GetMoreSparksModal
        title={t('surface.upgrade_modal.sparks.title')}
        isOpen={isMoreSparksModalOpen}
        onClose={onMoreSparksModalClose}
        noticeText={t('surface.miner.unlock_blocks.need_more_sparks', {
          amount: resetCost - finalSparkBalance,
        })}
      />

      <ConfirmationModal
        title={t('surface.miner.unlock_blocks_confirmation.title')}
        isOpen={isConfirmationModalOpen}
        onClose={onConfirmationModalClose}
      />
    </>
  );
});
