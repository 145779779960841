import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, Heading, type BoxProps} from '@youtoken/ui.primitives';
import {ProgressBar} from '@youtoken/ui.progress-bar';
import {useAccountLevelInfo} from './LoyaltyMiner';

export const AccountLevelInfo: React.FC<BoxProps> = observer(boxProps => {
  const info = useAccountLevelInfo();

  if (!info) {
    return null;
  }

  const {textColor, title, description, progress, progressText} = info;

  return (
    <Box
      flexDirection="column"
      borderWidth={1}
      borderColor="$ui-03"
      borderRadius={16}
      {...boxProps}
    >
      <Heading
        variant="$heading-02"
        color={textColor}
        mb={8}
        testID="MINER_LEVEL_INFO_TITLE"
      >
        {title}
      </Heading>
      <Text color={textColor} testID="MINER_LEVEL_INFO_DESCRIPTION">
        {description}
      </Text>
      {typeof progress === 'number' && (
        <Box mt={8}>
          <Text
            color={textColor}
            variant="$body-01-high-accent"
            mb={2}
            testID="MINER_LEVEL_INFO_PROGRESS_TEXT"
          >
            {progressText}
          </Text>
          <ProgressBar
            testID="MINER_LEVEL_INFO_PROGRESS_BAR"
            color="attention"
            progress={progress}
          />
        </Box>
      )}
    </Box>
  );
});
