import * as React from 'react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {GrowthAccountWidget} from '../../../../GrowthAccountWidget';
import {BonusesWidget} from '../../../../BonusesWidget';

export const WidgetsBar: React.FC<BoxProps> = boxProps => {
  return (
    <Box flexDirection="row" flex={1} gap={12} {...boxProps}>
      <GrowthAccountWidget flex={1} />
      <BonusesWidget flex={1} />
    </Box>
  );
};
