import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {Badge} from '@youtoken/ui.elements';
import {AccountLevelInfoItemTitle} from '../../../components/AccountLevelInfoItem/Title';

export interface LevelInfoFootbalTicketsProps {
  level: number;
}

export const LevelInfoFootbalTickets: React.FC<
  LevelInfoFootbalTicketsProps & BoxProps
> = observer(({level, testID = 'LEVEL_INFO_FOOTBAL_TICKETS', ...boxProps}) => {
  const {t} = useTranslation();

  const {getLevel} = LoyaltyResource.use({})!;

  const {status} = getLevel(level)!;

  return (
    <Box testID={testID} gap={8} {...boxProps}>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <AccountLevelInfoItemTitle icon="torino_fc">
          {t('surface.loyalty.footbal_tickers')}
        </AccountLevelInfoItemTitle>
        <Box justifyContent="center" alignItems="flex-end" ml={4}>
          <Badge
            testID={`${testID}_BADGE`}
            variant={status === 'completed' ? 'locked' : 'attention'}
            size="small"
            borderRadius={12}
          >
            <Text variant="$body-02">
              {t('surface.loyalty.footbal_tickers.ends_soon')}
            </Text>
          </Badge>
        </Box>
      </Box>
      <Box testID={`${testID}_DESCRIPTION`} gap={8}>
        <Text variant="$body-02" color="$text-02">
          {t('surface.loyalty.footbal_tickers.description.p1')}
        </Text>
        <Text variant="$body-02" color="$text-02">
          {t('surface.loyalty.footbal_tickers.description.p2')}
        </Text>
      </Box>
    </Box>
  );
});
