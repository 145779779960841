import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {MinerFeature} from '@youtoken/ui.resources-miner';
import {Box} from '@youtoken/ui.primitives';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {
  HexGrid,
  GetMoreSparksModal,
  Overview,
  ReferralBanner,
  LevelsNew,
  AccountLevelInfo,
  NextAction,
} from '../components';
import {HowItWorksBanner} from './HowItWorksBanner';

export const MinerSurface: React.FC = observer(() => {
  const {t} = useTranslation();

  const [isModalOpen, setModalOpen] = React.useState(false);
  const [sparksShort, setSparksShort] = React.useState(0);

  const {
    resources: {
      authMe: {
        data: {
          clientSettings: {hasSeenMinerUpgradeModalFirstTime},
        },
        newReferralEnabled,
        setHasSeenMinerUpgradeModalFirstTime,
      },
      loyalty: {
        data: {currentLevel},
      },
      minerOverview: {finalSparkBalance},
    },
  } = MinerFeature.use({});

  const openModal = React.useCallback(
    (miningPrice: number) => {
      if (currentLevel < 3) {
        SHARED_ROUTER_SERVICE.navigate('UpgradeYourLevel');
        return;
      }
      const sparksShortToMine = miningPrice - finalSparkBalance;
      setSparksShort(sparksShortToMine);
      setModalOpen(true);
    },
    [finalSparkBalance, setSparksShort, currentLevel, setModalOpen]
  );

  const handleNotEnoughSparks = React.useCallback(
    (miningPrice: number) => {
      openModal(miningPrice);
    },
    [openModal]
  );

  const closeModal = React.useCallback(() => {
    setModalOpen(false);
  }, []);

  React.useEffect(() => {
    if (currentLevel >= 3 && !hasSeenMinerUpgradeModalFirstTime) {
      setModalOpen(true);
      setHasSeenMinerUpgradeModalFirstTime();
    }
  }, []);

  return (
    <>
      <Box
        flex={1}
        mx={{default: 20, desktop: 0}}
        mt={{default: 16, desktop: 0}}
        mb={20}
        borderColor="$ui-03"
        borderWidth={{default: 0, tablet: 1}}
        borderRadius={16}
        zIndex={1}
      >
        <Box
          alignItems="center"
          width="100%"
          p={{default: 0, tablet: 20}}
          pt={{default: 0, tablet: 16}}
          pb={{default: 16, tablet: 56}}
          borderColor="$ui-01"
        >
          {newReferralEnabled && <ReferralBanner mb={16} mt={-16} />}

          <Overview zIndex={1} px={0} />

          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            width={{default: 360, tablet: 380}}
            height={{default: 360, tablet: 380}}
            mt={{default: -16, tablet: -32}}
          >
            <HexGrid onNotEnoughSparks={handleNotEnoughSparks} />
          </Box>

          <NextAction
            openGetMoreSparksModal={openModal}
            mt={{default: -8, tablet: 0}}
            mb={{default: -4, tablet: 0}}
          />
        </Box>
      </Box>

      <HowItWorksBanner
        mb={{default: 20, tablet: 0}}
        mx={{default: 20, desktop: 0}}
        px={20}
        pb={20}
        borderColor="$ui-03"
        borderRadius={16}
      />

      <Box
        borderColor="$ui-03"
        borderRadius={16}
        borderWidth={{default: 1, tablet: 0}}
        mb={{default: 20, tablet: 0}}
        mx={{default: 20, tablet: 0}}
        px={{default: 20, tablet: 0}}
        py={{default: 16, tablet: 20}}
      >
        <LevelsNew
          flexDirection="column"
          borderRadius={16}
          borderColor="$ui-03"
          borderWidth={{default: 0, tablet: 1}}
          p={{default: 0, tablet: 20}}
          pb={{default: 0, tablet: 10}}
        />

        <AccountLevelInfo
          mt={{default: 16, tablet: 20}}
          px={{default: 0, tablet: 20}}
          py={{default: 0, tablet: 20}}
          mb={4}
          borderWidth={{default: 0, tablet: 1}}
        />
      </Box>

      <GetMoreSparksModal
        title={t('surface.upgrade_modal.sparks.title')}
        isOpen={isModalOpen}
        onClose={closeModal}
        noticeText={
          sparksShort > 0
            ? t('surface.miner.unlock_blocks.need_more_sparks_to_mine', {
                count: sparksShort,
              })
            : undefined
        }
      />
    </>
  );
});
