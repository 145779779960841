import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Modal} from '../_common';
import {InviteFriendsModalContent} from '@youtoken/ui.surface-new-referral';

export const modalInviteFriendsName = 'modalInviteFriends';

export const ModalInviteFriends: typeof Modal = cell(props => {
  return (
    <Modal {...props}>
      <InviteFriendsModalContent px={24} pt={24} />
    </Modal>
  );
});
