import * as React from 'react';
import {LayoutChangeEvent} from 'react-native';
import {observer} from 'mobx-react';
import {HODLInstrumentResource} from '@youtoken/ui.resource-hodl-instrument';
import {Box, Heading, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {PerformanceItem} from './PerformanceItem/PerformanceItem';
import type {PerformanceSectionProps} from './types';

export const PerformanceSection: React.FC<PerformanceSectionProps & BoxProps> =
  observer(({baseTicker, quoteTicker, ...boxProps}) => {
    const {t} = useTranslation();
    const {filteredPerformance} = HODLInstrumentResource.use({
      baseTicker,
      quoteTicker,
    });

    const [width, setWidth] = React.useState(0);

    const handleLayout = React.useCallback((event: LayoutChangeEvent) => {
      const wrapperWidth = event.nativeEvent.layout.width;
      setWidth(Math.floor((wrapperWidth - 8 * 3) / 3)); // 8 - width of gap; 3 - 3 gaps; second 3 - 3 cards
    }, []);

    return (
      <Box px={24} py={20} {...boxProps}>
        <Box mb={16} flexDirection="row" alignItems="center">
          <Heading variant="$heading-02" mr={4}>
            {t('surface.hodls.instrument.performance_title')}
          </Heading>
          <QuestionTooltip
            content={t('surface.hodls.instrument.performance_tooltip')}
          />
        </Box>

        <Box flexDirection="row" flexWrap="wrap" m={-4} onLayout={handleLayout}>
          {filteredPerformance.map(el => {
            return (
              <PerformanceItem
                width={width}
                key={el.periodFormatted}
                periodFormatted={el.periodFormatted}
                direction={el.direction}
                amountFormatted={el.amountFormatted}
              />
            );
          })}
        </Box>
      </Box>
    );
  });
