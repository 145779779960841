import * as React from 'react';
import {observer} from 'mobx-react';
import {HODLsInstrumentsSectorsFeature} from '../../../../features/HODLsInstrumentsSectorsFeature';
import {Select} from '@youtoken/ui.select';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

const DownSortingIcon = (
  <Box flexDirection="row" mr={-8}>
    <Icon
      name="sorting"
      style={{
        transform: [{translateX: -4}],
      }}
      size={20}
    />
  </Box>
);

const UpSortingIcon = (
  <Box flexDirection="row" mr={-8}>
    <Icon
      name="sorting"
      style={{
        transform: [{rotate: '180deg'}, {translateX: 4}],
      }}
      size={20}
    />
  </Box>
);

export const SectorsInstrumentsSorter: React.FC<BoxProps> = observer(
  ({...boxProps}) => {
    const {t} = useTranslation();
    const {sortingItems, activeSortingItemValue, setActiveSortingItemValue} =
      HODLsInstrumentsSectorsFeature.use({});

    const sortingItemsForSelect = sortingItems.map(item => ({
      value: item.value,
      label: item.label,
      icon: item.direction === 'asc' ? UpSortingIcon : DownSortingIcon,
    }));

    const handlePress = React.useCallback(
      (value: string) => {
        setActiveSortingItemValue(value);
      },
      [setActiveSortingItemValue]
    );

    return (
      <Box zIndex={10} alignSelf="flex-end" {...boxProps}>
        <Select
          items={sortingItemsForSelect}
          onSelect={handlePress}
          selected={activeSortingItemValue}
          withExpandIcon={false}
          size="small"
          dropdownWidth={144}
          bottomSheetTitle={t('surface.hodls.instruments.sorter_title')}
          testID="INSTRUMENTS_SORTING_SELECT"
        />
      </Box>
    );
  }
);
