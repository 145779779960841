import * as React from 'react';
import {FlashList, ListRenderItemInfo} from '@shopify/flash-list';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {NewReferralActivity} from '@youtoken/ui.resource-new-referral';
import {JoinedListItem} from './JoinedListItem';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

type JoinedListProps = {
  activities: NewReferralActivity[];
};

const handlePressActivity = (activity: NewReferralActivity) => {
  SHARED_ROUTER_SERVICE.navigate(
    'FriendRewards',
    {name: activity.inviteeName},
    {
      id: activity.inviteeId,
    }
  );
};

const renderItem = ({item, index}: ListRenderItemInfo<NewReferralActivity>) => {
  return (
    <JoinedListItem
      activity={item}
      mb={8}
      onPress={handlePressActivity}
      testID={`JOINED_ITEM_${index}`}
    />
  );
};

export const JoinedList: React.FC<JoinedListProps & BoxProps> = ({
  activities,
  ...boxProps
}) => {
  return (
    <Box {...boxProps}>
      <FlashList
        data={activities}
        renderItem={renderItem}
        keyExtractor={item => item.inviteeId}
        estimatedListSize={{height: 740, width: 670}}
        estimatedItemSize={64}
      />
    </Box>
  );
};
