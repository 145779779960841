import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Button} from '@youtoken/ui.buttons';
import {Box, Heading, useIsMobile} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon} from '@youtoken/ui.icons';

interface FreeBitcoinsContentProps {
  onClose: () => void;
}

export const FreeBitcoinsContent: React.FC<FreeBitcoinsContentProps> = cell(
  ({onClose}) => {
    const {t} = useTranslation();
    const isMobile = useIsMobile();

    return (
      <Box alignItems="center" flex={1}>
        <Icon name="mining" size={48} color="$text-04" />
        <Heading
          variant={isMobile ? '$heading-02' : '$heading-01'}
          mt={{default: 16, tablet: 8}}
          mb={{default: 16, tablet: 24}}
          color="$text-04"
          textAlign="center"
        >
          {t('surface.miner.heading.tooltip.text')}
        </Heading>
        <Button
          type="inverted"
          onPress={onClose}
          alignSelf="stretch"
          testID="MINER_FREE_BITCOINS_CLOSE"
          size={isMobile ? 'medium' : 'large'}
        >
          {t('surface.miner.heading.tooltip.action')}
        </Button>
      </Box>
    );
  }
);
