import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {CompanyResource} from '@youtoken/ui.resource-companies';
import {LegalEntity} from '@youtoken/ui.resource-auth-me';
import {useResource} from '@youtoken/ui.data-storage';
import {Membership} from './Membership';

interface YouhodlerInfoProps extends BoxProps {
  country: string;
}

export const YouhodlerInfo: React.FC<YouhodlerInfoProps> = observer(
  ({country, ...boxProps}) => {
    const {youhodler, youhodlerITA} = useResource(CompanyResource, {});
    const company = country === LegalEntity.ITA ? youhodlerITA : youhodler;

    return (
      <Box {...boxProps}>
        <Text variant="$body-02" textTransform="uppercase" mb={12}>
          {company?.name}
        </Text>
        {Boolean(company?.address) && (
          <Text variant="$body-02" color="$text-02" mb={4}>
            {company?.address}
          </Text>
        )}
        {Boolean(company?.phone) && (
          <Text variant="$body-02" color="$text-02" mb={4}>
            {company?.phone}
          </Text>
        )}
        <Box flexDirection="row" mb={12}>
          <Link
            url={`mailto:${company?.email}`}
            variant="$body-02"
            color="$text-02"
          >
            {company?.email}
          </Link>
        </Box>

        {country !== LegalEntity.ITA && <Membership />}
      </Box>
    );
  }
);
